import { FileType } from '@srnade/component-ui';

import { PlayableTrack } from '@srnade/web/types';

export enum DndItemType {
    Track = 'Track',
    Reward = 'Reward',
}

export interface DragAndDropItem {
    fileName: string;
    readableFileSize: string;
    type: FileType;
    src: string;
    progress: number;
    name?: string;
    duration?: string;
    isrc?: string;
    playlist?: PlayableTrack[];
    audioId?: string;
    imageId?: string;
    videoId?: string;
    documentId?: string;
    otherId?: string;
}

export interface DragAndDropFormikChildProps {
    items: DragAndDropItem[];
    setItems: (value: any) => void;
    dndItemType: DndItemType;
}

export interface DragAndDropFormikChildItemProps {
    item: DragAndDropItem;
    index: number;
    progressValue: number;
    onDeleteInput: (value: any) => void;
    dndItemType: DndItemType;
}

export interface RewardItemProps {
    item: DragAndDropItem;
}

export interface TrackItemProps {
    item: DragAndDropItem;
}
