import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { Accordion, Typography } from '@srnade/component-ui';

export function Faqs() {
    const { t } = useTranslation('components', { keyPrefix: 'faqs' });

    const accordionContent: [{ title: string; content: string }] = t('content', { returnObjects: true });

    return (
        <div className="tablet:flex">
            <Typography className="mb-[3rem] tablet:mb-0 tablet:mr-[4rem] laptop:mr-[9rem]" variant="h1">
                {t('header')}
            </Typography>
            <ul className="w-full max-w-[83rem] list-none p-0">
                {accordionContent.map(({ title, content }, index) => (
                    <li key={title}>
                        <Accordion
                            title={title}
                            content={content}
                            clickableElementDataTestId={`faq-item-${index}`}
                            activeElementDataTestId={`faq-answer-${index}`}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
