import React from 'react';

import { BaseComponentProps, createRootClassName } from '@srnade/component-ui';
import Image from 'next/image';
import styles from './Icon.module.scss';

import visaIcon from 'assets/icons/visa.svg';
import padlockUnlockedIcon from 'assets/icons/padlock-unlocked.svg';
import afterpayIcon from 'assets/icons/afterpay.svg';
import amexIcon from 'assets/icons/amex.svg';
import appleRoundedIcon from 'assets/icons/apple-rounded-outlined.svg';
import bitcoinIcon from 'assets/icons/bitcoin.svg';
import cardsIcon from 'assets/icons/cards.svg';
import cryptosIcon from 'assets/icons/cryptos.svg';
import ethIcon from 'assets/icons/eth.svg';
import facebookRoundedOutlinedIcon from 'assets/icons/facebook-rounded-outlined.svg';
import fileDropIcon from 'assets/icons/file-drop.svg';
import flagAusIcon from 'assets/icons/flag-aus.svg';
import flagEuIcon from 'assets/icons/flag-eu.svg';
import flagUkIcon from 'assets/icons/flag-uk.svg';
import flagUsIcon from 'assets/icons/flag-us.svg';
import flowIcon from 'assets/icons/flow.svg';
import googleRoundedOutlinedIcon from 'assets/icons/google-rounded-outlined.svg';
import linkedinRoundedOutlinedIcon from 'assets/icons/linkedin-rounded-outlined.svg';
import mastercardIcon from 'assets/icons/mastercard.svg';
import maticIcon from 'assets/icons/matic.svg';
import metamaskIcon from 'assets/icons/metamask.svg';
import metamaskRoundedOutlinedIcon from 'assets/icons/metamask-rounded-outlined.svg';
import unionPayIcon from 'assets/icons/union-pay.svg';

const multicolorIconMap = {
    afterpay: afterpayIcon,
    amex: amexIcon,
    'apple-rounded-outlined': appleRoundedIcon,
    bitcoin: bitcoinIcon,
    cards: cardsIcon,
    cryptos: cryptosIcon,
    eth: ethIcon,
    'facebook-rounded-outlined': facebookRoundedOutlinedIcon,
    'file-drop': fileDropIcon,
    'flag-aus': flagAusIcon,
    'flag-eu': flagEuIcon,
    'flag-uk': flagUkIcon,
    'flag-us': flagUsIcon,
    flow: flowIcon,
    'google-rounded-outlined': googleRoundedOutlinedIcon,
    'linkedin-rounded-outlined': linkedinRoundedOutlinedIcon,
    mastercard: mastercardIcon,
    matic: maticIcon,
    metamask: metamaskIcon,
    'metamask-rounded-outlined': metamaskRoundedOutlinedIcon,
    'padlock-unlocked': padlockUnlockedIcon,
    'union-pay': unionPayIcon,
    visa: visaIcon,
};

export type IconName =
    | 'afterpay'
    | 'amex'
    | 'apple-rounded-outlined'
    | 'bitcoin'
    | 'cards'
    | 'cryptos'
    | 'eth'
    | 'facebook-rounded-outlined'
    | 'file-drop'
    | 'flag-aus'
    | 'flag-eu'
    | 'flag-uk'
    | 'flag-us'
    | 'flow'
    | 'google-rounded-outlined'
    | 'linkedin-rounded-outlined'
    | 'mastercard'
    | 'matic'
    | 'metamask'
    | 'metamask-rounded-outlined'
    | 'padlock-unlocked'
    | 'union-pay'
    | 'visa';

type IconProps = BaseComponentProps & {
    icon: IconName | string;
    size: number;
};

type IconFn = (props: IconProps) => JSX.Element;

const Icon: IconFn = ({ size, icon, className = '', style = {}, onClick }) => {
    const rootClassName = createRootClassName(styles, className);

    return (
        <div onClick={onClick}>
            <Image
                priority
                src={multicolorIconMap[icon]}
                className={rootClassName}
                width={Number(size)}
                height={Number(size)}
                style={style}
                alt={icon}
                unoptimized
            />
        </div>
    );
};

export { Icon };
