import { AccountType, ProductType, isPressing } from '@srnade/component-ui';
import {
    GtmItem,
    GtmProductEvent,
    GtmUserEvent,
    GtmPageNames,
    GtmProductEventType,
    GtmEventType,
    GtmPromotionEvent,
    GtmPromotionEventType,
    GtmPromotion,
    GtmPurchaseEvent,
    GtmLoggedInStatus,
    ReservedProductSummary,
} from '@srnade/web/types';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

// GA state identifiers (values should not change, only new keys added)
const GtmStates = {
    LOGGED_IN: 'logged_in',
    NOT_LOGGED_IN: 'not_logged_in',
    UNVERIFIED_LOGIN: 'unverified',
    IN_STOCK: 'IN STOCK',
    OUT_OF_STOCK: 'SOLD OUT',
    EDITION_OF: 'Edition of',
    STORE_NAME_SERENADE: 'serenade online store',
    NOT_USED: '', // catch all for placeholders
};

export const isProductEventType = (eventType: GtmEventType) => {
    return GtmProductEventType === eventType;
};

export const isPromoEventType = (eventType: GtmEventType) => {
    return GtmPromotionEventType === eventType;
};

// Promo id and slots, based on screen position (top to bottom)
export const promoId = (position: number): string => {
    return `promo_id_${position}`;
};

export const slot = (position: number): string => {
    return `slot_${position}`;
};

// GA context
export class GtmEvents {
    pageName: string | undefined;
    eventTypes: GtmEventType[];
    promoContext: GtmPromotion | undefined;

    constructor(pageName: string | undefined, eventTypes: GtmEventType[], promoContext?: GtmPromotion) {
        this.eventTypes = eventTypes;
        this.pageName = pageName;
        this.promoContext = promoContext;
    }

    // Callback if Product event type is enabled
    productMap(callback: () => void) {
        this.eventTypes.forEach((eType) => {
            if (isProductEventType(eType)) {
                callback();
            }
        });
    }

    // Callback if Promo event type is enabled
    promoMap(callback: () => void) {
        this.eventTypes.forEach((eType) => {
            if (isPromoEventType(eType)) {
                callback();
            }
        });
    }
}

const clearEcommerce = () => {
    // Clear the previous eCommerce object
    window.dataLayer.push({ ecommerce: null });
};

type GtmUser = {
    userId: string;
    loggedInStatus: GtmLoggedInStatus;
    accountType: AccountType;
};

// Push User events through the GA dataLayer.push()
export const gtmPushUser = (eventType: GtmUserEvent, user: GtmUser) => {
    window.dataLayer.push({
        event: eventType,
        user_id: user?.userId,
        login_status: user?.loggedInStatus,
        account_type: user?.accountType,
    });
};

// Product list impressions/clicks
export const gtmPushProductList = (eventType: GtmProductEvent, listings, itemName?: string) => {
    if (!listings || listings.length === 0) {
        return;
    }
    clearEcommerce();
    const list_name = eventType === GtmProductEvent.ViewItemList ? { item_list_name: itemName } : {};
    const items = mapFullListingToGtmItems(listings, itemName);
    window.dataLayer.push({
        event: eventType,
        ecommerce: {
            ...list_name,
            items,
        },
    });
};

export const gtmPushProduct = (eventType: GtmProductEvent, listing: any, itemName?: string, itemIndex?: number) => {
    if (!listing) {
        return;
    }
    clearEcommerce();
    const currency = eventType === GtmProductEvent.ViewItem ? { currency: listing.currency } : {};
    const items = mapListingToGtmItem(listing, itemName, itemIndex || 1);
    window.dataLayer.push({
        event: eventType,
        ecommerce: {
            ...currency,
            items,
        },
    });
};

// Promotion impression/click
export const gtmPushPromoList = (
    eventType: GtmPromotionEvent,
    listings,
    promoContext: GtmPromotion,
    itemName?: string,
) => {
    clearEcommerce();
    const items = mapFullListingToGtmItems(listings, itemName);
    window.dataLayer.push({
        event: eventType,
        ecommerce: {
            creative_name: promoContext.creative_name,
            creative_slot: promoContext.creative_slot,
            promotion_id: promoContext.promotion_id,
            promotion_name: promoContext.promotion_name,
            items,
        },
    });
};

// Product list impressions/clicks
export const gtmPushPurchase = (
    eventType: GtmPurchaseEvent,
    listing: any,
    paymentMethod: string,
    paymentSummary?: ReservedProductSummary,
    itemName?: string,
    itemIndex?: number,
) => {
    if (!listing) {
        return;
    }
    clearEcommerce();
    let additional_data;
    const price = mapPrice(listing.price);
    switch (eventType) {
        case GtmPurchaseEvent.AddToCart:
            additional_data = { currency: listing.currency };
            break;
        case GtmPurchaseEvent.BeginCheckout:
            additional_data = {
                currency: listing.currency,
                value: price,
                coupon: GtmStates.NOT_USED,
            };
            break;
        case GtmPurchaseEvent.Purchase:
            additional_data = {
                transaction_id: paymentSummary?.transactionId,
                affiliation: GtmStates.STORE_NAME_SERENADE, // for now, only our online store
                value: price,
                tax: 0.0,
                currency: listing.currency,
                coupon: GtmStates.NOT_USED,
            };
            break;
    }
    const items = mapListingToGtmItem(listing, itemName, itemIndex || 1);
    window.dataLayer.push({
        event: eventType,
        ecommerce: {
            ...additional_data,
            payment_type: paymentMethod,
            items,
        },
    });
};

// Mappings from GQL/API payloads to GA event payloads

const mapPrice = (price) => {
    // TODO this will eventually depend on currency, eg ETH there should be no change to the value
    return price ? price / 100 : 0;
};

// Pressing type, or collectible
const mapProductType = (prod) => {
    if (!prod?.productType) {
        return '';
    }
    if (isPressing(prod.productType)) {
        return prod.pressing?.pressingType;
    }
    // Collectible
    return ProductType.Collectible;
};

const mapListingId = (prod) => {
    // Edition of 20 etc..
    return `${GtmStates.EDITION_OF} ${prod.limit}`;
};

export const mapFullListingToGtmItems = (fullListing, itemName): GtmItem[] => {
    if (!fullListing && !fullListing.length) {
        return [];
    }
    const items: GtmItem[] = [];

    // Query results are inconsistent, need to check for 'node'
    //    findEditionsByUsername returns [ { product: {.. } }]
    //    findListings returns { edges: [ { node: { product: { .. } }}]}
    fullListing.map((listing, index) => {
        let item;
        if ('node' in listing) {
            item = mapListingToGtmItem(listing.node, itemName, index + 1);
        } else {
            item = mapListingToGtmItem(listing, itemName, index + 1);
        }
        items.push(item);
    });
    return items;
};

function isSoldOut(product: any): boolean {
    if (!product.limit) {
        return false; // open edition
    }
    // remainingInventory is calculated and can be set on product.
    return product.minted ? product.minted === product.limit : product['remainingInventory'] === 0;
}

export const mapListingToGtmItem = (listing, itemName, itemIndex): GtmItem => {
    const prod = listing.product;
    const item: GtmItem = {
        item_name: prod.title,
        item_id: prod.id,
        affiliation: GtmStates.STORE_NAME_SERENADE, // for now, only our online store
        coupon: GtmStates.NOT_USED,
        currency: listing?.currency || '', // Editions have no currency
        price: mapPrice(listing.price),
        item_category: mapProductType(prod),
        item_stock_detail: isSoldOut(prod) ? GtmStates.OUT_OF_STOCK : GtmStates.IN_STOCK,
        item_brand: prod.account.name,
        item_list_name: itemName || GtmPageNames.PAGE_SHOP_ALL,
        item_list_id: mapListingId(prod),
        index: itemIndex,
        quantity: 1, // TODO until we support carts etc with >1 QTY
    };
    return item;
};
