interface GtmCredentials {
    publicId: string;
    publicAuth: string;
    publicPreview: string;
}

interface GtmConfig {
    credentials: GtmCredentials;
}

export const gtmConfig: GtmConfig = {
    credentials: {
        publicId: process.env.NEXT_PUBLIC_GTM_ID!,
        publicAuth: process.env.NEXT_PUBLIC_GTM_AUTH!,
        publicPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW!,
    },
};
