import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    Date: { input: any; output: any };
    JSON: { input: any; output: any };
};

export type Account = {
    __typename?: 'Account';
    avatar?: Maybe<Image>;
    avatarId?: Maybe<Scalars['String']['output']>;
    bio?: Maybe<Scalars['String']['output']>;
    contractAddress?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    createdBy?: Maybe<User>;
    createdById?: Maybe<Scalars['String']['output']>;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    genres: Array<Genre>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    listings: Array<Listing>;
    /** @deprecated Tenant Domain. */
    members: Array<User>;
    missionStatement?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    products: Array<Product>;
    rejectedReason?: Maybe<Scalars['String']['output']>;
    royaltySplitterContractAddress?: Maybe<Scalars['String']['output']>;
    socials: Scalars['JSON']['output'];
    status: AccountStatus;
    statusChangedAt?: Maybe<Scalars['Date']['output']>;
    tags: Array<AccountTag>;
    type: AccountType;
    updated: Scalars['Date']['output'];
    updatedBy?: Maybe<User>;
    updatedById?: Maybe<Scalars['String']['output']>;
    username: Scalars['String']['output'];
};

export type AccountListingsArgs = {
    type?: InputMaybe<Scalars['String']['input']>;
};

export type AccountEdge = {
    __typename?: 'AccountEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<Account>;
};

export type AccountPageInfo = {
    __typename?: 'AccountPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type AccountRelay = {
    __typename?: 'AccountRelay';
    edges?: Maybe<Array<AccountEdge>>;
    name: Scalars['String']['output'];
    pageInfo?: Maybe<AccountPageInfo>;
};

export enum AccountStatus {
    Approved = 'Approved',
    Pending = 'Pending',
    Rejected = 'Rejected',
}

export enum AccountTag {
    Artist = 'Artist',
    Collector = 'Collector',
    RightsHolder = 'RightsHolder',
    Top = 'Top',
}

export enum AccountType {
    Artist = 'Artist',
    Collector = 'Collector',
    RightsHolder = 'RightsHolder',
}

export type AdminCreateRightsHolderInput = {
    email: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
};

export type Artist = {
    __typename?: 'Artist';
    name: Scalars['String']['output'];
    profilePicUrl?: Maybe<Scalars['String']['output']>;
    username: Scalars['String']['output'];
};

export type AssetStreamingUrlInput = {
    assetId: Scalars['String']['input'];
    formatInstanceId: Scalars['String']['input'];
};

export type Audio = {
    __typename?: 'Audio';
    checksum?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    createdBy?: Maybe<User>;
    createdById?: Maybe<Scalars['String']['output']>;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    duration?: Maybe<Scalars['Float']['output']>;
    file: FileProperties;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    muxAssetId?: Maybe<Scalars['String']['output']>;
    muxPlaybackId?: Maybe<Scalars['String']['output']>;
    muxPlaybackPolicy: MuxPlaybackPolicy;
    updated: Scalars['Date']['output'];
};

export type BonusContent = {
    __typename?: 'BonusContent';
    active?: Maybe<Scalars['Boolean']['output']>;
    assetId?: Maybe<Scalars['String']['output']>;
    displayName?: Maybe<Scalars['String']['output']>;
    displayOrder?: Maybe<Scalars['Float']['output']>;
    downloadable?: Maybe<Scalars['Boolean']['output']>;
    duration?: Maybe<Scalars['Int']['output']>;
    fileSize?: Maybe<Scalars['Float']['output']>;
    mimeType?: Maybe<Scalars['String']['output']>;
    streamable?: Maybe<Scalars['Boolean']['output']>;
};

export type ChangePasswordInput = {
    password: Scalars['String']['input'];
};

export enum ClaimStatus {
    INVALID = 'INVALID',
    REDEEMED = 'REDEEMED',
    SUCCESS = 'SUCCESS',
}

export type ClaimVoucherInput = {
    voucherCode: Scalars['String']['input'];
};

export type ClientTokensResponse = {
    __typename?: 'ClientTokensResponse';
    accessToken: Scalars['String']['output'];
    emailVerified: Scalars['Boolean']['output'];
    refreshToken: Scalars['String']['output'];
    user?: Maybe<User>;
};

export type ClientTokensResponseWithPlatformAdmin = {
    __typename?: 'ClientTokensResponseWithPlatformAdmin';
    accessToken: Scalars['String']['output'];
    emailVerified: Scalars['Boolean']['output'];
    isPlatformAdministrator: Scalars['Boolean']['output'];
    refreshToken: Scalars['String']['output'];
    user: User;
};

export type CoinbaseCharge = {
    __typename?: 'CoinbaseCharge';
    coinbaseChargeCode: Scalars['String']['output'];
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    listing: Listing;
    updated: Scalars['Date']['output'];
    user: User;
    userId: Scalars['String']['output'];
};

export type Collectible = {
    __typename?: 'Collectible';
    audio?: Maybe<CollectibleAsset>;
    image?: Maybe<CollectibleAsset>;
    video?: Maybe<CollectibleAsset>;
};

export type CollectibleAsset = {
    __typename?: 'CollectibleAsset';
    assetId: Scalars['String']['output'];
    mimeType: Scalars['String']['output'];
};

export type CollectibleInput = {
    audioId?: InputMaybe<Scalars['String']['input']>;
    imageId?: InputMaybe<Scalars['String']['input']>;
    videoId?: InputMaybe<Scalars['String']['input']>;
};

export type Compressed = {
    __typename?: 'Compressed';
    checksum?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    file: FileProperties;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    updated: Scalars['Date']['output'];
};

export type CompressedTrackDownloadInfo = {
    __typename?: 'CompressedTrackDownloadInfo';
    url: Scalars['String']['output'];
    userDownloadCount: Scalars['Float']['output'];
};

export type CompressedTracks = {
    __typename?: 'CompressedTracks';
    /** Total download limit */
    downloadLimit: Scalars['Float']['output'];
    /** Size of compressed tracks in bytes */
    fileSize?: Maybe<Scalars['Float']['output']>;
    /** Number of times user has downloaded so far */
    userDownloadCount?: Maybe<Scalars['Float']['output']>;
};

export enum CountryCode {
    AE = 'AE',
    AG = 'AG',
    AL = 'AL',
    AM = 'AM',
    AO = 'AO',
    AR = 'AR',
    AT = 'AT',
    AU = 'AU',
    AZ = 'AZ',
    BA = 'BA',
    BD = 'BD',
    BE = 'BE',
    BG = 'BG',
    BH = 'BH',
    BJ = 'BJ',
    BN = 'BN',
    BO = 'BO',
    BS = 'BS',
    BT = 'BT',
    BW = 'BW',
    CA = 'CA',
    CH = 'CH',
    CI = 'CI',
    CL = 'CL',
    CO = 'CO',
    CR = 'CR',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    DO = 'DO',
    DZ = 'DZ',
    EC = 'EC',
    EE = 'EE',
    EG = 'EG',
    ES = 'ES',
    ET = 'ET',
    FI = 'FI',
    FR = 'FR',
    GA = 'GA',
    GB = 'GB',
    GH = 'GH',
    GM = 'GM',
    GR = 'GR',
    GT = 'GT',
    GY = 'GY',
    HK = 'HK',
    HR = 'HR',
    HU = 'HU',
    ID = 'ID',
    IE = 'IE',
    IL = 'IL',
    IS = 'IS',
    IT = 'IT',
    JM = 'JM',
    JO = 'JO',
    JP = 'JP',
    KE = 'KE',
    KH = 'KH',
    KR = 'KR',
    KW = 'KW',
    KZ = 'KZ',
    LA = 'LA',
    LC = 'LC',
    LI = 'LI',
    LK = 'LK',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MA = 'MA',
    MC = 'MC',
    MD = 'MD',
    MG = 'MG',
    MK = 'MK',
    MN = 'MN',
    MO = 'MO',
    MT = 'MT',
    MU = 'MU',
    MX = 'MX',
    MY = 'MY',
    MZ = 'MZ',
    NA = 'NA',
    NE = 'NE',
    NG = 'NG',
    NL = 'NL',
    NO = 'NO',
    NZ = 'NZ',
    OM = 'OM',
    PA = 'PA',
    PE = 'PE',
    PH = 'PH',
    PK = 'PK',
    PL = 'PL',
    PT = 'PT',
    PY = 'PY',
    QA = 'QA',
    RO = 'RO',
    RS = 'RS',
    RW = 'RW',
    SA = 'SA',
    SE = 'SE',
    SG = 'SG',
    SI = 'SI',
    SK = 'SK',
    SM = 'SM',
    SN = 'SN',
    SV = 'SV',
    TH = 'TH',
    TN = 'TN',
    TR = 'TR',
    TT = 'TT',
    TW = 'TW',
    TZ = 'TZ',
    US = 'US',
    UY = 'UY',
    UZ = 'UZ',
    VN = 'VN',
    ZA = 'ZA',
}

export type CreateAccountInput = {
    avatar?: InputMaybe<Scalars['String']['input']>;
    bio?: InputMaybe<Scalars['String']['input']>;
    created?: InputMaybe<Scalars['Date']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    instagram?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    type: AccountType;
    username: Scalars['String']['input'];
};

export type CreateAudioInput = {
    duration?: InputMaybe<Scalars['Float']['input']>;
    file: CreateFilePropertiesInput;
    id?: InputMaybe<Scalars['String']['input']>;
    muxAssetId?: InputMaybe<Scalars['String']['input']>;
    muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
    muxPlaybackPolicy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDocumentInput = {
    file: CreateFilePropertiesInput;
    url: Scalars['String']['input'];
};

export type CreateDraftInput = {
    data?: InputMaybe<DraftDataInput>;
    name?: InputMaybe<Scalars['String']['input']>;
    type: DraftType;
};

export type CreateFilePropertiesInput = {
    extension: Scalars['String']['input'];
    fileType: FileType;
    filename: Scalars['String']['input'];
    mimeType: Scalars['String']['input'];
    originalFilename: Scalars['String']['input'];
    path: Scalars['String']['input'];
    s3Url: Scalars['String']['input'];
    size: Scalars['Float']['input'];
    url: Scalars['String']['input'];
};

export type CreateImageInput = {
    file: CreateFilePropertiesInput;
    height?: InputMaybe<Scalars['Float']['input']>;
    url: Scalars['String']['input'];
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateListingInput = {
    created?: InputMaybe<Scalars['Date']['input']>;
    currency: Currency;
    editionId?: InputMaybe<Scalars['String']['input']>;
    plannedCloseDateTime?: InputMaybe<Scalars['String']['input']>;
    plannedCloseTimeZone?: InputMaybe<Scalars['String']['input']>;
    price: Scalars['Float']['input'];
    productId?: InputMaybe<Scalars['String']['input']>;
    releaseDateTime?: InputMaybe<Scalars['String']['input']>;
    releaseTimeZone?: InputMaybe<Scalars['String']['input']>;
    saleType: ListingSaleType;
    tags?: InputMaybe<Array<ListingTag>>;
    type: ListingType;
};

export type CreateMetadataInput = {
    content: Scalars['JSON']['input'];
    externalId: Scalars['String']['input'];
};

export type CreateOtherInput = {
    file: CreateFilePropertiesInput;
    url: Scalars['String']['input'];
};

export type CreateProductInput = {
    additionalDetails?: InputMaybe<Scalars['String']['input']>;
    audioId?: InputMaybe<Scalars['String']['input']>;
    created?: InputMaybe<Scalars['Date']['input']>;
    description: Scalars['String']['input'];
    genres?: InputMaybe<Array<Genre>>;
    imageId?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Float']['input']>;
    minted?: InputMaybe<Scalars['Float']['input']>;
    parentRecordLabel?: InputMaybe<ParentRecordLabel>;
    tags?: InputMaybe<Array<OriginalProductTag>>;
    termsAndConditionsUrl?: InputMaybe<Scalars['String']['input']>;
    thumbnailId: Scalars['String']['input'];
    title: Scalars['String']['input'];
    videoId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductInputV2 = {
    additionalDetails?: InputMaybe<Scalars['String']['input']>;
    collectible?: InputMaybe<CollectibleInput>;
    description: Scalars['String']['input'];
    genres?: InputMaybe<Array<Genre>>;
    limit?: InputMaybe<Scalars['Float']['input']>;
    metadata?: InputMaybe<CreateProductMetadataInput>;
    parentRecordLabel?: InputMaybe<ParentRecordLabel>;
    pressing?: InputMaybe<PressingInput>;
    productType: ProductType;
    rewards?: InputMaybe<Array<ProductRewardInput>>;
    subGenres?: InputMaybe<Array<Genre>>;
    tags?: InputMaybe<Array<OriginalProductTag>>;
    termsAndConditionsUrl?: InputMaybe<Scalars['String']['input']>;
    thumbnailId: Scalars['String']['input'];
    title: Scalars['String']['input'];
    upc?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductMarketingConsentInput = {
    reservedProductId: Scalars['String']['input'];
};

export type CreateProductMetadataInput = {
    artworkBy?: InputMaybe<Array<Scalars['String']['input']>>;
    performedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    producedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    recordLabel?: InputMaybe<Scalars['String']['input']>;
    writtenBy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateProfileInput = {
    acceptsMarketing: Scalars['Boolean']['input'];
    bio?: InputMaybe<Scalars['String']['input']>;
    country: Scalars['String']['input'];
    email: Scalars['String']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    isPublic?: InputMaybe<Scalars['Boolean']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    missionStatement?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    photoURL?: InputMaybe<Scalars['String']['input']>;
    slug: Scalars['String']['input'];
    socials?: InputMaybe<Scalars['JSON']['input']>;
    tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateRewardInput = {
    audioId?: InputMaybe<Scalars['String']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    downloadable: Scalars['Boolean']['input'];
    imageId?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    otherId?: InputMaybe<Scalars['String']['input']>;
    playable: Scalars['Boolean']['input'];
    rewardType: RewardType;
    videoId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRightsHolderPaymentChannelInput = {
    countryCode: CountryCode;
    type: PaymentChannelType;
};

export type CreateRightsHolderPaymentChannelOnboardingLinkInput = {
    /** Redirect URL if the account link is expired, has been previously-visited, or is otherwise invalid. */
    refreshUrl: Scalars['String']['input'];
    /** Redirect URL upon leaving or completing the linked flow. */
    returnUrl: Scalars['String']['input'];
    /** Type of Payment Channel */
    type: PaymentChannelType;
};

export type CreateRoyaltyInput = {
    editionId?: InputMaybe<Scalars['String']['input']>;
    payee: Scalars['String']['input'];
    productId: Scalars['String']['input'];
    share: Scalars['Float']['input'];
    type: RoyaltyType;
};

export type CreateTrackInput = {
    audioId: Scalars['String']['input'];
    isrc?: InputMaybe<Scalars['String']['input']>;
    metadata?: InputMaybe<CreateTrackMetadataInput>;
    trackName: Scalars['String']['input'];
    trackNumber: Scalars['Float']['input'];
};

export type CreateTrackMetadataInput = {
    artworkBy?: InputMaybe<Array<Scalars['String']['input']>>;
    performedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    producedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    recordLabel?: InputMaybe<Scalars['String']['input']>;
    writtenBy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateUserInput = {
    acceptsMarketing: Scalars['Boolean']['input'];
    country?: InputMaybe<Scalars['String']['input']>;
    created?: InputMaybe<Scalars['Date']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    password: Scalars['String']['input'];
    /** If signing up with a voucher code, to claim a product */
    voucherCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoInput = {
    duration?: InputMaybe<Scalars['Float']['input']>;
    file: CreateFilePropertiesInput;
    muxAssetId?: InputMaybe<Scalars['String']['input']>;
    muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
    muxPlaybackPolicy?: InputMaybe<Scalars['String']['input']>;
};

export type Credits = {
    __typename?: 'Credits';
    artDirectionBy?: Maybe<Array<Scalars['String']['output']>>;
    artworkBy?: Maybe<Array<Scalars['String']['output']>>;
    creativeDirectionBy?: Maybe<Array<Scalars['String']['output']>>;
    designedBy?: Maybe<Array<Scalars['String']['output']>>;
    performedBy?: Maybe<Array<Scalars['String']['output']>>;
    photographyBy?: Maybe<Array<Scalars['String']['output']>>;
    producedBy?: Maybe<Array<Scalars['String']['output']>>;
    writtenBy?: Maybe<Array<Scalars['String']['output']>>;
};

export enum Currency {
    AUD = 'AUD',
    ETH = 'ETH',
    GBP = 'GBP',
    USD = 'USD',
    USDC = 'USDC',
}

export type Document = {
    __typename?: 'Document';
    checksum?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    createdBy?: Maybe<User>;
    createdById?: Maybe<Scalars['String']['output']>;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    file: FileProperties;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    updated: Scalars['Date']['output'];
    url: Scalars['String']['output'];
};

export type Draft = {
    __typename?: 'Draft';
    created: Scalars['Date']['output'];
    data: DraftData;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    name?: Maybe<Scalars['String']['output']>;
    status: DraftStatus;
    type: DraftType;
    updated: Scalars['Date']['output'];
};

export type DraftAudioTrackInput = {
    audioId?: InputMaybe<Scalars['String']['input']>;
    isrc?: InputMaybe<Scalars['String']['input']>;
    muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
    trackName?: InputMaybe<Scalars['String']['input']>;
    trackNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type DraftAudioTrackType = {
    __typename?: 'DraftAudioTrackType';
    audioId?: Maybe<Scalars['String']['output']>;
    isrc?: Maybe<Scalars['String']['output']>;
    muxPlaybackId?: Maybe<Scalars['String']['output']>;
    trackName?: Maybe<Scalars['String']['output']>;
    trackNumber?: Maybe<Scalars['Int']['output']>;
};

export type DraftCoverArtInput = {
    imageId?: InputMaybe<Scalars['String']['input']>;
    url?: InputMaybe<Scalars['String']['input']>;
};

export type DraftCoverArtType = {
    __typename?: 'DraftCoverArtType';
    imageId?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
};

export type DraftData = {
    __typename?: 'DraftData';
    data?: Maybe<DraftDataData>;
};

export type DraftDataData = {
    __typename?: 'DraftDataData';
    coverArt?: Maybe<DraftCoverArtType>;
    credit?: Maybe<DraftProductCreditsType>;
    detail?: Maybe<DraftPressingDetailType>;
    listing?: Maybe<DraftListingType>;
    pressingType?: Maybe<Scalars['String']['output']>;
    rewards?: Maybe<Array<DraftRewardsType>>;
    tracks?: Maybe<Array<DraftAudioTrackType>>;
};

export type DraftDataDataInput = {
    coverArt?: InputMaybe<DraftCoverArtInput>;
    credit?: InputMaybe<DraftProductCreditsInput>;
    detail?: InputMaybe<DraftPressingDetailInput>;
    listing?: InputMaybe<DraftListingInput>;
    pressingType?: InputMaybe<PressingType>;
    rewards?: InputMaybe<Array<DraftRewardsInput>>;
    tracks?: InputMaybe<Array<DraftAudioTrackInput>>;
};

export type DraftDataInput = {
    data?: InputMaybe<DraftDataDataInput>;
};

export type DraftEdge = {
    __typename?: 'DraftEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<Draft>;
};

export type DraftListingInput = {
    currency?: InputMaybe<Currency>;
    price?: InputMaybe<Scalars['Int']['input']>;
    /** IANA timezone name */
    releaseDateTime?: InputMaybe<Scalars['String']['input']>;
    /** DateTime string in ISO format */
    releaseTimeZone?: InputMaybe<Scalars['String']['input']>;
};

export type DraftListingType = {
    __typename?: 'DraftListingType';
    currency?: Maybe<Currency>;
    price?: Maybe<Scalars['String']['output']>;
    releaseDateTime?: Maybe<Scalars['String']['output']>;
    releaseTimeZone?: Maybe<Scalars['String']['output']>;
};

export type DraftPageInfo = {
    __typename?: 'DraftPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type DraftPressingDetailInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    pressingType?: InputMaybe<PressingType>;
    subGenres?: InputMaybe<Array<Genre>>;
    termsAndConditionsUrl?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    upc?: InputMaybe<Scalars['String']['input']>;
};

export type DraftPressingDetailType = {
    __typename?: 'DraftPressingDetailType';
    description?: Maybe<Scalars['String']['output']>;
    genres?: Maybe<Array<Genre>>;
    limit?: Maybe<Scalars['Int']['output']>;
    pressingType?: Maybe<PressingType>;
    subGenres?: Maybe<Array<Genre>>;
    termsAndConditionsUrl?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    upc?: Maybe<Scalars['String']['output']>;
};

export type DraftProductCreditsInput = {
    artworkBy?: InputMaybe<Array<Scalars['String']['input']>>;
    performedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    producedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    recordLabel?: InputMaybe<Scalars['String']['input']>;
    writtenBy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DraftProductCreditsType = {
    __typename?: 'DraftProductCreditsType';
    artworkBy?: Maybe<Array<Scalars['String']['output']>>;
    performedBy?: Maybe<Array<Scalars['String']['output']>>;
    producedBy?: Maybe<Array<Scalars['String']['output']>>;
    recordLabel?: Maybe<Scalars['String']['output']>;
    writtenBy?: Maybe<Array<Scalars['String']['output']>>;
};

export type DraftRelay = {
    __typename?: 'DraftRelay';
    edges?: Maybe<Array<DraftEdge>>;
    pageInfo?: Maybe<DraftPageInfo>;
};

export type DraftRewardsInput = {
    displayOrder?: InputMaybe<Scalars['Int']['input']>;
    fileId?: InputMaybe<Scalars['String']['input']>;
    fileType?: InputMaybe<FileType>;
    muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    url?: InputMaybe<Scalars['String']['input']>;
};

export type DraftRewardsType = {
    __typename?: 'DraftRewardsType';
    displayOrder?: Maybe<Scalars['Int']['output']>;
    fileId?: Maybe<Scalars['String']['output']>;
    fileType?: Maybe<FileType>;
    muxPlaybackId?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
};

export enum DraftStatus {
    Converted = 'Converted',
    Editing = 'Editing',
}

export enum DraftType {
    Product = 'Product',
}

export type Edition = {
    __typename?: 'Edition';
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    product: Product;
    productId: Scalars['String']['output'];
    reservedProduct?: Maybe<ReservedProduct>;
    reservedProductId?: Maybe<Scalars['String']['output']>;
    sequence: Scalars['Float']['output'];
    status: EditionStatus;
    tokenId: Scalars['String']['output'];
    transactionHash?: Maybe<Scalars['String']['output']>;
    updated: Scalars['Date']['output'];
};

export type EditionFormat = {
    __typename?: 'EditionFormat';
    artist: Artist;
    artworkUrl: Scalars['String']['output'];
    behindTheMusic?: Maybe<Scalars['String']['output']>;
    collectible?: Maybe<Collectible>;
    contentConsumable?: Maybe<Scalars['Boolean']['output']>;
    editionId?: Maybe<Scalars['String']['output']>;
    editionOwner?: Maybe<EditionOwner>;
    editionSequence?: Maybe<Scalars['Int']['output']>;
    editionSize?: Maybe<Scalars['Int']['output']>;
    editionStatus?: Maybe<EditionStatusNew>;
    formatInstanceId: Scalars['String']['output'];
    formatInstanceStatus: FormatInstanceStatus;
    formatSubType: FormatSubType;
    formatType: FormatType;
    limited: Scalars['Boolean']['output'];
    /** For example, Independent */
    parentRecordLabel?: Maybe<ParentRecordLabel>;
    plannedCloseDate?: Maybe<Scalars['String']['output']>;
    plannedCloseDateTimezone?: Maybe<Scalars['String']['output']>;
    plannedPreOrderStartDate?: Maybe<Scalars['String']['output']>;
    plannedPreOrderStartDateTimezone?: Maybe<Scalars['String']['output']>;
    plannedReleaseDate?: Maybe<Scalars['String']['output']>;
    plannedReleaseDateTimezone?: Maybe<Scalars['String']['output']>;
    preOrder: Scalars['Boolean']['output'];
    pressing?: Maybe<Pressing>;
    productId: Scalars['String']['output'];
    productStatus: ProductStatusNew;
    /** For example, Delta Music */
    recordLabel?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
    soldOut: Scalars['Boolean']['output'];
    termsAndConditionsUrl?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    tokenId?: Maybe<Scalars['String']['output']>;
    /** Indicates whether the edition is a wholesale edition */
    wholesale: Scalars['Boolean']['output'];
};

export type EditionFormatConnection = {
    __typename?: 'EditionFormatConnection';
    edges: Array<EditionFormatConnectionEdge>;
    pageInfo: PageInfo;
};

export type EditionFormatConnectionEdge = {
    __typename?: 'EditionFormatConnectionEdge';
    cursor: Scalars['String']['output'];
    node: EditionFormat;
};

export type EditionFormatFilters = {
    artistUsername: Scalars['String']['input'];
    sequenceId: Scalars['Float']['input'];
    slug: Scalars['String']['input'];
};

export type EditionFormatsFilters = {
    fanUsername: Scalars['String']['input'];
};

export type EditionOwner = {
    __typename?: 'EditionOwner';
    profilePicUrl?: Maybe<Scalars['String']['output']>;
    username: Scalars['String']['output'];
};

export enum EditionStatus {
    MintFailedFatal = 'MintFailedFatal',
    MintFailedRetryable = 'MintFailedRetryable',
    MintRequested = 'MintRequested',
    Minted = 'Minted',
    TransferFailed = 'TransferFailed',
    TransferRequested = 'TransferRequested',
    Transferred = 'Transferred',
}

export enum EditionStatusNew {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    MINTED = 'MINTED',
}

export type EmailStatusResponse = {
    __typename?: 'EmailStatusResponse';
    sendResult: Scalars['String']['output'];
};

export type FileProperties = {
    __typename?: 'FileProperties';
    extension: Scalars['String']['output'];
    fileType: FileType;
    filename: Scalars['String']['output'];
    mimeType: Scalars['String']['output'];
    originalFilename: Scalars['String']['output'];
    path: Scalars['String']['output'];
    s3Url: Scalars['String']['output'];
    size: Scalars['Float']['output'];
    url: Scalars['String']['output'];
};

export enum FileType {
    Audio = 'Audio',
    Compressed = 'Compressed',
    Document = 'Document',
    Image = 'Image',
    Other = 'Other',
    Video = 'Video',
}

export type FirstOwner = {
    __typename?: 'FirstOwner';
    account?: Maybe<Account>;
    accountId?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    edition: Edition;
    editionId: Scalars['String']['output'];
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    product: Product;
    productId: Scalars['String']['output'];
    updated: Scalars['Date']['output'];
};

export type FirstOwnerEdge = {
    __typename?: 'FirstOwnerEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<FirstOwner>;
};

export type FirstOwnerPageInfo = {
    __typename?: 'FirstOwnerPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type FirstOwnerRelay = {
    __typename?: 'FirstOwnerRelay';
    edges?: Maybe<Array<FirstOwnerEdge>>;
    pageInfo?: Maybe<FirstOwnerPageInfo>;
};

export type ForgotPasswordInput = {
    email: Scalars['String']['input'];
};

export type FormatInstanceAssetDownloadInput = {
    assetId: Scalars['String']['input'];
    formatInstanceId: Scalars['String']['input'];
};

export enum FormatInstanceStatus {
    DELETED = 'DELETED',
    RELEASED = 'RELEASED',
    SAVED = 'SAVED',
}

export type FormatInstanceVideoAssetThumbnailUrlInput = {
    assetId: Scalars['String']['input'];
    formatInstanceId: Scalars['String']['input'];
    height?: InputMaybe<Scalars['Float']['input']>;
    time?: InputMaybe<Scalars['Float']['input']>;
    width?: InputMaybe<Scalars['Float']['input']>;
};

export enum FormatSubType {
    ALBUM = 'ALBUM',
    AUDIO = 'AUDIO',
    EP = 'EP',
    IMAGE = 'IMAGE',
    SINGLE = 'SINGLE',
    VIDEO = 'VIDEO',
}

export enum FormatType {
    DIGITAL_COLLECTIBLE = 'DIGITAL_COLLECTIBLE',
    DIGITAL_PRESSING = 'DIGITAL_PRESSING',
}

export enum Genre {
    Blues = 'Blues',
    Classical = 'Classical',
    Country = 'Country',
    Dance = 'Dance',
    Electronica = 'Electronica',
    Folk = 'Folk',
    HipHop = 'HipHop',
    Indie = 'Indie',
    Jazz = 'Jazz',
    Metal = 'Metal',
    NewWave = 'NewWave',
    Orchestral = 'Orchestral',
    Pop = 'Pop',
    PostPunk = 'PostPunk',
    Punk = 'Punk',
    Reggae = 'Reggae',
    RhythmAndBlues = 'RhythmAndBlues',
    Rock = 'Rock',
    Ska = 'Ska',
    Soul = 'Soul',
}

export type GetManyAudiosInput = {
    ids: Array<Scalars['String']['input']>;
};

export type GetManyDocumentsInput = {
    ids: Array<Scalars['String']['input']>;
};

export type GetManyImagesInput = {
    ids: Array<Scalars['String']['input']>;
};

export type GetManyOthersInput = {
    ids: Array<Scalars['String']['input']>;
};

export type GetManyVideosInput = {
    ids: Array<Scalars['String']['input']>;
};

export enum IdentityManagementSystem {
    Auth0 = 'Auth0',
    Okta = 'Okta',
}

export type Image = {
    __typename?: 'Image';
    checksum?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    createdBy?: Maybe<User>;
    createdById?: Maybe<Scalars['String']['output']>;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    file: FileProperties;
    height: Scalars['Float']['output'];
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    updated: Scalars['Date']['output'];
    url: Scalars['String']['output'];
    width: Scalars['Float']['output'];
};

export type Listing = {
    __typename?: 'Listing';
    account?: Maybe<Account>;
    accountId?: Maybe<Scalars['String']['output']>;
    closed?: Maybe<Scalars['Date']['output']>;
    created: Scalars['Date']['output'];
    currency: Currency;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    edition?: Maybe<Edition>;
    editionId?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    plannedCloseDateTime?: Maybe<Scalars['Date']['output']>;
    plannedCloseTimeZone?: Maybe<Scalars['String']['output']>;
    price: Scalars['Float']['output'];
    product: Product;
    productId?: Maybe<Scalars['String']['output']>;
    releaseDateTime?: Maybe<Scalars['Date']['output']>;
    releaseTimeZone?: Maybe<Scalars['String']['output']>;
    saleType: ListingSaleType;
    status: ListingStatus;
    tags: Array<ListingTag>;
    type: ListingType;
    updated: Scalars['Date']['output'];
    user?: Maybe<User>;
    userId?: Maybe<Scalars['String']['output']>;
};

export type ListingEdge = {
    __typename?: 'ListingEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<Listing>;
};

export type ListingPageInfo = {
    __typename?: 'ListingPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type ListingRelay = {
    __typename?: 'ListingRelay';
    edges?: Maybe<Array<ListingEdge>>;
    pageInfo?: Maybe<ListingPageInfo>;
};

export enum ListingSaleType {
    Auction = 'Auction',
    LimitedEdition = 'LimitedEdition',
    OpenEdition = 'OpenEdition',
}

export enum ListingStatus {
    Activated = 'Activated',
    Closed = 'Closed',
    Deactivated = 'Deactivated',
}

export enum ListingTag {
    Hero = 'Hero',
    Sold = 'Sold',
    Trending = 'Trending',
}

export enum ListingType {
    Edition = 'Edition',
    Product = 'Product',
}

export type ListingsSortingInput = {
    created?: InputMaybe<OrderBy>;
    price?: InputMaybe<OrderBy>;
    productTitle?: InputMaybe<OrderBy>;
};

export type LoginInput = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type Metadata = {
    __typename?: 'Metadata';
    content: Scalars['JSON']['output'];
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    updated: Scalars['Date']['output'];
};

export type Mutation = {
    __typename?: 'Mutation';
    activateListing: Scalars['Boolean']['output'];
    /** @deprecated Tenant Domain. Accounts replaced with Profiles. */
    adminApproveAccount: Account;
    adminCreateRightsHolder: RightsHolder;
    /** Find a legacy Artist account and create a rights-holder entry for them. */
    adminCreateRightsHolderForLegacyArtist: Account;
    /** @deprecated Tenant Domain. Accounts replaced with Profiles. */
    adminDeactivateAccount: Scalars['Boolean']['output'];
    /** @deprecated Tenant Domain. Accounts replaced with Profiles. */
    adminDeleteAccount: Scalars['Boolean']['output'];
    /** @deprecated Tenant Domain. Accounts replaced with Profiles. */
    adminRejectAccount: Account;
    /**
     * Set whether NFT transfers are enabled for the specified product. Returns 'true' if the update was successful
     * @deprecated Replaced with Format Domain
     */
    adminSetNftTransfers: Scalars['Boolean']['output'];
    /** @deprecated Tenant Domain. Accounts replaced with Profiles. */
    adminUpdateAccount: Account;
    adminUpdateProduct: Product;
    adminUpdateStripePaymentIntents: Scalars['String']['output'];
    /** @deprecated Handled by Universal Login. Not necessary with passwordless or SSO. */
    changePassword: Scalars['Boolean']['output'];
    claimVoucher: ClaimStatus;
    closeListing: Scalars['Boolean']['output'];
    /** Convert the user's draft product to a concrete product */
    convertMyDraftProduct: Product;
    createAudio: Audio;
    createDocument: Document;
    /** @deprecated Replace with Edition Domain */
    createDownloadableTracksUrl: Scalars['String']['output'];
    /** Create a new draft */
    createDraft: Draft;
    /** @deprecated Owned by other domains. */
    createImage: Image;
    createListing: Listing;
    createMetadata: Metadata;
    createOther: Other;
    createProduct: Product;
    /** Create a new product marketing consent */
    createProductMarketingConsent: ProductMarketingConsent;
    createProductV2: Product;
    createProfile: Profile;
    createReward: Reward;
    createRightsHolderPaymentChannel: PaymentChannel;
    /** Creates a payment channel onboarding link of the specified type for the authenticated user */
    createRightsHolderPaymentChannelOnboardingLink: PaymentChannelOnboardingLink;
    createRoyalty: Royalty;
    createTrack: TrackWithMetadata;
    createVideo: Video;
    deactivateListing: Scalars['Boolean']['output'];
    deactivateRoyalty: Scalars['Boolean']['output'];
    deleteRoyalty: Scalars['Boolean']['output'];
    /** @deprecated Handled by Universal Login. Not necessary with passwordless or SSO. */
    forgotPassword: Scalars['Boolean']['output'];
    /** @deprecated Handled by Universal Login. */
    getUpdatedTokens: ClientTokensResponse;
    /** @deprecated Handled by Universal Login. */
    login: ClientTokensResponseWithPlatformAdmin;
    /** Re-publish ACCOUNT_APPROVED events for all approved accounts of a given type. */
    maintainancePublishApprovedAccounts: Scalars['Int']['output'];
    purchaseWithCrypto: ReservedProduct;
    /** @deprecated Handled by Universal Login with createProfile */
    register: ClientTokensResponse;
    /** @deprecated Handled by Universal Login. Not necessary with passwordless or SSO. */
    resendEmailVerificationEmail: EmailStatusResponse;
    /** @deprecated Tenant Domain. Profile Service. */
    setAsMarketingContact: Scalars['Boolean']['output'];
    /** @deprecated HubSpot API is deprecated */
    subscribeToMarketing: Scalars['Boolean']['output'];
    /** @deprecated HubSpot API is deprecated */
    unsubscribeFromMarketing: Scalars['Boolean']['output'];
    updateAccount: Account;
    updateAvatar: Account;
    updateListing: Listing;
    /** Update the user's draft */
    updateMyDraft: Draft;
    updateProduct: Product;
    updateRoyalty: Royalty;
};

export type MutationActivateListingArgs = {
    id: Scalars['String']['input'];
};

export type MutationAdminApproveAccountArgs = {
    id: Scalars['String']['input'];
};

export type MutationAdminCreateRightsHolderArgs = {
    rightsHolder: AdminCreateRightsHolderInput;
};

export type MutationAdminCreateRightsHolderForLegacyArtistArgs = {
    accountId: Scalars['String']['input'];
};

export type MutationAdminDeactivateAccountArgs = {
    accountId: Scalars['String']['input'];
    includingUser: Scalars['Boolean']['input'];
};

export type MutationAdminDeleteAccountArgs = {
    accountId: Scalars['String']['input'];
};

export type MutationAdminRejectAccountArgs = {
    id: Scalars['String']['input'];
};

export type MutationAdminSetNftTransfersArgs = {
    input: SetNftTransfersInput;
};

export type MutationAdminUpdateAccountArgs = {
    account: UpdateAccountInput;
    id: Scalars['String']['input'];
    user?: InputMaybe<UpdateUserInput>;
};

export type MutationAdminUpdateProductArgs = {
    id: Scalars['String']['input'];
    input: UpdateProductInput;
};

export type MutationAdminUpdateStripePaymentIntentsArgs = {
    createdAfterDate: Scalars['Date']['input'];
    stripePaymentIntentId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationClaimVoucherArgs = {
    voucher: ClaimVoucherInput;
};

export type MutationCloseListingArgs = {
    id: Scalars['String']['input'];
};

export type MutationConvertMyDraftProductArgs = {
    id: Scalars['String']['input'];
};

export type MutationCreateAudioArgs = {
    input: CreateAudioInput;
};

export type MutationCreateDocumentArgs = {
    input: CreateDocumentInput;
};

export type MutationCreateDownloadableTracksUrlArgs = {
    editionId: Scalars['String']['input'];
};

export type MutationCreateDraftArgs = {
    input: CreateDraftInput;
};

export type MutationCreateImageArgs = {
    input: CreateImageInput;
};

export type MutationCreateListingArgs = {
    input: CreateListingInput;
};

export type MutationCreateMetadataArgs = {
    input: CreateMetadataInput;
};

export type MutationCreateOtherArgs = {
    input: CreateOtherInput;
};

export type MutationCreateProductArgs = {
    listing: CreateListingInput;
    product: CreateProductInput;
};

export type MutationCreateProductMarketingConsentArgs = {
    input: CreateProductMarketingConsentInput;
};

export type MutationCreateProductV2Args = {
    listing: CreateListingInput;
    product: CreateProductInputV2;
};

export type MutationCreateProfileArgs = {
    profile: CreateProfileInput;
};

export type MutationCreateRewardArgs = {
    input: CreateRewardInput;
};

export type MutationCreateRightsHolderPaymentChannelArgs = {
    paymentChannel: CreateRightsHolderPaymentChannelInput;
};

export type MutationCreateRightsHolderPaymentChannelOnboardingLinkArgs = {
    input: CreateRightsHolderPaymentChannelOnboardingLinkInput;
};

export type MutationCreateRoyaltyArgs = {
    input: CreateRoyaltyInput;
};

export type MutationCreateTrackArgs = {
    input: CreateTrackInput;
};

export type MutationCreateVideoArgs = {
    input: CreateVideoInput;
};

export type MutationDeactivateListingArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeactivateRoyaltyArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteRoyaltyArgs = {
    id: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
    input: ForgotPasswordInput;
};

export type MutationGetUpdatedTokensArgs = {
    refreshToken: Scalars['String']['input'];
};

export type MutationLoginArgs = {
    input: LoginInput;
};

export type MutationMaintainancePublishApprovedAccountsArgs = {
    type: AccountType;
};

export type MutationPurchaseWithCryptoArgs = {
    listingId: Scalars['String']['input'];
};

export type MutationRegisterArgs = {
    account: CreateAccountInput;
    user: CreateUserInput;
};

export type MutationResendEmailVerificationEmailArgs = {
    voucher?: InputMaybe<VoucherCodeInput>;
};

export type MutationSetAsMarketingContactArgs = {
    input: SubscribeToMarketingInput;
};

export type MutationSubscribeToMarketingArgs = {
    input: SubscribeToMarketingInput;
};

export type MutationUnsubscribeFromMarketingArgs = {
    input: UnsubscribeFromMarketingInput;
};

export type MutationUpdateAccountArgs = {
    account: UpdateAccountInput;
    id: Scalars['String']['input'];
    user?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateAvatarArgs = {
    input: CreateImageInput;
};

export type MutationUpdateListingArgs = {
    id: Scalars['String']['input'];
    input: UpdateListingInput;
};

export type MutationUpdateMyDraftArgs = {
    id: Scalars['String']['input'];
    input: UpdateDraftInput;
};

export type MutationUpdateProductArgs = {
    id: Scalars['String']['input'];
    input: UpdateProductWithMetadataInput;
};

export type MutationUpdateRoyaltyArgs = {
    id: Scalars['String']['input'];
    input: UpdateRoyaltyInput;
};

export enum MuxPlaybackPolicy {
    Public = 'Public',
    Signed = 'Signed',
}

export enum OrderBy {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum OriginalProductTag {
    Original = 'Original',
}

export type Other = {
    __typename?: 'Other';
    checksum?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    createdBy?: Maybe<User>;
    createdById?: Maybe<Scalars['String']['output']>;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    file: FileProperties;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    updated: Scalars['Date']['output'];
    url: Scalars['String']['output'];
};

export type PageInfo = {
    __typename?: 'PageInfo';
    endCursor: Scalars['String']['output'];
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor: Scalars['String']['output'];
    totalCount: Scalars['Int']['output'];
};

export enum ParentRecordLabel {
    Independent = 'Independent',
    SonyMusic = 'SonyMusic',
    UniversalMusicGroup = 'UniversalMusicGroup',
    WarnerMusicGroup = 'WarnerMusicGroup',
}

export type PaymentChannel = {
    __typename?: 'PaymentChannel';
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    rightsHolderId: Scalars['String']['output'];
    status: PaymentChannelStatus;
    type: PaymentChannelType;
    updated: Scalars['Date']['output'];
};

export type PaymentChannelOnboardingLink = {
    __typename?: 'PaymentChannelOnboardingLink';
    created: Scalars['Float']['output'];
    expiresAt: Scalars['Float']['output'];
    url: Scalars['String']['output'];
};

export enum PaymentChannelStatus {
    ACTION_NEEDED = 'ACTION_NEEDED',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    ERROR = 'ERROR',
    NOT_STARTED = 'NOT_STARTED',
    SUBMITTED = 'SUBMITTED',
}

export enum PaymentChannelType {
    COINBASE = 'COINBASE',
    STRIPE = 'STRIPE',
}

export type PaymentUrlInput = {
    cancelUrl: Scalars['String']['input'];
    productIds: Array<Scalars['String']['input']>;
    successUrl: Scalars['String']['input'];
};

export enum Permission {
    AdminViewUserDetails = 'AdminViewUserDetails',
}

export enum PermissionKey {
    ActivateListing = 'ActivateListing',
    AdminAddRightsHolder = 'AdminAddRightsHolder',
    AdminApproveAccount = 'AdminApproveAccount',
    AdminApproveProduct = 'AdminApproveProduct',
    AdminDeactivateAccount = 'AdminDeactivateAccount',
    AdminDeleteAccount = 'AdminDeleteAccount',
    AdminDeleteProduct = 'AdminDeleteProduct',
    AdminFindRightsHolderDetails = 'AdminFindRightsHolderDetails',
    AdminGenerateEditionMetadata = 'AdminGenerateEditionMetadata',
    AdminGetDraft = 'AdminGetDraft',
    AdminGetPendingAccounts = 'AdminGetPendingAccounts',
    AdminGetProductMarketingConsents = 'AdminGetProductMarketingConsents',
    AdminGetSalesData = 'AdminGetSalesData',
    AdminMaintainancePublishApprovedAccounts = 'AdminMaintainancePublishApprovedAccounts',
    AdminRejectAccount = 'AdminRejectAccount',
    AdminSetNftTransfers = 'AdminSetNftTransfers',
    AdminTransferEdition = 'AdminTransferEdition',
    AdminUpdateAccount = 'AdminUpdateAccount',
    AdminUpdateAccountContract = 'AdminUpdateAccountContract',
    AdminUpdateEditionMetadata = 'AdminUpdateEditionMetadata',
    AdminUpdateListing = 'AdminUpdateListing',
    AdminUpdateProduct = 'AdminUpdateProduct',
    AdminUpdateStripePaymentIntents = 'AdminUpdateStripePaymentIntents',
    AdminViewUserDetails = 'AdminViewUserDetails',
    CloseListing = 'CloseListing',
    ConvertDraft = 'ConvertDraft',
    CreateAccount = 'CreateAccount',
    CreateDraft = 'CreateDraft',
    CreateEdition = 'CreateEdition',
    CreateListing = 'CreateListing',
    CreateProduct = 'CreateProduct',
    CreateRoyalty = 'CreateRoyalty',
    DeactivateListing = 'DeactivateListing',
    DeleteEdition = 'DeleteEdition',
    DeleteListing = 'DeleteListing',
    DeleteProduct = 'DeleteProduct',
    DeleteRoyalty = 'DeleteRoyalty',
    GetDraft = 'GetDraft',
    UpdateAccount = 'UpdateAccount',
    UpdateDraft = 'UpdateDraft',
    UpdateEdition = 'UpdateEdition',
    UpdateListing = 'UpdateListing',
    UpdateProduct = 'UpdateProduct',
    UpdateRoyalty = 'UpdateRoyalty',
}

/** Details of pressing tracks, bonus content and credits of the format instance */
export type Pressing = {
    __typename?: 'Pressing';
    /** An array of pressing tracks associated with the format instance */
    bonusContent: Array<BonusContent>;
    /** Compressed tracks information available to only the current edition owner */
    compressedTracks?: Maybe<CompressedTracks>;
    /** An object containing contributors information of the format instance */
    credits?: Maybe<Credits>;
    /** An array of pressing tracks associated with the format instance */
    tracks: Array<PressingTrack>;
};

export type PressingInput = {
    coverArtImageId: Scalars['String']['input'];
    pressingType: PressingType;
    trackIds: Array<Scalars['String']['input']>;
};

export type PressingTrack = {
    __typename?: 'PressingTrack';
    active?: Maybe<Scalars['Boolean']['output']>;
    assetId?: Maybe<Scalars['String']['output']>;
    displayName?: Maybe<Scalars['String']['output']>;
    downloadable?: Maybe<Scalars['Boolean']['output']>;
    duration?: Maybe<Scalars['Int']['output']>;
    fileExtension?: Maybe<Scalars['String']['output']>;
    fileSize?: Maybe<Scalars['Int']['output']>;
    mimeType?: Maybe<Scalars['String']['output']>;
    streamable?: Maybe<Scalars['Boolean']['output']>;
    trackNumber?: Maybe<Scalars['Int']['output']>;
};

export enum PressingType {
    Album = 'Album',
    ExtendedPlay = 'ExtendedPlay',
    Single = 'Single',
}

export type Product = {
    __typename?: 'Product';
    account: Account;
    accountId: Scalars['String']['output'];
    additionalDetails?: Maybe<Scalars['String']['output']>;
    allowTrackDownloads: Scalars['Boolean']['output'];
    /** Are NFT transfers allowed? */
    allowTransfers: Scalars['Boolean']['output'];
    /** @deprecated Replaced with Content Domain */
    audio?: Maybe<Audio>;
    audioId?: Maybe<Scalars['String']['output']>;
    burned: Scalars['Float']['output'];
    collectible?: Maybe<ProductCollectible>;
    compressed?: Maybe<Compressed>;
    compressedId?: Maybe<Scalars['String']['output']>;
    /** Smart contract address */
    contractAddress?: Maybe<Scalars['String']['output']>;
    contractType?: Maybe<Scalars['String']['output']>;
    contractVersion?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    createdBy?: Maybe<User>;
    createdById?: Maybe<Scalars['String']['output']>;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    description: Scalars['String']['output'];
    editions: Array<Edition>;
    firstOwners: Array<FirstOwner>;
    genres: Array<Genre>;
    id: Scalars['String']['output'];
    /** @deprecated Replaced with Product Domain */
    image?: Maybe<Image>;
    imageId?: Maybe<Scalars['String']['output']>;
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    limit?: Maybe<Scalars['Float']['output']>;
    listings: Array<Listing>;
    metadata?: Maybe<Scalars['JSON']['output']>;
    metadataId?: Maybe<Scalars['String']['output']>;
    minted: Scalars['Float']['output'];
    parentRecordLabel?: Maybe<ParentRecordLabel>;
    pressing?: Maybe<ProductPressing>;
    productType?: Maybe<ProductType>;
    rejectedReason?: Maybe<Scalars['String']['output']>;
    /** @deprecated Replaced with Format Domain */
    rewards?: Maybe<Array<Reward>>;
    royaltySplitterContractAddress?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
    status: ProductStatus;
    statusChangedAt?: Maybe<Scalars['Date']['output']>;
    subGenres?: Maybe<Array<Genre>>;
    tags: Array<OriginalProductTag>;
    termsAndConditionsUrl?: Maybe<Scalars['String']['output']>;
    thumbnail?: Maybe<Image>;
    thumbnailId?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    trackDownloadsLimit?: Maybe<Scalars['Float']['output']>;
    upc?: Maybe<Scalars['String']['output']>;
    updated: Scalars['Date']['output'];
    updatedBy?: Maybe<User>;
    updatedById?: Maybe<Scalars['String']['output']>;
    /** @deprecated Replaced with Content Domain */
    video?: Maybe<Video>;
    videoId?: Maybe<Scalars['String']['output']>;
};

export type ProductCollectible = {
    __typename?: 'ProductCollectible';
    audio?: Maybe<Audio>;
    audioId?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    image?: Maybe<Image>;
    imageId?: Maybe<Scalars['String']['output']>;
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    updated: Scalars['Date']['output'];
    video?: Maybe<Video>;
    videoId?: Maybe<Scalars['String']['output']>;
};

export type ProductEdge = {
    __typename?: 'ProductEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<Product>;
};

export type ProductFormat = {
    __typename?: 'ProductFormat';
    artist: Artist;
    artworkUrl: Scalars['String']['output'];
    /** The description of the format instance */
    behindTheMusic?: Maybe<Scalars['String']['output']>;
    collectible?: Maybe<Collectible>;
    currency?: Maybe<Currency>;
    editionSize?: Maybe<Scalars['Int']['output']>;
    formatInstanceId?: Maybe<Scalars['String']['output']>;
    formatInstanceStatus?: Maybe<FormatInstanceStatus>;
    /** Subtype of format (audio/ep/album/image/audio/video) */
    formatSubType: FormatSubType;
    /** Type of edition format (collectible/pressing) */
    formatType: FormatType;
    id: Scalars['String']['output'];
    /** Indicates if the product is limited edition sale */
    limited: Scalars['Boolean']['output'];
    /** For example, Independent */
    parentRecordLabel?: Maybe<ParentRecordLabel>;
    plannedCloseDate?: Maybe<Scalars['String']['output']>;
    plannedCloseDateTimezone?: Maybe<Scalars['String']['output']>;
    plannedPreOrderStartDate?: Maybe<Scalars['String']['output']>;
    plannedPreOrderStartDateTimezone?: Maybe<Scalars['String']['output']>;
    plannedReleaseDate?: Maybe<Scalars['String']['output']>;
    plannedReleaseDateTimezone?: Maybe<Scalars['String']['output']>;
    /** Indicates if the product is available for pre-order */
    preOrder: Scalars['Boolean']['output'];
    preOrderStartDate?: Maybe<Scalars['String']['output']>;
    pressing?: Maybe<Pressing>;
    price?: Maybe<Scalars['Int']['output']>;
    productInclusions: Array<Scalars['String']['output']>;
    productStatus: ProductStatusNew;
    /** For example, Delta Music */
    recordLabel?: Maybe<Scalars['String']['output']>;
    releaseDate?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
    /** Indicates if the edition is sold out */
    soldOut: Scalars['Boolean']['output'];
    termsAndConditionsUrl?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    /** Indicates whether the current requester owns any editions for this product */
    userOwnsAnyEditions?: Maybe<Scalars['Boolean']['output']>;
    /** Indicates whether the product is published */
    visible: Scalars['Boolean']['output'];
    /** Indicates whether the product is a wholesale product */
    wholesale: Scalars['Boolean']['output'];
};

export type ProductFormatConnection = {
    __typename?: 'ProductFormatConnection';
    edges: Array<ProductFormatConnectionEdge>;
    pageInfo: PageInfo;
};

export type ProductFormatConnectionEdge = {
    __typename?: 'ProductFormatConnectionEdge';
    cursor: Scalars['String']['output'];
    node: ProductFormat;
};

export type ProductFormatFilters = {
    artistUsername?: InputMaybe<Scalars['String']['input']>;
    productId?: InputMaybe<Scalars['String']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductFormatOrderBy {
    CREATED_AT_ASC = 'CREATED_AT_ASC',
    CREATED_AT_DESC = 'CREATED_AT_DESC',
    PRE_ORDER_START_DATE_ASC = 'PRE_ORDER_START_DATE_ASC',
    PRE_ORDER_START_DATE_DESC = 'PRE_ORDER_START_DATE_DESC',
    PRICE_ASC = 'PRICE_ASC',
    PRICE_DESC = 'PRICE_DESC',
    RELEASE_DATE_ASC = 'RELEASE_DATE_ASC',
    RELEASE_DATE_DESC = 'RELEASE_DATE_DESC',
    RELEASE_OR_PREORDER_START_DATE_ASC = 'RELEASE_OR_PREORDER_START_DATE_ASC',
    RELEASE_OR_PREORDER_START_DATE_DESC = 'RELEASE_OR_PREORDER_START_DATE_DESC',
    TITLE_ASC = 'TITLE_ASC',
    TITLE_DESC = 'TITLE_DESC',
}

export type ProductFormatsFilters = {
    artistUsername?: InputMaybe<Scalars['String']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    isListed?: InputMaybe<Scalars['Boolean']['input']>;
    productStatus?: InputMaybe<Array<ProductStatusNew>>;
    tags?: InputMaybe<Array<ProductTag>>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type ProductMarketingConsent = {
    __typename?: 'ProductMarketingConsent';
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    /** Resolve reservedProduct */
    reservedProduct: ReservedProduct;
    reservedProductId: Scalars['String']['output'];
    updated: Scalars['Date']['output'];
    /** Resolve user */
    user: User;
    userId: Scalars['String']['output'];
};

export type ProductMarketingConsentEdge = {
    __typename?: 'ProductMarketingConsentEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<ProductMarketingConsent>;
};

export type ProductMarketingConsentPageInfo = {
    __typename?: 'ProductMarketingConsentPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductMarketingConsentRelay = {
    __typename?: 'ProductMarketingConsentRelay';
    edges?: Maybe<Array<ProductMarketingConsentEdge>>;
    pageInfo?: Maybe<ProductMarketingConsentPageInfo>;
};

export type ProductPageInfo = {
    __typename?: 'ProductPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductPressing = {
    __typename?: 'ProductPressing';
    coverArtImage?: Maybe<Image>;
    coverArtImageId?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    pressingType: PressingType;
    tracks: Array<Track>;
    updated: Scalars['Date']['output'];
};

export type ProductRelay = {
    __typename?: 'ProductRelay';
    edges?: Maybe<Array<ProductEdge>>;
    pageInfo?: Maybe<ProductPageInfo>;
};

export type ProductRewardInput = {
    displayOrder: Scalars['Float']['input'];
    rewardId: Scalars['String']['input'];
};

export enum ProductStatus {
    Approved = 'Approved',
    Pending = 'Pending',
    Rejected = 'Rejected',
}

export enum ProductStatusNew {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    DELETED = 'DELETED',
    PRE_ORDER = 'PRE_ORDER',
    RELEASED = 'RELEASED',
}

export enum ProductTag {
    Hero = 'Hero',
}

export enum ProductType {
    Collectible = 'Collectible',
    Pressing = 'Pressing',
}

export type Profile = {
    __typename?: 'Profile';
    acceptsMarketing: Scalars['Boolean']['output'];
    bio?: Maybe<Scalars['String']['output']>;
    country: Scalars['String']['output'];
    defaultUserId?: Maybe<Scalars['String']['output']>;
    email: Scalars['String']['output'];
    firstName?: Maybe<Scalars['String']['output']>;
    genres?: Maybe<Array<Genre>>;
    id: Scalars['String']['output'];
    isPublic: Scalars['Boolean']['output'];
    lastName?: Maybe<Scalars['String']['output']>;
    missionStatement?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    photoURL?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
    socials?: Maybe<Scalars['JSON']['output']>;
    tags?: Maybe<Array<Scalars['String']['output']>>;
    tenantId: Scalars['String']['output'];
};

export type Query = {
    __typename?: 'Query';
    /** @deprecated Tenant Domain. Accounts replaced with Profiles. */
    adminFindPendingAccounts: Array<Account>;
    /** Find the rights holders details given a userId */
    adminFindRightsHolderDetails?: Maybe<RightsHolder>;
    availableArtistLetters: Array<Scalars['String']['output']>;
    /** @deprecated Tenant Domain. */
    checkPermissions: Scalars['JSON']['output'];
    compressedTrackDownloadInfo: CompressedTrackDownloadInfo;
    countProducts: Scalars['Float']['output'];
    editionFormat?: Maybe<EditionFormat>;
    editionFormats?: Maybe<EditionFormatConnection>;
    findAccount?: Maybe<Account>;
    findAccounts: AccountRelay;
    findApprovedArtists: Array<Account>;
    /** Get a draft */
    findDraft: Draft;
    /** Get drafts */
    findDrafts: DraftRelay;
    /** @deprecated Replace with Edition Domain */
    findEdition?: Maybe<Edition>;
    /** @deprecated Replace with Edition Domain */
    findEditionsByUserId: Array<Edition>;
    /** @deprecated Replace with Edition Domain */
    findEditionsByUsername: Array<Edition>;
    findFirstOwners: FirstOwnerRelay;
    findLatestProductListing: Listing;
    findListingById: Listing;
    findListings: ListingRelay;
    findListingsGenres: Array<Genre>;
    findProduct?: Maybe<Product>;
    findProductMarketingConsent?: Maybe<ProductMarketingConsent>;
    /** Find many product marketing consents */
    findProductMarketingConsentsByParentRecordLabel: ProductMarketingConsentRelay;
    findProducts: ProductRelay;
    findProfile?: Maybe<User>;
    /** @deprecated Replace with Edition Domain */
    findReservedEditionsByUsername: Array<ReservedEdition>;
    findReservedProductSummary?: Maybe<ReservationSummary>;
    /** Find the rights holders details for the authenticated user */
    findRightsHolderDetails?: Maybe<RightsHolder>;
    findRoyalties: RoyaltyRelay;
    findRoyaltyById: Royalty;
    findTrackById: TrackWithMetadata;
    /** @deprecated Replace with Edition Domain */
    findTracksDownloadCount: TracksDownloadCount;
    findTransactionById: Transaction;
    findTransactions: TransactionRelay;
    /** @deprecated Tenant Domain. */
    findUserById: User;
    formatInstanceAssetDownloadUrl: Scalars['String']['output'];
    formatInstanceAssetStreamingUrl: Scalars['String']['output'];
    formatInstanceVideoAssetThumbnailUrl: Scalars['String']['output'];
    getManyAudios: Array<Audio>;
    getManyDocuments: Array<Document>;
    getManyImages: Array<Image>;
    getManyOthers: Array<Other>;
    getManyVideos: Array<Video>;
    /** Get the user's draft */
    getMyDraft: Draft;
    /** Get the user's drafts */
    getMyDrafts: DraftRelay;
    /** @deprecated Tenant Domain. */
    hasPermissions: Scalars['Boolean']['output'];
    /** @deprecated Tenant Domain. Accounts replaced with Profiles. */
    isMember: Scalars['Boolean']['output'];
    listArtistsByLetters: Array<Account>;
    paymentUrl: Scalars['String']['output'];
    previewImage: Scalars['String']['output'];
    productFormat: ProductFormat;
    productFormats?: Maybe<ProductFormatConnection>;
    voucher: Voucher;
};

export type QueryAdminFindRightsHolderDetailsArgs = {
    userId: Scalars['String']['input'];
};

export type QueryCheckPermissionsArgs = {
    keys: Array<PermissionKey>;
};

export type QueryCompressedTrackDownloadInfoArgs = {
    editionId: Scalars['String']['input'];
};

export type QueryCountProductsArgs = {
    accountId: Scalars['String']['input'];
};

export type QueryEditionFormatArgs = {
    filters: EditionFormatFilters;
};

export type QueryEditionFormatsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    filters: EditionFormatsFilters;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFindAccountArgs = {
    id?: InputMaybe<Scalars['String']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindAccountsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    status?: InputMaybe<AccountStatus>;
    tags?: InputMaybe<Array<AccountTag>>;
    type?: InputMaybe<AccountType>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindDraftArgs = {
    id: Scalars['String']['input'];
};

export type QueryFindDraftsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    status?: InputMaybe<DraftStatus>;
    type?: InputMaybe<DraftType>;
};

export type QueryFindEditionArgs = {
    id?: InputMaybe<Scalars['String']['input']>;
    productId?: InputMaybe<Scalars['String']['input']>;
    reservedProductId?: InputMaybe<Scalars['String']['input']>;
    sequence?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryFindEditionsByUserIdArgs = {
    userId: Scalars['String']['input'];
};

export type QueryFindEditionsByUsernameArgs = {
    includeMinting?: InputMaybe<Scalars['Boolean']['input']>;
    username: Scalars['String']['input'];
};

export type QueryFindFirstOwnersArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    productSlug: Scalars['String']['input'];
};

export type QueryFindLatestProductListingArgs = {
    productId: Scalars['String']['input'];
};

export type QueryFindListingByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryFindListingsArgs = {
    accountStatus?: InputMaybe<AccountStatus>;
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    currency?: InputMaybe<Currency>;
    excludeTags?: InputMaybe<Array<ListingTag>>;
    first?: InputMaybe<Scalars['Float']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<ListingsSortingInput>;
    productStatus?: InputMaybe<ProductStatus>;
    productType?: InputMaybe<ProductType>;
    saleType?: InputMaybe<ListingSaleType>;
    tags?: InputMaybe<Array<ListingTag>>;
    type: ListingType;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindProductArgs = {
    id?: InputMaybe<Scalars['String']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindProductMarketingConsentArgs = {
    id: Scalars['String']['input'];
};

export type QueryFindProductMarketingConsentsByParentRecordLabelArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    parentRecordLabel: ParentRecordLabel;
};

export type QueryFindProductsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    productType?: InputMaybe<ProductType>;
    status?: InputMaybe<ProductStatus>;
    tags?: InputMaybe<Array<OriginalProductTag>>;
    title?: InputMaybe<Scalars['String']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindReservedEditionsByUsernameArgs = {
    includeMinting?: InputMaybe<Scalars['Boolean']['input']>;
    username: Scalars['String']['input'];
};

export type QueryFindReservedProductSummaryArgs = {
    reservedProductId: Scalars['String']['input'];
};

export type QueryFindRoyaltiesArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    createdById?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindRoyaltyByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryFindTrackByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryFindTracksDownloadCountArgs = {
    editionId: Scalars['String']['input'];
};

export type QueryFindTransactionByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryFindTransactionsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    createdById?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindUserByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryFormatInstanceAssetDownloadUrlArgs = {
    input: FormatInstanceAssetDownloadInput;
};

export type QueryFormatInstanceAssetStreamingUrlArgs = {
    input: AssetStreamingUrlInput;
};

export type QueryFormatInstanceVideoAssetThumbnailUrlArgs = {
    input: FormatInstanceVideoAssetThumbnailUrlInput;
};

export type QueryGetManyAudiosArgs = {
    input: GetManyAudiosInput;
};

export type QueryGetManyDocumentsArgs = {
    input: GetManyDocumentsInput;
};

export type QueryGetManyImagesArgs = {
    input: GetManyImagesInput;
};

export type QueryGetManyOthersArgs = {
    input: GetManyOthersInput;
};

export type QueryGetManyVideosArgs = {
    input: GetManyVideosInput;
};

export type QueryGetMyDraftArgs = {
    id: Scalars['String']['input'];
};

export type QueryGetMyDraftsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Float']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    status?: InputMaybe<DraftStatus>;
    type?: InputMaybe<DraftType>;
};

export type QueryHasPermissionsArgs = {
    keys: Array<PermissionKey>;
};

export type QueryIsMemberArgs = {
    accountId: Scalars['String']['input'];
};

export type QueryListArtistsByLettersArgs = {
    letters: Array<Scalars['String']['input']>;
};

export type QueryPaymentUrlArgs = {
    paymentUrlInput: PaymentUrlInput;
};

export type QueryPreviewImageArgs = {
    id: Scalars['String']['input'];
};

export type QueryProductFormatArgs = {
    filters: ProductFormatFilters;
};

export type QueryProductFormatsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    filters?: InputMaybe<ProductFormatsFilters>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    orderBy?: InputMaybe<Array<ProductFormatOrderBy>>;
};

export type QueryVoucherArgs = {
    voucher: ClaimVoucherInput;
};

export type ReservationSummary = {
    __typename?: 'ReservationSummary';
    id: Scalars['String']['output'];
    listing: Listing;
    paymentStatus: Scalars['String']['output'];
    presentmentAmount: Scalars['Float']['output'];
    presentmentCurrency: Scalars['String']['output'];
    provider: Scalars['String']['output'];
    settlementAmount: Scalars['Float']['output'];
    settlementCurrency: Scalars['String']['output'];
    transactionId: Scalars['String']['output'];
    transactionStatus: Scalars['String']['output'];
    userId: Scalars['String']['output'];
};

export type ReservedEdition = {
    __typename?: 'ReservedEdition';
    coinbaseCharge?: Maybe<CoinbaseCharge>;
    coinbaseChargeId?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    product?: Maybe<Product>;
    productId?: Maybe<Scalars['String']['output']>;
    provider?: Maybe<Scalars['String']['output']>;
    status: ReservedEditionStatus;
    stripeCheckoutSession?: Maybe<StripeCheckoutSession>;
    stripeCheckoutSessionId?: Maybe<Scalars['String']['output']>;
    tokenId: Scalars['String']['output'];
    updated: Scalars['Date']['output'];
    user?: Maybe<User>;
    userId?: Maybe<Scalars['String']['output']>;
};

export enum ReservedEditionStatus {
    Allocated = 'Allocated',
}

export type ReservedProduct = {
    __typename?: 'ReservedProduct';
    coinbaseCharge?: Maybe<CoinbaseCharge>;
    coinbaseChargeId?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    product?: Maybe<Product>;
    productId?: Maybe<Scalars['String']['output']>;
    provider?: Maybe<Scalars['String']['output']>;
    status: ReservedProductStatus;
    stripeCheckoutSession?: Maybe<StripeCheckoutSession>;
    stripeCheckoutSessionId?: Maybe<Scalars['String']['output']>;
    tokenId: Scalars['String']['output'];
    updated: Scalars['Date']['output'];
};

export enum ReservedProductStatus {
    PaymentCancelled = 'PaymentCancelled',
    PaymentCompleted = 'PaymentCompleted',
    PaymentFailed = 'PaymentFailed',
    PaymentPending = 'PaymentPending',
}

export type Reward = {
    __typename?: 'Reward';
    audio?: Maybe<Audio>;
    audioId?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    displayOrder: Scalars['Float']['output'];
    document?: Maybe<Document>;
    documentId?: Maybe<Scalars['String']['output']>;
    downloadable: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    image?: Maybe<Image>;
    imageId?: Maybe<Scalars['String']['output']>;
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    other?: Maybe<Other>;
    otherId?: Maybe<Scalars['String']['output']>;
    playable: Scalars['Boolean']['output'];
    rewardType: RewardType;
    updated: Scalars['Date']['output'];
    video?: Maybe<Video>;
    videoId?: Maybe<Scalars['String']['output']>;
};

export enum RewardType {
    BonusMaterial = 'BonusMaterial',
    Experience = 'Experience',
    Token = 'Token',
}

export type RightsHolder = {
    __typename?: 'RightsHolder';
    account: Account;
    accountId: Scalars['String']['output'];
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    domainId?: Maybe<Scalars['String']['output']>;
    email: Scalars['String']['output'];
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    name?: Maybe<Scalars['String']['output']>;
    paymentChannels?: Maybe<Array<PaymentChannel>>;
    updated: Scalars['Date']['output'];
};

export type Royalty = {
    __typename?: 'Royalty';
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    edition?: Maybe<Edition>;
    editionId?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    payee: Scalars['String']['output'];
    product?: Maybe<Product>;
    productId?: Maybe<Scalars['String']['output']>;
    share: Scalars['Float']['output'];
    type: RoyaltyType;
    updated: Scalars['Date']['output'];
};

export type RoyaltyEdge = {
    __typename?: 'RoyaltyEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<Royalty>;
};

export type RoyaltyPageInfo = {
    __typename?: 'RoyaltyPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type RoyaltyRelay = {
    __typename?: 'RoyaltyRelay';
    edges?: Maybe<Array<RoyaltyEdge>>;
    pageInfo?: Maybe<RoyaltyPageInfo>;
};

export enum RoyaltyType {
    Primary = 'Primary',
    Secondary = 'Secondary',
}

export type SetNftTransfersInput = {
    /** Set to 'true' to enable transfers or 'false' to disable transfers */
    allowTransfer: Scalars['Boolean']['input'];
    productId: Scalars['String']['input'];
};

export type StripeCheckoutSession = {
    __typename?: 'StripeCheckoutSession';
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    listing: Listing;
    listingId: Scalars['String']['output'];
    updated: Scalars['Date']['output'];
    url: Scalars['String']['output'];
    user: User;
    userId: Scalars['String']['output'];
};

export type SubscribeToMarketingInput = {
    email: Scalars['String']['input'];
};

export type Track = {
    __typename?: 'Track';
    audio: Audio;
    audioId: Scalars['String']['output'];
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    isrc?: Maybe<Scalars['String']['output']>;
    metadata?: Maybe<Scalars['JSON']['output']>;
    metadataId?: Maybe<Scalars['String']['output']>;
    trackName: Scalars['String']['output'];
    /** To specify the track order */
    trackNumber: Scalars['Float']['output'];
    updated: Scalars['Date']['output'];
};

export type TrackWithMetadata = {
    __typename?: 'TrackWithMetadata';
    audio: Audio;
    id: Scalars['String']['output'];
    isrc?: Maybe<Scalars['String']['output']>;
    metadata?: Maybe<Scalars['JSON']['output']>;
    trackName: Scalars['String']['output'];
    trackNumber: Scalars['Float']['output'];
};

export type TracksDownloadCount = {
    __typename?: 'TracksDownloadCount';
    allowedDownloadCount: Scalars['Float']['output'];
    downloadCount: Scalars['Float']['output'];
};

export type Transaction = {
    __typename?: 'Transaction';
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    failureReason?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    listing: Listing;
    listingId: Scalars['String']['output'];
    presentmentAmount?: Maybe<Scalars['String']['output']>;
    presentmentCurrency?: Maybe<Scalars['String']['output']>;
    settlementAmount?: Maybe<Scalars['String']['output']>;
    settlementCurrency?: Maybe<Scalars['String']['output']>;
    status: TransactionStatus;
    statusChangedAt?: Maybe<Scalars['Date']['output']>;
    transactionHash?: Maybe<Scalars['String']['output']>;
    updated: Scalars['Date']['output'];
    user: User;
    userId: Scalars['String']['output'];
};

export type TransactionEdge = {
    __typename?: 'TransactionEdge';
    cursor?: Maybe<Scalars['String']['output']>;
    node?: Maybe<Transaction>;
};

export type TransactionPageInfo = {
    __typename?: 'TransactionPageInfo';
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    hasPreviousPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type TransactionRelay = {
    __typename?: 'TransactionRelay';
    edges?: Maybe<Array<TransactionEdge>>;
    pageInfo?: Maybe<TransactionPageInfo>;
};

export enum TransactionStatus {
    Completed = 'Completed',
    Failed = 'Failed',
    Pending = 'Pending',
}

export type UnsubscribeFromMarketingInput = {
    email: Scalars['String']['input'];
};

export type UpdateAccountInput = {
    avatar?: InputMaybe<UpdateImageInput>;
    bio?: InputMaybe<Scalars['String']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    missionStatement?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    socials?: InputMaybe<Scalars['JSON']['input']>;
    tags?: InputMaybe<Array<AccountTag>>;
    type?: InputMaybe<AccountType>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDraftInput = {
    data?: InputMaybe<DraftDataInput>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateImageInput = {
    file: CreateFilePropertiesInput;
    height?: InputMaybe<Scalars['Float']['input']>;
    url: Scalars['String']['input'];
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateListingInput = {
    currency?: InputMaybe<Currency>;
    plannedCloseDateTime?: InputMaybe<Scalars['String']['input']>;
    plannedCloseTimeZone?: InputMaybe<Scalars['String']['input']>;
    price?: InputMaybe<Scalars['Float']['input']>;
    releaseDateTime?: InputMaybe<Scalars['String']['input']>;
    releaseTimeZone?: InputMaybe<Scalars['String']['input']>;
    saleType?: InputMaybe<ListingSaleType>;
    tags?: InputMaybe<Array<ListingTag>>;
};

export type UpdateProductInput = {
    additionalDetails?: InputMaybe<Scalars['String']['input']>;
    audioId?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    imageId?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Float']['input']>;
    parentRecordLabel?: InputMaybe<ParentRecordLabel>;
    rewards?: InputMaybe<Array<ProductRewardInput>>;
    tags?: InputMaybe<Array<OriginalProductTag>>;
    termsAndConditionsUrl?: InputMaybe<Scalars['String']['input']>;
    thumbnailId?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    videoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductMetadataInput = {
    artworkBy?: InputMaybe<Array<Scalars['String']['input']>>;
    performedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    producedBy?: InputMaybe<Array<Scalars['String']['input']>>;
    recordLabel?: InputMaybe<Scalars['String']['input']>;
    writtenBy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateProductWithMetadataInput = {
    additionalDetails?: InputMaybe<Scalars['String']['input']>;
    audioId?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    genres?: InputMaybe<Array<Genre>>;
    imageId?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Float']['input']>;
    metadata?: InputMaybe<UpdateProductMetadataInput>;
    parentRecordLabel?: InputMaybe<ParentRecordLabel>;
    rewards?: InputMaybe<Array<ProductRewardInput>>;
    tags?: InputMaybe<Array<OriginalProductTag>>;
    termsAndConditionsUrl?: InputMaybe<Scalars['String']['input']>;
    thumbnailId?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    videoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoyaltyInput = {
    payee?: InputMaybe<Scalars['String']['input']>;
    share?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
    __typename?: 'User';
    accounts: Array<Account>;
    country?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    /** @deprecated Wallet Service. */
    defaultWallet: Wallet;
    defaultWalletId?: Maybe<Scalars['String']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    /** Only available to admins with AdminViewUserDetails permission */
    email?: Maybe<Scalars['String']['output']>;
    firstName: Scalars['String']['output'];
    id: Scalars['String']['output'];
    idm?: Maybe<IdentityManagementSystem>;
    idmId?: Maybe<Scalars['String']['output']>;
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    isEmailVerified: Scalars['Boolean']['output'];
    lastName: Scalars['String']['output'];
    reservedEditions: Array<ReservedEdition>;
    reservedProducts: Array<ReservedProduct>;
    schemaVersion?: Maybe<UserSchemaVersion>;
    updated: Scalars['Date']['output'];
    wallets: Array<Wallet>;
};

export enum UserSchemaVersion {
    v1_0_0 = 'v1_0_0',
    v2_0_0 = 'v2_0_0',
}

export type Video = {
    __typename?: 'Video';
    checksum?: Maybe<Scalars['String']['output']>;
    created: Scalars['Date']['output'];
    createdBy: User;
    createdById?: Maybe<Scalars['String']['output']>;
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    duration?: Maybe<Scalars['Float']['output']>;
    file: FileProperties;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    muxAssetId?: Maybe<Scalars['String']['output']>;
    muxPlaybackId?: Maybe<Scalars['String']['output']>;
    muxPlaybackPolicy: MuxPlaybackPolicy;
    updated: Scalars['Date']['output'];
};

export type Voucher = {
    __typename?: 'Voucher';
    id: Scalars['String']['output'];
    product: VoucherArtistProduct;
    status: VoucherStatus;
};

export type VoucherArtistProduct = {
    __typename?: 'VoucherArtistProduct';
    artistName: Scalars['String']['output'];
    artworkUrl: Scalars['String']['output'];
    id: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export type VoucherCodeInput = {
    voucherCode: Scalars['String']['input'];
};

export enum VoucherStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export type Wallet = {
    __typename?: 'Wallet';
    address: Scalars['String']['output'];
    created: Scalars['Date']['output'];
    deactivated?: Maybe<Scalars['Date']['output']>;
    deleted?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    isDeleted: Scalars['Boolean']['output'];
    nickname: Scalars['String']['output'];
    provider: WalletProvider;
    type: WalletType;
    updated: Scalars['Date']['output'];
    user?: Maybe<User>;
    userId?: Maybe<Scalars['String']['output']>;
};

export enum WalletProvider {
    Kred = 'Kred',
    Torus = 'Torus',
}

export enum WalletType {
    Hosted = 'Hosted',
    SelfCustody = 'SelfCustody',
}

export type AccountFragment = {
    __typename?: 'Account';
    id: string;
    isActive: boolean;
    isDeleted: boolean;
    type: AccountType;
    status: AccountStatus;
    name: string;
    username: string;
    genres: Array<Genre>;
    bio?: string | null;
    missionStatement?: string | null;
    socials: any;
    tags: Array<AccountTag>;
    avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
};

export type AccountTileFragmentFragment = {
    __typename?: 'Account';
    id: string;
    type: AccountType;
    status: AccountStatus;
    name: string;
    username: string;
    genres: Array<Genre>;
    tags: Array<AccountTag>;
    avatar?: { __typename?: 'Image'; id: string; url: string } | null;
};

export type UpdateAvatarMutationVariables = Exact<{
    input: CreateImageInput;
}>;

export type UpdateAvatarMutation = {
    __typename?: 'Mutation';
    updateAvatar: { __typename?: 'Account'; avatar?: { __typename?: 'Image'; id: string; url: string } | null };
};

export type UpdateAccountMutationVariables = Exact<{
    id: Scalars['String']['input'];
    user?: InputMaybe<UpdateUserInput>;
    account: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
    __typename?: 'Mutation';
    updateAccount: {
        __typename?: 'Account';
        id: string;
        isActive: boolean;
        isDeleted: boolean;
        type: AccountType;
        status: AccountStatus;
        name: string;
        username: string;
        genres: Array<Genre>;
        bio?: string | null;
        missionStatement?: string | null;
        socials: any;
        tags: Array<AccountTag>;
        avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
    };
};

export type FindAccountQueryVariables = Exact<{
    id?: InputMaybe<Scalars['String']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindAccountQuery = {
    __typename?: 'Query';
    findAccount?: {
        __typename?: 'Account';
        id: string;
        isActive: boolean;
        isDeleted: boolean;
        type: AccountType;
        status: AccountStatus;
        name: string;
        username: string;
        genres: Array<Genre>;
        bio?: string | null;
        missionStatement?: string | null;
        socials: any;
        tags: Array<AccountTag>;
        avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
    } | null;
};

export type FindAccountIdQueryVariables = Exact<{
    id?: InputMaybe<Scalars['String']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindAccountIdQuery = { __typename?: 'Query'; findAccount?: { __typename?: 'Account'; id: string } | null };

export type FindTopAccountsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Float']['input']>;
    type: AccountType;
    tags?: InputMaybe<Array<AccountTag> | AccountTag>;
    status?: InputMaybe<AccountStatus>;
}>;

export type FindTopAccountsQuery = {
    __typename?: 'Query';
    findAccounts: {
        __typename?: 'AccountRelay';
        edges?: Array<{
            __typename?: 'AccountEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Account';
                id: string;
                type: AccountType;
                status: AccountStatus;
                name: string;
                username: string;
                genres: Array<Genre>;
                tags: Array<AccountTag>;
                avatar?: { __typename?: 'Image'; id: string; url: string } | null;
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'AccountPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type FindAccountsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Float']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    type: AccountType;
    username?: InputMaybe<Scalars['String']['input']>;
    tags?: InputMaybe<Array<AccountTag> | AccountTag>;
    status?: InputMaybe<AccountStatus>;
}>;

export type FindAccountsQuery = {
    __typename?: 'Query';
    findAccounts: {
        __typename?: 'AccountRelay';
        edges?: Array<{
            __typename?: 'AccountEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Account';
                id: string;
                isActive: boolean;
                isDeleted: boolean;
                type: AccountType;
                status: AccountStatus;
                name: string;
                username: string;
                genres: Array<Genre>;
                bio?: string | null;
                missionStatement?: string | null;
                socials: any;
                tags: Array<AccountTag>;
                avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'AccountPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type AvailableArtistLettersQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableArtistLettersQuery = { __typename?: 'Query'; availableArtistLetters: Array<string> };

export type ListApprovedArtistsNavQueryVariables = Exact<{ [key: string]: never }>;

export type ListApprovedArtistsNavQuery = {
    __typename?: 'Query';
    findApprovedArtists: Array<{
        __typename?: 'Account';
        id: string;
        name: string;
        username: string;
        tags: Array<AccountTag>;
    }>;
};

export type ProductCountQueryVariables = Exact<{
    accountId: Scalars['String']['input'];
}>;

export type ProductCountQuery = { __typename?: 'Query'; countProducts: number };

export type IsMemberQueryVariables = Exact<{
    accountId: Scalars['String']['input'];
}>;

export type IsMemberQuery = { __typename?: 'Query'; isMember: boolean };

export type CreateAudioMutationVariables = Exact<{
    input: CreateAudioInput;
}>;

export type CreateAudioMutation = {
    __typename?: 'Mutation';
    audio: {
        __typename?: 'Audio';
        id: string;
        muxPlaybackId?: string | null;
        file: { __typename?: 'FileProperties'; fileType: FileType; originalFilename: string };
    };
};

export type GetManyAudiosQueryVariables = Exact<{
    input: GetManyAudiosInput;
}>;

export type GetManyAudiosQuery = {
    __typename?: 'Query';
    getManyAudios: Array<{
        __typename?: 'Audio';
        id: string;
        muxPlaybackId?: string | null;
        duration?: number | null;
        file: {
            __typename?: 'FileProperties';
            fileType: FileType;
            originalFilename: string;
            size: number;
            url: string;
        };
    }>;
};

export type RegisterMutationVariables = Exact<{
    user: CreateUserInput;
    account: CreateAccountInput;
}>;

export type RegisterMutation = {
    __typename?: 'Mutation';
    register: { __typename?: 'ClientTokensResponse'; accessToken: string; refreshToken: string };
};

export type FindProfileQueryVariables = Exact<{ [key: string]: never }>;

export type FindProfileQuery = {
    __typename?: 'Query';
    findProfile?: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        country?: string | null;
        isEmailVerified: boolean;
        accounts: Array<{
            __typename?: 'Account';
            id: string;
            isActive: boolean;
            isDeleted: boolean;
            type: AccountType;
            status: AccountStatus;
            name: string;
            username: string;
            genres: Array<Genre>;
            bio?: string | null;
            missionStatement?: string | null;
            socials: any;
            tags: Array<AccountTag>;
            avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
        }>;
    } | null;
};

export type GetUpdatedTokensMutationVariables = Exact<{
    refreshToken: Scalars['String']['input'];
}>;

export type GetUpdatedTokensMutation = {
    __typename?: 'Mutation';
    getUpdatedTokens: { __typename?: 'ClientTokensResponse'; accessToken: string; refreshToken: string };
};

export type HasPermissionsQueryVariables = Exact<{
    keys: Array<PermissionKey> | PermissionKey;
}>;

export type HasPermissionsQuery = { __typename?: 'Query'; hasPermissions: boolean };

export type ResendEmailVerificationEmailMutationVariables = Exact<{
    voucher?: InputMaybe<VoucherCodeInput>;
}>;

export type ResendEmailVerificationEmailMutation = {
    __typename?: 'Mutation';
    resendEmailVerificationEmail: { __typename?: 'EmailStatusResponse'; sendResult: string };
};

export type ForgotPasswordMutationVariables = Exact<{
    email: Scalars['String']['input'];
}>;

export type ForgotPasswordMutation = { __typename?: 'Mutation'; forgotPassword: boolean };

export type LoginMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    login: { __typename?: 'ClientTokensResponseWithPlatformAdmin'; accessToken: string; refreshToken: string };
};

export type CreateDocumentMutationVariables = Exact<{
    input: CreateDocumentInput;
}>;

export type CreateDocumentMutation = {
    __typename?: 'Mutation';
    document: {
        __typename?: 'Document';
        id: string;
        url: string;
        file: { __typename?: 'FileProperties'; fileType: FileType; originalFilename: string };
    };
};

export type GetManyDocumentsQueryVariables = Exact<{
    input: GetManyDocumentsInput;
}>;

export type GetManyDocumentsQuery = {
    __typename?: 'Query';
    getManyDocuments: Array<{
        __typename?: 'Document';
        id: string;
        url: string;
        file: {
            __typename?: 'FileProperties';
            fileType: FileType;
            originalFilename: string;
            size: number;
            url: string;
        };
    }>;
};

export type DraftFragment = {
    __typename?: 'Draft';
    id: string;
    status: DraftStatus;
    type: DraftType;
    updated: any;
    data: {
        __typename?: 'DraftData';
        data?: {
            __typename?: 'DraftDataData';
            pressingType?: string | null;
            coverArt?: { __typename?: 'DraftCoverArtType'; imageId?: string | null; url?: string | null } | null;
            credit?: {
                __typename?: 'DraftProductCreditsType';
                artworkBy?: Array<string> | null;
                performedBy?: Array<string> | null;
                producedBy?: Array<string> | null;
                recordLabel?: string | null;
                writtenBy?: Array<string> | null;
            } | null;
            detail?: {
                __typename?: 'DraftPressingDetailType';
                description?: string | null;
                genres?: Array<Genre> | null;
                limit?: number | null;
                pressingType?: PressingType | null;
                subGenres?: Array<Genre> | null;
                termsAndConditionsUrl?: string | null;
                title?: string | null;
                upc?: string | null;
            } | null;
            listing?: {
                __typename?: 'DraftListingType';
                currency?: Currency | null;
                price?: string | null;
                releaseDateTime?: string | null;
                releaseTimeZone?: string | null;
            } | null;
            rewards?: Array<{
                __typename?: 'DraftRewardsType';
                displayOrder?: number | null;
                fileId?: string | null;
                fileType?: FileType | null;
                muxPlaybackId?: string | null;
                name?: string | null;
                url?: string | null;
            }> | null;
            tracks?: Array<{
                __typename?: 'DraftAudioTrackType';
                audioId?: string | null;
                isrc?: string | null;
                muxPlaybackId?: string | null;
                trackName?: string | null;
                trackNumber?: number | null;
            }> | null;
        } | null;
    };
};

export type GetMyDraftQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetMyDraftQuery = {
    __typename?: 'Query';
    getMyDraft: {
        __typename?: 'Draft';
        id: string;
        status: DraftStatus;
        type: DraftType;
        updated: any;
        data: {
            __typename?: 'DraftData';
            data?: {
                __typename?: 'DraftDataData';
                pressingType?: string | null;
                coverArt?: { __typename?: 'DraftCoverArtType'; imageId?: string | null; url?: string | null } | null;
                credit?: {
                    __typename?: 'DraftProductCreditsType';
                    artworkBy?: Array<string> | null;
                    performedBy?: Array<string> | null;
                    producedBy?: Array<string> | null;
                    recordLabel?: string | null;
                    writtenBy?: Array<string> | null;
                } | null;
                detail?: {
                    __typename?: 'DraftPressingDetailType';
                    description?: string | null;
                    genres?: Array<Genre> | null;
                    limit?: number | null;
                    pressingType?: PressingType | null;
                    subGenres?: Array<Genre> | null;
                    termsAndConditionsUrl?: string | null;
                    title?: string | null;
                    upc?: string | null;
                } | null;
                listing?: {
                    __typename?: 'DraftListingType';
                    currency?: Currency | null;
                    price?: string | null;
                    releaseDateTime?: string | null;
                    releaseTimeZone?: string | null;
                } | null;
                rewards?: Array<{
                    __typename?: 'DraftRewardsType';
                    displayOrder?: number | null;
                    fileId?: string | null;
                    fileType?: FileType | null;
                    muxPlaybackId?: string | null;
                    name?: string | null;
                    url?: string | null;
                }> | null;
                tracks?: Array<{
                    __typename?: 'DraftAudioTrackType';
                    audioId?: string | null;
                    isrc?: string | null;
                    muxPlaybackId?: string | null;
                    trackName?: string | null;
                    trackNumber?: number | null;
                }> | null;
            } | null;
        };
    };
};

export type GetMyDraftsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Float']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<DraftStatus>;
}>;

export type GetMyDraftsQuery = {
    __typename?: 'Query';
    getMyDrafts: {
        __typename?: 'DraftRelay';
        edges?: Array<{
            __typename?: 'DraftEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Draft';
                id: string;
                data: {
                    __typename?: 'DraftData';
                    data?: {
                        __typename?: 'DraftDataData';
                        pressingType?: string | null;
                        detail?: { __typename?: 'DraftPressingDetailType'; title?: string | null } | null;
                        coverArt?: { __typename?: 'DraftCoverArtType'; url?: string | null } | null;
                    } | null;
                };
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'DraftPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type CreateDraftMutationVariables = Exact<{
    input: CreateDraftInput;
}>;

export type CreateDraftMutation = { __typename?: 'Mutation'; createDraft: { __typename?: 'Draft'; id: string } };

export type UpdateMyDraftMutationVariables = Exact<{
    id: Scalars['String']['input'];
    input: UpdateDraftInput;
}>;

export type UpdateMyDraftMutation = {
    __typename?: 'Mutation';
    updateMyDraft: {
        __typename?: 'Draft';
        id: string;
        status: DraftStatus;
        type: DraftType;
        updated: any;
        data: {
            __typename?: 'DraftData';
            data?: {
                __typename?: 'DraftDataData';
                pressingType?: string | null;
                coverArt?: { __typename?: 'DraftCoverArtType'; imageId?: string | null; url?: string | null } | null;
                credit?: {
                    __typename?: 'DraftProductCreditsType';
                    artworkBy?: Array<string> | null;
                    performedBy?: Array<string> | null;
                    producedBy?: Array<string> | null;
                    recordLabel?: string | null;
                    writtenBy?: Array<string> | null;
                } | null;
                detail?: {
                    __typename?: 'DraftPressingDetailType';
                    description?: string | null;
                    genres?: Array<Genre> | null;
                    limit?: number | null;
                    pressingType?: PressingType | null;
                    subGenres?: Array<Genre> | null;
                    termsAndConditionsUrl?: string | null;
                    title?: string | null;
                    upc?: string | null;
                } | null;
                listing?: {
                    __typename?: 'DraftListingType';
                    currency?: Currency | null;
                    price?: string | null;
                    releaseDateTime?: string | null;
                    releaseTimeZone?: string | null;
                } | null;
                rewards?: Array<{
                    __typename?: 'DraftRewardsType';
                    displayOrder?: number | null;
                    fileId?: string | null;
                    fileType?: FileType | null;
                    muxPlaybackId?: string | null;
                    name?: string | null;
                    url?: string | null;
                }> | null;
                tracks?: Array<{
                    __typename?: 'DraftAudioTrackType';
                    audioId?: string | null;
                    isrc?: string | null;
                    muxPlaybackId?: string | null;
                    trackName?: string | null;
                    trackNumber?: number | null;
                }> | null;
            } | null;
        };
    };
};

export type ConvertMyDraftProductMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type ConvertMyDraftProductMutation = {
    __typename?: 'Mutation';
    convertMyDraftProduct: {
        __typename?: 'Product';
        id: string;
        slug: string;
        account: { __typename?: 'Account'; username: string };
    };
};

export type GetAssetStreamingUrlQueryVariables = Exact<{
    input: AssetStreamingUrlInput;
}>;

export type GetAssetStreamingUrlQuery = { __typename?: 'Query'; formatInstanceAssetStreamingUrl: string };

export type GetAssetDownloadUrlQueryVariables = Exact<{
    input: FormatInstanceAssetDownloadInput;
}>;

export type GetAssetDownloadUrlQuery = { __typename?: 'Query'; formatInstanceAssetDownloadUrl: string };

export type GetVideoAssetThumbnailUrlQueryVariables = Exact<{
    input: FormatInstanceVideoAssetThumbnailUrlInput;
}>;

export type GetVideoAssetThumbnailUrlQuery = { __typename?: 'Query'; formatInstanceVideoAssetThumbnailUrl: string };

export type GetCompressedTrackDownloadInfoQueryVariables = Exact<{
    editionId: Scalars['String']['input'];
}>;

export type GetCompressedTrackDownloadInfoQuery = {
    __typename?: 'Query';
    compressedTrackDownloadInfo: { __typename?: 'CompressedTrackDownloadInfo'; url: string; userDownloadCount: number };
};

export type GetEditionFormatQueryVariables = Exact<{
    filters: EditionFormatFilters;
}>;

export type GetEditionFormatQuery = {
    __typename?: 'Query';
    editionFormat?: {
        __typename?: 'EditionFormat';
        artworkUrl: string;
        behindTheMusic?: string | null;
        contentConsumable?: boolean | null;
        editionId?: string | null;
        editionSequence?: number | null;
        editionSize?: number | null;
        editionStatus?: EditionStatusNew | null;
        formatInstanceId: string;
        formatInstanceStatus: FormatInstanceStatus;
        formatSubType: FormatSubType;
        formatType: FormatType;
        limited: boolean;
        parentRecordLabel?: ParentRecordLabel | null;
        plannedCloseDate?: string | null;
        plannedCloseDateTimezone?: string | null;
        plannedReleaseDate?: string | null;
        plannedReleaseDateTimezone?: string | null;
        plannedPreOrderStartDate?: string | null;
        plannedPreOrderStartDateTimezone?: string | null;
        preOrder: boolean;
        productId: string;
        productStatus: ProductStatusNew;
        recordLabel?: string | null;
        slug: string;
        soldOut: boolean;
        termsAndConditionsUrl?: string | null;
        title: string;
        artist: { __typename?: 'Artist'; name: string; profilePicUrl?: string | null; username: string };
        collectible?: {
            __typename?: 'Collectible';
            audio?: { __typename?: 'CollectibleAsset'; assetId: string; mimeType: string } | null;
            image?: { __typename?: 'CollectibleAsset'; assetId: string; mimeType: string } | null;
            video?: { __typename?: 'CollectibleAsset'; assetId: string; mimeType: string } | null;
        } | null;
        editionOwner?: { __typename?: 'EditionOwner'; profilePicUrl?: string | null; username: string } | null;
        pressing?: {
            __typename?: 'Pressing';
            bonusContent: Array<{
                __typename?: 'BonusContent';
                active?: boolean | null;
                assetId?: string | null;
                displayName?: string | null;
                downloadable?: boolean | null;
                duration?: number | null;
                fileSize?: number | null;
                mimeType?: string | null;
                streamable?: boolean | null;
            }>;
            compressedTracks?: {
                __typename?: 'CompressedTracks';
                downloadLimit: number;
                userDownloadCount?: number | null;
                fileSize?: number | null;
            } | null;
            credits?: {
                __typename?: 'Credits';
                artDirectionBy?: Array<string> | null;
                artworkBy?: Array<string> | null;
                creativeDirectionBy?: Array<string> | null;
                designedBy?: Array<string> | null;
                performedBy?: Array<string> | null;
                photographyBy?: Array<string> | null;
                producedBy?: Array<string> | null;
                writtenBy?: Array<string> | null;
            } | null;
            tracks: Array<{
                __typename?: 'PressingTrack';
                active?: boolean | null;
                assetId?: string | null;
                displayName?: string | null;
                downloadable?: boolean | null;
                duration?: number | null;
                fileSize?: number | null;
                mimeType?: string | null;
                streamable?: boolean | null;
                trackNumber?: number | null;
                fileExtension?: string | null;
            }>;
        } | null;
    } | null;
};

export type GetEditionFormatsForProfilePageQueryVariables = Exact<{
    filters: EditionFormatsFilters;
    first?: InputMaybe<Scalars['Int']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEditionFormatsForProfilePageQuery = {
    __typename?: 'Query';
    editionFormats?: {
        __typename?: 'EditionFormatConnection';
        edges: Array<{
            __typename?: 'EditionFormatConnectionEdge';
            cursor: string;
            node: {
                __typename?: 'EditionFormat';
                editionId?: string | null;
                title: string;
                slug: string;
                productStatus: ProductStatusNew;
                editionSequence?: number | null;
                editionSize?: number | null;
                artworkUrl: string;
                formatType: FormatType;
                formatSubType: FormatSubType;
                limited: boolean;
                preOrder: boolean;
                soldOut: boolean;
                editionStatus?: EditionStatusNew | null;
                tokenId?: string | null;
                contentConsumable?: boolean | null;
                wholesale: boolean;
                plannedCloseDate?: string | null;
                plannedCloseDateTimezone?: string | null;
                plannedReleaseDate?: string | null;
                plannedReleaseDateTimezone?: string | null;
                editionOwner?: { __typename?: 'EditionOwner'; profilePicUrl?: string | null; username: string } | null;
                artist: { __typename?: 'Artist'; name: string; profilePicUrl?: string | null; username: string };
            };
        }>;
        pageInfo: {
            __typename?: 'PageInfo';
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            totalCount: number;
            startCursor: string;
        };
    } | null;
};

export type EditionTileFragmentFragment = {
    __typename?: 'Edition';
    id: string;
    sequence: number;
    status: EditionStatus;
    tokenId: string;
    transactionHash?: string | null;
    product: {
        __typename?: 'Product';
        id: string;
        status: ProductStatus;
        allowTransfers: boolean;
        title: string;
        slug: string;
        additionalDetails?: string | null;
        minted: number;
        limit?: number | null;
        productType?: ProductType | null;
        thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
        account: {
            __typename?: 'Account';
            id: string;
            name: string;
            username: string;
            avatar?: { __typename?: 'Image'; id: string; url: string } | null;
        };
        pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
        listings: Array<{ __typename?: 'Listing'; id: string; saleType: ListingSaleType }>;
    };
};

export type EditionFragment = {
    __typename?: 'Edition';
    id: string;
    sequence: number;
    status: EditionStatus;
    tokenId: string;
    transactionHash?: string | null;
    product: {
        __typename?: 'Product';
        id: string;
        status: ProductStatus;
        allowTransfers: boolean;
        title: string;
        slug: string;
        description: string;
        additionalDetails?: string | null;
        termsAndConditionsUrl?: string | null;
        minted: number;
        limit?: number | null;
        parentRecordLabel?: ParentRecordLabel | null;
        metadata?: any | null;
        productType?: ProductType | null;
        thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
        account: {
            __typename?: 'Account';
            id: string;
            isActive: boolean;
            isDeleted: boolean;
            type: AccountType;
            status: AccountStatus;
            name: string;
            username: string;
            genres: Array<Genre>;
            bio?: string | null;
            missionStatement?: string | null;
            socials: any;
            tags: Array<AccountTag>;
            avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
        };
        collectible?: {
            __typename?: 'ProductCollectible';
            image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            audio?: { __typename?: 'Audio'; id: string } | null;
            video?: { __typename?: 'Video'; id: string } | null;
        } | null;
        pressing?: {
            __typename?: 'ProductPressing';
            pressingType: PressingType;
            coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            tracks: Array<{
                __typename?: 'Track';
                id: string;
                trackName: string;
                trackNumber: number;
                metadata?: any | null;
                audio: {
                    __typename?: 'Audio';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number; extension: string };
                };
            }>;
        } | null;
        rewards?: Array<{
            __typename?: 'Reward';
            id: string;
            name: string;
            downloadable: boolean;
            displayOrder: number;
            playable: boolean;
            created: any;
            image?: {
                __typename?: 'Image';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            video?: { __typename?: 'Video'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            audio?: { __typename?: 'Audio'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            document?: {
                __typename?: 'Document';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
        }> | null;
    };
};

export type CreateDownloadableTracksUrlMutationVariables = Exact<{
    editionId: Scalars['String']['input'];
}>;

export type CreateDownloadableTracksUrlMutation = { __typename?: 'Mutation'; createDownloadableTracksUrl: string };

export type FindEditionForProductPageQueryVariables = Exact<{
    productId: Scalars['String']['input'];
    sequence: Scalars['Float']['input'];
}>;

export type FindEditionForProductPageQuery = {
    __typename?: 'Query';
    findEdition?: {
        __typename?: 'Edition';
        id: string;
        sequence: number;
        status: EditionStatus;
        tokenId: string;
        transactionHash?: string | null;
    } | null;
};

export type FindEditionQueryVariables = Exact<{
    productId?: InputMaybe<Scalars['String']['input']>;
    sequence?: InputMaybe<Scalars['Float']['input']>;
}>;

export type FindEditionQuery = {
    __typename?: 'Query';
    findEdition?: {
        __typename?: 'Edition';
        id: string;
        sequence: number;
        status: EditionStatus;
        tokenId: string;
        transactionHash?: string | null;
        product: {
            __typename?: 'Product';
            id: string;
            status: ProductStatus;
            allowTransfers: boolean;
            title: string;
            slug: string;
            description: string;
            additionalDetails?: string | null;
            termsAndConditionsUrl?: string | null;
            minted: number;
            limit?: number | null;
            parentRecordLabel?: ParentRecordLabel | null;
            metadata?: any | null;
            productType?: ProductType | null;
            thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
            account: {
                __typename?: 'Account';
                id: string;
                isActive: boolean;
                isDeleted: boolean;
                type: AccountType;
                status: AccountStatus;
                name: string;
                username: string;
                genres: Array<Genre>;
                bio?: string | null;
                missionStatement?: string | null;
                socials: any;
                tags: Array<AccountTag>;
                avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            };
            collectible?: {
                __typename?: 'ProductCollectible';
                image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                audio?: { __typename?: 'Audio'; id: string } | null;
                video?: { __typename?: 'Video'; id: string } | null;
            } | null;
            pressing?: {
                __typename?: 'ProductPressing';
                pressingType: PressingType;
                coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                tracks: Array<{
                    __typename?: 'Track';
                    id: string;
                    trackName: string;
                    trackNumber: number;
                    metadata?: any | null;
                    audio: {
                        __typename?: 'Audio';
                        id: string;
                        file: { __typename?: 'FileProperties'; size: number; extension: string };
                    };
                }>;
            } | null;
            rewards?: Array<{
                __typename?: 'Reward';
                id: string;
                name: string;
                downloadable: boolean;
                displayOrder: number;
                playable: boolean;
                created: any;
                image?: {
                    __typename?: 'Image';
                    id: string;
                    url: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                video?: {
                    __typename?: 'Video';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                audio?: {
                    __typename?: 'Audio';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                document?: {
                    __typename?: 'Document';
                    id: string;
                    url: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
            }> | null;
        };
    } | null;
};

export type FindEditionsForProfilePageQueryVariables = Exact<{
    username: Scalars['String']['input'];
    includeMinting?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FindEditionsForProfilePageQuery = {
    __typename?: 'Query';
    findEditionsByUsername: Array<{
        __typename?: 'Edition';
        id: string;
        sequence: number;
        status: EditionStatus;
        tokenId: string;
        transactionHash?: string | null;
        product: {
            __typename?: 'Product';
            id: string;
            status: ProductStatus;
            allowTransfers: boolean;
            title: string;
            slug: string;
            additionalDetails?: string | null;
            minted: number;
            limit?: number | null;
            productType?: ProductType | null;
            thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
            account: {
                __typename?: 'Account';
                id: string;
                name: string;
                username: string;
                avatar?: { __typename?: 'Image'; id: string; url: string } | null;
            };
            pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
            listings: Array<{ __typename?: 'Listing'; id: string; saleType: ListingSaleType }>;
        };
    }>;
};

export type FindEditionByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type FindEditionByIdQuery = {
    __typename?: 'Query';
    findEdition?: {
        __typename?: 'Edition';
        id: string;
        sequence: number;
        status: EditionStatus;
        tokenId: string;
        transactionHash?: string | null;
        product: {
            __typename?: 'Product';
            id: string;
            status: ProductStatus;
            allowTransfers: boolean;
            title: string;
            slug: string;
            additionalDetails?: string | null;
            minted: number;
            limit?: number | null;
            productType?: ProductType | null;
            thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
            account: {
                __typename?: 'Account';
                id: string;
                name: string;
                username: string;
                avatar?: { __typename?: 'Image'; id: string; url: string } | null;
            };
            pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
            listings: Array<{ __typename?: 'Listing'; id: string; saleType: ListingSaleType }>;
        };
    } | null;
};

export type FindEditionsByUsernameQueryVariables = Exact<{
    username: Scalars['String']['input'];
    includeMinting?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FindEditionsByUsernameQuery = {
    __typename?: 'Query';
    findEditionsByUsername: Array<{
        __typename?: 'Edition';
        id: string;
        sequence: number;
        status: EditionStatus;
        tokenId: string;
        transactionHash?: string | null;
        product: {
            __typename?: 'Product';
            id: string;
            status: ProductStatus;
            allowTransfers: boolean;
            title: string;
            slug: string;
            description: string;
            additionalDetails?: string | null;
            termsAndConditionsUrl?: string | null;
            minted: number;
            limit?: number | null;
            parentRecordLabel?: ParentRecordLabel | null;
            metadata?: any | null;
            productType?: ProductType | null;
            thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
            account: {
                __typename?: 'Account';
                id: string;
                isActive: boolean;
                isDeleted: boolean;
                type: AccountType;
                status: AccountStatus;
                name: string;
                username: string;
                genres: Array<Genre>;
                bio?: string | null;
                missionStatement?: string | null;
                socials: any;
                tags: Array<AccountTag>;
                avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            };
            collectible?: {
                __typename?: 'ProductCollectible';
                image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                audio?: { __typename?: 'Audio'; id: string } | null;
                video?: { __typename?: 'Video'; id: string } | null;
            } | null;
            pressing?: {
                __typename?: 'ProductPressing';
                pressingType: PressingType;
                coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                tracks: Array<{
                    __typename?: 'Track';
                    id: string;
                    trackName: string;
                    trackNumber: number;
                    metadata?: any | null;
                    audio: {
                        __typename?: 'Audio';
                        id: string;
                        file: { __typename?: 'FileProperties'; size: number; extension: string };
                    };
                }>;
            } | null;
            rewards?: Array<{
                __typename?: 'Reward';
                id: string;
                name: string;
                downloadable: boolean;
                displayOrder: number;
                playable: boolean;
                created: any;
                image?: {
                    __typename?: 'Image';
                    id: string;
                    url: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                video?: {
                    __typename?: 'Video';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                audio?: {
                    __typename?: 'Audio';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                document?: {
                    __typename?: 'Document';
                    id: string;
                    url: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
            }> | null;
        };
    }>;
};

export type FindTracksDownloadCountQueryVariables = Exact<{
    editionId: Scalars['String']['input'];
}>;

export type FindTracksDownloadCountQuery = {
    __typename?: 'Query';
    findTracksDownloadCount: {
        __typename?: 'TracksDownloadCount';
        downloadCount: number;
        allowedDownloadCount: number;
    };
};

export type FirstOwnerFragment = {
    __typename?: 'FirstOwner';
    id: string;
    name: string;
    edition: { __typename?: 'Edition'; id: string; sequence: number };
    account?: {
        __typename?: 'Account';
        id: string;
        username: string;
        avatar?: { __typename?: 'Image'; id: string; url: string } | null;
    } | null;
};

export type FindFirstOwnersQueryVariables = Exact<{
    productSlug: Scalars['String']['input'];
    first: Scalars['Float']['input'];
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindFirstOwnersQuery = {
    __typename?: 'Query';
    findFirstOwners: {
        __typename?: 'FirstOwnerRelay';
        edges?: Array<{
            __typename?: 'FirstOwnerEdge';
            node?: {
                __typename?: 'FirstOwner';
                id: string;
                name: string;
                edition: { __typename?: 'Edition'; id: string; sequence: number };
                account?: {
                    __typename?: 'Account';
                    id: string;
                    username: string;
                    avatar?: { __typename?: 'Image'; id: string; url: string } | null;
                } | null;
            } | null;
        }> | null;
        pageInfo?: { __typename?: 'FirstOwnerPageInfo'; endCursor?: string | null; hasNextPage: boolean } | null;
    };
};

export type SubscribeToMarketingMutationVariables = Exact<{
    input: SubscribeToMarketingInput;
}>;

export type SubscribeToMarketingMutation = { __typename?: 'Mutation'; subscribeToMarketing: boolean };

export type SetAsMarketingContactMutationVariables = Exact<{
    input: SubscribeToMarketingInput;
}>;

export type SetAsMarketingContactMutation = { __typename?: 'Mutation'; setAsMarketingContact: boolean };

export type CreateImageMutationVariables = Exact<{
    input: CreateImageInput;
}>;

export type CreateImageMutation = {
    __typename?: 'Mutation';
    image: {
        __typename?: 'Image';
        id: string;
        url: string;
        file: { __typename?: 'FileProperties'; fileType: FileType; originalFilename: string };
    };
};

export type GetManyImagesQueryVariables = Exact<{
    input: GetManyImagesInput;
}>;

export type GetManyImagesQuery = {
    __typename?: 'Query';
    getManyImages: Array<{
        __typename?: 'Image';
        id: string;
        url: string;
        width: number;
        height: number;
        file: {
            __typename?: 'FileProperties';
            fileType: FileType;
            originalFilename: string;
            size: number;
            url: string;
        };
    }>;
};

export type ListingFragment = {
    __typename?: 'Listing';
    id: string;
    type: ListingType;
    saleType: ListingSaleType;
    currency: Currency;
    price: number;
    product: {
        __typename?: 'Product';
        id: string;
        status: ProductStatus;
        allowTransfers: boolean;
        title: string;
        slug: string;
        description: string;
        additionalDetails?: string | null;
        termsAndConditionsUrl?: string | null;
        minted: number;
        limit?: number | null;
        parentRecordLabel?: ParentRecordLabel | null;
        metadata?: any | null;
        productType?: ProductType | null;
        thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
        account: {
            __typename?: 'Account';
            id: string;
            isActive: boolean;
            isDeleted: boolean;
            type: AccountType;
            status: AccountStatus;
            name: string;
            username: string;
            genres: Array<Genre>;
            bio?: string | null;
            missionStatement?: string | null;
            socials: any;
            tags: Array<AccountTag>;
            avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
        };
        collectible?: {
            __typename?: 'ProductCollectible';
            image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            audio?: { __typename?: 'Audio'; id: string } | null;
            video?: { __typename?: 'Video'; id: string } | null;
        } | null;
        pressing?: {
            __typename?: 'ProductPressing';
            pressingType: PressingType;
            coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            tracks: Array<{
                __typename?: 'Track';
                id: string;
                trackName: string;
                trackNumber: number;
                metadata?: any | null;
                audio: {
                    __typename?: 'Audio';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number; extension: string };
                };
            }>;
        } | null;
        rewards?: Array<{
            __typename?: 'Reward';
            id: string;
            name: string;
            downloadable: boolean;
            displayOrder: number;
            playable: boolean;
            created: any;
            image?: {
                __typename?: 'Image';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            video?: { __typename?: 'Video'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            audio?: { __typename?: 'Audio'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            document?: {
                __typename?: 'Document';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
        }> | null;
    };
};

export type ListingTileFragmentFragment = {
    __typename?: 'Listing';
    id: string;
    type: ListingType;
    status: ListingStatus;
    saleType: ListingSaleType;
    currency: Currency;
    price: number;
    plannedCloseTimeZone?: string | null;
    plannedCloseDateTime?: any | null;
    product: {
        __typename?: 'Product';
        id: string;
        status: ProductStatus;
        allowTransfers: boolean;
        title: string;
        slug: string;
        additionalDetails?: string | null;
        minted: number;
        limit?: number | null;
        productType?: ProductType | null;
        thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
        account: {
            __typename?: 'Account';
            id: string;
            name: string;
            username: string;
            avatar?: { __typename?: 'Image'; id: string; url: string } | null;
        };
        pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
    };
};

export type FindHeroBannerListingsQueryVariables = Exact<{ [key: string]: never }>;

export type FindHeroBannerListingsQuery = {
    __typename?: 'Query';
    findListings: {
        __typename?: 'ListingRelay';
        edges?: Array<{
            __typename?: 'ListingEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Listing';
                id: string;
                type: ListingType;
                status: ListingStatus;
                saleType: ListingSaleType;
                currency: Currency;
                price: number;
                plannedCloseTimeZone?: string | null;
                plannedCloseDateTime?: any | null;
                product: {
                    __typename?: 'Product';
                    id: string;
                    status: ProductStatus;
                    allowTransfers: boolean;
                    title: string;
                    slug: string;
                    additionalDetails?: string | null;
                    minted: number;
                    limit?: number | null;
                    productType?: ProductType | null;
                    thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
                    account: {
                        __typename?: 'Account';
                        id: string;
                        name: string;
                        username: string;
                        avatar?: { __typename?: 'Image'; id: string; url: string } | null;
                    };
                    pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
                };
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'ListingPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type FindGridListingsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Float']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<ListingsSortingInput>;
    type: ListingType;
    tags?: InputMaybe<Array<ListingTag> | ListingTag>;
    excludeTags?: InputMaybe<Array<ListingTag> | ListingTag>;
    genres?: InputMaybe<Array<Genre> | Genre>;
    username?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindGridListingsQuery = {
    __typename?: 'Query';
    findListings: {
        __typename?: 'ListingRelay';
        edges?: Array<{
            __typename?: 'ListingEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Listing';
                id: string;
                type: ListingType;
                status: ListingStatus;
                saleType: ListingSaleType;
                currency: Currency;
                price: number;
                plannedCloseTimeZone?: string | null;
                plannedCloseDateTime?: any | null;
                product: {
                    __typename?: 'Product';
                    id: string;
                    status: ProductStatus;
                    allowTransfers: boolean;
                    title: string;
                    slug: string;
                    additionalDetails?: string | null;
                    minted: number;
                    limit?: number | null;
                    productType?: ProductType | null;
                    thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
                    account: {
                        __typename?: 'Account';
                        id: string;
                        name: string;
                        username: string;
                        avatar?: { __typename?: 'Image'; id: string; url: string } | null;
                    };
                    pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
                };
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'ListingPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type FindListingsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Float']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<ListingsSortingInput>;
    type: ListingType;
    tags?: InputMaybe<Array<ListingTag> | ListingTag>;
    excludeTags?: InputMaybe<Array<ListingTag> | ListingTag>;
    genres?: InputMaybe<Array<Genre> | Genre>;
    username?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindListingsQuery = {
    __typename?: 'Query';
    findListings: {
        __typename?: 'ListingRelay';
        edges?: Array<{
            __typename?: 'ListingEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Listing';
                id: string;
                type: ListingType;
                saleType: ListingSaleType;
                currency: Currency;
                price: number;
                product: {
                    __typename?: 'Product';
                    id: string;
                    status: ProductStatus;
                    allowTransfers: boolean;
                    title: string;
                    slug: string;
                    description: string;
                    additionalDetails?: string | null;
                    termsAndConditionsUrl?: string | null;
                    minted: number;
                    limit?: number | null;
                    parentRecordLabel?: ParentRecordLabel | null;
                    metadata?: any | null;
                    productType?: ProductType | null;
                    thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
                    account: {
                        __typename?: 'Account';
                        id: string;
                        isActive: boolean;
                        isDeleted: boolean;
                        type: AccountType;
                        status: AccountStatus;
                        name: string;
                        username: string;
                        genres: Array<Genre>;
                        bio?: string | null;
                        missionStatement?: string | null;
                        socials: any;
                        tags: Array<AccountTag>;
                        avatar?: {
                            __typename?: 'Image';
                            id: string;
                            url: string;
                            width: number;
                            height: number;
                        } | null;
                    };
                    collectible?: {
                        __typename?: 'ProductCollectible';
                        image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                        audio?: { __typename?: 'Audio'; id: string } | null;
                        video?: { __typename?: 'Video'; id: string } | null;
                    } | null;
                    pressing?: {
                        __typename?: 'ProductPressing';
                        pressingType: PressingType;
                        coverArtImage?: {
                            __typename?: 'Image';
                            id: string;
                            url: string;
                            width: number;
                            height: number;
                        } | null;
                        tracks: Array<{
                            __typename?: 'Track';
                            id: string;
                            trackName: string;
                            trackNumber: number;
                            metadata?: any | null;
                            audio: {
                                __typename?: 'Audio';
                                id: string;
                                file: { __typename?: 'FileProperties'; size: number; extension: string };
                            };
                        }>;
                    } | null;
                    rewards?: Array<{
                        __typename?: 'Reward';
                        id: string;
                        name: string;
                        downloadable: boolean;
                        displayOrder: number;
                        playable: boolean;
                        created: any;
                        image?: {
                            __typename?: 'Image';
                            id: string;
                            url: string;
                            file: { __typename?: 'FileProperties'; size: number };
                        } | null;
                        video?: {
                            __typename?: 'Video';
                            id: string;
                            file: { __typename?: 'FileProperties'; size: number };
                        } | null;
                        audio?: {
                            __typename?: 'Audio';
                            id: string;
                            file: { __typename?: 'FileProperties'; size: number };
                        } | null;
                        document?: {
                            __typename?: 'Document';
                            id: string;
                            url: string;
                            file: { __typename?: 'FileProperties'; size: number };
                        } | null;
                        other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
                    }> | null;
                };
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'ListingPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type FindLatestListingForProductPageQueryVariables = Exact<{
    productId: Scalars['String']['input'];
}>;

export type FindLatestListingForProductPageQuery = {
    __typename?: 'Query';
    findLatestProductListing: {
        __typename?: 'Listing';
        id: string;
        type: ListingType;
        saleType: ListingSaleType;
        currency: Currency;
        price: number;
        status: ListingStatus;
        plannedCloseTimeZone?: string | null;
        plannedCloseDateTime?: any | null;
    };
};

export type FindLatestProductListingQueryVariables = Exact<{
    productId: Scalars['String']['input'];
}>;

export type FindLatestProductListingQuery = {
    __typename?: 'Query';
    findLatestProductListing: {
        __typename?: 'Listing';
        id: string;
        type: ListingType;
        saleType: ListingSaleType;
        currency: Currency;
        price: number;
        product: {
            __typename?: 'Product';
            id: string;
            status: ProductStatus;
            allowTransfers: boolean;
            title: string;
            slug: string;
            description: string;
            additionalDetails?: string | null;
            termsAndConditionsUrl?: string | null;
            minted: number;
            limit?: number | null;
            parentRecordLabel?: ParentRecordLabel | null;
            metadata?: any | null;
            productType?: ProductType | null;
            thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
            account: {
                __typename?: 'Account';
                id: string;
                isActive: boolean;
                isDeleted: boolean;
                type: AccountType;
                status: AccountStatus;
                name: string;
                username: string;
                genres: Array<Genre>;
                bio?: string | null;
                missionStatement?: string | null;
                socials: any;
                tags: Array<AccountTag>;
                avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            };
            collectible?: {
                __typename?: 'ProductCollectible';
                image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                audio?: { __typename?: 'Audio'; id: string } | null;
                video?: { __typename?: 'Video'; id: string } | null;
            } | null;
            pressing?: {
                __typename?: 'ProductPressing';
                pressingType: PressingType;
                coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                tracks: Array<{
                    __typename?: 'Track';
                    id: string;
                    trackName: string;
                    trackNumber: number;
                    metadata?: any | null;
                    audio: {
                        __typename?: 'Audio';
                        id: string;
                        file: { __typename?: 'FileProperties'; size: number; extension: string };
                    };
                }>;
            } | null;
            rewards?: Array<{
                __typename?: 'Reward';
                id: string;
                name: string;
                downloadable: boolean;
                displayOrder: number;
                playable: boolean;
                created: any;
                image?: {
                    __typename?: 'Image';
                    id: string;
                    url: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                video?: {
                    __typename?: 'Video';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                audio?: {
                    __typename?: 'Audio';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                document?: {
                    __typename?: 'Document';
                    id: string;
                    url: string;
                    file: { __typename?: 'FileProperties'; size: number };
                } | null;
                other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
            }> | null;
        };
    };
};

export type FindListingsGenresQueryVariables = Exact<{ [key: string]: never }>;

export type FindListingsGenresQuery = { __typename?: 'Query'; findListingsGenres: Array<Genre> };

export type PurchaseWithCryptoMutationVariables = Exact<{
    listingId: Scalars['String']['input'];
}>;

export type PurchaseWithCryptoMutation = {
    __typename?: 'Mutation';
    purchaseWithCrypto: {
        __typename?: 'ReservedProduct';
        id: string;
        coinbaseCharge?: { __typename?: 'CoinbaseCharge'; id: string; coinbaseChargeCode: string } | null;
        product?: {
            __typename?: 'Product';
            id: string;
            parentRecordLabel?: ParentRecordLabel | null;
            account: { __typename?: 'Account'; id: string; name: string };
        } | null;
    };
};

export type CreateOtherMutationVariables = Exact<{
    input: CreateOtherInput;
}>;

export type CreateOtherMutation = {
    __typename?: 'Mutation';
    other: {
        __typename?: 'Other';
        id: string;
        url: string;
        file: { __typename?: 'FileProperties'; fileType: FileType; originalFilename: string };
    };
};

export type GetManyOthersQueryVariables = Exact<{
    input: GetManyOthersInput;
}>;

export type GetManyOthersQuery = {
    __typename?: 'Query';
    getManyOthers: Array<{
        __typename?: 'Other';
        id: string;
        url: string;
        file: {
            __typename?: 'FileProperties';
            fileType: FileType;
            originalFilename: string;
            size: number;
            url: string;
        };
    }>;
};

export type GetProductFormatQueryVariables = Exact<{
    filters: ProductFormatFilters;
}>;

export type GetProductFormatQuery = {
    __typename?: 'Query';
    productFormat: {
        __typename?: 'ProductFormat';
        id: string;
        title: string;
        behindTheMusic?: string | null;
        artworkUrl: string;
        formatInstanceId?: string | null;
        formatType: FormatType;
        formatSubType: FormatSubType;
        limited: boolean;
        preOrder: boolean;
        price?: number | null;
        productStatus: ProductStatusNew;
        slug: string;
        soldOut: boolean;
        parentRecordLabel?: ParentRecordLabel | null;
        plannedCloseDate?: string | null;
        plannedCloseDateTimezone?: string | null;
        plannedPreOrderStartDate?: string | null;
        plannedPreOrderStartDateTimezone?: string | null;
        plannedReleaseDate?: string | null;
        plannedReleaseDateTimezone?: string | null;
        currency?: Currency | null;
        productInclusions: Array<string>;
        recordLabel?: string | null;
        termsAndConditionsUrl?: string | null;
        visible: boolean;
        userOwnsAnyEditions?: boolean | null;
        wholesale: boolean;
        artist: { __typename?: 'Artist'; name: string; profilePicUrl?: string | null; username: string };
        pressing?: {
            __typename?: 'Pressing';
            bonusContent: Array<{
                __typename?: 'BonusContent';
                active?: boolean | null;
                assetId?: string | null;
                displayName?: string | null;
                downloadable?: boolean | null;
                fileSize?: number | null;
                mimeType?: string | null;
                streamable?: boolean | null;
                duration?: number | null;
            }>;
            credits?: {
                __typename?: 'Credits';
                artDirectionBy?: Array<string> | null;
                artworkBy?: Array<string> | null;
                creativeDirectionBy?: Array<string> | null;
                designedBy?: Array<string> | null;
                performedBy?: Array<string> | null;
                photographyBy?: Array<string> | null;
                producedBy?: Array<string> | null;
                writtenBy?: Array<string> | null;
            } | null;
            tracks: Array<{
                __typename?: 'PressingTrack';
                active?: boolean | null;
                assetId?: string | null;
                displayName?: string | null;
                downloadable?: boolean | null;
                duration?: number | null;
                fileSize?: number | null;
                mimeType?: string | null;
                streamable?: boolean | null;
                trackNumber?: number | null;
            }>;
        } | null;
        collectible?: {
            __typename?: 'Collectible';
            audio?: { __typename?: 'CollectibleAsset'; assetId: string; mimeType: string } | null;
            image?: { __typename?: 'CollectibleAsset'; assetId: string; mimeType: string } | null;
            video?: { __typename?: 'CollectibleAsset'; assetId: string; mimeType: string } | null;
        } | null;
    };
};

export type GetReleasesProductFormatsQueryVariables = Exact<{
    filters?: InputMaybe<ProductFormatsFilters>;
    first?: InputMaybe<Scalars['Int']['input']>;
    orderBy?: InputMaybe<Array<ProductFormatOrderBy> | ProductFormatOrderBy>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetReleasesProductFormatsQuery = {
    __typename?: 'Query';
    productFormats?: {
        __typename?: 'ProductFormatConnection';
        edges: Array<{
            __typename?: 'ProductFormatConnectionEdge';
            cursor: string;
            node: {
                __typename?: 'ProductFormat';
                id: string;
                title: string;
                artworkUrl: string;
                formatType: FormatType;
                formatSubType: FormatSubType;
                limited: boolean;
                editionSize?: number | null;
                preOrder: boolean;
                price?: number | null;
                currency?: Currency | null;
                productStatus: ProductStatusNew;
                slug: string;
                soldOut: boolean;
                plannedCloseDate?: string | null;
                preOrderStartDate?: string | null;
                plannedPreOrderStartDate?: string | null;
                releaseDate?: string | null;
                plannedReleaseDate?: string | null;
                visible: boolean;
                wholesale: boolean;
                artist: { __typename?: 'Artist'; name: string; profilePicUrl?: string | null; username: string };
            };
        }>;
        pageInfo: {
            __typename?: 'PageInfo';
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            totalCount: number;
            startCursor: string;
        };
    } | null;
};

export type GetPaymentUrlQueryVariables = Exact<{
    paymentUrlInput: PaymentUrlInput;
}>;

export type GetPaymentUrlQuery = { __typename?: 'Query'; paymentUrl: string };

export type GetProductFormatsQueryVariables = Exact<{
    filters: ProductFormatsFilters;
    first?: InputMaybe<Scalars['Int']['input']>;
    orderBy?: InputMaybe<Array<ProductFormatOrderBy> | ProductFormatOrderBy>;
    after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProductFormatsQuery = {
    __typename?: 'Query';
    productFormats?: {
        __typename?: 'ProductFormatConnection';
        edges: Array<{
            __typename?: 'ProductFormatConnectionEdge';
            cursor: string;
            node: {
                __typename?: 'ProductFormat';
                id: string;
                artworkUrl: string;
                currency?: Currency | null;
                editionSize?: number | null;
                formatSubType: FormatSubType;
                formatType: FormatType;
                limited: boolean;
                plannedCloseDate?: string | null;
                plannedPreOrderStartDate?: string | null;
                plannedReleaseDate?: string | null;
                preOrder: boolean;
                price?: number | null;
                productStatus: ProductStatusNew;
                slug: string;
                soldOut: boolean;
                title: string;
                visible: boolean;
                wholesale: boolean;
                artist: { __typename?: 'Artist'; name: string; profilePicUrl?: string | null; username: string };
            };
        }>;
        pageInfo: {
            __typename?: 'PageInfo';
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            totalCount: number;
            startCursor: string;
        };
    } | null;
};

export type CollectibleFragment = {
    __typename?: 'ProductCollectible';
    image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
    audio?: { __typename?: 'Audio'; id: string } | null;
    video?: { __typename?: 'Video'; id: string } | null;
};

export type PressingFragment = {
    __typename?: 'ProductPressing';
    pressingType: PressingType;
    coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
    tracks: Array<{
        __typename?: 'Track';
        id: string;
        trackName: string;
        trackNumber: number;
        metadata?: any | null;
        audio: {
            __typename?: 'Audio';
            id: string;
            file: { __typename?: 'FileProperties'; size: number; extension: string };
        };
    }>;
};

export type RewardsFragment = {
    __typename?: 'Reward';
    id: string;
    name: string;
    downloadable: boolean;
    displayOrder: number;
    playable: boolean;
    created: any;
    image?: {
        __typename?: 'Image';
        id: string;
        url: string;
        file: { __typename?: 'FileProperties'; size: number };
    } | null;
    video?: { __typename?: 'Video'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
    audio?: { __typename?: 'Audio'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
    document?: {
        __typename?: 'Document';
        id: string;
        url: string;
        file: { __typename?: 'FileProperties'; size: number };
    } | null;
    other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
};

export type ProductFragment = {
    __typename?: 'Product';
    id: string;
    status: ProductStatus;
    allowTransfers: boolean;
    title: string;
    slug: string;
    description: string;
    additionalDetails?: string | null;
    termsAndConditionsUrl?: string | null;
    minted: number;
    limit?: number | null;
    parentRecordLabel?: ParentRecordLabel | null;
    metadata?: any | null;
    productType?: ProductType | null;
    thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
    account: {
        __typename?: 'Account';
        id: string;
        isActive: boolean;
        isDeleted: boolean;
        type: AccountType;
        status: AccountStatus;
        name: string;
        username: string;
        genres: Array<Genre>;
        bio?: string | null;
        missionStatement?: string | null;
        socials: any;
        tags: Array<AccountTag>;
        avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
    };
    collectible?: {
        __typename?: 'ProductCollectible';
        image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
        audio?: { __typename?: 'Audio'; id: string } | null;
        video?: { __typename?: 'Video'; id: string } | null;
    } | null;
    pressing?: {
        __typename?: 'ProductPressing';
        pressingType: PressingType;
        coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
        tracks: Array<{
            __typename?: 'Track';
            id: string;
            trackName: string;
            trackNumber: number;
            metadata?: any | null;
            audio: {
                __typename?: 'Audio';
                id: string;
                file: { __typename?: 'FileProperties'; size: number; extension: string };
            };
        }>;
    } | null;
    rewards?: Array<{
        __typename?: 'Reward';
        id: string;
        name: string;
        downloadable: boolean;
        displayOrder: number;
        playable: boolean;
        created: any;
        image?: {
            __typename?: 'Image';
            id: string;
            url: string;
            file: { __typename?: 'FileProperties'; size: number };
        } | null;
        video?: { __typename?: 'Video'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
        audio?: { __typename?: 'Audio'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
        document?: {
            __typename?: 'Document';
            id: string;
            url: string;
            file: { __typename?: 'FileProperties'; size: number };
        } | null;
        other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
    }> | null;
};

export type ProductTileFragmentFragment = {
    __typename?: 'Product';
    id: string;
    status: ProductStatus;
    allowTransfers: boolean;
    title: string;
    slug: string;
    additionalDetails?: string | null;
    minted: number;
    limit?: number | null;
    productType?: ProductType | null;
    thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
    account: {
        __typename?: 'Account';
        id: string;
        name: string;
        username: string;
        avatar?: { __typename?: 'Image'; id: string; url: string } | null;
    };
    pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
};

export type CreateProductMutationVariables = Exact<{
    product: CreateProductInput;
    listing: CreateListingInput;
}>;

export type CreateProductMutation = { __typename?: 'Mutation'; product: { __typename?: 'Product'; id: string } };

export type CreateProductV2MutationVariables = Exact<{
    product: CreateProductInputV2;
    listing: CreateListingInput;
}>;

export type CreateProductV2Mutation = { __typename?: 'Mutation'; product: { __typename?: 'Product'; id: string } };

export type CreateProductMarketingConsentMutationVariables = Exact<{
    input: CreateProductMarketingConsentInput;
}>;

export type CreateProductMarketingConsentMutation = {
    __typename?: 'Mutation';
    createProductMarketingConsent: { __typename?: 'ProductMarketingConsent'; id: string };
};

export type FindProductPageQueryVariables = Exact<{
    id?: InputMaybe<Scalars['String']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindProductPageQuery = {
    __typename?: 'Query';
    findProduct?: {
        __typename?: 'Product';
        id: string;
        status: ProductStatus;
        allowTransfers: boolean;
        allowTrackDownloads: boolean;
        title: string;
        slug: string;
        description: string;
        parentRecordLabel?: ParentRecordLabel | null;
        additionalDetails?: string | null;
        termsAndConditionsUrl?: string | null;
        limit?: number | null;
        minted: number;
        metadata?: any | null;
        productType?: ProductType | null;
        thumbnail?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
        account: {
            __typename?: 'Account';
            id: string;
            name: string;
            username: string;
            missionStatement?: string | null;
            socials: any;
            avatar?: { __typename?: 'Image'; id: string; url: string } | null;
        };
        collectible?: {
            __typename?: 'ProductCollectible';
            image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            audio?: { __typename?: 'Audio'; id: string } | null;
            video?: { __typename?: 'Video'; id: string } | null;
        } | null;
        pressing?: {
            __typename?: 'ProductPressing';
            pressingType: PressingType;
            coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            tracks: Array<{
                __typename?: 'Track';
                id: string;
                trackName: string;
                trackNumber: number;
                metadata?: any | null;
                audio: {
                    __typename?: 'Audio';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number; extension: string };
                };
            }>;
        } | null;
        rewards?: Array<{
            __typename?: 'Reward';
            id: string;
            name: string;
            downloadable: boolean;
            displayOrder: number;
            playable: boolean;
            created: any;
            image?: {
                __typename?: 'Image';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            video?: { __typename?: 'Video'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            audio?: { __typename?: 'Audio'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            document?: {
                __typename?: 'Document';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
        }> | null;
        compressed?: {
            __typename?: 'Compressed';
            id: string;
            file: { __typename?: 'FileProperties'; size: number; s3Url: string };
        } | null;
    } | null;
};

export type FindProductQueryVariables = Exact<{
    id?: InputMaybe<Scalars['String']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindProductQuery = {
    __typename?: 'Query';
    findProduct?: {
        __typename?: 'Product';
        id: string;
        status: ProductStatus;
        allowTransfers: boolean;
        title: string;
        slug: string;
        description: string;
        additionalDetails?: string | null;
        termsAndConditionsUrl?: string | null;
        minted: number;
        limit?: number | null;
        parentRecordLabel?: ParentRecordLabel | null;
        metadata?: any | null;
        productType?: ProductType | null;
        thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
        account: {
            __typename?: 'Account';
            id: string;
            isActive: boolean;
            isDeleted: boolean;
            type: AccountType;
            status: AccountStatus;
            name: string;
            username: string;
            genres: Array<Genre>;
            bio?: string | null;
            missionStatement?: string | null;
            socials: any;
            tags: Array<AccountTag>;
            avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
        };
        collectible?: {
            __typename?: 'ProductCollectible';
            image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            audio?: { __typename?: 'Audio'; id: string } | null;
            video?: { __typename?: 'Video'; id: string } | null;
        } | null;
        pressing?: {
            __typename?: 'ProductPressing';
            pressingType: PressingType;
            coverArtImage?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
            tracks: Array<{
                __typename?: 'Track';
                id: string;
                trackName: string;
                trackNumber: number;
                metadata?: any | null;
                audio: {
                    __typename?: 'Audio';
                    id: string;
                    file: { __typename?: 'FileProperties'; size: number; extension: string };
                };
            }>;
        } | null;
        rewards?: Array<{
            __typename?: 'Reward';
            id: string;
            name: string;
            downloadable: boolean;
            displayOrder: number;
            playable: boolean;
            created: any;
            image?: {
                __typename?: 'Image';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            video?: { __typename?: 'Video'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            audio?: { __typename?: 'Audio'; id: string; file: { __typename?: 'FileProperties'; size: number } } | null;
            document?: {
                __typename?: 'Document';
                id: string;
                url: string;
                file: { __typename?: 'FileProperties'; size: number };
            } | null;
            other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
        }> | null;
    } | null;
};

export type FindGridProductsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Float']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindGridProductsQuery = {
    __typename?: 'Query';
    findProducts: {
        __typename?: 'ProductRelay';
        edges?: Array<{
            __typename?: 'ProductEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Product';
                id: string;
                status: ProductStatus;
                allowTransfers: boolean;
                title: string;
                slug: string;
                additionalDetails?: string | null;
                minted: number;
                limit?: number | null;
                productType?: ProductType | null;
                thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
                account: {
                    __typename?: 'Account';
                    id: string;
                    name: string;
                    username: string;
                    avatar?: { __typename?: 'Image'; id: string; url: string } | null;
                };
                pressing?: { __typename?: 'ProductPressing'; id: string; pressingType: PressingType } | null;
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'ProductPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type FindProductsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Float']['input']>;
    last?: InputMaybe<Scalars['Float']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    after?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<Scalars['JSON']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindProductsQuery = {
    __typename?: 'Query';
    findProducts: {
        __typename?: 'ProductRelay';
        edges?: Array<{
            __typename?: 'ProductEdge';
            cursor?: string | null;
            node?: {
                __typename?: 'Product';
                id: string;
                status: ProductStatus;
                allowTransfers: boolean;
                title: string;
                slug: string;
                description: string;
                additionalDetails?: string | null;
                termsAndConditionsUrl?: string | null;
                minted: number;
                limit?: number | null;
                parentRecordLabel?: ParentRecordLabel | null;
                metadata?: any | null;
                productType?: ProductType | null;
                thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
                account: {
                    __typename?: 'Account';
                    id: string;
                    isActive: boolean;
                    isDeleted: boolean;
                    type: AccountType;
                    status: AccountStatus;
                    name: string;
                    username: string;
                    genres: Array<Genre>;
                    bio?: string | null;
                    missionStatement?: string | null;
                    socials: any;
                    tags: Array<AccountTag>;
                    avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                };
                collectible?: {
                    __typename?: 'ProductCollectible';
                    image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                    audio?: { __typename?: 'Audio'; id: string } | null;
                    video?: { __typename?: 'Video'; id: string } | null;
                } | null;
                pressing?: {
                    __typename?: 'ProductPressing';
                    pressingType: PressingType;
                    coverArtImage?: {
                        __typename?: 'Image';
                        id: string;
                        url: string;
                        width: number;
                        height: number;
                    } | null;
                    tracks: Array<{
                        __typename?: 'Track';
                        id: string;
                        trackName: string;
                        trackNumber: number;
                        metadata?: any | null;
                        audio: {
                            __typename?: 'Audio';
                            id: string;
                            file: { __typename?: 'FileProperties'; size: number; extension: string };
                        };
                    }>;
                } | null;
                rewards?: Array<{
                    __typename?: 'Reward';
                    id: string;
                    name: string;
                    downloadable: boolean;
                    displayOrder: number;
                    playable: boolean;
                    created: any;
                    image?: {
                        __typename?: 'Image';
                        id: string;
                        url: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    video?: {
                        __typename?: 'Video';
                        id: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    audio?: {
                        __typename?: 'Audio';
                        id: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    document?: {
                        __typename?: 'Document';
                        id: string;
                        url: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
                }> | null;
            } | null;
        }> | null;
        pageInfo?: {
            __typename?: 'ProductPageInfo';
            startCursor?: string | null;
            endCursor?: string | null;
            hasPreviousPage: boolean;
            hasNextPage: boolean;
        } | null;
    };
};

export type CreateProfileMutationVariables = Exact<{
    profile: CreateProfileInput;
}>;

export type CreateProfileMutation = { __typename?: 'Mutation'; createProfile: { __typename?: 'Profile'; id: string } };

export type FindReservedProductSummaryQueryVariables = Exact<{
    reservedProductId: Scalars['String']['input'];
}>;

export type FindReservedProductSummaryQuery = {
    __typename?: 'Query';
    findReservedProductSummary?: {
        __typename?: 'ReservationSummary';
        id: string;
        transactionId: string;
        provider: string;
        userId: string;
        paymentStatus: string;
        transactionStatus: string;
        presentmentCurrency: string;
        presentmentAmount: number;
        settlementCurrency: string;
        settlementAmount: number;
        listing: {
            __typename?: 'Listing';
            id: string;
            type: ListingType;
            saleType: ListingSaleType;
            currency: Currency;
            price: number;
            product: {
                __typename?: 'Product';
                id: string;
                status: ProductStatus;
                allowTransfers: boolean;
                title: string;
                slug: string;
                description: string;
                additionalDetails?: string | null;
                termsAndConditionsUrl?: string | null;
                minted: number;
                limit?: number | null;
                parentRecordLabel?: ParentRecordLabel | null;
                metadata?: any | null;
                productType?: ProductType | null;
                thumbnail?: { __typename?: 'Image'; id: string; url: string } | null;
                account: {
                    __typename?: 'Account';
                    id: string;
                    isActive: boolean;
                    isDeleted: boolean;
                    type: AccountType;
                    status: AccountStatus;
                    name: string;
                    username: string;
                    genres: Array<Genre>;
                    bio?: string | null;
                    missionStatement?: string | null;
                    socials: any;
                    tags: Array<AccountTag>;
                    avatar?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                };
                collectible?: {
                    __typename?: 'ProductCollectible';
                    image?: { __typename?: 'Image'; id: string; url: string; width: number; height: number } | null;
                    audio?: { __typename?: 'Audio'; id: string } | null;
                    video?: { __typename?: 'Video'; id: string } | null;
                } | null;
                pressing?: {
                    __typename?: 'ProductPressing';
                    pressingType: PressingType;
                    coverArtImage?: {
                        __typename?: 'Image';
                        id: string;
                        url: string;
                        width: number;
                        height: number;
                    } | null;
                    tracks: Array<{
                        __typename?: 'Track';
                        id: string;
                        trackName: string;
                        trackNumber: number;
                        metadata?: any | null;
                        audio: {
                            __typename?: 'Audio';
                            id: string;
                            file: { __typename?: 'FileProperties'; size: number; extension: string };
                        };
                    }>;
                } | null;
                rewards?: Array<{
                    __typename?: 'Reward';
                    id: string;
                    name: string;
                    downloadable: boolean;
                    displayOrder: number;
                    playable: boolean;
                    created: any;
                    image?: {
                        __typename?: 'Image';
                        id: string;
                        url: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    video?: {
                        __typename?: 'Video';
                        id: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    audio?: {
                        __typename?: 'Audio';
                        id: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    document?: {
                        __typename?: 'Document';
                        id: string;
                        url: string;
                        file: { __typename?: 'FileProperties'; size: number };
                    } | null;
                    other?: { __typename?: 'Other'; file: { __typename?: 'FileProperties'; size: number } } | null;
                }> | null;
            };
        };
    } | null;
};

export type CreateRightsHolderPaymentChannelMutationVariables = Exact<{
    paymentChannel: CreateRightsHolderPaymentChannelInput;
}>;

export type CreateRightsHolderPaymentChannelMutation = {
    __typename?: 'Mutation';
    createRightsHolderPaymentChannel: {
        __typename?: 'PaymentChannel';
        type: PaymentChannelType;
        status: PaymentChannelStatus;
    };
};

export type CreateRightsHolderPaymentChannelOnboardingLinkMutationVariables = Exact<{
    input: CreateRightsHolderPaymentChannelOnboardingLinkInput;
}>;

export type CreateRightsHolderPaymentChannelOnboardingLinkMutation = {
    __typename?: 'Mutation';
    createRightsHolderPaymentChannelOnboardingLink: { __typename?: 'PaymentChannelOnboardingLink'; url: string };
};

export type FindRightsHolderDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type FindRightsHolderDetailsQuery = {
    __typename?: 'Query';
    findRightsHolderDetails?: {
        __typename?: 'RightsHolder';
        paymentChannels?: Array<{
            __typename?: 'PaymentChannel';
            type: PaymentChannelType;
            status: PaymentChannelStatus;
        }> | null;
    } | null;
};

export type CreateVideoMutationVariables = Exact<{
    input: CreateVideoInput;
}>;

export type CreateVideoMutation = {
    __typename?: 'Mutation';
    video: {
        __typename?: 'Video';
        id: string;
        muxPlaybackId?: string | null;
        file: { __typename?: 'FileProperties'; fileType: FileType; originalFilename: string };
    };
};

export type GetManyVideosQueryVariables = Exact<{
    input: GetManyVideosInput;
}>;

export type GetManyVideosQuery = {
    __typename?: 'Query';
    getManyVideos: Array<{
        __typename?: 'Video';
        id: string;
        muxPlaybackId?: string | null;
        duration?: number | null;
        file: {
            __typename?: 'FileProperties';
            fileType: FileType;
            originalFilename: string;
            size: number;
            url: string;
        };
    }>;
};

export type GetVoucherQueryVariables = Exact<{
    voucher: ClaimVoucherInput;
}>;

export type GetVoucherQuery = {
    __typename?: 'Query';
    voucher: {
        __typename?: 'Voucher';
        id: string;
        status: VoucherStatus;
        product: {
            __typename?: 'VoucherArtistProduct';
            id: string;
            title: string;
            artworkUrl: string;
            artistName: string;
        };
    };
};

export type ClaimVoucherMutationVariables = Exact<{
    voucher: ClaimVoucherInput;
}>;

export type ClaimVoucherMutation = { __typename?: 'Mutation'; claimVoucher: ClaimStatus };

export const AccountTileFragmentFragmentDoc = gql`
    fragment AccountTileFragment on Account {
        id
        type
        status
        name
        username
        genres
        avatar {
            id
            url
        }
        tags
    }
`;
export const DraftFragmentDoc = gql`
    fragment draft on Draft {
        id
        status
        type
        updated
        data {
            data {
                pressingType
                coverArt {
                    imageId
                    url
                }
                credit {
                    artworkBy
                    performedBy
                    producedBy
                    recordLabel
                    writtenBy
                }
                detail {
                    description
                    genres
                    limit
                    pressingType
                    subGenres
                    termsAndConditionsUrl
                    title
                    upc
                }
                listing {
                    currency
                    price
                    releaseDateTime
                    releaseTimeZone
                }
                rewards {
                    displayOrder
                    fileId
                    fileType
                    muxPlaybackId
                    name
                    url
                }
                tracks {
                    audioId
                    isrc
                    muxPlaybackId
                    trackName
                    trackNumber
                }
            }
        }
    }
`;
export const EditionTileFragmentFragmentDoc = gql`
    fragment EditionTileFragment on Edition {
        id
        sequence
        status
        tokenId
        transactionHash
        product {
            id
            status
            allowTransfers
            title
            slug
            additionalDetails
            minted
            limit
            thumbnail {
                id
                url
            }
            account {
                id
                name
                username
                avatar {
                    id
                    url
                }
            }
            productType
            pressing {
                id
                pressingType
            }
            listings {
                id
                saleType
            }
        }
    }
`;
export const AccountFragmentDoc = gql`
    fragment account on Account {
        id
        isActive
        isDeleted
        type
        status
        name
        username
        genres
        bio
        missionStatement
        socials
        avatar {
            id
            url
            width
            height
        }
        tags
    }
`;
export const CollectibleFragmentDoc = gql`
    fragment collectible on ProductCollectible {
        image {
            id
            url
            width
            height
        }
        audio {
            id
        }
        video {
            id
        }
    }
`;
export const PressingFragmentDoc = gql`
    fragment pressing on ProductPressing {
        pressingType
        coverArtImage {
            id
            url
            width
            height
        }
        tracks {
            id
            audio {
                id
                file {
                    size
                    extension
                }
            }
            trackName
            trackNumber
            metadata
        }
    }
`;
export const RewardsFragmentDoc = gql`
    fragment rewards on Reward {
        id
        name
        downloadable
        displayOrder
        playable
        created
        image {
            id
            url
            file {
                size
            }
        }
        video {
            id
            file {
                size
            }
        }
        audio {
            id
            file {
                size
            }
        }
        document {
            id
            url
            file {
                size
            }
        }
        other {
            file {
                size
            }
        }
    }
`;
export const ProductFragmentDoc = gql`
    fragment product on Product {
        id
        status
        allowTransfers
        title
        slug
        description
        additionalDetails
        termsAndConditionsUrl
        minted
        limit
        parentRecordLabel
        termsAndConditionsUrl
        metadata
        thumbnail {
            id
            url
        }
        account {
            ...account
        }
        productType
        collectible {
            ...collectible
        }
        pressing {
            ...pressing
        }
        rewards {
            ...rewards
        }
    }
    ${AccountFragmentDoc}
    ${CollectibleFragmentDoc}
    ${PressingFragmentDoc}
    ${RewardsFragmentDoc}
`;
export const EditionFragmentDoc = gql`
    fragment edition on Edition {
        id
        sequence
        status
        tokenId
        transactionHash
        product {
            ...product
        }
    }
    ${ProductFragmentDoc}
`;
export const FirstOwnerFragmentDoc = gql`
    fragment firstOwner on FirstOwner {
        id
        name
        edition {
            id
            sequence
        }
        account {
            id
            username
            avatar {
                id
                url
            }
        }
    }
`;
export const ListingFragmentDoc = gql`
    fragment listing on Listing {
        id
        type
        saleType
        currency
        price
        product {
            ...product
        }
    }
    ${ProductFragmentDoc}
`;
export const ListingTileFragmentFragmentDoc = gql`
    fragment ListingTileFragment on Listing {
        id
        type
        status
        saleType
        currency
        price
        status
        plannedCloseTimeZone
        plannedCloseDateTime
        product {
            id
            status
            allowTransfers
            title
            slug
            additionalDetails
            minted
            limit
            thumbnail {
                id
                url
            }
            account {
                id
                name
                username
                avatar {
                    id
                    url
                }
            }
            productType
            pressing {
                id
                pressingType
            }
        }
    }
`;
export const ProductTileFragmentFragmentDoc = gql`
    fragment ProductTileFragment on Product {
        id
        status
        allowTransfers
        title
        slug
        additionalDetails
        minted
        limit
        thumbnail {
            id
            url
        }
        account {
            id
            name
            username
            avatar {
                id
                url
            }
        }
        productType
        pressing {
            id
            pressingType
        }
    }
`;
export const UpdateAvatarDocument = gql`
    mutation UpdateAvatar($input: CreateImageInput!) {
        updateAvatar(input: $input) {
            avatar {
                id
                url
            }
        }
    }
`;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($id: String!, $user: UpdateUserInput, $account: UpdateAccountInput!) {
        updateAccount(id: $id, user: $user, account: $account) {
            ...account
        }
    }
    ${AccountFragmentDoc}
`;
export const FindAccountDocument = gql`
    query FindAccount($id: String, $username: String) {
        findAccount(id: $id, username: $username) {
            ...account
        }
    }
    ${AccountFragmentDoc}
`;
export const FindAccountIdDocument = gql`
    query FindAccountId($id: String, $username: String) {
        findAccount(id: $id, username: $username) {
            id
        }
    }
`;
export const FindTopAccountsDocument = gql`
    query FindTopAccounts($first: Float, $type: AccountType!, $tags: [AccountTag!], $status: AccountStatus) {
        findAccounts(first: $first, type: $type, tags: $tags, status: $status) {
            edges {
                cursor
                node {
                    ...AccountTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${AccountTileFragmentFragmentDoc}
`;
export const FindAccountsDocument = gql`
    query FindAccounts(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: JSON
        $type: AccountType!
        $username: String
        $tags: [AccountTag!]
        $status: AccountStatus
    ) {
        findAccounts(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            type: $type
            tags: $tags
            username: $username
            status: $status
        ) {
            edges {
                cursor
                node {
                    ...account
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${AccountFragmentDoc}
`;
export const AvailableArtistLettersDocument = gql`
    query AvailableArtistLetters {
        availableArtistLetters
    }
`;
export const ListApprovedArtistsNavDocument = gql`
    query ListApprovedArtistsNav {
        findApprovedArtists {
            id
            name
            username
            tags
        }
    }
`;
export const ProductCountDocument = gql`
    query ProductCount($accountId: String!) {
        countProducts(accountId: $accountId)
    }
`;
export const IsMemberDocument = gql`
    query IsMember($accountId: String!) {
        isMember(accountId: $accountId)
    }
`;
export const CreateAudioDocument = gql`
    mutation CreateAudio($input: CreateAudioInput!) {
        audio: createAudio(input: $input) {
            id
            muxPlaybackId
            file {
                fileType
                originalFilename
            }
        }
    }
`;
export const GetManyAudiosDocument = gql`
    query GetManyAudios($input: GetManyAudiosInput!) {
        getManyAudios(input: $input) {
            id
            muxPlaybackId
            duration
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
export const RegisterDocument = gql`
    mutation Register($user: CreateUserInput!, $account: CreateAccountInput!) {
        register(user: $user, account: $account) {
            accessToken
            refreshToken
        }
    }
`;
export const FindProfileDocument = gql`
    query FindProfile {
        findProfile {
            id
            firstName
            lastName
            country
            isEmailVerified
            accounts {
                ...account
            }
        }
    }
    ${AccountFragmentDoc}
`;
export const GetUpdatedTokensDocument = gql`
    mutation GetUpdatedTokens($refreshToken: String!) {
        getUpdatedTokens(refreshToken: $refreshToken) {
            accessToken
            refreshToken
        }
    }
`;
export const HasPermissionsDocument = gql`
    query HasPermissions($keys: [PermissionKey!]!) {
        hasPermissions(keys: $keys)
    }
`;
export const ResendEmailVerificationEmailDocument = gql`
    mutation ResendEmailVerificationEmail($voucher: VoucherCodeInput) {
        resendEmailVerificationEmail(voucher: $voucher) {
            sendResult
        }
    }
`;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(input: { email: $email })
    }
`;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            accessToken
            refreshToken
        }
    }
`;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($input: CreateDocumentInput!) {
        document: createDocument(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
            }
        }
    }
`;
export const GetManyDocumentsDocument = gql`
    query GetManyDocuments($input: GetManyDocumentsInput!) {
        getManyDocuments(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
export const GetMyDraftDocument = gql`
    query GetMyDraft($id: String!) {
        getMyDraft(id: $id) {
            ...draft
        }
    }
    ${DraftFragmentDoc}
`;
export const GetMyDraftsDocument = gql`
    query GetMyDrafts($first: Float, $last: Float, $before: String, $after: String, $status: DraftStatus) {
        getMyDrafts(first: $first, last: $last, before: $before, after: $after, status: $status) {
            edges {
                cursor
                node {
                    id
                    data {
                        data {
                            pressingType
                            detail {
                                title
                            }
                            coverArt {
                                url
                            }
                        }
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;
export const CreateDraftDocument = gql`
    mutation CreateDraft($input: CreateDraftInput!) {
        createDraft(input: $input) {
            id
        }
    }
`;
export const UpdateMyDraftDocument = gql`
    mutation UpdateMyDraft($id: String!, $input: UpdateDraftInput!) {
        updateMyDraft(id: $id, input: $input) {
            ...draft
        }
    }
    ${DraftFragmentDoc}
`;
export const ConvertMyDraftProductDocument = gql`
    mutation ConvertMyDraftProduct($id: String!) {
        convertMyDraftProduct(id: $id) {
            id
            slug
            account {
                username
            }
        }
    }
`;
export const GetAssetStreamingUrlDocument = gql`
    query GetAssetStreamingUrl($input: AssetStreamingUrlInput!) {
        formatInstanceAssetStreamingUrl(input: $input)
    }
`;
export const GetAssetDownloadUrlDocument = gql`
    query GetAssetDownloadUrl($input: FormatInstanceAssetDownloadInput!) {
        formatInstanceAssetDownloadUrl(input: $input)
    }
`;
export const GetVideoAssetThumbnailUrlDocument = gql`
    query GetVideoAssetThumbnailUrl($input: FormatInstanceVideoAssetThumbnailUrlInput!) {
        formatInstanceVideoAssetThumbnailUrl(input: $input)
    }
`;
export const GetCompressedTrackDownloadInfoDocument = gql`
    query GetCompressedTrackDownloadInfo($editionId: String!) {
        compressedTrackDownloadInfo(editionId: $editionId) {
            url
            userDownloadCount
        }
    }
`;
export const GetEditionFormatDocument = gql`
    query GetEditionFormat($filters: EditionFormatFilters!) {
        editionFormat(filters: $filters) {
            artist {
                name
                profilePicUrl
                username
            }
            artworkUrl
            behindTheMusic
            collectible {
                audio {
                    assetId
                    mimeType
                }
                image {
                    assetId
                    mimeType
                }
                video {
                    assetId
                    mimeType
                }
            }
            contentConsumable
            editionId
            editionOwner {
                profilePicUrl
                username
            }
            editionSequence
            editionSize
            editionStatus
            formatInstanceId
            formatInstanceStatus
            formatSubType
            formatType
            limited
            parentRecordLabel
            plannedCloseDate
            plannedCloseDateTimezone
            plannedReleaseDate
            plannedReleaseDateTimezone
            plannedPreOrderStartDate
            plannedPreOrderStartDateTimezone
            preOrder
            pressing {
                bonusContent {
                    active
                    assetId
                    displayName
                    downloadable
                    duration
                    fileSize
                    mimeType
                    streamable
                }
                compressedTracks {
                    downloadLimit
                    userDownloadCount
                    fileSize
                }
                credits {
                    artDirectionBy
                    artworkBy
                    creativeDirectionBy
                    designedBy
                    performedBy
                    photographyBy
                    producedBy
                    writtenBy
                }
                tracks {
                    active
                    assetId
                    displayName
                    downloadable
                    duration
                    fileSize
                    mimeType
                    streamable
                    trackNumber
                    fileExtension
                }
            }
            productId
            productStatus
            recordLabel
            slug
            soldOut
            termsAndConditionsUrl
            title
        }
    }
`;
export const GetEditionFormatsForProfilePageDocument = gql`
    query GetEditionFormatsForProfilePage($filters: EditionFormatsFilters!, $first: Int, $after: String) {
        editionFormats(filters: $filters, first: $first, after: $after) {
            edges {
                cursor
                node {
                    editionId
                    title
                    slug
                    productStatus
                    editionSequence
                    editionSize
                    artworkUrl
                    editionOwner {
                        profilePicUrl
                        username
                    }
                    formatType
                    formatSubType
                    limited
                    preOrder
                    soldOut
                    editionStatus
                    tokenId
                    artist {
                        name
                        profilePicUrl
                        username
                    }
                    contentConsumable
                    wholesale
                    plannedCloseDate
                    plannedCloseDateTimezone
                    plannedReleaseDate
                    plannedReleaseDateTimezone
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                totalCount
                startCursor
            }
        }
    }
`;
export const CreateDownloadableTracksUrlDocument = gql`
    mutation CreateDownloadableTracksUrl($editionId: String!) {
        createDownloadableTracksUrl(editionId: $editionId)
    }
`;
export const FindEditionForProductPageDocument = gql`
    query FindEditionForProductPage($productId: String!, $sequence: Float!) {
        findEdition(productId: $productId, sequence: $sequence) {
            id
            sequence
            status
            tokenId
            transactionHash
        }
    }
`;
export const FindEditionDocument = gql`
    query FindEdition($productId: String, $sequence: Float) {
        findEdition(productId: $productId, sequence: $sequence) {
            ...edition
        }
    }
    ${EditionFragmentDoc}
`;
export const FindEditionsForProfilePageDocument = gql`
    query FindEditionsForProfilePage($username: String!, $includeMinting: Boolean) {
        findEditionsByUsername(username: $username, includeMinting: $includeMinting) {
            ...EditionTileFragment
        }
    }
    ${EditionTileFragmentFragmentDoc}
`;
export const FindEditionByIdDocument = gql`
    query FindEditionById($id: String!) {
        findEdition(id: $id) {
            ...EditionTileFragment
        }
    }
    ${EditionTileFragmentFragmentDoc}
`;
export const FindEditionsByUsernameDocument = gql`
    query FindEditionsByUsername($username: String!, $includeMinting: Boolean) {
        findEditionsByUsername(username: $username, includeMinting: $includeMinting) {
            ...edition
        }
    }
    ${EditionFragmentDoc}
`;
export const FindTracksDownloadCountDocument = gql`
    query FindTracksDownloadCount($editionId: String!) {
        findTracksDownloadCount(editionId: $editionId) {
            downloadCount
            allowedDownloadCount
        }
    }
`;
export const FindFirstOwnersDocument = gql`
    query FindFirstOwners($productSlug: String!, $first: Float!, $after: String) {
        findFirstOwners(productSlug: $productSlug, first: $first, after: $after) {
            edges {
                node {
                    ...firstOwner
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
    ${FirstOwnerFragmentDoc}
`;
export const SubscribeToMarketingDocument = gql`
    mutation SubscribeToMarketing($input: SubscribeToMarketingInput!) {
        subscribeToMarketing(input: $input)
    }
`;
export const SetAsMarketingContactDocument = gql`
    mutation SetAsMarketingContact($input: SubscribeToMarketingInput!) {
        setAsMarketingContact(input: $input)
    }
`;
export const CreateImageDocument = gql`
    mutation CreateImage($input: CreateImageInput!) {
        image: createImage(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
            }
        }
    }
`;
export const GetManyImagesDocument = gql`
    query GetManyImages($input: GetManyImagesInput!) {
        getManyImages(input: $input) {
            id
            url
            width
            height
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
export const FindHeroBannerListingsDocument = gql`
    query FindHeroBannerListings {
        findListings(first: 8, type: Product, tags: [Hero]) {
            edges {
                cursor
                node {
                    ...ListingTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${ListingTileFragmentFragmentDoc}
`;
export const FindGridListingsDocument = gql`
    query FindGridListings(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: ListingsSortingInput
        $type: ListingType!
        $tags: [ListingTag!]
        $excludeTags: [ListingTag!]
        $genres: [Genre!]
        $username: String
    ) {
        findListings(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            type: $type
            tags: $tags
            excludeTags: $excludeTags
            genres: $genres
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...ListingTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${ListingTileFragmentFragmentDoc}
`;
export const FindListingsDocument = gql`
    query FindListings(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: ListingsSortingInput
        $type: ListingType!
        $tags: [ListingTag!]
        $excludeTags: [ListingTag!]
        $genres: [Genre!]
        $username: String
    ) {
        findListings(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            type: $type
            tags: $tags
            excludeTags: $excludeTags
            genres: $genres
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...listing
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${ListingFragmentDoc}
`;
export const FindLatestListingForProductPageDocument = gql`
    query FindLatestListingForProductPage($productId: String!) {
        findLatestProductListing(productId: $productId) {
            id
            type
            saleType
            currency
            price
            status
            plannedCloseTimeZone
            plannedCloseDateTime
        }
    }
`;
export const FindLatestProductListingDocument = gql`
    query FindLatestProductListing($productId: String!) {
        findLatestProductListing(productId: $productId) {
            ...listing
        }
    }
    ${ListingFragmentDoc}
`;
export const FindListingsGenresDocument = gql`
    query FindListingsGenres {
        findListingsGenres
    }
`;
export const PurchaseWithCryptoDocument = gql`
    mutation PurchaseWithCrypto($listingId: String!) {
        purchaseWithCrypto(listingId: $listingId) {
            id
            coinbaseCharge {
                id
                coinbaseChargeCode
            }
            product {
                id
                parentRecordLabel
                account {
                    id
                    name
                }
            }
        }
    }
`;
export const CreateOtherDocument = gql`
    mutation CreateOther($input: CreateOtherInput!) {
        other: createOther(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
            }
        }
    }
`;
export const GetManyOthersDocument = gql`
    query GetManyOthers($input: GetManyOthersInput!) {
        getManyOthers(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
export const GetProductFormatDocument = gql`
    query GetProductFormat($filters: ProductFormatFilters!) {
        productFormat(filters: $filters) {
            artist {
                name
                profilePicUrl
                username
            }
            id
            title
            behindTheMusic
            pressing {
                bonusContent {
                    active
                    assetId
                    displayName
                    downloadable
                    fileSize
                    mimeType
                    streamable
                    duration
                }
                credits {
                    artDirectionBy
                    artworkBy
                    creativeDirectionBy
                    designedBy
                    performedBy
                    photographyBy
                    producedBy
                    writtenBy
                }
                tracks {
                    active
                    assetId
                    displayName
                    downloadable
                    duration
                    fileSize
                    mimeType
                    streamable
                    trackNumber
                }
            }
            artworkUrl
            collectible {
                audio {
                    assetId
                    mimeType
                }
                image {
                    assetId
                    mimeType
                }
                video {
                    assetId
                    mimeType
                }
            }
            formatInstanceId
            formatType
            formatSubType
            limited
            preOrder
            price
            productStatus
            slug
            soldOut
            parentRecordLabel
            plannedCloseDate
            plannedCloseDateTimezone
            plannedPreOrderStartDate
            plannedPreOrderStartDateTimezone
            plannedReleaseDate
            plannedReleaseDateTimezone
            currency
            productInclusions
            recordLabel
            termsAndConditionsUrl
            visible
            userOwnsAnyEditions
            wholesale
        }
    }
`;
export const GetReleasesProductFormatsDocument = gql`
    query GetReleasesProductFormats(
        $filters: ProductFormatsFilters
        $first: Int
        $orderBy: [ProductFormatOrderBy!]
        $after: String
    ) {
        productFormats(filters: $filters, first: $first, orderBy: $orderBy, after: $after) {
            edges {
                cursor
                node {
                    artist {
                        name
                        profilePicUrl
                        username
                    }
                    id
                    title
                    artworkUrl
                    formatType
                    formatSubType
                    limited
                    editionSize
                    preOrder
                    price
                    currency
                    productStatus
                    slug
                    soldOut
                    plannedCloseDate
                    preOrderStartDate
                    plannedPreOrderStartDate
                    releaseDate
                    plannedReleaseDate
                    visible
                    wholesale
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                totalCount
                startCursor
            }
        }
    }
`;
export const GetPaymentUrlDocument = gql`
    query GetPaymentUrl($paymentUrlInput: PaymentUrlInput!) {
        paymentUrl(paymentUrlInput: $paymentUrlInput)
    }
`;
export const GetProductFormatsDocument = gql`
    query GetProductFormats(
        $filters: ProductFormatsFilters!
        $first: Int
        $orderBy: [ProductFormatOrderBy!]
        $after: String
    ) {
        productFormats(filters: $filters, first: $first, orderBy: $orderBy, after: $after) {
            edges {
                cursor
                node {
                    artist {
                        name
                        profilePicUrl
                        username
                    }
                    id
                    artworkUrl
                    currency
                    editionSize
                    formatSubType
                    formatType
                    id
                    limited
                    plannedCloseDate
                    plannedPreOrderStartDate
                    plannedReleaseDate
                    preOrder
                    price
                    productStatus
                    slug
                    soldOut
                    title
                    visible
                    wholesale
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                totalCount
                startCursor
            }
        }
    }
`;
export const CreateProductDocument = gql`
    mutation CreateProduct($product: CreateProductInput!, $listing: CreateListingInput!) {
        product: createProduct(product: $product, listing: $listing) {
            id
        }
    }
`;
export const CreateProductV2Document = gql`
    mutation CreateProductV2($product: CreateProductInputV2!, $listing: CreateListingInput!) {
        product: createProductV2(product: $product, listing: $listing) {
            id
        }
    }
`;
export const CreateProductMarketingConsentDocument = gql`
    mutation CreateProductMarketingConsent($input: CreateProductMarketingConsentInput!) {
        createProductMarketingConsent(input: $input) {
            id
        }
    }
`;
export const FindProductPageDocument = gql`
    query FindProductPage($id: String, $slug: String) {
        findProduct(id: $id, slug: $slug) {
            id
            status
            allowTransfers
            allowTrackDownloads
            title
            slug
            description
            parentRecordLabel
            additionalDetails
            termsAndConditionsUrl
            limit
            minted
            termsAndConditionsUrl
            metadata
            parentRecordLabel
            productType
            thumbnail {
                id
                url
                width
                height
            }
            account {
                id
                name
                username
                missionStatement
                socials
                avatar {
                    id
                    url
                }
            }
            collectible {
                ...collectible
            }
            pressing {
                ...pressing
            }
            rewards {
                ...rewards
            }
            compressed {
                id
                file {
                    size
                    s3Url
                }
            }
        }
    }
    ${CollectibleFragmentDoc}
    ${PressingFragmentDoc}
    ${RewardsFragmentDoc}
`;
export const FindProductDocument = gql`
    query FindProduct($id: String, $slug: String) {
        findProduct(id: $id, slug: $slug) {
            ...product
        }
    }
    ${ProductFragmentDoc}
`;
export const FindGridProductsDocument = gql`
    query FindGridProducts(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: JSON
        $title: String
        $username: String
    ) {
        findProducts(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            title: $title
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...ProductTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${ProductTileFragmentFragmentDoc}
`;
export const FindProductsDocument = gql`
    query FindProducts(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: JSON
        $title: String
        $username: String
    ) {
        findProducts(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            title: $title
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...product
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${ProductFragmentDoc}
`;
export const CreateProfileDocument = gql`
    mutation CreateProfile($profile: CreateProfileInput!) {
        createProfile(profile: $profile) {
            id
        }
    }
`;
export const FindReservedProductSummaryDocument = gql`
    query FindReservedProductSummary($reservedProductId: String!) {
        findReservedProductSummary(reservedProductId: $reservedProductId) {
            id
            transactionId
            listing {
                ...listing
            }
            provider
            userId
            paymentStatus
            transactionStatus
            presentmentCurrency
            presentmentAmount
            settlementCurrency
            settlementAmount
        }
    }
    ${ListingFragmentDoc}
`;
export const CreateRightsHolderPaymentChannelDocument = gql`
    mutation CreateRightsHolderPaymentChannel($paymentChannel: CreateRightsHolderPaymentChannelInput!) {
        createRightsHolderPaymentChannel(paymentChannel: $paymentChannel) {
            type
            status
        }
    }
`;
export const CreateRightsHolderPaymentChannelOnboardingLinkDocument = gql`
    mutation CreateRightsHolderPaymentChannelOnboardingLink(
        $input: CreateRightsHolderPaymentChannelOnboardingLinkInput!
    ) {
        createRightsHolderPaymentChannelOnboardingLink(input: $input) {
            url
        }
    }
`;
export const FindRightsHolderDetailsDocument = gql`
    query FindRightsHolderDetails {
        findRightsHolderDetails {
            paymentChannels {
                type
                status
            }
        }
    }
`;
export const CreateVideoDocument = gql`
    mutation CreateVideo($input: CreateVideoInput!) {
        video: createVideo(input: $input) {
            id
            muxPlaybackId
            file {
                fileType
                originalFilename
            }
        }
    }
`;
export const GetManyVideosDocument = gql`
    query GetManyVideos($input: GetManyVideosInput!) {
        getManyVideos(input: $input) {
            id
            muxPlaybackId
            duration
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
export const GetVoucherDocument = gql`
    query GetVoucher($voucher: ClaimVoucherInput!) {
        voucher(voucher: $voucher) {
            id
            status
            product {
                id
                title
                artworkUrl
                artistName
            }
        }
    }
`;
export const ClaimVoucherDocument = gql`
    mutation ClaimVoucher($voucher: ClaimVoucherInput!) {
        claimVoucher(voucher: $voucher)
    }
`;

export type SdkFunctionWrapper = <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>,
    operationName: string,
    operationType?: string,
    variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
    return {
        UpdateAvatar(
            variables: UpdateAvatarMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<UpdateAvatarMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateAvatarMutation>(UpdateAvatarDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'UpdateAvatar',
                'mutation',
                variables,
            );
        },
        UpdateAccount(
            variables: UpdateAccountMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<UpdateAccountMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateAccountMutation>(UpdateAccountDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'UpdateAccount',
                'mutation',
                variables,
            );
        },
        FindAccount(
            variables?: FindAccountQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindAccountQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindAccountQuery>(FindAccountDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindAccount',
                'query',
                variables,
            );
        },
        FindAccountId(
            variables?: FindAccountIdQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindAccountIdQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindAccountIdQuery>(FindAccountIdDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindAccountId',
                'query',
                variables,
            );
        },
        FindTopAccounts(
            variables: FindTopAccountsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindTopAccountsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindTopAccountsQuery>(FindTopAccountsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindTopAccounts',
                'query',
                variables,
            );
        },
        FindAccounts(
            variables: FindAccountsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindAccountsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindAccountsQuery>(FindAccountsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindAccounts',
                'query',
                variables,
            );
        },
        AvailableArtistLetters(
            variables?: AvailableArtistLettersQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<AvailableArtistLettersQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<AvailableArtistLettersQuery>(AvailableArtistLettersDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'AvailableArtistLetters',
                'query',
                variables,
            );
        },
        ListApprovedArtistsNav(
            variables?: ListApprovedArtistsNavQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<ListApprovedArtistsNavQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ListApprovedArtistsNavQuery>(ListApprovedArtistsNavDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'ListApprovedArtistsNav',
                'query',
                variables,
            );
        },
        ProductCount(
            variables: ProductCountQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<ProductCountQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ProductCountQuery>(ProductCountDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'ProductCount',
                'query',
                variables,
            );
        },
        IsMember(
            variables: IsMemberQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<IsMemberQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<IsMemberQuery>(IsMemberDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'IsMember',
                'query',
                variables,
            );
        },
        CreateAudio(
            variables: CreateAudioMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateAudioMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateAudioMutation>(CreateAudioDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateAudio',
                'mutation',
                variables,
            );
        },
        GetManyAudios(
            variables: GetManyAudiosQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetManyAudiosQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetManyAudiosQuery>(GetManyAudiosDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetManyAudios',
                'query',
                variables,
            );
        },
        Register(
            variables: RegisterMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<RegisterMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RegisterMutation>(RegisterDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'Register',
                'mutation',
                variables,
            );
        },
        FindProfile(
            variables?: FindProfileQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindProfileQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindProfileQuery>(FindProfileDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindProfile',
                'query',
                variables,
            );
        },
        GetUpdatedTokens(
            variables: GetUpdatedTokensMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetUpdatedTokensMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetUpdatedTokensMutation>(GetUpdatedTokensDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetUpdatedTokens',
                'mutation',
                variables,
            );
        },
        HasPermissions(
            variables: HasPermissionsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<HasPermissionsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<HasPermissionsQuery>(HasPermissionsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'HasPermissions',
                'query',
                variables,
            );
        },
        ResendEmailVerificationEmail(
            variables?: ResendEmailVerificationEmailMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<ResendEmailVerificationEmailMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ResendEmailVerificationEmailMutation>(
                        ResendEmailVerificationEmailDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'ResendEmailVerificationEmail',
                'mutation',
                variables,
            );
        },
        ForgotPassword(
            variables: ForgotPasswordMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<ForgotPasswordMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ForgotPasswordMutation>(ForgotPasswordDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'ForgotPassword',
                'mutation',
                variables,
            );
        },
        Login(variables: LoginMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LoginMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<LoginMutation>(LoginDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'Login',
                'mutation',
                variables,
            );
        },
        CreateDocument(
            variables: CreateDocumentMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateDocumentMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateDocumentMutation>(CreateDocumentDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateDocument',
                'mutation',
                variables,
            );
        },
        GetManyDocuments(
            variables: GetManyDocumentsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetManyDocumentsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetManyDocumentsQuery>(GetManyDocumentsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetManyDocuments',
                'query',
                variables,
            );
        },
        GetMyDraft(
            variables: GetMyDraftQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetMyDraftQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetMyDraftQuery>(GetMyDraftDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetMyDraft',
                'query',
                variables,
            );
        },
        GetMyDrafts(
            variables?: GetMyDraftsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetMyDraftsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetMyDraftsQuery>(GetMyDraftsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetMyDrafts',
                'query',
                variables,
            );
        },
        CreateDraft(
            variables: CreateDraftMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateDraftMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateDraftMutation>(CreateDraftDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateDraft',
                'mutation',
                variables,
            );
        },
        UpdateMyDraft(
            variables: UpdateMyDraftMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<UpdateMyDraftMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateMyDraftMutation>(UpdateMyDraftDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'UpdateMyDraft',
                'mutation',
                variables,
            );
        },
        ConvertMyDraftProduct(
            variables: ConvertMyDraftProductMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<ConvertMyDraftProductMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ConvertMyDraftProductMutation>(ConvertMyDraftProductDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'ConvertMyDraftProduct',
                'mutation',
                variables,
            );
        },
        GetAssetStreamingUrl(
            variables: GetAssetStreamingUrlQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetAssetStreamingUrlQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetAssetStreamingUrlQuery>(GetAssetStreamingUrlDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetAssetStreamingUrl',
                'query',
                variables,
            );
        },
        GetAssetDownloadUrl(
            variables: GetAssetDownloadUrlQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetAssetDownloadUrlQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetAssetDownloadUrlQuery>(GetAssetDownloadUrlDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetAssetDownloadUrl',
                'query',
                variables,
            );
        },
        GetVideoAssetThumbnailUrl(
            variables: GetVideoAssetThumbnailUrlQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetVideoAssetThumbnailUrlQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetVideoAssetThumbnailUrlQuery>(GetVideoAssetThumbnailUrlDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetVideoAssetThumbnailUrl',
                'query',
                variables,
            );
        },
        GetCompressedTrackDownloadInfo(
            variables: GetCompressedTrackDownloadInfoQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetCompressedTrackDownloadInfoQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetCompressedTrackDownloadInfoQuery>(
                        GetCompressedTrackDownloadInfoDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'GetCompressedTrackDownloadInfo',
                'query',
                variables,
            );
        },
        GetEditionFormat(
            variables: GetEditionFormatQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetEditionFormatQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetEditionFormatQuery>(GetEditionFormatDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetEditionFormat',
                'query',
                variables,
            );
        },
        GetEditionFormatsForProfilePage(
            variables: GetEditionFormatsForProfilePageQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetEditionFormatsForProfilePageQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetEditionFormatsForProfilePageQuery>(
                        GetEditionFormatsForProfilePageDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'GetEditionFormatsForProfilePage',
                'query',
                variables,
            );
        },
        CreateDownloadableTracksUrl(
            variables: CreateDownloadableTracksUrlMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateDownloadableTracksUrlMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateDownloadableTracksUrlMutation>(
                        CreateDownloadableTracksUrlDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreateDownloadableTracksUrl',
                'mutation',
                variables,
            );
        },
        FindEditionForProductPage(
            variables: FindEditionForProductPageQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindEditionForProductPageQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindEditionForProductPageQuery>(FindEditionForProductPageDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindEditionForProductPage',
                'query',
                variables,
            );
        },
        FindEdition(
            variables?: FindEditionQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindEditionQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindEditionQuery>(FindEditionDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindEdition',
                'query',
                variables,
            );
        },
        FindEditionsForProfilePage(
            variables: FindEditionsForProfilePageQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindEditionsForProfilePageQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindEditionsForProfilePageQuery>(FindEditionsForProfilePageDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindEditionsForProfilePage',
                'query',
                variables,
            );
        },
        FindEditionById(
            variables: FindEditionByIdQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindEditionByIdQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindEditionByIdQuery>(FindEditionByIdDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindEditionById',
                'query',
                variables,
            );
        },
        FindEditionsByUsername(
            variables: FindEditionsByUsernameQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindEditionsByUsernameQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindEditionsByUsernameQuery>(FindEditionsByUsernameDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindEditionsByUsername',
                'query',
                variables,
            );
        },
        FindTracksDownloadCount(
            variables: FindTracksDownloadCountQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindTracksDownloadCountQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindTracksDownloadCountQuery>(FindTracksDownloadCountDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindTracksDownloadCount',
                'query',
                variables,
            );
        },
        FindFirstOwners(
            variables: FindFirstOwnersQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindFirstOwnersQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindFirstOwnersQuery>(FindFirstOwnersDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindFirstOwners',
                'query',
                variables,
            );
        },
        SubscribeToMarketing(
            variables: SubscribeToMarketingMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<SubscribeToMarketingMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SubscribeToMarketingMutation>(SubscribeToMarketingDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'SubscribeToMarketing',
                'mutation',
                variables,
            );
        },
        SetAsMarketingContact(
            variables: SetAsMarketingContactMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<SetAsMarketingContactMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SetAsMarketingContactMutation>(SetAsMarketingContactDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'SetAsMarketingContact',
                'mutation',
                variables,
            );
        },
        CreateImage(
            variables: CreateImageMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateImageMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateImageMutation>(CreateImageDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateImage',
                'mutation',
                variables,
            );
        },
        GetManyImages(
            variables: GetManyImagesQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetManyImagesQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetManyImagesQuery>(GetManyImagesDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetManyImages',
                'query',
                variables,
            );
        },
        FindHeroBannerListings(
            variables?: FindHeroBannerListingsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindHeroBannerListingsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindHeroBannerListingsQuery>(FindHeroBannerListingsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindHeroBannerListings',
                'query',
                variables,
            );
        },
        FindGridListings(
            variables: FindGridListingsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindGridListingsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindGridListingsQuery>(FindGridListingsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindGridListings',
                'query',
                variables,
            );
        },
        FindListings(
            variables: FindListingsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindListingsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindListingsQuery>(FindListingsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindListings',
                'query',
                variables,
            );
        },
        FindLatestListingForProductPage(
            variables: FindLatestListingForProductPageQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindLatestListingForProductPageQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindLatestListingForProductPageQuery>(
                        FindLatestListingForProductPageDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'FindLatestListingForProductPage',
                'query',
                variables,
            );
        },
        FindLatestProductListing(
            variables: FindLatestProductListingQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindLatestProductListingQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindLatestProductListingQuery>(FindLatestProductListingDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindLatestProductListing',
                'query',
                variables,
            );
        },
        FindListingsGenres(
            variables?: FindListingsGenresQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindListingsGenresQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindListingsGenresQuery>(FindListingsGenresDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindListingsGenres',
                'query',
                variables,
            );
        },
        PurchaseWithCrypto(
            variables: PurchaseWithCryptoMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<PurchaseWithCryptoMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<PurchaseWithCryptoMutation>(PurchaseWithCryptoDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'PurchaseWithCrypto',
                'mutation',
                variables,
            );
        },
        CreateOther(
            variables: CreateOtherMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateOtherMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateOtherMutation>(CreateOtherDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateOther',
                'mutation',
                variables,
            );
        },
        GetManyOthers(
            variables: GetManyOthersQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetManyOthersQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetManyOthersQuery>(GetManyOthersDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetManyOthers',
                'query',
                variables,
            );
        },
        GetProductFormat(
            variables: GetProductFormatQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetProductFormatQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProductFormatQuery>(GetProductFormatDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetProductFormat',
                'query',
                variables,
            );
        },
        GetReleasesProductFormats(
            variables?: GetReleasesProductFormatsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetReleasesProductFormatsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetReleasesProductFormatsQuery>(GetReleasesProductFormatsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetReleasesProductFormats',
                'query',
                variables,
            );
        },
        GetPaymentUrl(
            variables: GetPaymentUrlQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetPaymentUrlQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetPaymentUrlQuery>(GetPaymentUrlDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetPaymentUrl',
                'query',
                variables,
            );
        },
        GetProductFormats(
            variables: GetProductFormatsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetProductFormatsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProductFormatsQuery>(GetProductFormatsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetProductFormats',
                'query',
                variables,
            );
        },
        CreateProduct(
            variables: CreateProductMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateProductMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateProductMutation>(CreateProductDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateProduct',
                'mutation',
                variables,
            );
        },
        CreateProductV2(
            variables: CreateProductV2MutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateProductV2Mutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateProductV2Mutation>(CreateProductV2Document, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateProductV2',
                'mutation',
                variables,
            );
        },
        CreateProductMarketingConsent(
            variables: CreateProductMarketingConsentMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateProductMarketingConsentMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateProductMarketingConsentMutation>(
                        CreateProductMarketingConsentDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreateProductMarketingConsent',
                'mutation',
                variables,
            );
        },
        FindProductPage(
            variables?: FindProductPageQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindProductPageQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindProductPageQuery>(FindProductPageDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindProductPage',
                'query',
                variables,
            );
        },
        FindProduct(
            variables?: FindProductQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindProductQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindProductQuery>(FindProductDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindProduct',
                'query',
                variables,
            );
        },
        FindGridProducts(
            variables?: FindGridProductsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindGridProductsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindGridProductsQuery>(FindGridProductsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindGridProducts',
                'query',
                variables,
            );
        },
        FindProducts(
            variables?: FindProductsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindProductsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindProductsQuery>(FindProductsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindProducts',
                'query',
                variables,
            );
        },
        CreateProfile(
            variables: CreateProfileMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateProfileMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateProfileMutation>(CreateProfileDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateProfile',
                'mutation',
                variables,
            );
        },
        FindReservedProductSummary(
            variables: FindReservedProductSummaryQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindReservedProductSummaryQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindReservedProductSummaryQuery>(FindReservedProductSummaryDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindReservedProductSummary',
                'query',
                variables,
            );
        },
        CreateRightsHolderPaymentChannel(
            variables: CreateRightsHolderPaymentChannelMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateRightsHolderPaymentChannelMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateRightsHolderPaymentChannelMutation>(
                        CreateRightsHolderPaymentChannelDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreateRightsHolderPaymentChannel',
                'mutation',
                variables,
            );
        },
        CreateRightsHolderPaymentChannelOnboardingLink(
            variables: CreateRightsHolderPaymentChannelOnboardingLinkMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateRightsHolderPaymentChannelOnboardingLinkMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateRightsHolderPaymentChannelOnboardingLinkMutation>(
                        CreateRightsHolderPaymentChannelOnboardingLinkDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders },
                    ),
                'CreateRightsHolderPaymentChannelOnboardingLink',
                'mutation',
                variables,
            );
        },
        FindRightsHolderDetails(
            variables?: FindRightsHolderDetailsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<FindRightsHolderDetailsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<FindRightsHolderDetailsQuery>(FindRightsHolderDetailsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'FindRightsHolderDetails',
                'query',
                variables,
            );
        },
        CreateVideo(
            variables: CreateVideoMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<CreateVideoMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateVideoMutation>(CreateVideoDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'CreateVideo',
                'mutation',
                variables,
            );
        },
        GetManyVideos(
            variables: GetManyVideosQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetManyVideosQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetManyVideosQuery>(GetManyVideosDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetManyVideos',
                'query',
                variables,
            );
        },
        GetVoucher(
            variables: GetVoucherQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<GetVoucherQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetVoucherQuery>(GetVoucherDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'GetVoucher',
                'query',
                variables,
            );
        },
        ClaimVoucher(
            variables: ClaimVoucherMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders,
        ): Promise<ClaimVoucherMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ClaimVoucherMutation>(ClaimVoucherDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'ClaimVoucher',
                'mutation',
                variables,
            );
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
