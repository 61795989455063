import { useContext } from 'react';

import { TreatmentType } from '@srnade/web/constants';

import { FeatureFlagsContext } from '@srnade/web/contexts/FeatureFlagsContext';
import { Product } from '../types';

/**
 * Returns a feature flag method which may be used to check for feature flag values.
 *
 * Example usage:
 *
 *     const const { useFeatureOn } = useFeatureFlags();
 *     const nftTransfersEnabled = useFeatureOn(TreatmentType.NftTransfers);
 *
 * @returns { useFeatureOn }
 */
export const useFeatureFlags = () => {
    const context = useContext(FeatureFlagsContext);
    if (!context) {
        throw new Error('useFeatureFlags must be used within FeatureFlagsProvider');
    }

    return context;
};

/**
 * Returns whether NFT transfer are allowed. Combines our business rules of the global flag and individual product flag
 * @param allowTransfers is NFT transfers allowed for this product?
 */
export const useNftTransfersFeatureFlag = (allowTransfers?: Product['allowTransfers']): boolean => {
    const { useFeatureOn } = useFeatureFlags();
    const globallyAllowNftTransfers = useFeatureOn(TreatmentType.NftTransfers);
    if (!globallyAllowNftTransfers) {
        return globallyAllowNftTransfers;
    }
    return Boolean(allowTransfers);
};
