import clsx from 'clsx';

import { Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';

export function EmptyTrackItem() {
    const { t } = useTranslation('components', { keyPrefix: 'pressingTrack' });
    return (
        <div
            className={clsx('rounded-[1rem] mb-[1rem] p-[1px] h-[6rem]', 'bg-cream border-[#AFAFAF] border-[0.1rem]')}
            data-testid="empty-track-list-item"
        >
            <div
                className={clsx(
                    'px-[2rem] py-[1rem] h-full rounded-[1rem] flex items-center hover:opacity-100 justify-between w-full',
                    'cursor-default pointer-events-none',
                )}
            >
                <Typography
                    variant="bodyGrey"
                    className="font-p normal text-darkGray text-[1.5rem] leading-[2rem]"
                    data-testid="track-coming-soon"
                >
                    {t('comingSoon')}
                </Typography>
            </div>
        </div>
    );
}
