import React from 'react';
import clsx from 'clsx';
import { BaseComponentProps, Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import { PressingTrack as PressingTrackType } from '@srnade/web/__generated__/graphql';

type Track = Pick<PressingTrackType, 'displayName' | 'trackNumber' | 'streamable'>;

type PressingTrackProps = {
    track: Track;
    checkStreamable?: boolean;
};

export const PressingTrack: React.FC<PressingTrackProps> = ({ track, checkStreamable }) => {
    const { t } = useTranslation('components', { keyPrefix: 'pressingTrack' });

    const noDisplayName = !track.displayName;
    const trackIsNotStreamable = !track.streamable;

    if (noDisplayName || (checkStreamable && trackIsNotStreamable)) {
        const trackName = track.displayName ? track.displayName : t('releasingSoon');

        return (
            <Typography variant="body" className="flex">
                {track.trackNumber}.&nbsp;
                <Typography
                    variant="body"
                    className={clsx('text-darkGray', {
                        italic: noDisplayName,
                    })}
                >
                    {trackName}
                </Typography>
            </Typography>
        );
    }

    return (
        <Typography variant="body">
            {track.trackNumber}. {track.displayName}
        </Typography>
    );
};

type PressingTracksProps = BaseComponentProps & {
    checkStreamable?: boolean;
    tracks: Track[];
};

export const PressingTracks: React.FC<PressingTracksProps> = ({ tracks, className, checkStreamable }) => {
    return (
        <div className={className}>
            {tracks.map((track) => (
                <PressingTrack key={track.trackNumber} track={track} checkStreamable={checkStreamable} />
            ))}
        </div>
    );
};
