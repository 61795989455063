import { createSlice } from '@reduxjs/toolkit';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import {
    GetCompressedTrackDownloadInfoQuery,
    GetCompressedTrackDownloadInfoQueryVariables,
} from '@srnade/web/__generated__/graphql';
import { GET_COMPRESSED_TRACK_DOWNLOAD_INFO } from '@srnade/web/services';
import client from 'clients/apollo';
import { GlobalState } from '../global';

export enum CompressedTracksDownloadLoadStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Failed = 'Failed',
}

export interface CompressedTracksDownloadState {
    compressedTrackDownloadInfo: GetCompressedTrackDownloadInfoQuery['compressedTrackDownloadInfo'] | null;
    status: CompressedTracksDownloadLoadStatus;
    error: string | null | undefined;
}

const initialState: CompressedTracksDownloadState = {
    compressedTrackDownloadInfo: null,
    status: CompressedTracksDownloadLoadStatus.Idle,
    error: null,
};

type CompressedTracksDownloadInfoArgs = GetCompressedTrackDownloadInfoQueryVariables;

export const fetchCompressedTracksInfo: AsyncThunk<
    GetCompressedTrackDownloadInfoQuery,
    CompressedTracksDownloadInfoArgs,
    AsyncThunkConfig
> = createAsyncThunk(
    'compressedTracks/fetchCompressedTracksInfo',
    async (args: CompressedTracksDownloadInfoArgs, thunkApi) => {
        try {
            const compressedTracksDownloadInfoResponse = await client.query<
                GetCompressedTrackDownloadInfoQuery,
                GetCompressedTrackDownloadInfoQueryVariables
            >({
                query: GET_COMPRESSED_TRACK_DOWNLOAD_INFO,
                fetchPolicy: 'no-cache',
                variables: {
                    editionId: args.editionId,
                },
            });

            return thunkApi.fulfillWithValue(compressedTracksDownloadInfoResponse.data);
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

const compressedTracksSlice = createSlice({
    name: 'compressedTracks',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetCompressedTracksDownloadInfo: () => initialState,
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompressedTracksInfo.pending, (state) => {
                state.status = CompressedTracksDownloadLoadStatus.Pending;
            })
            .addCase(fetchCompressedTracksInfo.fulfilled, (state, action) => {
                state.status = CompressedTracksDownloadLoadStatus.Idle;
                state.compressedTrackDownloadInfo = action.payload.compressedTrackDownloadInfo;
                state.error = null;
            })
            .addCase(fetchCompressedTracksInfo.rejected, (state, action) => {
                state.status = CompressedTracksDownloadLoadStatus.Failed;
                state.compressedTrackDownloadInfo = null;
                if (action.payload instanceof Error) {
                    // For example this could be validation error handling
                    state.error = action?.payload?.message;
                } else {
                    state.error = action.error.message;
                }
            });
    },
});

export const { resetCompressedTracksDownloadInfo } = compressedTracksSlice.actions;
export const selectCompressedDownloadInfo = (state: GlobalState) => state.compressedTracks.compressedTrackDownloadInfo;
export const selectCompressedStatus = (state: GlobalState) => state.compressedTracks.status;
export const selectCompressedError = (state: GlobalState) => state.compressedTracks.error;
export const CompressedTracksDownloadReducer = compressedTracksSlice.reducer;
export default compressedTracksSlice;
