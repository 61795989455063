import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

export const useSafeRouterPush = () => {
    const router = useRouter();

    const [onChanging, setOnChanging] = useState(false);
    const handleRouteChange = useCallback(() => {
        setOnChanging(false);
    }, [setOnChanging]);

    // safePush is used to avoid route pushing errors when users click multiple times or when the network is slow:  "Error: Abort fetching component for route"
    const safePush = useCallback(
        async (path: string) => {
            if (!onChanging) {
                setOnChanging(true);
                await router.push(path);
            }
        },
        [router, setOnChanging, onChanging],
    );

    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router, setOnChanging, handleRouteChange]);

    return { safePush, loading: onChanging };
};
