import { gql } from '@apollo/client';

/**
 * Mutations
 */

export const CREATE_AUDIO = gql`
    mutation CreateAudio($input: CreateAudioInput!) {
        audio: createAudio(input: $input) {
            id
            muxPlaybackId
            file {
                fileType
                originalFilename
            }
        }
    }
`;

/**
 * Queries
 */

export const GET_MANY_AUDIOS = gql`
    query GetManyAudios($input: GetManyAudiosInput!) {
        getManyAudios(input: $input) {
            id
            muxPlaybackId
            duration
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
