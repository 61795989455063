import React from 'react';
import clsx from 'clsx';
import { BaseComponentProps, Button } from '@srnade/component-ui';
// import { GoogleLogin } from 'react-google-login';

// @todo move to config?
const clientId: string = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string;

export interface GoogleResponse {
    googleId: string;
    accessToken: string;
    profileObj: {
        email: string;
        familyName: string;
        givenName: string;
        googleId: string;
        imageUrl: string;
        name: string;
    };
    tokenId: string;
    tokenObject: {
        access_token: string;
        expires_at: number;
        expires_in: number;
        first_issued_at: number;
        idToken: string;
        idpId: string;
        login_hint: string;
        scope: string;
        session_state: string;
        extraQueryParams: {
            authuser: string;
        };
    };
}

interface GoogleAuthButtonProps {
    label?: string;
    onSuccess: (response: GoogleResponse) => void;
    onFailure: (error: any) => void;
}

export function GoogleAuthButton({
    className,
    children,
    onSuccess,
    onFailure,
}: GoogleAuthButtonProps & BaseComponentProps): JSX.Element {
    /**
     * Handle successful Google response
     * @param response
     */
    const handleSuccess = (response: any): void => {
        onSuccess(response);
    };

    return (
        <div>Google Login</div>
        // <GoogleLogin
        //     clientId={clientId}
        //     render={(renderProps) => (
        //         <Button onClick={renderProps.onClick} disabled={renderProps.disabled}>
        //             {children}
        //         </Button>
        //     )}
        //     onSuccess={handleSuccess}
        //     onFailure={onFailure}
        //     cookiePolicy={'single_host_origin'}
        //     className={clsx(styles.root, className)}
        // />
    );
}
