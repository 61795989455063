import { Typography } from '@srnade/component-ui';
import { Link } from '@srnade/web/components';

import { getProductUrlPath } from '@srnade/web/utils';

type ProductTitleProps = {
    title: string;
    slug: string;
    artistUsername: string;
    isEdition: boolean;
};

export const ProductTitle = ({ title, slug, artistUsername, isEdition }: ProductTitleProps) => {
    return (
        <Typography variant="h1" data-testid="product-title">
            {isEdition ? (
                <Link href={getProductUrlPath(artistUsername, slug)} className="hover:underline">
                    {title}
                </Link>
            ) : (
                title
            )}
        </Typography>
    );
};
