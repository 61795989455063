import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isBefore } from 'date-fns';
import clsx from 'clsx';

import { time } from '@srnade/component-ui';

import styles from './CountdownTimer.module.scss';

interface CountdownTimerProps {
    end: Date;
    children?: ReactNode | undefined;
}

enum DisplayTypes {
    Days = 'd',
    Hours = 'h',
    Minutes = 'm',
    Seconds = 's',
}

interface DisplayProps {
    value: number;
    type: DisplayTypes;
    separator?: boolean;
}

function calculateTimeLeft(end: Date): time.Duration {
    const start = new Date(new Date().toISOString());

    if (isBefore(end, start)) {
        return {};
    }

    return time.intervalToDuration({ start, end });
}

const Separator = () => <span className="mx-[1.1rem] sm:mx-[1.3rem] md:mx-[1.6rem]">:</span>;

const Display = ({ value, type, separator = false }: DisplayProps) => {
    return (
        <>
            <span>{value}</span>
            <span>{type}</span>
            {separator && <Separator />}
        </>
    );
};

export function CountdownTimer(props: CountdownTimerProps) {
    const endDateUTC = useMemo(() => new Date(props.end.toISOString()), [props.end]);
    const timerInterval = useRef<NodeJS.Timeout>();
    const [state, setState] = useState<time.Duration>(() => calculateTimeLeft(endDateUTC));
    const [isExpired, setIsExpired] = useState<boolean>(false);

    const cleanup = useCallback(() => {
        if (timerInterval.current) {
            clearInterval(timerInterval.current);
            timerInterval.current = undefined;
        }
    }, []);

    const tick = useCallback(() => {
        timerInterval.current = setInterval(() => {
            const duration = calculateTimeLeft(endDateUTC);
            if (Object.keys(duration).length === 0) {
                setIsExpired(true);
                cleanup();
            }
            setState(duration);
        }, 1000);
    }, [endDateUTC, cleanup]);

    // componentDidMount
    useEffect(() => {
        tick();
        return () => {
            cleanup();
        };
    }, [tick, cleanup]);

    return (
        <>
            {!isExpired && (
                <div className={clsx(styles.root)}>
                    <div className="flex flex-row" data-testid="countdown-timer">
                        {state.days != null && <Display value={state.days} type={DisplayTypes.Days} separator />}
                        {state.hours != null && <Display value={state.hours} type={DisplayTypes.Hours} separator />}
                        {state.minutes != null && (
                            <Display value={state.minutes} type={DisplayTypes.Minutes} separator />
                        )}
                        {state.seconds != null && <Display value={state.seconds} type={DisplayTypes.Seconds} />}
                    </div>
                    {props.children}
                </div>
            )}
        </>
    );
}
