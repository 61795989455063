import {
    AudioFileConstraints,
    CombinedFileConstraints,
    DocumentFileConstraints,
    ImageFileConstraints,
    OtherFileConstraints,
    ValidAudioFileFormat,
    ValidDocumentFileFormat,
    ValidFileFormat,
    ValidImageFilesFormat,
    ValidOtherFileFormat,
    ValidVideoFilesFormat,
    VideoFileConstraints,
    time,
    bytesInMegabytes,
} from '@srnade/component-ui';

import { getFileMimeType } from '@srnade/web/utils/file.util';

export const fileFormats: ValidFileFormat[] = [
    ...Object.values(ValidImageFilesFormat),
    ...Object.values(ValidAudioFileFormat),
    ...Object.values(ValidVideoFilesFormat),
    ...Object.values(ValidDocumentFileFormat),
    ...Object.values(ValidOtherFileFormat),
];

const additionalMimeTypes: { [key: string]: string | string[] } = {
    [ValidAudioFileFormat.FLAC]: 'audio/flac',
    [ValidAudioFileFormat.M4A]: 'audio/x-m4a',
    [ValidAudioFileFormat.MP3]: 'audio/mpeg',
    [ValidAudioFileFormat.WAV]: ['audio/wav', 'audio/x-wav', 'audio/vnd.wave', 'audio/x-pn-wav'],
};
/**
 * Adds `validMimeTypes` property to the provided file constraint object.
 * @param constraints T | CombinedFileConstraints
 * @returns T | CombinedFileConstraints
 */
export function fileConstraintsFactory<T = CombinedFileConstraints>(constraints: T): T {
    return Object.defineProperty<T>(constraints, 'validMimeTypes', {
        get: function (): string {
            const mimeTypes = (this as CombinedFileConstraints).validFormats.map((format: string) => {
                return [getFileMimeType(format), additionalMimeTypes[format]].filter((item) => item);
            });

            return mimeTypes.join(',');
        },
    });
}

export const GenericVideoFileConstraints = fileConstraintsFactory<VideoFileConstraints>({
    validFormats: Object.values(ValidVideoFilesFormat),
    maxFileSizeBytes: bytesInMegabytes(500),
    maxDurationMilliSeconds: time.ONE_HOUR_MS * 2,
});

export const GenericAudioFileConstraints = fileConstraintsFactory<AudioFileConstraints>({
    validFormats: Object.values(ValidAudioFileFormat),
    maxFileSizeBytes: bytesInMegabytes(250),
    maxDurationMilliSeconds: time.ONE_HOUR_MS * 2,
    minKbps: 160,
});

export const GenericImageFileConstraints = fileConstraintsFactory<ImageFileConstraints>({
    validFormats: Object.values(ValidImageFilesFormat),
    minWidth: 400,
    maxWidth: 800,
    minHeight: 400,
    maxHeight: 800,
    maxFileSizeBytes: bytesInMegabytes(5),
});

export const GenericDocFileConstraints = fileConstraintsFactory<DocumentFileConstraints>({
    validFormats: Object.values(ValidDocumentFileFormat),
    maxFileSizeBytes: bytesInMegabytes(100),
});

export const GenericOtherFileConstraints = fileConstraintsFactory<OtherFileConstraints>({
    validFormats: Object.values(ValidOtherFileFormat),
    maxFileSizeBytes: bytesInMegabytes(800),
});
