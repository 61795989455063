import React, { useCallback } from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { useDropzone, DropzoneOptions, FileRejection, FileError, ErrorCode } from 'react-dropzone';
import clsx from 'clsx';

import { BaseComponentProps, Typography, Icon } from '@srnade/component-ui';
import {
    bytesInMegabytes,
    CombinedFileConstraints,
    InvalidFileErrorCode,
    oxfordCommaJoinFromArray,
} from '@srnade/component-ui';

import { validateFileConstraints } from '@srnade/web/utils/file.util';

import { getReadableFileSize } from '@srnade/web/utils/format.util';

import styles from './FileUpload.module.scss';

interface FileUploadZoneOptions extends DropzoneOptions {
    fileRequirementsText?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>[];
    onError: (error: string | string[]) => void;
    topHeadingText?: string;
    noTextContent?: boolean;
    onAccepted: (files: File[]) => void;
    fileConstraints?: CombinedFileConstraints;
    multiple?: boolean;
    uploadButtonOnlyText?: string;
    reUploadImage?: boolean;
}

export type FileUploadZoneProps = FileUploadZoneOptions & BaseComponentProps;

export function FileUploadZone({
    accept,
    disabled,
    onError,
    noDrag,
    topHeadingText,
    fileRequirementsText,
    maxSize,
    noTextContent,
    onAccepted,
    multiple,
    fileConstraints,
    uploadButtonOnlyText,
    reUploadImage,
}: FileUploadZoneProps) {
    const defaultMaxSize = bytesInMegabytes(5); // 5MB
    const defaultConfig = {
        accept: 'image/png, image/jpg, image/jpeg, image/gif',
        multiple: false,
        maxSize: defaultMaxSize, // 5MB
    };
    const { t } = useTranslation('components', {
        keyPrefix: 'fileUploadZone',
    });
    const { t: t1 } = useTranslation('create-pressing', {
        keyPrefix: 'pages',
    });

    const checkFileConstraints = (fileConstraints && Object.keys(fileConstraints).length > 0) ?? false;

    /**
     * Custom validator function that validate files against the constraints and return `FileRejection` object.
     */
    const validator = useCallback(
        async (file: File) => {
            if (!checkFileConstraints || !fileConstraints) return;
            const result = await validateFileConstraints(file, fileConstraints);
            if (!result.valid) {
                return {
                    file,
                    errors: [
                        {
                            code: result.errorCode,
                        } as FileError,
                    ],
                } as FileRejection;
            }
            return null;
        },
        [checkFileConstraints, fileConstraints],
    );

    /**
     * @description - This function will be called when an invalid file is dropped
     * @param {FileRejection} file - The file that was rejected
     */
    const onDropRejected = useCallback(
        (files: FileRejection[]) => {
            if (!files.length) return;
            const errorMessages: string[] = [];

            let invalidFileErrorCode = {};
            Object.keys(InvalidFileErrorCode).forEach(
                (errorCode) =>
                    (invalidFileErrorCode[errorCode] = errorCode.charAt(0).toLocaleLowerCase() + errorCode.slice(1)),
            );

            files.forEach((file) => {
                const { errors } = file;
                const errorCode = errors.map((error) => error.code).shift() as string;

                const { maxDurationMilliSeconds, minHeight, maxHeight, minWidth, maxWidth, validFormats } =
                    fileConstraints || {};

                // Mapping dropzone error codes with ours
                const errorMap = {
                    ...invalidFileErrorCode,
                    [ErrorCode.FileInvalidType]: invalidFileErrorCode[InvalidFileErrorCode.InvalidFormat],
                    [ErrorCode.FileTooLarge]: invalidFileErrorCode[InvalidFileErrorCode.InvalidSize],
                    [ErrorCode.TooManyFiles]: 'noMultipleUpload',
                } as { [key: string]: string };

                const errorMessage = t(`errors.${errorMap[errorCode]}`, {
                    fileName: file.file.name,
                    fileSizeLimit: getReadableFileSize(maxSize ?? defaultConfig.maxSize),
                    ...(checkFileConstraints && {
                        maxDuration: maxDurationMilliSeconds,
                        minHeight,
                        maxHeight,
                        minWidth,
                        maxWidth,
                        supportedFormats: validFormats && oxfordCommaJoinFromArray(validFormats),
                    }),
                });
                errorMessages.push(errorMessage as string);
            });
            onError(multiple ? errorMessages : errorMessages[0]);
        },
        [t, maxSize, defaultConfig.maxSize, checkFileConstraints, fileConstraints, onError, multiple],
    );

    /**
     * Validate accepted file properties against file constraints to ensure all constraints are passed
     * Note: Can't use custom validator function as we rely on a `async` fn to validate. Hence, this is a workaround to validate accepted files.
     */
    const validateOnDrop = useCallback(
        async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            const promises = acceptedFiles.map((file) => validator(file));
            const results = await Promise.all(promises);
            const { validFiles, fileRejections } = results.reduce(
                (existingResults, result, index) => {
                    if (result === null) {
                        existingResults.validFiles.push(acceptedFiles[index]);
                    } else {
                        existingResults.fileRejections.push(result as FileRejection);
                    }
                    return existingResults;
                },
                { validFiles: [] as File[], fileRejections: rejectedFiles },
            );

            if (validFiles.length) {
                onAccepted(validFiles);
            }
            if (fileRejections.length) {
                onDropRejected(fileRejections);
            }
        },
        [onAccepted, onDropRejected, validator],
    );

    // Overwrite the default config with the custom config provided via props
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        ...defaultConfig,
        disabled,
        noDrag,
        maxSize: maxSize ?? fileConstraints?.maxFileSizeBytes ?? defaultConfig.maxSize,
        accept: accept ?? fileConstraints?.validMimeTypes ?? defaultConfig.accept,
        multiple,
        ...(checkFileConstraints && {
            onDrop: validateOnDrop,
        }),
        ...(!checkFileConstraints && { onDropAccepted: onAccepted, onDropRejected }),
    });

    let fileName = disabled ? `file-upload-disabled.svg` : `file-upload.svg`;
    fileName = noTextContent ? `camera.svg` : fileName;
    const dragAndDropText = t('dragAndDrop');
    const clickToBrowseText = t('clickToBrowse');
    const dimensionValue = noTextContent ? '50' : '100%';
    const dimensionProps = {
        width: dimensionValue,
        height: dimensionValue,
    };

    return (
        <>
            {uploadButtonOnlyText ? (
                <div data-testid="file-upload-zone-upload-more" {...getRootProps()}>
                    <input {...getInputProps()} disabled={disabled} />
                    <Typography variant="bodyBold" uppercase className="underline hover:no-underline">
                        {uploadButtonOnlyText}
                    </Typography>
                </div>
            ) : (
                <div
                    data-testid="file-upload-zone"
                    {...getRootProps()}
                    className={clsx('w-full h-full rounded-[2rem] p-[0.1rem] border-none', {
                        'hover:bg-gradient-to-bl from-gradientGreen via-gradientBlue to-gradientPurple':
                            !reUploadImage && !disabled && !noTextContent,
                        'pointer-events-none': disabled,
                    })}
                >
                    <div
                        className={clsx(
                            'w-full h-full flex items-center justify-center flex-col basis-full relative rounded-[2rem]',
                            {
                                [styles.active]: isDragActive,
                                'bg-cream hover:bg-white border hover:border-transparent': !noTextContent && !disabled,
                                'border border-darkGray text-darkGray': disabled,
                            },
                        )}
                    >
                        <input {...getInputProps()} disabled={disabled} />

                        {!noTextContent ? (
                            <>
                                {/* @todo icon should reflect pressing track and reward */}
                                <Icon
                                    className="mb-[2rem] fill-current"
                                    icon="file-upload"
                                    size={70}
                                    {...dimensionProps}
                                />

                                <Typography className="px-[3rem] text-center text-current" variant="h1">
                                    {topHeadingText}
                                </Typography>
                                <Typography className="text-center mt-8 px-[2rem] text-current" variant="body">
                                    {fileRequirementsText}
                                </Typography>
                                <Typography variant="body" className="mt-[2rem] text-current">
                                    <span className="hidden laptop:inline-block">{!noDrag && dragAndDropText}</span>{' '}
                                    <span className="underline">{clickToBrowseText}</span>
                                </Typography>
                            </>
                        ) : (
                            <div className="w-[4.8rem] h-[5rem] rounded-[0.4rem] p-[0.2rem] border-none cursor-pointer relative bg-gradient-to-bl from-gradientGreen via-gradientBlue to-gradientPurple">
                                <div className="bg-white hover:bg-gradient-to-bl from-gradientGreen via-gradientBlue to-gradientPurple h-full rounded-[0.4rem] flex justify-center items-center">
                                    <Icon size={28} icon="camera" {...dimensionProps} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
