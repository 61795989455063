import { useState } from 'react';
import Image from 'next/image';

import { Icon, PlayButton, Typography } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';

import pdfZipPlaceholder from 'assets/images/pdf-zip-placeholder.svg';

interface PdfZipDownloadTileProps {
    onDownload?: () => Promise<string>;
}

export const PdfZipDownloadTile = ({ onDownload }: PdfZipDownloadTileProps) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { t } = useTranslation('components', { keyPrefix: 'PdfZipDownloadTile' });

    const triggerDownload = (downloadUrl: string) => {
        const anchorTag = document.getElementById('downloadBonusContent') as HTMLAnchorElement;
        if (anchorTag) {
            anchorTag.href = downloadUrl;
            anchorTag.click();
        }
    };

    const handleOnClick = async () => {
        if (isDownloading || !onDownload) return;

        setIsDownloading(true);
        const downloadUrl = await onDownload();
        if (downloadUrl) {
            triggerDownload(downloadUrl);
        }
        setIsDownloading(false);
    };

    return (
        <div className="w-[28.5rem] h-[28.5rem] p-[3rem] tablet:w-[62.5rem] tablet:h-[62.5rem] bg-gray flex justify-center items-center align-center flex-col">
            <Image src={pdfZipPlaceholder} alt={'pdfzip'} unoptimized />

            <div className="mt-[2rem] mb-[1rem] cursor-pointer" onClick={handleOnClick} data-testid="download-icon">
                <>
                    {isDownloading ? (
                        <PlayButton variant="loading" size={60} className="w-[6rem] h-[6rem]" />
                    ) : (
                        <Icon icon="download-circle-white" hoverIcon="downloadGradient" size={60} />
                    )}
                </>
                <a id="downloadBonusContent" className="invisible" download />
            </div>

            <Typography variant="h4">{isDownloading ? t('downloading') : t('downloadOnly')}</Typography>
        </div>
    );
};
