import React, { useCallback } from 'react';

import { FirstOwnersPanel } from './components/FirstOwnersPanel.component';
import { useQuery } from '@apollo/client';

import { FIND_FIRST_OWNERS, FindFirstOwnersData } from '@srnade/web/services';
import { Account, Product } from '@srnade/web/__generated__/graphql';

interface Props {
    product: Pick<Product, 'limit' | 'slug' | 'id' | 'title'> & {
        account: Pick<Account, 'username'>;
    };
    isListingClosed: boolean;
    isEdition: boolean;
}

export const FirstOwners = ({ product, isListingClosed, isEdition }: Props) => {
    const { loading, error, data, fetchMore } = useQuery<FindFirstOwnersData>(FIND_FIRST_OWNERS, {
        variables: {
            productSlug: product.slug,
            first: 50,
        },
        fetchPolicy: 'cache-and-network',
    });

    const loadNext = useCallback(async () => {
        await fetchMore({
            variables: {
                after: data?.findFirstOwners.pageInfo.endCursor,
            },
        });
    }, [data, fetchMore]);

    if (loading) {
        return null;
    }

    if (error || !data) {
        return null;
    }

    return (
        <FirstOwnersPanel
            product={product}
            isListingClosed={isListingClosed}
            isEdition={isEdition}
            firstOwners={data.findFirstOwners.edges.map((e) => e.node)}
            hasNext={data.findFirstOwners.pageInfo.hasNextPage}
            loadNext={loadNext}
        />
    );
};
