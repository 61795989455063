import { formatBytes } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import { Icon, PlayButton, Typography } from '@srnade/component-ui';
import { RewardsFragment } from '@srnade/web/__generated__/graphql';
import clsx from 'clsx';

type ProductRewardProps = {
    reward: RewardsFragment;
    locked: boolean;
    onDownload: (id: string) => void;
    isLoading: boolean;
};

const getRewardFileSize = (reward: RewardsFragment): number => {
    if (reward.document) {
        return reward.document.file.size;
    }
    if (reward.video) {
        return reward.video.file.size;
    }
    if (reward.audio) {
        return reward.audio.file.size;
    }
    if (reward.image) {
        return reward.image.file.size;
    }
    if (reward.other) {
        return reward.other.file.size;
    }

    throw new Error('Cannot find reward size');
};

export const ProductReward = ({ reward, locked, onDownload, isLoading }: ProductRewardProps) => {
    const { t } = useTranslation('components', { keyPrefix: 'rewards' });
    const size = getRewardFileSize(reward);
    const formattedSize = formatBytes(size);

    return (
        <div
            className={clsx(
                'rounded-[1rem] mb-[1rem] p-[1rem] flex items-center',
                {
                    'bg-cream border-[#b3aeb4] border-[0.1rem]': locked,
                },
                {
                    'bg-white': !locked,
                },
            )}
        >
            <div
                className={clsx('mr-[1.2rem] cursor-pointer hover:opacity-75', {
                    'opacity-30 cursor-default pointer-events-none': locked,
                })}
            >
                {reward.downloadable && (
                    <div>
                        <div
                            onClick={() => {
                                if (locked || isLoading) return;

                                onDownload(reward.id);
                            }}
                        >
                            {isLoading ? (
                                <PlayButton variant="loading" />
                            ) : (
                                <Icon icon={locked ? 'download-circle' : 'downloadGradient'} size={locked ? 44 : 46} />
                            )}
                        </div>

                        {/* The anchor is invisible because it is mainly being used to download a file using the pre-signed s3 url. */}
                        <a id={reward.id} className="invisible" download />
                    </div>
                )}
            </div>
            <div className="flex justify-between items-center w-full">
                <div>
                    <Typography
                        className={clsx('leading-none mb-[0.3rem] line-clamp-1', {
                            'opacity-40': locked,
                        })}
                        variant="bodyBold"
                    >
                        {reward.name}
                    </Typography>
                    <Typography className={clsx('leading-none', { 'opacity-60': locked })} variant="caption">
                        {locked ? t('unlocksWithPurchase') : t('unlocked')} - {formattedSize}
                    </Typography>
                </div>
                <div className={clsx('mx-[1rem]', { 'opacity-50': locked })}>
                    <Icon icon={`padlock-${locked ? 'closed' : 'open'}`} size={locked ? 16 : 22} />
                </div>
            </div>
        </div>
    );
};
