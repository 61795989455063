import { Button } from '@srnade/component-ui';
import { Edition } from '@srnade/web/__generated__/graphql';
import { useTranslation } from '@srnade/web/i18n/client';

import { getPolygonScanURL } from '@srnade/web/utils';

type ProductAuthenticityLinkProps = {
    edition: Pick<Edition, 'transactionHash'>;
};

export const ProductAuthenticityLink = ({ edition }: ProductAuthenticityLinkProps) => {
    const { t } = useTranslation('product-page');
    const polygonScanUrl = getPolygonScanURL();
    const transactionHash = edition.transactionHash;

    return (
        <a
            href={`${polygonScanUrl}${transactionHash}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="product-authenticity-link"
            data-gtm-id="edition_authenticity"
        >
            <Button className="mt-[4rem] w-fit" type="text" text={t('authenticityButton')} variant="small" />
        </a>
    );
};
