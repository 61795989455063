import { getTokenExpiry } from '@srnade/component-ui';

export function getTokenExpiryFromUrl(url?: string | null): number | null {
    if (!url) return null;

    const searchParams = new URL(url).searchParams;
    if (!searchParams.has('token')) {
        return null;
    }

    const token = searchParams.get('token') as string;
    try {
        return getTokenExpiry(token);
    } catch (e) {
        console.error(e);
    }

    return null;
}

export function isValidUrl(string: string) {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}

export function isValidUrlWithFileExtension(string: string) {
    try {
        const url = new URL(string);
        const pathname = url.pathname;
        const split = pathname.split('.');
        const extension = split[split.length - 1];
        return extension.length > 0 && pathname !== extension;
    } catch (err) {
        return false;
    }
}
