import { useRouter } from 'next/router';
import { PayWith } from '@srnade/web/const';
import { useAuth } from '../auth';
import { useLazyQuery } from '@apollo/client';
import { GetPaymentUrlQuery, GetPaymentUrlQueryVariables } from '../__generated__/graphql';
import { GET_PAYMENT_URL } from '../services';
import { addUsernamePrefix } from '@srnade/component-ui';
import { useEffect } from 'react';
import { signIn } from 'next-auth/react';

const BUY_ACTION = 'buy';

export const useStripe = (productId: string, origin: string): PayWithStripe => {
    const router = useRouter();
    const { activeAccount, authenticating, user } = useAuth();

    const [getPaymentUrl, { loading }] = useLazyQuery<GetPaymentUrlQuery, GetPaymentUrlQueryVariables>(
        GET_PAYMENT_URL,
        {
            onCompleted: (data) => {
                window.location.href = data.paymentUrl;
            },
            fetchPolicy: 'no-cache',
        },
    );

    const successUrl = activeAccount
        ? activeAccount.username && `${origin}/${addUsernamePrefix(activeAccount?.username)}`
        : origin;

    const cancelUrl = `${origin}${router.asPath.split('?')[0]}`; // remove query string to prevent navigation back to the stripe checkout website

    useEffect(() => {
        if (authenticating || !activeAccount || !user?.isEmailVerified || loading) {
            return;
        }

        const { action, ...restQuery } = router.query;
        if (action === BUY_ACTION) {
            // Update the router path to remove the action=<value> from the query
            router.replace({ pathname: router.pathname, query: { ...restQuery } }, undefined, { shallow: true });

            // automatically trigger the payment if the user is logged in and the action is to buy
            getPaymentUrl({
                variables: {
                    paymentUrlInput: {
                        productIds: [productId],
                        cancelUrl,
                        successUrl,
                    },
                },
            });
        }
    }, [
        authenticating,
        activeAccount,
        router,
        cancelUrl,
        user?.isEmailVerified,
        getPaymentUrl,
        productId,
        successUrl,
        loading,
    ]);

    const pay = () => {
        if (!productId) {
            return;
        }

        // If not active account, the user isn't logged in and we should redirect them to the sign in page
        if (!activeAccount) {
            const redirectUrl = `${router.asPath}?action=${BUY_ACTION}`;

            signIn('auth0', { callbackUrl: redirectUrl });
            return;
        }

        // The user is logged in and we can proceed with the payment by getting the payment url
        getPaymentUrl({
            variables: {
                paymentUrlInput: {
                    productIds: [productId],
                    cancelUrl,
                    successUrl,
                },
            },
        });
    };

    return { name: 'stripe', pay, loading };
};

export interface PayWithStripe extends PayWith {
    pay: () => void;
    loading: boolean;
}
