import { Button, Dialog, DialogContent, DialogFooter, DialogReturnValue, Typography } from '@srnade/component-ui';
import { PressingTrack } from '@srnade/web/__generated__/graphql';
import { useTranslation } from '@srnade/web/i18n/client';
import { getReadableFileSize } from '@srnade/web/utils';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

export type TrackDetails = Pick<
    PressingTrack,
    'active' | 'displayName' | 'trackNumber' | 'downloadable' | 'fileSize' | 'fileExtension'
>;

interface AudioDownloadsPopUpProps {
    productName: string;
    artistName: string;
    tracks: TrackDetails[];
    downloadAvailable: boolean;
    dialogToggle: DialogReturnValue;
    onDownload?: () => void;
    zipSize?: number;
    isDownloading?: boolean;
}
export function AudioDownloadsPopUp({
    dialogToggle,
    productName,
    artistName,
    zipSize,
    tracks,
    downloadAvailable,
    onDownload,
    isDownloading,
}: AudioDownloadsPopUpProps) {
    const { t } = useTranslation('components', { keyPrefix: 'audioDownloadPopup' });
    const [, , disable] = dialogToggle;

    return (
        <Dialog
            variant="small"
            type="secondary"
            title={t('title')}
            dialogToggle={dialogToggle}
            onRequestClose={disable}
            key="audio-download"
        >
            <DialogContent className="flex flex-col items-center mb-[6rem]">
                <>
                    <Typography variant="body" className="max-w-[69rem]">
                        <Trans
                            i18nKey="description"
                            t={t}
                            values={{ productName, artistName, zipSize: getReadableFileSize(zipSize ?? 0) }}
                            tOptions={{ interpolation: { escapeValue: false } }}
                        />
                    </Typography>

                    <div className="flex flex-start flex-col text-left">
                        {tracks?.map((track) => (
                            <div className="flex flex-row" key={track.trackNumber}>
                                <Typography
                                    variant="h6"
                                    className={clsx('mb-0 flex flex-row gap-x-2', {
                                        'text-darkGray': !track.downloadable,
                                    })}
                                >
                                    <span className="flex w-[17px]">{track.trackNumber}.</span>
                                    <div>
                                        <span>{track.displayName}</span>
                                        {track.downloadable && (
                                            <>
                                                <span className="ml-2 text-darkGray">({track.fileExtension})</span>
                                                <span className="ml-2 text-darkGray">
                                                    {getReadableFileSize(track?.fileSize ?? 0)}
                                                </span>
                                            </>
                                        )}
                                        {!track.downloadable && (
                                            <span className="ml-2 italic">{t('unavailableForDownload')}</span>
                                        )}
                                    </div>
                                </Typography>
                            </div>
                        ))}
                    </div>
                </>
            </DialogContent>

            <DialogFooter>
                {downloadAvailable && (
                    <Button
                        text="Download"
                        type="primary"
                        variant="standard"
                        className="w-full max-w-[40rem]"
                        onClick={onDownload}
                        loading={isDownloading}
                        disabled={isDownloading}
                    />
                )}
                <Button
                    text="Close"
                    type="secondary"
                    variant="standard"
                    className="w-full max-w-[40rem]"
                    setIcon={false}
                    onClick={disable}
                />
            </DialogFooter>
        </Dialog>
    );
}
