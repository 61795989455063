import React from 'react';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';
import { Card, CardHeader, CardContent } from '@srnade/component-ui';
import type { BaseComponentProps } from '@srnade/component-ui';

import 'react-loading-skeleton/dist/skeleton.css';
export interface SkeletonAccountTileProps {
    type?: 'standard' | 'carousel';
}

export function SkeletonAccountTile({
    className,
    type = 'standard',
}: SkeletonAccountTileProps & BaseComponentProps): JSX.Element {
    return (
        <div className={className} data-testid="skeleton-account-tile">
            <Card
                className={clsx('rounded-2xl p-[1rem]', {
                    'max-w-[33.5rem] h-[46rem] tablet:w-[33.5rem] laptop:max-w-[29.5rem] laptop:h-[42rem]':
                        type === 'standard',
                    'max-w-[29.5rem] h-[42rem]': type === 'carousel',
                })}
                border
            >
                <CardHeader>
                    {type === 'carousel' ? (
                        <Skeleton height="275px" />
                    ) : (
                        <>
                            <div className="laptop:hidden">
                                <Skeleton height="315px" />
                            </div>
                            <div className="hidden laptop:grid">
                                <Skeleton height="275px" />
                            </div>
                        </>
                    )}
                </CardHeader>
                <CardContent className="mx-[1rem] mt-[1.6rem]">
                    <Skeleton className="mb-[1.2rem]" width="173px" height="17px" />
                    <Skeleton width="58px" height="14px" />
                    <Skeleton className="mt-[1rem]" width="95px" height="14px" />
                    <Skeleton className="mt-[1rem] mb-[1rem]" width="95px" height="14px" />
                    {/* <Skeleton className="mt-[1rem]" width="100%" height="41px" /> */}
                </CardContent>
            </Card>
        </div>
    );
}
