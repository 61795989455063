import React from 'react';
import clsx from 'clsx';
import { useTranslation } from '@srnade/web/i18n/client';
import { BaseComponentProps, Typography } from '@srnade/component-ui';

import { Link } from '@srnade/web/components';

export interface Breadcrumb {
    href?: string;
    label: string;
}

interface BreadcrumbsProps {
    links: Breadcrumb[];
}

export function Breadcrumbs({ links, className, children }: BreadcrumbsProps & BaseComponentProps): JSX.Element {
    const { t } = useTranslation('components', { keyPrefix: 'breadCrumbs' });

    return (
        <div className={clsx('flex flex-row mb-[2rem] mt-[0.2rem] items-center laptop:ml-[0.3rem]', className)} data-testid="breadcrumbs">
            <div className="flex flex-row">
                <Link href="/" passHref legacyBehavior>
                    <Typography className="hover:underline" variant="body">
                        {t('home')}
                    </Typography>
                </Link>
            </div>
            {links.length > 0 &&
                links.map(({ href, label }) => {
                    if (href) {
                        return (
                            <div key={`Link_${href}`} className="flex flex-row">
                                <div className="my-[-0.4rem] mx-[0.5rem]">
                                    <Typography className="mt-[0.5rem]" variant="body">
                                        &gt;
                                    </Typography>
                                </div>
                                <Link href={href} passHref legacyBehavior>
                                    <Typography
                                        className={clsx('cursor-pointer line-clamp-1 capitalize', {
                                            'hover:underline': href,
                                        })}
                                        variant="body"
                                    >
                                        {label}
                                    </Typography>
                                </Link>
                            </div>
                        );
                    } else {
                        return (
                            <div key={`Link_${href}`} className="flex flex-row">
                                <div className="my-[-0.4rem] mx-[0.5rem]">
                                    <Typography className="mt-[0.5rem]" variant="body">
                                        &gt;
                                    </Typography>
                                </div>
                                <Typography className="cursor-default line-clamp-1 capitalize" variant="body">
                                    {label}
                                </Typography>
                            </div>
                        );
                    }
                })}
            {children}
        </div>
    );
}
