import { PanelContent } from '../Panel';
import { EmptyTrackItem } from './EmptyTrackItem.component';
import { Track, TrackItem } from './TrackItem.component';

type TrackListProps = {
    tracks: Track[];
    locked: boolean;
    showEmpty?: boolean;
    selectedTrack?: SelectedTrack;
    onClick?: (trackAssetId: string) => void;
};

export type SelectedTrack = {
    assetId: string;
    isPlaying: boolean;
    isRestart: boolean;
};

export function TrackList({ tracks, locked, showEmpty, selectedTrack, onClick }: TrackListProps) {
    return tracks.length === 0 && showEmpty === true ? (
        <div className={'mt-[20px]'}>
            <EmptyTrackItem />
        </div>
    ) : (
        <PanelContent className="max-h-[35rem]" totalItems={tracks.length || 0}>
            {tracks.map((track, index) => (
                <TrackItem
                    active={track.active}
                    assetId={track?.assetId}
                    displayName={track?.displayName}
                    duration={track?.duration}
                    highlight={selectedTrack?.assetId === track?.assetId}
                    isPlaying={selectedTrack?.assetId === track?.assetId && selectedTrack?.isPlaying === true}
                    key={`track-${track.assetId ?? index}`}
                    locked={locked || track?.active !== true || track?.streamable !== true}
                    streamable={track?.streamable}
                    trackNumber={track?.trackNumber}
                    onClick={onClick}
                />
            ))}
        </PanelContent>
    );
}
