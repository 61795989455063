// Note: this hasn't been tested yet

// Usage:
// function App() {
//     // Similar to useState but first arg is key to the value in local storage.
//     const [name, setName] = useCookie('name', 'Bob');

//     return (
//         <div>
//             <input type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
//         </div>
//     );
// }
import { useState } from 'react';

export const useSessionStorage = (key: string, initialValue: null | string) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            if (typeof window !== 'undefined') {
                // Get from cookie storage by key
                const item = window.sessionStorage.getItem(key);
                // Parse stored value or if none return initialValue
                return item ? JSON.parse(item) : initialValue;
            }
        } catch (error) {
            // If error also return initialValue
            console.error(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            if (typeof window !== 'undefined') {
                // Save to session storage
                window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    };

    return [storedValue, setValue];
};
