import React, { ReactElement } from 'react';

import { Carousel as BaseCarousel, GTM_ID, Typography } from '@srnade/component-ui';

interface CarouselProps {
    title?: string;
    background?: boolean;
    gtmId?: string;
    children: ReactElement[];
}

/**
 * Displays a carousel with an optional heading
 * @param props Carousel props
 */
export function Carousel({ title, background, children, gtmId }: CarouselProps): JSX.Element {
    const titleInput = title?.split(' ') || '';
    const gtmAttr = !!gtmId ? { [GTM_ID]: gtmId } : {};
    return (
        <>
            <div className="flex justify-center">
                {title && (
                    <Typography variant="h1">
                        {titleInput[0]} {titleInput[1]}{' '}
                        <span className="font-h2 font-normal italic">{titleInput[2]}</span>
                    </Typography>
                )}
            </div>
            <div className="bg-cream mt-[3rem]" {...gtmAttr}>
                <BaseCarousel className="rounded-none" centeredSlides carouselBackground={background}>
                    {children}
                </BaseCarousel>
            </div>
        </>
    );
}
