import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useHoverDirty } from 'react-use';

import { Icon, Typography } from '@srnade/component-ui';
import { time } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';
import { PressingTrack } from '@srnade/web/__generated__/graphql';

export type Track = Pick<
    PressingTrack,
    'assetId' | 'active' | 'duration' | 'displayName' | 'trackNumber' | 'streamable'
>;

export type TrackItemProps = {
    highlight: boolean;
    locked: boolean;
    isPlaying: boolean;
    onClick?: (trackAssetId: string) => void;
} & Track;

export function TrackItem({
    active,
    assetId,
    displayName,
    duration,
    highlight,
    locked,
    streamable,
    trackNumber,
    isPlaying,
    onClick,
}: TrackItemProps) {
    const rootRef = useRef<HTMLDivElement>(null);
    const hovering = useHoverDirty(rootRef);
    const { t } = useTranslation('components', { keyPrefix: 'pressingTrack' });

    const releasedTrack = Boolean(active && displayName && displayName.length > 0);

    // fix locked state, we need to check if the track is released
    if (!releasedTrack) {
        locked = true;
    }

    const streamableTrack = Boolean(releasedTrack && streamable && !locked);
    const highlightTrackTitle = Boolean(!locked && (highlight || isPlaying));

    const handleOnPlay = () => {
        if (streamableTrack && assetId && onClick) {
            onClick(assetId);
        }
    };

    useEffect(() => {
        if (highlight && rootRef.current) {
            rootRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [highlight]);

    return (
        <div
            className={clsx(
                'rounded-[1rem] mb-[1rem] last:mb-0 p-[1px] h-[6rem]',
                {
                    'bg-cream border-[#AFAFAF] border-[0.1rem]': locked,
                },
                {
                    'bg-white shadow:none from-white to-white ': !locked,
                },
                {
                    'hover:bg-gradient-to-b cursor-pointer hover:from-gradientBlue hover:via-gradientBlue hover:to-gradientPurple hover:shadow-gradient':
                        !locked && streamableTrack,
                },
            )}
            data-testid="track-list-item"
            ref={rootRef}
            onClick={releasedTrack ? handleOnPlay : undefined}
        >
            <div
                className={clsx(
                    'px-[2rem] py-[1rem] h-full rounded-[1rem] flex items-center hover:opacity-100  justify-between w-full',
                    {
                        ' cursor-default pointer-events-none': locked,
                        'bg-white': !locked,
                    },
                )}
                data-testid={locked ? 'locked-track' : 'unlocked-track'}
            >
                <div className="flex flex-row items-center max-w-[calc(100%-6rem)]">
                    <Typography
                        className={clsx('leading-none mr-[2rem]', { 'text-darkGray': locked })}
                        variant={highlightTrackTitle ? 'bodyBold' : 'body'}
                    >
                        {trackNumber}.
                    </Typography>

                    {releasedTrack ? (
                        <Typography
                            className={clsx('leading-none line-clamp-1', { 'text-darkGray': locked })}
                            variant={highlightTrackTitle ? 'bodyBold' : 'body'}
                        >
                            {displayName}
                        </Typography>
                    ) : (
                        <Typography
                            variant="bodyGrey"
                            className="font-h2 italic text-darkGray text-[1.5rem] leading-[2rem]"
                            data-testid={`unreleased-${assetId}`}
                        >
                            {t('unreleased')}
                        </Typography>
                    )}
                </div>

                {streamableTrack && isPlaying && <Icon icon="playingGradient" size={30} />}
                {streamableTrack && !isPlaying && hovering && <Icon icon="playGradient" size={30} />}

                {releasedTrack && duration && !isPlaying && (!hovering || !streamableTrack) ? (
                    <Typography
                        variant="body"
                        className="text-darkGray text-[1.5rem] leading-[2rem]"
                        data-testid={`track-duration-${assetId}`}
                    >
                        {time.convertMsToTime(duration, false, true, true)}
                    </Typography>
                ) : null}
            </div>
        </div>
    );
}
