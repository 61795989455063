interface SiteLink {
    type?: string;
    label: string;
    url: string;
}

export const siteLinks: SiteLink[] = [
    { type: 'general', label: 'Faqs', url: 'https://intercom.help/serenade/en/' },
    { type: 'general', label: 'Collectors Club', url: '/collectors-club' },
    { type: 'general', label: 'Careers', url: 'https://jobs.polymer.co/serenade/' },
    { type: 'general', label: 'Contact', url: '/contact' },
    { type: 'social', label: 'Instagram', url: 'https://www.instagram.com/serenade' },
    { type: 'social', label: 'Facebook', url: 'https://www.facebook.com/serenadecommunityco' },
    { type: 'social', label: 'Discord', url: 'https://discord.gg/WrVc5ZXkmh' },
    {
        type: 'social',
        label: 'Spotify',
        url: 'https://open.spotify.com/user/8mfzpkouw948h7qf7g3w0u8je?si=f9e6a07138f148a0&nd=1',
    },
    { type: 'social', label: 'Twitter', url: 'https://twitter.com/joinserenade' },
    { type: 'social', label: 'Linkedin', url: 'https://www.linkedin.com/company/serenadeco/' },
    { type: 'social', label: 'Tiktok', url: 'https://www.tiktok.com/@serenade.co' },
    { type: 'social', label: 'Youtube', url: 'https://www.youtube.com/channel/UCXhjcDZDWGK0KFu5H3t6hoA' },
];

export const contactLinks: SiteLink[] = [
    { label: 'instagram', url: 'https://www.instagram.com/serenade' },
    { label: 'facebook', url: 'https://www.facebook.com/serenadecommunityco' },
    { label: 'discord', url: 'https://discord.gg/WrVc5ZXkmh' },
    { label: 'spotify', url: 'https://open.spotify.com/user/8mfzpkouw948h7qf7g3w0u8je?si=f9e6a07138f148a0&nd=1' },
    { label: 'twitter', url: 'https://twitter.com/joinserenade' },
];
