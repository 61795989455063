export enum TreatmentDisplay {
    On = 'On',
    Off = 'Off',
    Control = 'Control',
}

export enum TreatmentType {
    NftTransfers = 'NftTransfers',
    Notifications = 'Notifications',
    AccountApproved = 'AccountApproved',
    UMGPurchaseTermsAndConditions = 'UMGPurchaseTermsAndConditions',
    ProductMarketingConsent = 'ProductMarketingConsent',
    GoogleAnalyticsEvents = 'GoogleAnalyticsEvents',
    CreatorPlatform = 'creatorPlatform',
}

export const TreatmentDisplayMap = {
    [TreatmentDisplay.On]: 'on',
    [TreatmentDisplay.Off]: 'off',
    [TreatmentDisplay.Control]: 'control',
} as const;

export const TreatmentTypeMap = {
    [TreatmentType.NftTransfers]: 'nftTransfers',
    [TreatmentType.Notifications]: 'notifications',
    [TreatmentType.UMGPurchaseTermsAndConditions]: 'umg_purchase_terms_conditions',
    [TreatmentType.ProductMarketingConsent]: 'product_marketing_consent',
    [TreatmentType.GoogleAnalyticsEvents]: 'google_analytics_events',
    [TreatmentType.CreatorPlatform]: 'creator_platform',
} as const;

export const TreatmentKeys: string[] = Object.values(TreatmentTypeMap);
export const treatmentNames: string[] = Object.values(TreatmentTypeMap);

// SplitIO specific reserved treatment which is returned when the SDK is not ready when a treatment lookup is attempted
export const ControlTreatment: string = 'control';
