interface LogEventParams {
    eventTypeLogging: EventTypeLogging;
    eventDescriptor: string;
    eventTime?: Date; // if not provided, this will be default to Date.now()
    accountId?: string; // optional, as the user may not be logged in
}

/**
 * Enum for logging event types.
 * Add new event types to this enum as needed, ensuring each type has a unique and descriptive name.
 */
export enum EventTypeLogging {
    VoucherClaim = 'Voucher Claim',
}

/**
 * Logs an analytics event to the console.
 *
 * @param params - The details of the event to log.
 */
export function consoleLogEvent(params: LogEventParams): void {
    const { eventTypeLogging, eventTime, eventDescriptor, accountId } = params;
    const timestamp = eventTime?.toISOString() ?? new Date().toISOString();
    const account = accountId ? `for accountId ${accountId}` : '';

    let logMessage = `[${eventTypeLogging}] ${timestamp} ${eventDescriptor} ${account}`;

    console.log(logMessage.trim());
}
