import React, { useEffect, useState } from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { BaseComponentProps, Typography, Button, Icon } from '@srnade/component-ui';
import { AccountType, isArtist } from '@srnade/component-ui';
import { useGtmEvents } from '@srnade/web/hooks';
import { GtmLoggedInStatus, GtmUserEvent } from '@srnade/web/types';

import { useAuth } from '@srnade/web/auth';
import { Link } from '@srnade/web/components';

import { ListArtistNames } from '../ArtistMenu';
import { signIn } from 'next-auth/react';

interface NavMenuProps {
    visible: boolean;
}

export function NavMenu({ visible }: NavMenuProps & BaseComponentProps): JSX.Element {
    const { authenticated, activeAccount, logout, user } = useAuth();
    const [showArtistCharacters, setShowArtistCharacters] = useState<boolean>(false);
    const { t } = useTranslation('components', { keyPrefix: 'navMenu' });
    const { pushUserEvent } = useGtmEvents(GtmUserEvent.LogoutSuccess);

    const accountType = activeAccount?.type as AccountType;

    const handleLogout = async () => {
        if (user && activeAccount) {
            // TODO temp, remove once splitio issue is fixed (MARK-641)
            pushUserEvent(user.id, GtmLoggedInStatus.NotLoggedIn, activeAccount.type);
        }
        await logout();
    };

    useEffect(() => {
        if (!visible) {
            setShowArtistCharacters(false);
        }
    }, [visible]);

    const authnButtons = (
        <div className="w-full">
            <Button
                className="mt-[4rem] w-full"
                text={t('signIn')}
                type="standard"
                onClick={() => signIn('auth0', { callbackUrl: '/me' })}
                data-testid="navmenu-sign-in-button"
            />
            <Button
                className="mt-[2rem] w-full"
                text={t('signUp')}
                type="secondary"
                onClick={() => signIn('auth0', { callbackUrl: '/shop-all' }, { screen_hint: 'signup' })}
                data-testid="navmenu-sign-up-button"
            />
        </div>
    );

    return (
        <div className={clsx('px-[2rem] pb-[10rem]')}>
            <Link href="/shop-all" passHref legacyBehavior>
                <Typography
                    className={clsx('mt-[5rem]', {
                        'mt-[3rem]': activeAccount && isArtist(accountType),
                    })}
                    variant="subheadingBold"
                    uppercase
                >
                    {t('releases')}
                </Typography>
            </Link>
            <>
                <Typography
                    className="mt-[3rem] cursor-pointer"
                    variant="subheadingBold"
                    uppercase
                    onClick={() => {
                        setShowArtistCharacters(!showArtistCharacters);
                    }}
                >
                    {t('artists')}
                    <Icon
                        className={clsx('ml-2', {
                            'rotate-180': showArtistCharacters,
                        })}
                        icon="chevron-down"
                        size={10}
                    />
                </Typography>
                {/*@ts-ignore TODO AnimatePresence needs to update their props spec to acknowledge they admit a children prop */}
                <AnimatePresence initial={false}>
                    {showArtistCharacters && (
                        <motion.div
                            key="content"
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                                open: { opacity: 1, height: 'auto' },
                                collapsed: { opacity: 0, height: 0 },
                            }}
                            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                        >
                            <div className="min-h-[81rem] pt-8" data-gtm-id="nav_artist">
                                <ListArtistNames />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </>
            <Link href="https://artists.serenade.co" passHref legacyBehavior>
                <Typography className="mt-[3rem]" variant="subheadingBold" uppercase>
                    {t('about')}
                </Typography>
            </Link>
            {/* <Link href="/opensea" passHref>
                <Typography className="mt-[3rem]" variant="subheadingBold" uppercase>
                    {t('openSea')}
                </Typography>
            </Link> */}
            <a href="https://intercom.help/serenade/en/" target="_blank" rel="noreferrer">
                <Typography className="mt-[3rem]" variant="subheadingBold" uppercase>
                    {t('faqs')}
                </Typography>
            </a>
            <Link href="/contact" passHref legacyBehavior>
                <Typography className="mt-[3rem]" variant="subheadingBold" uppercase>
                    {t('contact')}
                </Typography>
            </Link>
            <div className="flex basis-full">
                {authenticated ? (
                    <Typography
                        className="mt-[3rem] cursor-pointer"
                        variant="subheadingBold"
                        uppercase
                        data-testid="navmenu-sign-out-button"
                    >
                        <span onClick={() => handleLogout()}>{t('signOut')}</span>
                    </Typography>
                ) : (
                    authnButtons
                )}
            </div>
        </div>
    );
}
