import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from '@srnade/web/i18n/client';
import ReactHtmlParser from 'react-html-parser';

import {
    Dialog,
    DialogContent,
    type BaseComponentProps,
    Typography,
    Button,
    DialogReturnValue,
} from '@srnade/component-ui';
import { ParentRecordLabel, ParentRecordLabelHumanized } from '@srnade/component-ui';

import { CREATE_PRODUCT_MARKETING_CONSENT } from '@srnade/web/services';
import {
    CreateProductMarketingConsentMutation,
    CreateProductMarketingConsentMutationVariables,
} from '@srnade/web/__generated__/graphql';

export interface ProductMarketingConsentModalProps {
    dialogToggle: DialogReturnValue;
    artistName: string;
    reservedProductId: string;
    parentRecordLabel: ParentRecordLabel;
    onClose: () => void;
}

export function ProductMarketingConsentModal({
    dialogToggle,
    artistName,
    reservedProductId,
    parentRecordLabel,
    onClose = () => {},
}: ProductMarketingConsentModalProps & BaseComponentProps): JSX.Element {
    const [, , closeModal] = dialogToggle;
    const { t } = useTranslation('components', { keyPrefix: 'productMarketingConsent' });
    const [createConsent, { loading }] = useMutation<
        CreateProductMarketingConsentMutation,
        CreateProductMarketingConsentMutationVariables
    >(CREATE_PRODUCT_MARKETING_CONSENT);

    const doCreateConsent = useCallback(
        async (reservedProdId: string) => {
            await createConsent({
                variables: {
                    input: {
                        reservedProductId: reservedProdId,
                    },
                },
            });
        },
        [createConsent],
    );

    const handleCreateConsent = useCallback(async () => {
        // call create product marketing consent mutation
        if (reservedProductId) {
            await doCreateConsent(reservedProductId);
        }

        closeModal();
        onClose();
    }, [doCreateConsent, closeModal, onClose, reservedProductId]);

    const handleOnClose = useCallback(() => {
        closeModal();
        onClose();
    }, [closeModal, onClose]);

    const privacyLink = useMemo(() => t(`${parentRecordLabel}.privacyLink`), [parentRecordLabel, t]);
    const disclaimer = useMemo(
        () =>
            t(`${parentRecordLabel}.disclaimer`, {
                privacyLink,
                interpolation: { escapeValue: false },
            }),
        [parentRecordLabel, privacyLink, t],
    );

    return (
        <Dialog
            title={t('title')}
            className="laptop:pt-24 laptop:pb-26 max-w-[73rem]"
            dialogToggle={dialogToggle}
            onRequestClose={handleOnClose}
        >
            <div data-testid="product-marketing-consent-modal">
                <DialogContent className="w-full max-w-[58rem] mx-auto p-0">
                    <div className="flex items-center w-full max-w-[51rem] mx-auto">
                        <Typography variant="body">
                            {t('description', {
                                artistName,
                                parentRecordLabel: ParentRecordLabelHumanized[parentRecordLabel],
                            })}
                        </Typography>
                    </div>
                    <div className="flex flex-col tablet:flex-row items-center justify-center gap-8 mt-8 mx-auto">
                        <Button
                            className="w-full max-w-[22rem]"
                            type="secondary"
                            text={t('btnNo')}
                            onClick={handleOnClose}
                            setIcon={false}
                            loading={false}
                            disabled={loading}
                            data-testid="product-marketing-consent-no-btn"
                        />
                        <Button
                            className="w-full max-w-[22rem]"
                            type="secondary"
                            text={t('btnYes')}
                            onClick={handleCreateConsent}
                            setIcon={false}
                            loading={false}
                            disabled={loading}
                            data-testid="product-marketing-consent-yes-btn"
                        />
                    </div>

                    <div className="w-full max-w-[49rem] mx-auto mt-16">
                        <Typography variant="caption">{ReactHtmlParser(disclaimer)}</Typography>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
}
