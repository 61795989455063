export enum Genre {
    Rock = 'Rock',
    Indie = 'Indie',
    Metal = 'Metal',
    HipHop = 'HipHop',
    RhythmAndBlues = 'RhythmAndBlues',
    Soul = 'Soul',
    Folk = 'Folk',
    Blues = 'Blues',
    Country = 'Country',
    Orchestral = 'Orchestral',
    Jazz = 'Jazz',
    Dance = 'Dance',
    Electronica = 'Electronica',
    Pop = 'Pop',
    Ska = 'Ska',
    Reggae = 'Reggae',
    Punk = 'Punk',
    PostPunk = 'PostPunk',
    NewWave = 'NewWave',
    Classical = 'Classical',
}

export const GenresHumanized: { [key: string]: string } = {
    [Genre.Rock]: 'Rock',
    [Genre.Indie]: 'Indie',
    [Genre.Metal]: 'Metal',
    [Genre.HipHop]: 'Hip Hop',
    [Genre.RhythmAndBlues]: 'RnB',
    [Genre.Soul]: 'Soul',
    [Genre.Folk]: 'Folk',
    [Genre.Blues]: 'Blues',
    [Genre.Country]: 'Country',
    [Genre.Orchestral]: 'Orchestral',
    [Genre.Jazz]: 'Jazz',
    [Genre.Dance]: 'Dance',
    [Genre.Electronica]: 'Electronic',
    [Genre.Pop]: 'Pop',
    [Genre.Ska]: 'Ska',
    [Genre.Reggae]: 'Reggae',
    [Genre.Punk]: 'Punk',
    [Genre.PostPunk]: 'Post Punk',
    [Genre.NewWave]: 'New Wave',
    [Genre.Classical]: 'Classical',
};
