import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import {
    GetProductFormatQuery,
    GetProductFormatQueryVariables,
    ProductFormatFilters,
} from '@srnade/web/__generated__/graphql';
import { GET_PRODUCT_FORMAT } from '@srnade/web/services';
import client from 'clients/apollo';
import { HYDRATE } from 'next-redux-wrapper';

export enum ProductFormatStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Failed = 'Failed',
}

export interface ProductFormatState {
    productFormat: GetProductFormatQuery['productFormat'] | null;
    status: ProductFormatStatus;
    error: string | null | undefined;
}

const initialState: ProductFormatState = {
    productFormat: null,
    status: ProductFormatStatus.Idle,
    error: null,
};

interface FetchProductFormatArgs {
    filters: GetProductFormatQueryVariables['filters'];
    token?: string;
    accountId?: string;
}

export const fetchProductFormat: AsyncThunk<GetProductFormatQuery, FetchProductFormatArgs, AsyncThunkConfig> =
    createAsyncThunk('product/fetchProductFormat', async (args: FetchProductFormatArgs, thunkApi) => {
        try {
            const { filters, token, accountId } = args;
            const response = await client.query<GetProductFormatQuery, GetProductFormatQueryVariables>({
                query: GET_PRODUCT_FORMAT,
                fetchPolicy: 'no-cache',
                variables: {
                    filters,
                },
                ...(token &&
                    accountId && {
                        context: {
                            headers: { authorization: `Bearer ${token}`, 'account-id': accountId },
                        },
                    }),
            });
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    });

const productFormatSlice = createSlice({
    name: 'product',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setProductFormat(_, action) {
            return {
                status: action.payload?.status ?? ProductFormatStatus.Idle,
                productFormat: action.payload?.productFormat ?? null,
                error: action.payload?.error ?? null,
            };
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // Please read https://github.com/kirill-konshin/next-redux-wrapper?tab=readme-ov-file#state-reconciliation-during-hydration
            .addCase(HYDRATE, (state, action: PayloadAction<GetProductFormatQuery, typeof HYDRATE>) => {
                state.status = ProductFormatStatus.Idle;
                state.productFormat = action.payload.productFormat;
                state.error = null;
            })
            .addCase(fetchProductFormat.pending, (state) => {
                state.status = ProductFormatStatus.Pending;
            })
            .addCase(fetchProductFormat.fulfilled, (state, action) => {
                state.status = ProductFormatStatus.Idle;
                state.productFormat = action.payload.productFormat;
                state.error = null;
            })
            .addCase(fetchProductFormat.rejected, (state, action) => {
                state.status = ProductFormatStatus.Failed;
                if (action.payload instanceof Error) {
                    // For example this could be validation error handling
                    state.error = action?.payload?.message;
                } else {
                    state.error = action.error.message;
                }
            });
    },
});

export const { setProductFormat } = productFormatSlice.actions;
export const ProductFormatReducer = productFormatSlice.reducer;
export default productFormatSlice;
