import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import {
    GetAssetStreamingUrlQuery,
    GetAssetStreamingUrlQueryVariables,
    PressingTrack,
} from '@srnade/web/__generated__/graphql';
import { GET_ASSET_STREAMING_URL } from '@srnade/web/services';
import client from 'clients/apollo';
import { HYDRATE } from 'next-redux-wrapper';
import { GlobalState } from '../global';

export enum PlayerStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Success = 'Success',
    Failed = 'Failed',
}

export type Track = Pick<PressingTrack, 'assetId' | 'trackNumber' | 'displayName'> & {
    streamingUrl: string | null;
};

export interface PlayerState {
    tracks: Track[];
    currentTrackIndex: number | null;
    isPlaying: boolean; // current track is playing
    isVisible: boolean; // player is visible
    isRestart: boolean; // restart current track
    artworkUrl: string;
    artist: ArtistType;
    status: PlayerStatus;
    error: string | null | undefined;
}

export interface ArtistType {
    name: string;
    profilePicUrl?: string | null;
    username: string;
}

const initialState: PlayerState = {
    tracks: [],
    currentTrackIndex: null,
    isPlaying: false,
    isVisible: false,
    isRestart: false,
    artworkUrl: '',
    artist: {
        name: '',
        username: '',
    },
    status: PlayerStatus.Idle,
    error: null,
};

export const fetchStreamUrl: AsyncThunk<
    { data: GetAssetStreamingUrlQuery; assetId: string },
    { variables: GetAssetStreamingUrlQueryVariables },
    AsyncThunkConfig
> = createAsyncThunk('player/fetchStreamUrl', async ({ variables }, thunkApi) => {
    try {
        const response = await client.query<GetAssetStreamingUrlQuery, GetAssetStreamingUrlQueryVariables>({
            query: GET_ASSET_STREAMING_URL,
            fetchPolicy: 'no-cache',
            variables,
        });

        return { data: response.data, assetId: variables.input.assetId };
    } catch (error) {
        console.log('fetchStreamUrl serror', error);
        return thunkApi.rejectWithValue(error);
    }
});

const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        play(state) {
            state.isPlaying = true;
        },
        pause(state) {
            state.isPlaying = false;
        },
        changeIsPlaying(state, action: PayloadAction<boolean>) {
            state.isPlaying = action.payload;
        },
        changeIsRestart(state, action: PayloadAction<boolean>) {
            state.isRestart = action.payload;
        },
        back(state) {
            const currentTrack = state.currentTrackIndex !== null ? state.tracks[state.currentTrackIndex] : null;
            const index = state.tracks.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
            if (index !== -1 && state.currentTrackIndex !== 0) {
                state.currentTrackIndex = (index - 1 + state.tracks.length) % state.tracks.length;
            }
        },
        next(state) {
            const currentTrack = state.currentTrackIndex !== null ? state.tracks[state.currentTrackIndex] : null;
            const index = state.tracks.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
            if (index !== -1 && state.currentTrackIndex !== state.tracks.length - 1) {
                state.currentTrackIndex = (index + 1) % state.tracks.length;
            }
        },
        storeTrackDetails(state, action: PayloadAction<{ tracks: Track[]; artworkUrl: string; artist: ArtistType }>) {
            state.tracks = action.payload.tracks;
            state.artist = action.payload.artist;
            state.artworkUrl = action.payload.artworkUrl;
            state.currentTrackIndex = state.tracks?.findIndex((track) => track.trackNumber === 1);
            if (state.currentTrackIndex === -1) {
                state.currentTrackIndex = 0;
            }
            state.isRestart = false;
        },
        changeCurrentTrack(state, action: PayloadAction<Track | null>) {
            const trackIndex = state.tracks.findIndex((track) => track.assetId === action.payload?.assetId);
            if (trackIndex !== -1) {
                state.currentTrackIndex = trackIndex;
            }
        },
        showPlayer(state, action: PayloadAction<boolean>) {
            state.isVisible = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Please read https://github.com/kirill-konshin/next-redux-wrapper?tab=readme-ov-file#state-reconciliation-during-hydration
            .addCase(
                HYDRATE,
                (
                    state,
                    action: PayloadAction<{ data: GetAssetStreamingUrlQuery; assetId: string }, typeof HYDRATE>,
                ) => {
                    const { assetId, data } = action.payload;
                    const trackIndex = state.tracks.findIndex((track) => track.assetId === assetId);
                    const streamUrl = data?.formatInstanceAssetStreamingUrl;
                    if (trackIndex !== -1) {
                        state.tracks[trackIndex].streamingUrl = streamUrl;
                    }
                    state.status = PlayerStatus.Idle;
                    state.error = null;
                },
            )
            .addCase(fetchStreamUrl.pending, (state) => {
                state.status = PlayerStatus.Pending;
            })
            .addCase(fetchStreamUrl.fulfilled, (state, action) => {
                const { assetId, data } = action.payload;
                const trackIndex = state.tracks.findIndex((track) => track.assetId === assetId);
                const streamUrl = data?.formatInstanceAssetStreamingUrl;
                if (trackIndex !== -1) {
                    state.tracks[trackIndex].streamingUrl = streamUrl;
                }
                state.status = PlayerStatus.Success;
                state.error = null;
            })
            .addCase(fetchStreamUrl.rejected, (state, action) => {
                state.status = PlayerStatus.Failed;
                if (action.payload instanceof Error) {
                    state.error = action?.payload?.message;
                } else {
                    state.error = action.error.message;
                }
            });
    },
});

export const {
    play,
    pause,
    back,
    next,
    storeTrackDetails,
    changeCurrentTrack,
    changeIsPlaying,
    changeIsRestart,
    showPlayer,
} = playerSlice.actions;

export const selectPlayerFetchStatus = (state: GlobalState) => state.player.status;
export const selectPlayerTracks = (state: GlobalState) => state.player.tracks;
export const selectPlayerCurrentTrack = (state: GlobalState) =>
    state.player.currentTrackIndex === null ? null : state.player.tracks[state.player.currentTrackIndex];
export const selectPlayerIsPlaying = (state: GlobalState) => state.player.isPlaying;
export const selectPlayerIsVisible = (state: GlobalState) => state.player.isVisible;
export const selectPlayerIsRestart = (state: GlobalState) => state.player.isRestart;
export const selectPlayerStreamingUrl = (state: GlobalState) =>
    state.player.currentTrackIndex === null ? null : state.player.tracks[state.player.currentTrackIndex]?.streamingUrl;
export const selectPlayerArtist = (state: GlobalState) => state.player.artist;
export const selectPlayerArtworkUrl = (state: GlobalState) => state.player.artworkUrl;

export default playerSlice;
