/**
 * In order to prevent mapping, the camelCase are used for enum values that directly map to the translation object keys.
 */
// @todo incorrect enum format
export enum FileUploadErrorCodes {
    IncorrectFileSize = 'incorrectFileSize',
    IncorrectFileType = 'incorrectFileType',
    FileUploadError = 'fileUploadError',
    FileNotFound = 'fileNotFound',
}
