import clsx from 'clsx';
import { Icon, Typography, IconSize, ProgressBar } from '@srnade/component-ui';
import { DndItemType, DragAndDropFormikChildItemProps } from '@srnade/web/types';

import { TrackItem, RewardItem } from '../index';

const ellipseIconSize: IconSize = { width: 13, height: 27 };
const closeIconSize: IconSize = { width: 17, height: 17 };

export function DragAndDropFormikChildItem({
    item,
    index,
    progressValue,
    onDeleteInput,
    dndItemType = DndItemType.Track,
}: DragAndDropFormikChildItemProps) {
    return (
        <div className="flex flex-col" data-testid="dnd-container-item">
            <div className="flex laptop:items-center w-full">
                <div
                    className={clsx('flex mt-[1.2rem] laptop:mt-0', {
                        'mt-[1.2rem] tablet:mt-[1.6rem]': dndItemType === DndItemType.Reward,
                    })}
                >
                    <Icon icon="ellipse" size={ellipseIconSize} />

                    <Typography
                        data-testid="item-number"
                        variant="h4"
                        className="ml-[1.5rem] tablet:ml-[3.4rem] tablet:mr-[2rem] w-[3rem]"
                    >
                        {index + 1}
                    </Typography>
                </div>

                <div className="flex w-full">
                    {/*
                     * Dot symbol needs to be removed when using it as a name for <form.TextField />
                     * to avoid formik detecting it as a sub-element of a json.
                     *
                     * For example: if we name a text field 'music.textbox', during formik error validation,
                     * it will see this as:
                     *
                     * {
                     *  music: { textbox: "" }
                     * }
                     *
                     * We're using filename as a name for <form.TextField /> in TrackItem and RewardItem so dot needs to be removed.
                     */}
                    {dndItemType === DndItemType.Track && <TrackItem item={item} />}
                    {dndItemType === DndItemType.Reward && <RewardItem item={item} />}
                </div>
                <div>
                    <button
                        className="ml-[3rem] mt-[1.2rem] laptop:mt-0 flex items-start"
                        onClick={() => onDeleteInput(item)}
                    >
                        <Icon icon="close" size={closeIconSize} />
                    </button>
                </div>
            </div>

            {progressValue < 100 && (
                <div className="flex flex-row">
                    <ProgressBar value={progressValue} />
                    <Typography variant="h4" className="pl-4 w-[5.5rem] laptop:w-[5.2rem]">
                        {progressValue}&#37;
                    </Typography>
                </div>
            )}
        </div>
    );
}
