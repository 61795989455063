import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';

import { Typography } from '@srnade/component-ui';

export function RemainingTime({
    remainingTime,
    preOrder,
}: {
    remainingTime: string;
    preOrder: boolean;
}): React.ReactNode {
    const { t } = useTranslation('components', { keyPrefix: 'productTile' });

    return (
        <>
            <Typography className="mb-[0.5rem] text-right" variant="body">
                {preOrder ? t('releasingIn') : remainingTime === '0s' ? t('ended') : t('ending')}
            </Typography>
            <Typography
                className={clsx('mb-[0.5rem] text-right', {
                    'text-error': remainingTime?.endsWith('s'),
                })}
                variant="subheadingBold"
            >
                {remainingTime}
            </Typography>
        </>
    );
}
