import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@srnade/component-ui';

import styles from './PanelContent.module.scss';

interface PanelContentProps {
    totalItems: number;
    onFirstScroll?: () => void;
}

export function PanelContent({
    className,
    children,
    totalItems,
    onFirstScroll: onScrolled,
}: PanelContentProps & BaseComponentProps) {
    const listRef = useRef<HTMLDivElement | null>(null);
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        if (!scrolled && !!onScrolled) {
            setScrolled(true);
            onScrolled();
        }
    };

    return (
        <div
            className={clsx(className, 'overflow-y-scroll', styles.panel, 'mt-[2rem]', {
                'pr-[1rem]': totalItems > 5,
            })}
            onScroll={handleScroll}
            ref={listRef}
            data-testid="panel-content"
        >
            {children}
        </div>
    );
}
