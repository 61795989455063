import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { addComma } from '@srnade/component-ui';
import { Credits as CreditsType } from '@srnade/web/__generated__/graphql';
import { Typography, BaseComponentProps } from '@srnade/component-ui';

interface CreditsProps {
    credits: CreditsType;
}

export const Credits = ({ className, credits }: CreditsProps & BaseComponentProps) => {
    const { t } = useTranslation('components', { keyPrefix: 'credits' });

    const performedBy = credits.performedBy;
    const producedBy = credits.producedBy;
    const writtenBy = credits.writtenBy;
    const artworkBy = credits.artworkBy;
    const artDirectionBy = credits.artDirectionBy;
    const creativeDirectionBy = credits.creativeDirectionBy;
    const photographyBy = credits.photographyBy;

    return (
        <div className={className}>
            <Typography className="mb-[1rem]" variant="bodyBold">
                {t('header')}
            </Typography>
            <div>
                {performedBy?.length! > 0 && (
                    <>
                        <Typography className="text-darkGray" variant="body">
                            {t('performedBy')}
                        </Typography>
                        <Typography data-testid="performed-by" variant="body">
                            {addComma(performedBy!)}
                        </Typography>
                    </>
                )}
                {writtenBy?.length! > 0 && (
                    <>
                        <Typography className="text-darkGray" variant="body">
                            {t('writtenBy')}
                        </Typography>
                        <Typography data-testid="written-by" variant="body">
                            {addComma(writtenBy!)}
                        </Typography>
                    </>
                )}
                {producedBy?.length! > 0 && (
                    <>
                        <Typography className="text-darkGray" variant="body">
                            {t('producedBy')}
                        </Typography>
                        <Typography data-testid="produced-by" variant="body">
                            {addComma(producedBy!)}
                        </Typography>
                    </>
                )}
                {artworkBy?.length! > 0 && (
                    <>
                        <Typography className="text-darkGray" variant="body">
                            {t('artworkBy')}
                        </Typography>
                        <Typography data-testid="artwork-by" variant="body">
                            {addComma(artworkBy!)}
                        </Typography>
                    </>
                )}
                {artDirectionBy?.length! > 0 && (
                    <>
                        <Typography className="text-darkGray" variant="body">
                            {t('artDirectionBy')}
                        </Typography>
                        <Typography data-testid="art-direction-by" variant="body">
                            {addComma(artDirectionBy!)}
                        </Typography>
                    </>
                )}
                {creativeDirectionBy?.length! > 0 && (
                    <>
                        <Typography className="text-darkGray" variant="body">
                            {t('creativeDirectionBy')}
                        </Typography>
                        <Typography data-testid="creative-direction-by" variant="body">
                            {addComma(creativeDirectionBy!)}
                        </Typography>
                    </>
                )}
                {photographyBy?.length! > 0 && (
                    <>
                        <Typography className="text-darkGray" variant="body">
                            {t('photographyBy')}
                        </Typography>
                        <Typography data-testid="photography-by" variant="body">
                            {addComma(photographyBy!)}
                        </Typography>
                    </>
                )}
            </div>
        </div>
    );
};
