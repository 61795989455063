// https://usehooks.com/useLocalStorage/
// @note the code has been modified from the above URL to ensure Next.js compatibility and also
// strip quotes from strings.
//
// Usage:
// function App() {
//     // Similar to useState but first arg is key to the value in local storage.
//     const [name, setName] = useLocalStorage('name', 'Bob');

//     return (
//         <div>
//             <input type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
//         </div>
//     );
// }
import { useCallback, useState } from 'react';

/**
 * Get local storage item
 * @param {string} key The key to the local storage item
 * @param {T} initialValue The initial value to be returned if the key doesn't exist
 * @returns {T}
 */
export const getItem = <T>(key: string, initialValue: T): T => {
    if (typeof window === 'undefined') {
        return initialValue;
    }

    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
        // If error also return initialValue
        console.error(error);
        return initialValue;
    }
};

/**
 * Set local storage item
 * @param {string} key The key to the local storage item
 * @param {T} value The value to be stored
 */
export const setItem = <T>(key: string, value: T) => {
    try {
        // Save to local storage
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error);
    }
};

/**
 * Remove local storage item
 * @param {string} key The key to the local storage item
 */
export const removeItem = (key: string) => {
    try {
        // Save to local storage
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem(key);
        }
    } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error);
    }
};

/**
 * useLocalStorage hook
 * @param {string} key The key to the local storage item
 * @param {T} initialValue The initial value to be returned if the key doesn't exist
 * @returns {[T, (value: T) => void]} [storedValue, setValue]
 */
export function useLocalStorage<T>(key: string, initialValue: T) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once

    const readValue = useCallback(() => getItem<T>(key, initialValue), [initialValue, key]);

    const [storedValue, setStoredValue] = useState<T>(readValue);
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: T) => {
            // Save state
            setStoredValue(value);
            setItem(key, value);
        },
        [key],
    );

    return [storedValue, setValue] as const;
}
