import { LoadingIcon, clsx, cssClass, ELEMENT_ICON } from 'yet-another-react-lightbox';

import { Typography } from '@srnade/component-ui';

interface LoadingSlideProps {
    showLoadingText?: boolean;
}
export function LoadingSlide({ showLoadingText }: LoadingSlideProps) {
    return (
        <div className="flex justify-center flex-col items-center color-white w-full h-full">
            <LoadingIcon className={clsx(cssClass(ELEMENT_ICON), 'yarl__slide_loading', 'text-white')} />
            {showLoadingText && (
                <Typography className="text-white mt-4" variant={'h3'}>
                    Loading
                </Typography>
            )}
        </div>
    );
}
