import React, { useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import { Typography } from '@srnade/component-ui';
import { FirstOwner } from '../../../../types';
import Skeleton from 'react-loading-skeleton';

import { Avatar, PanelHeader, PanelContent } from '@srnade/web/components';
import { useAuth } from '@srnade/web/auth';

import sofaIcon from 'assets/icons/sofa.svg';

import styles from '../FirstOwners.module.scss';
import { FirstOwnersDetailsCard } from './FirstOwnerDetailsCard';
import { Account, Product } from '@srnade/web/__generated__/graphql';
import { useAnalytics } from '@srnade/web/contexts';
import { AnalyticsEventType } from '@srnade/web/types';

interface FirstOwnersPanelProps {
    product: Pick<Product, 'limit' | 'slug' | 'id' | 'title'> & {
        account: Pick<Account, 'username'>;
    };
    isListingClosed: boolean;
    isEdition: boolean;
    firstOwners: FirstOwner[];
    loadNext: () => void;
    hasNext: boolean;
}

interface EditionSequence {
    sequence: number;
    empty?: true;
    skeleton?: true;
    firstOwner?: FirstOwner;
}

export const FirstOwnersPanel = ({
    product,
    isListingClosed,
    isEdition,
    firstOwners,
    hasNext,
    loadNext,
}: FirstOwnersPanelProps) => {
    const { activeAccount, user } = useAuth();
    const { pushAnalytics } = useAnalytics();

    const { t } = useTranslation('components', { keyPrefix: 'community' });

    const editionSequences = useMemo((): EditionSequence[] => {
        if (!firstOwners) return [];

        if (firstOwners.length === 0) {
            return [
                {
                    sequence: 1,
                    empty: true,
                },
            ];
        }

        const sortedFirstOwners = [...firstOwners].sort((a, b) => a.edition.sequence - b.edition.sequence);
        const maxEditionSequence = sortedFirstOwners[sortedFirstOwners.length - 1].edition.sequence;
        const result: EditionSequence[] = [];

        let lastSeenSequence: number = 0;
        for (let firstOwner of sortedFirstOwners) {
            // Fill in the gap between the last seen sequence and the current firstOwner
            for (let sequence = lastSeenSequence + 1; sequence < firstOwner.edition.sequence; sequence++) {
                result.push({
                    sequence,
                    empty: true,
                });
            }

            result.push({
                sequence: firstOwner.edition.sequence,
                firstOwner,
            });

            lastSeenSequence = firstOwner.edition.sequence;
        }

        if (!product.limit || maxEditionSequence < product.limit) {
            if (hasNext) {
                result.push({
                    skeleton: true,
                    sequence: maxEditionSequence + 1,
                });
            } else {
                result.push({
                    empty: true,
                    sequence: maxEditionSequence + 1,
                });
            }
        }

        return result;
    }, [firstOwners, hasNext, product.limit]);

    const [hasScrolled, setHasScrolled] = useState(false);

    const lastEdition: EditionSequence = editionSequences[editionSequences.length - 1];

    const handleHasScrolled = () => {
        if (!hasScrolled) {
            // InView for Empty element skips unless the list has first scolled.
            setHasScrolled(true);
        }
    };

    const handleSkeletonInView = (inView: boolean) => {
        if (inView) {
            loadNext();
        }
    };

    const handleEmptyInView = (inView: boolean) => {
        if (!inView) {
            return;
        }
        // End of owners list reached.
        pushAnalytics(AnalyticsEventType.ViewEndOfOwnersList, {
            page_name: isEdition ? 'edition_page' : 'product_page',
            product_id: product.id,
            product_title: product.title,
        });
    };

    return (
        <div className="mt-[4rem]">
            <PanelHeader title={t('title')} introduction={t('firstOwners.introduction')} toolTip={t('toolTip')} />
            <PanelContent
                className="max-h-[36.5rem]"
                totalItems={editionSequences?.length || 0}
                onFirstScroll={handleHasScrolled}
            >
                {editionSequences.map((editionSequence) => {
                    if (!editionSequence.firstOwner) return null;
                    return (
                        <FirstOwnersDetailsCard
                            key={editionSequence.sequence}
                            firstOwner={editionSequence.firstOwner}
                            product={product}
                            className={
                                editionSequence.firstOwner.account &&
                                editionSequence.firstOwner.account?.id === activeAccount?.id
                                    ? styles.firstOwnerEntryGradient
                                    : styles.firstOwnerEntry
                            }
                        />
                    );
                })}
                {lastEdition.skeleton && (
                    <InView className={styles.firstOwnerEntry} onChange={handleSkeletonInView}>
                        <div className="p-[0.8rem] w-full flex justify-between items-center">
                            <div className="flex w-full items-center">
                                <Avatar size="small" />
                                <Typography variant="h6" className={clsx(styles.name, 'opacity-50 px-[0.8rem]')}>
                                    <Skeleton width="120px" height="18px" />
                                </Typography>
                            </div>
                            <div className={styles.editionSequence}>
                                <Typography variant="h5">#{lastEdition.sequence}</Typography>
                            </div>
                        </div>
                    </InView>
                )}
                {lastEdition.empty && !isListingClosed && (
                    <div className={styles.firstOwnerEntry}>
                        <InView
                            className="p-[0.8rem] w-full flex justify-between items-center"
                            onChange={handleEmptyInView}
                            threshold={1}
                            triggerOnce={true}
                            skip={!hasScrolled}
                        >
                            <div className="flex w-full items-center">
                                <Avatar
                                    size="small"
                                    src={sofaIcon}
                                    name={t('firstOwners.empty')}
                                    alt={t('firstOwners.empty')}
                                    className={styles.emptyAvatar}
                                    unoptimized
                                />
                                <Typography variant="h6" className={clsx(styles.name, 'opacity-50 px-[0.8rem]')}>
                                    {t('firstOwners.empty')}
                                </Typography>
                            </div>
                            <div className={styles.editionSequence}>
                                <Typography variant="h5">#{lastEdition.sequence}</Typography>
                            </div>
                        </InView>
                    </div>
                )}
            </PanelContent>
        </div>
    );
};
