import { gql } from '@apollo/client';

/**
 * Queries
 */
export const GET_VOUCHER = gql`
    query GetVoucher($voucher: ClaimVoucherInput!) {
        voucher(voucher: $voucher) {
            id
            status
            product {
                id
                title
                artworkUrl
                artistName
            }
        }
    }
`;

/**
 * Mutations
 */

export const CLAIM_VOUCHER = gql`
    mutation ClaimVoucher($voucher: ClaimVoucherInput!) {
        claimVoucher(voucher: $voucher)
    }
`;
