import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type TouchedStateHook<T> = [T, Dispatch<SetStateAction<T>>, boolean];

export const useTouchedState = <T>(initialState: T | (() => T)): TouchedStateHook<T> => {
    const [value, setValue] = useState<T>(initialState);
    const [touched, setTouched] = useState(false);

    const updateValue = useCallback(
        (value: SetStateAction<T>) => {
            setValue(value);
            setTouched(true);
        },
        [setValue, setTouched],
    );

    return [value, updateValue, touched];
};
