import { ApolloError } from '@apollo/client';
import { Button, formikFields as form, ParentRecordLabel } from '@srnade/component-ui';
import { TreatmentType } from '@srnade/web/constants';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import { Form, Formik, useFormikContext } from 'formik';
import { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as yup from 'yup';

import { ProductStatusNew } from '@srnade/web/__generated__/graphql';
import { useAuth } from '@srnade/web/auth';
import { PayWithStripe, useFeatureFlags } from '@srnade/web/hooks';
import { GtmPaymentMethod } from '@srnade/web/types';

type ParentRecordLabelProp = {
    parentRecordLabel: ParentRecordLabel | null | undefined;
};

type AcknowledgementProps = ParentRecordLabelProp & {
    isSubmitting: boolean;
    isValid: boolean;
};

const Acknowledgement = ({ parentRecordLabel, isSubmitting, isValid }: AcknowledgementProps) => {
    const { t } = useTranslation('product-page');

    const privacyLink = useMemo(() => {
        if (parentRecordLabel === null || parentRecordLabel === undefined) {
            return '';
        }
        return t(`acknowledgement.${parentRecordLabel}.privacyLink`);
    }, [parentRecordLabel, t]);

    const disclaimer = useMemo(() => {
        if (parentRecordLabel === null || parentRecordLabel === undefined) {
            return '';
        }
        return t(`acknowledgement.${parentRecordLabel}.disclaimer`, {
            privacyLink,
            interpolation: { escapeValue: false },
        });
    }, [parentRecordLabel, privacyLink, t]);

    if (parentRecordLabel === ParentRecordLabel.UniversalMusicGroup) {
        return (
            <form.Checkbox name="acceptsUMGAgreement" required disabled={isSubmitting}>
                {ReactHtmlParser(disclaimer)}
            </form.Checkbox>
        );
    }

    return null;
};

type BuyButtonProps = {
    paymentMethod: PayWithStripe;
    price: string;
    loading: boolean;
    buyClicked: () => void;
    productStatus: ProductStatusNew;
};

const BuyButton = ({ paymentMethod, price, loading, buyClicked, productStatus }: BuyButtonProps) => {
    const { submitForm, isValid } = useFormikContext();
    const { t } = useTranslation('product-page');

    const handleSubmitForm = () => {
        if (isValid) {
            buyClicked(); // only capture GA event when Buy form is valid
            paymentMethod.pay();
        } else {
            submitForm();
        }
    };

    const isPreOrder = productStatus === ProductStatusNew.PRE_ORDER;
    const isReleased = productStatus === ProductStatusNew.RELEASED;

    if (!isPreOrder && !isReleased) {
        return null;
    }

    return (
        <Button
            type="submit"
            className="w-full"
            loading={loading}
            onClick={handleSubmitForm}
            disabled={loading}
            data-testid={`buy-now-button-${paymentMethod.name}`}
        >
            {!loading && (
                <>
                    <span className="pr-6">{isPreOrder ? t('preOrderNowButton') : t('buyNowButton')}</span>
                    <span>{price}</span>
                </>
            )}
        </Button>
    );
};

type VerifyEmailButtonProps = {
    emailSent: boolean;
    resendEmailVerificationEmail: () => void;
    resendEmailVerificationLoading: boolean;
    resendEmailVerificationError?: ApolloError;
};

const VerifyEmailButton = ({
    emailSent,
    resendEmailVerificationEmail,
    resendEmailVerificationLoading,
    resendEmailVerificationError,
}: VerifyEmailButtonProps) => {
    const { t } = useTranslation('product-page');

    return (
        <Button
            className="w-full mt-[4rem]"
            text={emailSent ? t('verifyEmailSent') : t('verifyEmail')}
            loading={resendEmailVerificationLoading}
            onClick={() => resendEmailVerificationEmail()}
            disabled={emailSent && !resendEmailVerificationError}
            setIcon={!emailSent}
        />
    );
};

const onSubmit = () => {};

type ProductBuyTabsProps = {
    verifyEmail: boolean;
    emailSent: boolean;
    stripe: PayWithStripe;
    resendEmailVerificationEmail: () => void;
    price: string;
    resendEmailVerificationLoading: boolean;
    resendEmailVerificationError?: ApolloError;
    buyClicked: (paymentMethod: string) => void;
    productStatus: ProductStatusNew;
} & ParentRecordLabelProp;

export const ProductBuyTabs = ({
    verifyEmail,
    emailSent,
    stripe,
    resendEmailVerificationEmail,
    price,
    resendEmailVerificationLoading,
    resendEmailVerificationError,
    parentRecordLabel,
    buyClicked,
    productStatus,
}: ProductBuyTabsProps) => {
    const { activeAccount, authenticating } = useAuth();
    const { t } = useTranslation('product-page');
    const { useFeatureOn } = useFeatureFlags();
    const FF_UMG_TCS = useFeatureOn(TreatmentType.UMGPurchaseTermsAndConditions);
    const isAccountActivated = activeAccount !== null && verifyEmail === true;

    const isUMG = parentRecordLabel === ParentRecordLabel.UniversalMusicGroup;
    const shouldShowUMGTCs = isUMG && FF_UMG_TCS;

    const validationSchema = useMemo(
        () =>
            yup.object({
                acceptsUMGAgreement: shouldShowUMGTCs
                    ? yup.boolean().oneOf([true], t('acknowledgement.acceptsTermsError'))
                    : yup.boolean(),
            }),
        [t, shouldShowUMGTCs],
    );

    return (
        <>
            {isAccountActivated && authenticating === false ? (
                <VerifyEmailButton
                    emailSent={emailSent}
                    resendEmailVerificationEmail={resendEmailVerificationEmail}
                    resendEmailVerificationLoading={resendEmailVerificationLoading}
                    resendEmailVerificationError={resendEmailVerificationError}
                />
            ) : (
                <Formik
                    initialValues={{
                        acceptsUMGAgreement: !shouldShowUMGTCs,
                    }}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                    isInitialValid={!shouldShowUMGTCs}
                >
                    {({ isSubmitting, isValid }) => (
                        <Form className="w-full mt-[4rem]">
                            {shouldShowUMGTCs && (
                                <Acknowledgement
                                    parentRecordLabel={parentRecordLabel}
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                />
                            )}
                            <div
                                className={clsx('flex flex-col', {
                                    'mt-[2rem]': shouldShowUMGTCs,
                                })}
                                data-testid="buy-buttons-container"
                            >
                                <BuyButton
                                    paymentMethod={stripe}
                                    price={price}
                                    loading={stripe.loading || isAccountActivated}
                                    buyClicked={() => buyClicked(GtmPaymentMethod.CreditCard)}
                                    productStatus={productStatus}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
