import { gql } from '@apollo/client';

export const CREATE_IMAGE = gql`
    mutation CreateImage($input: CreateImageInput!) {
        image: createImage(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
            }
        }
    }
`;

export const GET_MANY_IMAGES = gql`
    query GetManyImages($input: GetManyImagesInput!) {
        getManyImages(input: $input) {
            id
            url
            width
            height
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
