import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { Tabs, TabPanel } from '@srnade/component-ui';
import { QueryResult, useQuery } from '@apollo/client';
import { AccountType, AccountTag, addUsernamePrefix, AccountStatus } from '@srnade/component-ui';

import { Carousel, AccountTile, SkeletonAccountTile } from '@srnade/web/components';
import { type FindAccountsData, FIND_TOP_ACCOUNTS } from '@srnade/web/services';

function RenderAccounts({ loading, data, error }: QueryResult<FindAccountsData>): JSX.Element | null {
    const accounts = data?.findAccounts.edges;

    return loading || !accounts || accounts.length === 0 ? (
        <Carousel background>
            {[1, 2, 3, 4].map((id: number) => (
                <SkeletonAccountTile key={id} type="carousel" />
            ))}
        </Carousel>
    ) : (
        <Carousel background>
            {accounts.map((edges) => (
                <AccountTile
                    key={edges.node.id}
                    account={edges.node}
                    href={`/${addUsernamePrefix(edges.node.username)}`}
                    type="carousel"
                    hoverable
                    clickable
                />
            ))}
        </Carousel>
    );
}

interface TopAccountsProps {}

// Displays border, Switch toggler and carousel with slightly transparent gradient background
export function TopAccounts({}: TopAccountsProps): JSX.Element {
    const { t } = useTranslation('homepage', { keyPrefix: 'topAccounts' });

    const artists = useQuery<FindAccountsData>(FIND_TOP_ACCOUNTS, {
        variables: { first: 10, type: AccountType.Artist, tags: [AccountTag.Top], status: AccountStatus.Approved },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const fans = useQuery<FindAccountsData>(FIND_TOP_ACCOUNTS, {
        variables: { first: 10, type: AccountType.Collector, tags: [AccountTag.Top], status: AccountStatus.Approved },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    return (
        <div
            className="border h-[68rem] mx-[0.1rem] tablet:mx-[5rem] laptop:mx-[10rem] rounded-2xl relative"
            data-gtm-id="top_artists_fans"
        >
            <div className="mt-[5rem] -left-[0.2rem] -right-[0.2rem] tablet:-left-[5.1rem] tablet:-right-[5.1rem] laptop:-left-[10.1rem] laptop:-right-[10.1rem] absolute">
                <div className="flex justify-center overflow-hidden">
                    <Tabs className="ml-auto mr-auto" tabList={[{ name: t('artists') }, { name: t('fans') }]}>
                        <TabPanel key={1}>
                            <RenderAccounts {...artists} />
                        </TabPanel>
                        <TabPanel key={2}>
                            <RenderAccounts {...fans} />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
