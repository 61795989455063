import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from '@react-md/utils';

import { TabPanel, Tabs } from '@srnade/component-ui';

import {
    GetAssetDownloadUrlQuery,
    GetAssetDownloadUrlQueryVariables,
    GetAssetStreamingUrlQuery,
    GetAssetStreamingUrlQueryVariables,
    GetEditionFormatQuery,
    GetVideoAssetThumbnailUrlQuery,
    GetVideoAssetThumbnailUrlQueryVariables,
} from '@srnade/web/__generated__/graphql';
import { useAuth } from '@srnade/web/auth';
import { AudioDownloadsPopUp, PlayableTracks, TrackList } from '@srnade/web/components';
import { useTranslation } from '@srnade/web/i18n/client';
import { GET_ASSET_DOWNLOAD_URL, GET_ASSET_STREAMING_URL, GET_VIDEO_ASSET_THUMBNAIL_URL } from '@srnade/web/services';
import { useAppDispatch, useAppSelector } from '@srnade/web/store/global';
import {
    changeCurrentTrack,
    changeIsPlaying,
    changeIsRestart,
    selectPlayerArtist,
    selectPlayerArtworkUrl,
    selectPlayerCurrentTrack,
    selectPlayerIsPlaying,
    selectPlayerIsRestart,
    selectPlayerIsVisible,
    selectPlayerTracks,
    showPlayer,
} from '@srnade/web/store/reducers/player';

import {
    CompressedTracksDownloadLoadStatus,
    fetchCompressedTracksInfo,
    resetCompressedTracksDownloadInfo,
    selectCompressedDownloadInfo,
    selectCompressedStatus,
} from '@srnade/web/store/reducers/compressed-tracks';
import { DownloadAudioAvailable } from '../DownloadAudio';
import { DownloadAudioUnavailable } from '../DownloadAudio/DownloadAudioUnavailable.component';
import { StreamableBonusContent } from '../StreamableBonusContent';

interface PressingDetailsProps {
    editionFormat: GetEditionFormatQuery['editionFormat'];
    selectedTabIndex: number;
    onSelectedTabChange: (index: number) => void;
}

export const TRACKS_INDEX = 0;
export const BONUS_CONTENT_INDEX = 1;

export const EditionPressingDetails = ({
    editionFormat,
    selectedTabIndex,
    onSelectedTabChange,
}: PressingDetailsProps) => {
    const { t: tTracks } = useTranslation('components', { keyPrefix: 'tracks' });
    const { t: tBonus } = useTranslation('components', { keyPrefix: 'bonusContent' });
    const { activeAccount } = useAuth();
    const isRestart = useAppSelector(selectPlayerIsRestart);
    const isVisible = useAppSelector(selectPlayerIsVisible);
    const playerTracks = useAppSelector(selectPlayerTracks);
    const currentTrack = useAppSelector(selectPlayerCurrentTrack);
    const isPlaying = useAppSelector(selectPlayerIsPlaying);
    const artist = useAppSelector(selectPlayerArtist);
    const artworkUrl = useAppSelector(selectPlayerArtworkUrl);

    const compressedTracksDownloadStatus = useAppSelector(selectCompressedStatus);
    const compressedTrackDownloadInfo = useAppSelector(selectCompressedDownloadInfo);
    const dispatch = useAppDispatch();

    const [audioDownloadAvailable, setAudioDownloadAvailable] = useState(false);
    const [downloadLimit, setDownloadLimit] = useState<number>();
    const [downloadCount, setDownloadCount] = useState<number>();
    const audioDownloadPopupToggle = useToggle(false);
    const [isOpenAudioDownloadPopUp, showAudioDownloadPopUp, hideAudioDownloadPopUp] = audioDownloadPopupToggle;
    const tracks = editionFormat?.pressing?.tracks || [];

    const editionOwnerIsMe = editionFormat?.editionOwner?.username == activeAccount?.username;

    const triggerCompressedTracksDownload = useCallback(
        (downloadUrl: string) => {
            const anchorTag = document.getElementById('downloadCompressedTracks') as HTMLAnchorElement;
            if (anchorTag) {
                anchorTag.href = downloadUrl;
                anchorTag.click();
                dispatch(resetCompressedTracksDownloadInfo());
            }
        },
        [dispatch],
    );

    useEffect(() => {
        if (
            isOpenAudioDownloadPopUp &&
            compressedTrackDownloadInfo?.url &&
            compressedTracksDownloadStatus === CompressedTracksDownloadLoadStatus.Idle
        ) {
            setDownloadCount(compressedTrackDownloadInfo.userDownloadCount);
            triggerCompressedTracksDownload(compressedTrackDownloadInfo.url);
            hideAudioDownloadPopUp();
        }
    }, [
        compressedTrackDownloadInfo,
        compressedTracksDownloadStatus,
        hideAudioDownloadPopUp,
        isOpenAudioDownloadPopUp,
        triggerCompressedTracksDownload,
    ]);

    useEffect(() => {
        if (!editionFormat?.pressing?.compressedTracks) return;

        const { userDownloadCount, downloadLimit } = editionFormat?.pressing?.compressedTracks;

        if (userDownloadCount != null && userDownloadCount >= 0) {
            setDownloadCount(userDownloadCount);
        }

        if (userDownloadCount != null) {
            setDownloadLimit(downloadLimit);
        }
    }, [editionFormat?.pressing?.compressedTracks, editionOwnerIsMe]);

    useEffect(() => {
        if (downloadCount === undefined || !downloadLimit) return;
        setAudioDownloadAvailable(downloadCount < downloadLimit);
    }, [downloadCount, downloadLimit]);

    const handleCompressedTracksDownload = useCallback(async () => {
        if (!editionFormat?.editionId) return;

        await dispatch(
            fetchCompressedTracksInfo({
                editionId: editionFormat?.editionId,
            }),
        );
    }, [editionFormat?.editionId, dispatch]);

    const [getAssetDownloadUrl] = useLazyQuery<GetAssetDownloadUrlQuery, GetAssetDownloadUrlQueryVariables>(
        GET_ASSET_DOWNLOAD_URL,
        {
            fetchPolicy: 'no-cache',
        },
    );

    const [getAssetStreamingUrl] = useLazyQuery<GetAssetStreamingUrlQuery, GetAssetStreamingUrlQueryVariables>(
        GET_ASSET_STREAMING_URL,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
    );

    const [getVideoAssetThumbnailUrl] = useLazyQuery<
        GetVideoAssetThumbnailUrlQuery,
        GetVideoAssetThumbnailUrlQueryVariables
    >(GET_VIDEO_ASSET_THUMBNAIL_URL, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    });

    const handleGetDownloadUrl = useCallback(
        async (assetId: string): Promise<string | undefined> => {
            if (!editionFormat?.formatInstanceId) return;

            const response = await getAssetDownloadUrl({
                fetchPolicy: 'no-cache',
                variables: {
                    input: {
                        formatInstanceId: editionFormat.formatInstanceId,
                        assetId,
                    },
                },
            });

            return response.data?.formatInstanceAssetDownloadUrl;
        },
        [editionFormat?.formatInstanceId, getAssetDownloadUrl],
    );

    const handleGetStreamingUrl = useCallback(
        async (assetId: string): Promise<string | undefined> => {
            if (!editionFormat?.formatInstanceId) return;

            const response = await getAssetStreamingUrl({
                fetchPolicy: 'no-cache',
                variables: {
                    input: {
                        formatInstanceId: editionFormat.formatInstanceId,
                        assetId,
                    },
                },
            });

            return response.data?.formatInstanceAssetStreamingUrl;
        },
        [editionFormat?.formatInstanceId, getAssetStreamingUrl],
    );

    const handleGetVideoAssetThumbnailUrl = useCallback(
        async (assetId: string): Promise<string | undefined> => {
            if (!editionFormat?.formatInstanceId) return;

            const response = await getVideoAssetThumbnailUrl({
                fetchPolicy: 'no-cache',
                variables: {
                    input: {
                        formatInstanceId: editionFormat.formatInstanceId,
                        assetId,
                    },
                },
            });

            return response.data?.formatInstanceVideoAssetThumbnailUrl;
        },
        [editionFormat?.formatInstanceId, getVideoAssetThumbnailUrl],
    );

    const handleTrackSelect = useCallback(
        async (assetId: string) => {
            const sameTrack = currentTrack?.assetId === assetId;
            if (!isVisible) {
                dispatch(showPlayer(true));
            }
            dispatch(changeIsPlaying(true));
            dispatch(changeIsRestart(sameTrack));
            if (sameTrack) {
                return; // no change of track
            }
            const newTrack = playerTracks.find((track) => track.assetId === assetId);
            if (newTrack) {
                dispatch(changeCurrentTrack(newTrack));
            }
        },
        [dispatch, isVisible, currentTrack?.assetId, playerTracks],
    );

    const handleForcePauseTrack = useCallback(() => {
        dispatch(changeIsPlaying(false));
    }, [dispatch]);

    const bonusContents = useMemo(() => editionFormat?.pressing?.bonusContent || [], [editionFormat]);
    // TODO handle status Failed, redirect to the 404 page

    if (!editionFormat) {
        return null;
    }
    const compressedTracksFileSize = editionFormat.pressing?.compressedTracks?.fileSize;
    const showAudioDownload =
        editionOwnerIsMe &&
        downloadCount !== undefined &&
        !!downloadLimit &&
        playerTracks.length > 0 &&
        (compressedTracksFileSize || 0) > 0;
    const trackTitle = `${tTracks('title')} (${tracks.length})`;
    const bonusTitle = `${tBonus('title')} (${bonusContents.length})`;

    return (
        <>
            <div className="flex justify-center overflow-hidden">
                <Tabs
                    className=" w-full max-w-[80rem]"
                    tabList={[{ name: trackTitle }, { name: bonusTitle, disabled: bonusContents.length === 0 }]}
                    variant="tabs"
                    selectedIndex={selectedTabIndex}
                    onSelect={(index, lastIndex) => {
                        if (index !== lastIndex) {
                            onSelectedTabChange(index);
                        }
                    }}
                >
                    <TabPanel key={1}>
                        <div className="flex flex-col mt-[2.5rem] mb-0">
                            {editionOwnerIsMe ? (
                                <>
                                    <PlayableTracks
                                        playerTracks={playerTracks}
                                        allTracks={tracks}
                                        selectedTrack={{
                                            assetId: currentTrack?.assetId || '',
                                            isPlaying,
                                            isRestart,
                                        }}
                                        handlePlayPause={() => {
                                            if (!isVisible) {
                                                dispatch(showPlayer(true));
                                            }
                                            dispatch(changeIsPlaying(!isPlaying));
                                        }}
                                        onSelectTrack={(assetId) => {
                                            handleTrackSelect(assetId);
                                        }}
                                    />
                                    {showAudioDownload && (
                                        <div className="mt-[3rem]">
                                            {audioDownloadAvailable ? (
                                                <DownloadAudioAvailable
                                                    limit={downloadLimit}
                                                    count={downloadCount}
                                                    onClick={showAudioDownloadPopUp}
                                                />
                                            ) : (
                                                <DownloadAudioUnavailable limit={downloadCount} />
                                            )}

                                            <AudioDownloadsPopUp
                                                tracks={playerTracks}
                                                productName={editionFormat.title}
                                                artistName={artist.name}
                                                downloadAvailable={audioDownloadAvailable}
                                                dialogToggle={audioDownloadPopupToggle}
                                                zipSize={
                                                    editionFormat?.pressing?.compressedTracks?.fileSize ?? undefined
                                                }
                                                onDownload={handleCompressedTracksDownload}
                                                isDownloading={
                                                    compressedTracksDownloadStatus ===
                                                    CompressedTracksDownloadLoadStatus.Pending
                                                }
                                            />
                                            <a id="downloadCompressedTracks" className="invisible" download />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <TrackList tracks={tracks} locked={true} showEmpty={true} />
                            )}
                        </div>
                    </TabPanel>
                    {bonusContents.length > 0 && (
                        <TabPanel key={2}>
                            <StreamableBonusContent
                                artistName={artist.name}
                                locked={!editionOwnerIsMe}
                                data-testid="bonus-content"
                                bonusContents={bonusContents}
                                onGetDownloadUrl={handleGetDownloadUrl}
                                onGetStreamableUrl={handleGetStreamingUrl}
                                onGetVideoAssetThumbnailUrl={handleGetVideoAssetThumbnailUrl}
                                pressingTitle={editionFormat.title}
                                editionArtworkUrl={artworkUrl || ''}
                                onOpenLightBox={handleForcePauseTrack}
                                showTitle={false}
                            />
                        </TabPanel>
                    )}
                </Tabs>
            </div>
        </>
    );
};
