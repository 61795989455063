import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { useToggle } from '@react-md/utils';

import { Dialog, DialogContent, Button, Typography } from '@srnade/component-ui';
import { FileProperties } from '@srnade/component-ui';

import { FileUploadErrorCodes } from '@srnade/web/const';
import { getDimensions } from '@srnade/web/utils';
import { useFileUploader } from '@srnade/web/hooks';
import { CropPopUpCloseResult } from '@srnade/web/types';

import { FileUploading } from '../FileUploading';
import { ImageCropper } from '../ImageCropper';

interface CropImagePopUpProps {
    imageFile: File;
    onCropPopUpClose: (data: CropPopUpCloseResult) => void;
    fileStoragePath: string;
    toggle: boolean;
}

export function CropImagePopUp({ imageFile, onCropPopUpClose, fileStoragePath, toggle }: CropImagePopUpProps) {
    const { t } = useTranslation('components', { keyPrefix: 'cropImage' });
    const { t: tCommon } = useTranslation('common', { keyPrefix: 'buttonText' });

    const dialogToggle = useToggle(toggle ?? false);
    const [visible, enable, disable] = dialogToggle;

    // State for the error message
    const [error, setError] = useState<FileUploadErrorCodes | null>();

    // Callback for when file upload fails
    const onUploadFailed = useCallback((errorMessage) => {
        setError(errorMessage);
    }, []);

    // Callback for when file is successfully uploaded
    const onUploadSuccess = useCallback(
        async (file: File, properties: FileProperties) => {
            if (properties && Object.keys(properties).length > 0) {
                const { width, height } = await getDimensions(file);
                disable();
                onCropPopUpClose({ imageInput: { url: properties.url, width, height, file: properties } });
            }
        },
        [disable, onCropPopUpClose],
    );

    // Initialize the useFileUploader hook with the onUploadSuccess and onUploadFailed callbacks
    const { onFileDrop, progress, uploading } = useFileUploader({
        onUploadSuccess,
        onUploadFailed,
    });

    // Callback once image is cropped
    const onCrop = useCallback(
        (file: File) => {
            const fileUploadLocation = fileStoragePath; // This is the location of the file in the S3 bucket
            setError(null); // clear error if any
            onFileDrop(file, fileUploadLocation);
        },
        [fileStoragePath, onFileDrop],
    );

    const forceClose = () => {
        disable();
        onCropPopUpClose({ error: undefined, imageInput: undefined });
    };

    useEffect(() => {
        if (error) {
            disable();
            onCropPopUpClose({ error });
        }
    }, [disable, error, onCropPopUpClose]);

    useEffect(() => {
        if (toggle) {
            enable();
        }
    }, [enable, toggle]);

    return (
        <Dialog
            variant="small"
            type="secondary"
            title={t('title')}
            dialogToggle={dialogToggle}
            onRequestClose={forceClose}
        >
            <DialogContent className="flex flex-col items-center">
                {uploading ? (
                    <>
                        <FileUploading
                            className="max-w-[30rem] min-h-[30rem] tablet:max-w-[33.5rem] tablet:min-h-[33.5rem] w-full h-full my-[6rem]"
                            currentProgress={progress}
                        />

                        <Button
                            text="Cancel"
                            type="secondary"
                            variant="standard"
                            setIcon={false}
                            onClick={disable}
                            className="max-w-[350px] w-full"
                        />
                    </>
                ) : (
                    <>
                        <Typography className="mb-[3rem]" variant="body">
                            {t('instructions')}
                        </Typography>
                        {imageFile && (
                            <ImageCropper image={imageFile} onCrop={onCrop}>
                                <Button
                                    className="w-full"
                                    type="secondary"
                                    text={tCommon('cancel')}
                                    setIcon={false}
                                    onClick={forceClose}
                                />
                            </ImageCropper>
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}
