import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { BaseComponentProps } from '@srnade/component-ui';
import { GenericImageFileConstraints } from '../../config/common.config';

import { CropPopUpCloseResult } from '@srnade/web/types';
import { UPDATE_AVATAR } from '@srnade/web/services';

import { FileUploadZone } from '../FileUploadZone';
import { CropImagePopUp } from '../CropImagePopUp';
import { Avatar } from '../Avatar';
import { UpdateAvatarMutation } from '@srnade/web/__generated__/graphql';
interface ProfilePictureProps {
    name?: string;
    src?: string;
    allowEdit?: boolean;
    onError?: (error: string) => void; // Some components may not have allowEdit set hence onError is optional
}
export function ProfilePicture({ className, name, src, allowEdit, onError }: ProfilePictureProps & BaseComponentProps) {
    const [image, setImage] = useState<File | null>();
    const [imageSrc, setImageSrc] = useState<string | undefined>(src ?? undefined);
    const [dialogToggle, setDialogToggle] = useState(false);
    const [updateAvatar] = useMutation<UpdateAvatarMutation>(UPDATE_AVATAR);

    const onAccepted = (file: File[]) => {
        setImage(file[0]);
        setDialogToggle(true);
        onError?.('');
    };

    const onDropError = useCallback(
        (error: string | string[]) => {
            if (Array.isArray(error) && error.length === 1) {
                onError?.(error[0]);
            } else if (typeof error === 'string') {
                onError?.(error);
            }
        },
        [onError],
    );

    const onCropPopUpClose = useCallback(
        (result: CropPopUpCloseResult) => {
            setImage(null);
            setDialogToggle(false);

            const { imageInput, error } = result;

            if (imageInput?.url) {
                setImageSrc(imageInput?.url);
                updateAvatar({ variables: { input: result.imageInput } });
            }
        },
        [updateAvatar],
    );

    useEffect(() => {
        setImageSrc(src);
    }, [src]);

    return (
        <div className="relative flex self-start h-full" data-testid="profile-picture">
            <Avatar className={clsx(className, 'flex-1 w-full')} size="large" src={imageSrc} name={name} />
            {allowEdit && onError && (
                <div className="absolute bottom-[2rem] right-[2rem] h-[5rem]">
                    <FileUploadZone
                        onError={onDropError}
                        noDrag={true}
                        noTextContent={true}
                        onAccepted={onAccepted}
                        fileConstraints={GenericImageFileConstraints}
                    />
                </div>
            )}
            <CropImagePopUp
                imageFile={image as File}
                fileStoragePath="images"
                onCropPopUpClose={onCropPopUpClose}
                toggle={dialogToggle}
            />
        </div>
    );
}
