import clsx from 'clsx';
import { useState, useRef, useEffect } from 'react';

import styles from './Slider.module.scss';

interface SliderProps {
    percentage: number;
    onSeek: (e: any) => void;
    onSeekMouseDown: (e: any) => void;
    onSeekMouseUp: (e: any) => void;
    onDuration: (e: any) => void;
    disabled?: boolean;
}

export function Slider({
    percentage = 0,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    onDuration,
    disabled = false,
}: SliderProps) {
    const [position, setPosition] = useState(0);
    const [marginLeft, setMarginLeft] = useState(0);
    const [progressBarWidth, setProgressBarWidth] = useState(0);

    const rangeRef = useRef<HTMLInputElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const rangeWidth = rangeRef.current?.getBoundingClientRect().width;
        const thumbWidth = thumbRef.current?.getBoundingClientRect().width;
        if (rangeWidth && thumbWidth) {
            const centerThumb = (thumbWidth / 100) * percentage * -1;
            const centerProgressBar = thumbWidth + (rangeWidth / 100) * percentage - (thumbWidth / 100) * percentage;
            setPosition(percentage);
            setMarginLeft(centerThumb);
            setProgressBarWidth(centerProgressBar);
        }
    }, [percentage]);

    return (
        <div className={styles.sliderContainer}>
            <div
                className={styles.progressBarCover}
                style={{
                    width: `${progressBarWidth}px`,
                }}
            />
            <div
                className={clsx(styles.thumb, {
                    'bg-none bg-cream border-gray border-2': disabled,
                })}
                ref={thumbRef}
                style={{
                    left: `${position}%`,
                    marginLeft: `${marginLeft}px`,
                }}
            />
            <input
                type="range"
                value={!isNaN(position) ? position : 0}
                ref={rangeRef}
                step="0.01"
                className={clsx(styles.range, {
                    'pointer-events-none': disabled,
                })}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCapture={onSeekMouseUp}
                onDurationChange={onDuration}
                disabled={disabled}
            />
        </div>
    );
}
