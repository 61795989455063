import React, { PropsWithChildren } from 'react';
import { Button, Typography } from '@srnade/component-ui';

import { PageTemplate } from '@srnade/web/templates';
import { Container, SubscribeBanner } from '@srnade/web/components';

// https://nextjs.org/docs/advanced-features/error-handling
export class ErrorBoundary extends React.Component<PropsWithChildren> {
    state: { hasError: false };

    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI

        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can use your own error logging service here
        console.log({ error, errorInfo });
    }

    render() {
        // Check if the error is thrown
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <PageTemplate title="Error" showSubscribeBanner>
                    <Container className="mt-[10rem] mb-[21rem]" centeredContent>
                        <Typography variant="h1">Something went wrong!</Typography>
                        <Button
                            className="w-full max-w-[30rem] mt-[6rem]"
                            variant="standard"
                            text="Try again?"
                            onClick={() => this.setState({ hasError: false })}
                        />
                    </Container>
                </PageTemplate>
            );
        }

        // Return children components in case of no error
        return this.props.children;
    }
}
