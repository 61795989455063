import React from 'react';
import Image from 'next/legacy/image';
import { Icon, Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';

import { Container } from '@srnade/web/components';
import digitalPressing from 'assets/images/digital-pressing.png';

import styles from './EducationalModule.module.css';

const Vinyl = () => {
    return (
        <div className="h-full w-full">
            <Image
                src={digitalPressing}
                layout="responsive"
                height={413}
                width={413}
                alt="Digital Pressing"
                className={styles.digitalPressing}
            />
        </div>
    );
};

export function EducationalModule() {
    const { t } = useTranslation('homepage', { keyPrefix: 'educationalModule' });

    /**
     * Renders content for left or right sections
     * @param {boolean} leftSection
     * @returns Section content
     */
    const renderContent = (leftSection?: boolean) => {
        const iconSize = 51;

        return (
            <div
                className={clsx('col-span-1 grid content-around tablet:pr-[5rem] tablet:mt-0', {
                    'laptop:pl-[6rem] laptop:pr-0': !leftSection,
                })}
            >
                <div className="flex mb-[4rem] laptop:mb-0">
                    <div className="mr-[2rem]">
                        <Icon icon={leftSection ? 'collect-circle' : 'audio-circle'} size={iconSize} />
                    </div>
                    <Typography variant="subheading">
                        {leftSection ? ReactHtmlParser(t('collect')) : ReactHtmlParser(t('uncover'))}
                    </Typography>
                </div>
                <div className="flex mb-[4rem] laptop:mb-0">
                    <div className="mr-[2rem]">
                        <Icon icon={leftSection ? 'gallery-circle' : 'collectible-circle'} size={iconSize} />
                    </div>
                    <Typography variant="subheading">
                        {leftSection ? ReactHtmlParser(t('curate')) : ReactHtmlParser(t('support'))}
                    </Typography>
                </div>
                <div className="flex mb-[4rem] laptop:mb-0">
                    <div className="mr-[2rem]">
                        <Icon icon={leftSection ? 'padlock-open-circle' : 'community-circle'} size={iconSize} />
                    </div>
                    <Typography variant="subheading">
                        {leftSection ? ReactHtmlParser(t('unlock')) : ReactHtmlParser(t('join'))}
                    </Typography>
                </div>
            </div>
        );
    };

    return (
        <Container className="grid justify-items-center pt-[1rem] laptop:pb-[3rem]">
            {/* Displays heading in all breakpoints */}
            <Typography className="text-center" variant="h1">
                {t('heading.deepening')} <span className="font-h2 font-normal italic">{t('heading.experience')}</span>{' '}
                {t('heading.music')}
            </Typography>
            {/*/ Mobile / Laptop / Desktop breakpoints */}
            <div className="tablet:hidden laptop:grid laptop:grid-cols-3 laptop:mt-[8rem]">
                <div className="mt-[4rem] mb-[6rem] tablet:hidden">
                    <Vinyl />
                </div>
                {renderContent(true)}
                <div className="hidden laptop:flex col-span-1">
                    <Vinyl />
                </div>
                {renderContent()}
            </div>
            {/*/ Tablet breakpoint */}
            <div className="hidden tablet:grid tablet:grid-cols-2 tablet:mt-[4rem] laptop:hidden">
                <div className="col-span-1">
                    {renderContent(true)}
                    <div className="hidden laptop:flex col-span-1">
                        <Vinyl />
                    </div>
                    {renderContent()}
                </div>
                <div className="col-span-1">
                    <Vinyl />
                </div>
            </div>
        </Container>
    );
}
