import clsx from 'clsx';
import { useTranslation } from '@srnade/web/i18n/client';
import { Button, Typography } from '@srnade/component-ui';

import { Link } from '@srnade/web/components';

interface ProductEmptyInventoryProps {
    openSeaUrl?: string;
    className?: string;
}

export const ProductEmptyInventory = ({ openSeaUrl, className }: ProductEmptyInventoryProps) => {
    const { t } = useTranslation('product-page');

    return (
        <div className={clsx('flex items-center', className)}>
            <Typography variant="subheadingBold" uppercase>
                {t('soldOut')}
            </Typography>
            {openSeaUrl && (
                <Link
                    href={openSeaUrl}
                    passHref
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-[2rem] flex-grow"
                    data-testid="product-open-sea-link"
                    data-gtm-id="product_opensea_link"
                >
                    <Button className="w-full" text={t('availableOnOpenSea')} />
                </Link>
            )}
        </div>
    );
};
