import { useEffect, useRef } from 'react';

import Image from 'next/image';
import { useOrientation } from 'react-use';
import Lightbox, {
    Slide,
    StreamableBonusContentSlide,
    isImageSlide,
    FullscreenRef,
    ThumbnailsRef,
} from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Download from 'yet-another-react-lightbox/plugins/download';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';

import { PdfZipDownloadTile } from '../PdfZipDownloadTile';
import ImageSlide from './ImageSlide.component';
import StreamableSlide from './StreamableSlide.component';

import { useDevice } from '@srnade/web/hooks';

import { Icon } from '@srnade/component-ui';
import pdfZipPlaceholder from 'assets/images/pdf-zip-placeholder.svg';
import { useState } from 'react';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import styles from './BonusContentLightBox.module.scss';

declare module 'yet-another-react-lightbox' {
    export interface GenericBonusContentSlide extends GenericSlide {
        src: string;
        onDownload?: () => Promise<string>;
        title: string;
    }

    export interface StreamableBonusContentSlide extends GenericBonusContentSlide {
        onRefreshStreamableUrl: () => Promise<string>;
        poster: string;
        assetId: string;
        pressingTitle: string;
        artistName: string;
    }

    interface SlideTypes {
        audio: StreamableBonusContentSlide;
        video: StreamableBonusContentSlide;
        other: GenericBonusContentSlide;
    }
}

export interface BonusContentLightBoxProps {
    slides: Slide[];
    open: boolean;
    close: () => void;
    showSlide?: number;
}

function isStreamableSlide(slide: Slide): slide is StreamableBonusContentSlide {
    return slide.type === 'audio' || slide.type === 'video';
}

export const BonusContentLightBox = ({ slides, open, close, showSlide = 0 }: BonusContentLightBoxProps) => {
    const [downloadIconName, setDownloadIconName] = useState('download-circle-white');
    const orientation = useOrientation();
    const { mobile } = useDevice();
    const fullscreenRef = useRef<FullscreenRef>(null);
    const thumbnailsRef = useRef<ThumbnailsRef>(null);

    const onLightBoxOpening = () => {
        if (mobile) {
            if (orientation.type === 'landscape-primary') {
                fullscreenRef.current?.enter();
                thumbnailsRef.current?.hide();
            } else if (!thumbnailsRef?.current?.visible) {
                thumbnailsRef.current?.show();
            }
        }
    };

    useEffect(onLightBoxOpening, [mobile, orientation]);

    return (
        <Lightbox
            plugins={[Captions, Download, Fullscreen, Thumbnails]}
            fullscreen={{ ref: fullscreenRef }}
            open={open}
            close={close}
            slides={slides}
            className={styles.root}
            styles={{
                root: {
                    '--yarl__slide_title_font_size': '18px',
                    '--yarl__slide_title_font_weight': 700,
                },
                slide: {
                    '--yarl__slide_captions_container_background': 'rgb(0, 0, 0)',
                    '--yarl__carousel_padding_px': 45,
                },
            }}
            render={{
                slide: ({ slide, rect }) => {
                    if (isStreamableSlide(slide)) {
                        return <StreamableSlide key={slide.assetId} slide={slide} />;
                    } else if (slide.type === 'other') {
                        return <PdfZipDownloadTile onDownload={slide.onDownload} />;
                    } else if (isImageSlide(slide)) {
                        return <ImageSlide slide={slide} rect={rect} showLoadingText={true} />;
                    }
                    return null;
                },
                thumbnail: ({ slide, rect }) => {
                    if (isStreamableSlide(slide)) {
                        return <ImageSlide slide={slide} rect={rect} />;
                    } else if (slide.type === 'other') {
                        return (
                            <div className="w-full h-full max-w-[7rem] max-h-[7rem] flex items-center justify-center bg-gray">
                                <Image
                                    src={pdfZipPlaceholder}
                                    draggable={false}
                                    alt=""
                                    objectFit="contain"
                                    className="p-[1rem]"
                                    priority={true}
                                    unoptimized
                                />
                                ;
                            </div>
                        );
                    } else if (isImageSlide(slide)) {
                        return <ImageSlide slide={slide} rect={rect} />;
                    }
                    return;
                },
                iconDownload: () => {
                    // Replace the below component with Alyson's updated Icon component
                    return (
                        <div
                            className="cursor-pointer"
                            onMouseEnter={() => setDownloadIconName('downloadGradient')}
                            onMouseLeave={() => setDownloadIconName('download-circle-white')}
                        >
                            <Icon icon={downloadIconName} size={40} />
                        </div>
                    );
                },
            }}
            thumbnails={{
                height: 68,
                padding: 8,
                width: 110,
                ref: thumbnailsRef,
            }}
            download={{
                download: async ({ slide, saveAs }) => {
                    // @ts-ignore
                    if (slide.download && slide.onDownload) {
                        // @ts-ignore
                        const downloadUrl = await slide.onDownload();
                        if (downloadUrl) {
                            saveAs(downloadUrl);
                        }
                    }
                },
            }}
            index={showSlide}
            carousel={{
                finite: slides?.length === 1,
            }}
            on={{
                entering: onLightBoxOpening,
            }}
        />
    );
};
