// Taken from: https://github.com/CodingWith-Adam/react-easy-crop-tutorial/blob/main/src/cropImage.js

import { Area } from 'react-easy-crop/types';

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

function getRadianAngle(degreeValue: number): number {
    return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of
 * https://github.com/DominicTobias/react-image-crop
 *
 * @param {File} image - Image file URL
 * @param {Object|null} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc: string, pixelCrop: Area, rotation = 0): Promise<Blob | null> {
    if (!pixelCrop) {
        throw new Error('pixelCrop must not be empty.');
    }

    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // Set each dimensions to double largest dimension to allow for a safe area
    // for the image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // Translate canvas context to a central location on image to allow
    // rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // Draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // Set canvas width to final desired crop size - this will clear
    // existing context.
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // Paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    // Option 1) return as Base64 string.
    // toDataURL() is synchronous and will block the main thread.
    // For large images this could be for as long as a couple of seconds.
    // We are using toDataURL('image/jpeg') otherwise it will default to
    // 'image/png' and the conversion will be slower.
    // return canvas.toDataURL('image/jpeg');

    // Options 2) return as a BLOB
    // It is faster and asynchronous, but not supported in old browsers.
    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            if (!file) reject(new Error('File could not be created.'));
            resolve(file);
        }, 'image/jpeg');
    });
}
