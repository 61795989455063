import { useEffect, useRef } from 'react';

function usePolling(args: { shouldPoll: boolean; pollingIntervals: number[]; refetchFn: () => Promise<unknown> }) {
    const nextIntervalIndex = useRef(0);
    const { shouldPoll, pollingIntervals, refetchFn } = args;

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (
            shouldPoll &&
            nextIntervalIndex.current < pollingIntervals.length &&
            pollingIntervals[nextIntervalIndex.current] !== undefined
        ) {
            timeoutId = setTimeout(async () => {
                await refetchFn();
                nextIntervalIndex.current = nextIntervalIndex.current + 1;
            }, pollingIntervals[nextIntervalIndex.current]);
        }

        return () => clearTimeout(timeoutId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldPoll, refetchFn, pollingIntervals, nextIntervalIndex.current]);

    return nextIntervalIndex;
}

export default usePolling;
