import { useEffect, useState } from 'react';
import i18next, { FlatNamespace, KeyPrefix } from 'i18next';
import {
    FallbackNs,
    UseTranslationOptions,
    UseTranslationResponse,
    initReactI18next,
    useTranslation as useTranslationOrg,
} from 'react-i18next';
import { useCookies } from 'react-cookie';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getOptions, languages, cookieName } from './settings';
import { $Tuple } from 'react-i18next/helpers';

const runsOnServerSide = typeof window === 'undefined';

i18next
    .use(initReactI18next)
    .use(resourcesToBackend((language, namespace) => import(`../../public/locales/${language}/${namespace}.json`)))
    .init({
        ...getOptions(),
        lng: 'en', // default language
        detection: {
            order: ['path', 'htmlTag', 'cookie', 'navigator'],
        },
        preload: runsOnServerSide ? languages : [],
    });

export function useTranslation<
    Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
    KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(ns?: Ns, options?: UseTranslationOptions<KPrefix>): UseTranslationResponse<FallbackNs<Ns>, KPrefix> {
    const [cookies, setCookie] = useCookies([cookieName]);
    const ret = useTranslationOrg<Ns, KPrefix>(ns, options);
    const { i18n } = ret;

    if (runsOnServerSide && options?.lng && i18n.resolvedLanguage !== options?.lng) {
        i18n.changeLanguage(options?.lng);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (activeLng === i18n.resolvedLanguage) return;
            setActiveLng(i18n.resolvedLanguage);
        }, [activeLng, i18n.resolvedLanguage]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (!options?.lng || i18n.resolvedLanguage === options?.lng) return;
            i18n.changeLanguage(options?.lng);
        }, [options?.lng, i18n]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (cookies.i18next === options?.lng) return;
            setCookie(cookieName, options?.lng, { path: '/' });
        }, [options?.lng, setCookie, cookies.i18next]);
    }
    return ret;
}
