import React from 'react';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import Head from 'next/head';
import NProgress from 'nprogress';
import { ApolloProvider } from '@apollo/client';
import Script from 'next/script';
import { Provider as ReduxProvider } from 'react-redux';
import { SessionProvider } from 'next-auth/react';

import { ErrorBoundary } from '@srnade/web/components';
import { AuthProvider } from '@srnade/web/auth';
import apolloClient from 'clients/apollo';
import { gtmConfig } from '@srnade/web/config/gtm.config';
import { GlobalState as StoreGlobalState, nextReduxStoreWrapper } from 'store/global';
import { GtmProvider } from 'store/context/gtm.context';
import { AnalyticsProvider } from '@srnade/web/contexts/AnalyticsContext/AnalyticsContext';
import { FeatureFlagsProviderWrapper } from '@srnade/web/contexts/FeatureFlagsContext/FeatureFlagsContext';
import '@srnade/web/i18n/client';

import 'styles/imports.scss';
import '@srnade/component-ui/dist/style.css';
import 'styles/globals.scss';
import 'styles/nprogress.css';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// @todo should we switch to this?
// https://github.com/vercel/next.js/blob/canary/examples/with-apollo/pages/_app.js

// This is the workaround Next limitation on not being able to trigger initial load events, if any.
// Source: https://github.com/vercel/next.js/pull/21824
export function reportWebVitals(_) {}

interface CustomAppProps extends Omit<AppProps, 'Component' | 'pageProps'> {
    Component: AppProps['Component'] & { Layout: JSX.Element };
    pageProps: AppProps['pageProps'] & { initialReduxState: Partial<StoreGlobalState> };
}

const App = ({ Component, ...rest }: AppProps): JSX.Element => {
    const { publicId, publicAuth, publicPreview } = gtmConfig.credentials;
    const { store, props } = nextReduxStoreWrapper.useWrappedStore(rest);

    return (
        <ErrorBoundary>
            {/* Google Tag Manager - Global base code */}
            <Script
                id="gtm-base"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${publicAuth}&gtm_preview=${publicPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${publicId}');
                    `,
                }}
            />
            {/* 
                HubSpot Form - Collectors Club page
                Setting empty string to 'css' and 'cssRequired' values will inform HubSpot 
                that we're injecting a custom CSS to the form.
                
                Docs: https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
             */}
            <Head>
                <title>Serenade</title>
                <meta name="og:title" content="Serenade" key="title" />
                <meta name="description" content="The home of eco-friendly Digital Pressings" key="description" />
                <meta name="viewport" content="minimum-scale=1.0, initial-scale=1.0, width=device-width" />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <ReduxProvider store={store}>
                <SessionProvider>
                    <ApolloProvider client={apolloClient}>
                        <AuthProvider>
                            <FeatureFlagsProviderWrapper>
                                <AnalyticsProvider>
                                    <GtmProvider>
                                        <Component {...props.pageProps} />
                                    </GtmProvider>
                                </AnalyticsProvider>
                            </FeatureFlagsProviderWrapper>
                        </AuthProvider>
                    </ApolloProvider>
                </SessionProvider>
            </ReduxProvider>
        </ErrorBoundary>
    );
};

export default App;
