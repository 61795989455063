import { Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import { FormatSubType, FormatType } from '@srnade/web/__generated__/graphql';

interface ProductTypeInfoProps {
    productFormat: {
        formatType: FormatType;
        formatSubType: FormatSubType;
        limited: boolean;
        editionSize?: number;
    };
    className?: string;
}

export const ProductTypeInfo: React.FC<ProductTypeInfoProps> = ({ productFormat, className }) => {
    const { t } = useTranslation('product-page');

    const formatTypeHumanized: Record<FormatType, string> = {
        [FormatType.DIGITAL_COLLECTIBLE]: t('digitalCollectible'),
        [FormatType.DIGITAL_PRESSING]: t('digitalPressing'),
    };

    const formatSubTypeHumanized: Record<FormatSubType, string> = {
        [FormatSubType.ALBUM]: t('album'),
        [FormatSubType.AUDIO]: t('audio'),
        [FormatSubType.EP]: t('ep'),
        [FormatSubType.IMAGE]: t('image'),
        [FormatSubType.SINGLE]: t('single'),
        [FormatSubType.VIDEO]: t('video'),
    };

    // Initially, just include the format type and format sub type
    // e.g. "Digital Pressing Album"; "Digital Collectible Image"
    const texts: string[] = [
        `${formatTypeHumanized[productFormat.formatType]} ${formatSubTypeHumanized[productFormat.formatSubType]}`,
    ];

    // If it's a limited edition, include the edition size
    if (productFormat.limited && productFormat.editionSize) {
        const editionLimitText = t('editionLimit', {
            limit: productFormat.editionSize,
        });

        texts.push(editionLimitText);
    }

    const text = texts.join('   |   ');

    return (
        <div className={className} data-testid="product-type-info__container">
            <Typography variant="captionBold" className="whitespace-pre-wrap" uppercase>
                {text}
            </Typography>
        </div>
    );
};
