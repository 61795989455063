import client from '@srnade/web/clients/apollo';
import { hygraphClientName } from '@srnade/web/types';

import {
    GetSimplePagesDocument,
    GetSimplePagesQuery,
    GetSimplePagesQueryVariables,
} from '@srnade/web/__generated__/hygraph/graphql';

export async function getSimplePage(slug: GetSimplePagesQueryVariables['slug']) {
    return client.query<GetSimplePagesQuery, GetSimplePagesQueryVariables>({
        query: GetSimplePagesDocument,
        context: { clientName: hygraphClientName },
        variables: { slug },
    });
}
