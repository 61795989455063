import { gql } from '@apollo/client';

/**
 * Mutations
 */

export const CREATE_DOCUMENT = gql`
    mutation CreateDocument($input: CreateDocumentInput!) {
        document: createDocument(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
            }
        }
    }
`;

export const GET_MANY_DOCUMENTS = gql`
    query GetManyDocuments($input: GetManyDocumentsInput!) {
        getManyDocuments(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
