import { Pill } from '@srnade/component-ui';
import { LinkWrapper } from '@srnade/web/components';

interface SettingsLinkProps {
    href: string;
    linkText: string;
    isActive?: boolean;
}
export function SettingsLink({ href, linkText, isActive }: SettingsLinkProps) {
    return isActive ? (
        <Pill
            className={'w-[15rem] h-[5.1rem] laptop:w-full'}
            text={linkText}
            variant={'highlight'}
            data-testid="SettingsLink-btn"
        />
    ) : (
        <LinkWrapper href={href}>
            <Pill
                className={'w-[15rem] h-[5.1rem] laptop:w-full'}
                text={linkText}
                variant={'standard'}
                data-testid="SettingsLink-btn"
            />
        </LinkWrapper>
    );
}
