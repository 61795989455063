import React from 'react';
import clsx from 'clsx';

import { BaseComponentProps, createRootClassName } from '@srnade/component-ui';

import { Image } from '@srnade/web/components/Image';

// Note: there is a circular dependency causing the RTL tests to fail if we import directly from 'utils';
import { imageHandlerBlur, imageHandlerLoader } from '@srnade/web/utils/image.util';

import styles from './Avatar.module.scss';
import { isValidUrlWithFileExtension } from '@srnade/web/utils';

export type AvatarProps = BaseComponentProps & {
    name?: string;
    size?: 'small' | 'normal' | 'medium' | 'large' | 'tile';
    src?: string | null;
    alt?: string;
    border?: boolean;
    unoptimized?: boolean;
};

type SizeMap = {
    [key in keyof AvatarProps['size']]: any;
};

const sizeMapping: SizeMap = { small: 47, normal: 60, medium: 190, large: 255, tile: 275 };

const cdnUrl = process.env.NEXT_PUBLIC_IMAGE_HANDLING_CDN;

export function Avatar({
    className,
    name = '',
    size = 'small',
    src,
    border = true,
    unoptimized,
}: AvatarProps): JSX.Element {
    const rootClassName = createRootClassName(styles, className, { size });
    const initials = name
        .split(' ')
        .map((n) => n[0])
        .join('');

    let imageSrc: string | null = null;
    if (src && isValidUrlWithFileExtension(src)) {
        imageSrc = src as string;
    } else if (src && isValidUrlWithFileExtension(`${cdnUrl}/${src}`)) {
        imageSrc = `${cdnUrl}/${src}`;
    }

    return (
        <div className={clsx(rootClassName, { [styles.border]: size !== 'tile' && border })}>
            <div className={clsx(styles.holder, { [styles.holder_border]: border })}>
                <div className={styles.frame}>
                    {imageSrc ? (
                        <Image
                            src={imageSrc}
                            className={styles.image}
                            alt={name}
                            width={sizeMapping[size]}
                            height={sizeMapping[size]}
                            loader={imageHandlerLoader}
                            placeholder="blur"
                            blurDataURL={imageHandlerBlur}
                            unoptimized={unoptimized}
                        />
                    ) : (
                        <div className={styles.placeholder}>{initials}</div>
                    )}
                </div>
            </div>
        </div>
    );
}
