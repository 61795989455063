export enum Breakpoint {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Laptop = 'Laptop',
    Desktop = 'Desktop',
}

export enum BreakpointWidth {
    Mobile = 320,
    Tablet = 834,
    Laptop = 1290,
    Desktop = 1440,
}
