interface PositionOfAuthority {
    [key: string]: string;
}

/**
 * List of positions used by the validation schema
 */
export const POSITION_OF_AUTHORITIES: PositionOfAuthority = {
    Artist: 'Artist',
    Manager: 'Manager',
    'Record Label': 'RecordLabel',
    Agent: 'Agent',
    Publisher: 'Publisher',
    'Award Show': 'AwardShow',
    Festival: 'Festival',
    Promoter: 'Promoter',
    Other: 'Other',
};
