import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import ReactHtmlParser from 'react-html-parser';
import ReactTypingEffect from 'react-typing-effect';
import { Typography } from '@srnade/component-ui';

export function HeroHeading() {
    const { t } = useTranslation('homepage', { keyPrefix: 'heroBanner' });

    return (
        <>
            <Typography variant="h1">{ReactHtmlParser(t('heading'))}</Typography>
            <ReactTypingEffect
                text={t('bandSynonyms', { returnObjects: true })}
                speed="100"
                typingDelay="200"
                eraseSpeed="100"
                eraseDelay="3000"
                cursorRenderer={(cursor) => <Typography variant="h1">{cursor}</Typography>}
                displayTextRenderer={(text) => {
                    return (
                        <Typography variant="h1">
                            {text.split('').map((char, i) => {
                                const key = `${i}`;
                                return (
                                    <span key={key} style={i % 2 === 0 ? {} : {}}>
                                        {char}
                                    </span>
                                );
                            })}
                        </Typography>
                    );
                }}
            />
        </>
    );
}
