import React from 'react';
import { Icon, Typography } from '@srnade/component-ui';
import clsx from 'clsx';

import { useTranslation } from '@srnade/web/i18n/client';

interface DownloadAudioButtonProps {
    onClick?: () => void;
    canDownloadAudio: boolean;
}
export function DownloadAudioButton({ onClick, canDownloadAudio }: DownloadAudioButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'DownloadAudioButton' });

    return (
        <div
            className="flex items-center justify-center"
            {...(onClick ? { onClick } : {})}
            data-testid="download-audio-button"
        >
            <Icon
                icon={canDownloadAudio ? 'downloadGradient' : 'padlock-closed'}
                size={20}
                disabled={!canDownloadAudio}
                className="flex mr-[5px]"
            />

            <Typography
                className={clsx('flex basis-full', {
                    'text-darkGray cursor-default': !canDownloadAudio,
                    'hover:underline hover:decoration-current cursor-pointer': onClick,
                })}
                variant={canDownloadAudio ? 'bodyBold' : 'body'}
            >
                {t('title')}
            </Typography>
        </div>
    );
}
