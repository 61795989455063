import React from 'react';
import clsx from 'clsx';
import { BaseComponentProps, GTM_ID, Icon } from '@srnade/component-ui';

import { isDev, isLocal } from '@srnade/web/utils';
import { Link } from '@srnade/web/components';

interface OpenSeaButtonProps {
    url: string;
    disabled?: boolean;
    gtmId: string;
}

export function OpenSeaButton({ className, url, disabled, gtmId }: OpenSeaButtonProps & BaseComponentProps) {
    // @Todo Make a util function to generate the opensea url.
    const openSeaUrl = isDev || isLocal ? `https://testnets.${url?.split('https://')[1]}` : url;
    const gtmAttr = gtmId ? { [GTM_ID]: gtmId } : {};

    return (
        <div className={clsx(className, { 'opacity-30 pointer-events-none cursor-default': disabled })}>
            <Link href={openSeaUrl} passHref target="_blank" rel="noopener noreferrer" {...gtmAttr}>
                <div className="w-[4.7rem] h-[4.7rem] rounded-[0.4rem] bg-black p-[0.1rem] border-none cursor-pointer relative hover:bg-gradient-to-bl from-gradientGreen via-gradientBlue to-gradientPurple">
                    <div className="bg-cream h-full rounded-[0.4rem] flex justify-center items-center">
                        <Icon icon="opensea-borderless" size={38} />
                    </div>
                </div>
            </Link>
        </div>
    );
}
