import React from 'react';
import Marquee from 'react-fast-marquee';
import { useTranslation } from '@srnade/web/i18n/client';
import Image from 'next/legacy/image';
import { Typography } from '@srnade/component-ui';

import { PartnerOrderByInput, useGetPartnersQuery } from '@srnade/web/__generated__/hygraph/graphql';

export function PartnersBanner(): JSX.Element {
    const { t } = useTranslation('homepage', { keyPrefix: 'partnersBanner' });
    const { loading, error, data } = useGetPartnersQuery({
        variables: { orderBy: PartnerOrderByInput.publishedAt_ASC },
    });
    if (error) {
        console.error('[HYGRAPH_CMS_ERROR]: error fetching "partners logos" from CMS', JSON.stringify({ error }));
    }

    const partners = data?.partners ?? [];

    if (loading || error) return <></>;

    return (
        <div className="flex flex-col justify-center items-center bg-white pt-[3rem] pb-[2.5rem]">
            <Typography variant="subheadingBold" className="mb-[2rem]">
                {t('heading')}
            </Typography>
            {partners.length > 0 && (
                <Marquee gradient={false}>
                    {partners.map((partner, index) => (
                        <div
                            key={`PartnersLogo_${partner.name}_${index}`}
                            className="mx-[2rem] relative w-[13rem] h-[10rem]"
                        >
                            <Image src={partner.image.url} alt={partner.name} layout="fill" />
                        </div>
                    ))}
                </Marquee>
            )}
        </div>
    );
}
