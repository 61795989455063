import { FormatSubType, PressingType, ParentRecordLabel } from '../__generated__/graphql';

export const FormatSubTypeHumanized: { [key: string]: string } = {
    [FormatSubType.EP]: 'EP',
    [FormatSubType.SINGLE]: 'Single',
    [FormatSubType.ALBUM]: 'Album',
};

export const FormatSubTypeUrl: { [key: string]: string } = {
    [FormatSubType.EP]: 'ep',
    [FormatSubType.SINGLE]: 'single',
    [FormatSubType.ALBUM]: 'album',
};

/** product purchase local storage key prefix */
export const RECENT_PURCHASE_LS_PREFIX = 'recent-purchase';

/** product purchase local storage item shape */
export interface PurchaseLocalStorageItem {
    reservedProductId: string | null;
    parentRecordLabel: ParentRecordLabel | null;
    artistName: string | null;
}

export const PressingTypeUrl: { [key: string]: string } = {
    [PressingType.ExtendedPlay]: 'ep',
    [PressingType.Single]: 'single',
    [PressingType.Album]: 'album',
};

export const PressingTypeHumanized: { [key: string]: string } = {
    [PressingType.ExtendedPlay]: 'EP',
    [PressingType.Single]: 'Single',
    [PressingType.Album]: 'Album',
};
