export enum AccountStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
}

export enum AccountType {
    Collector = 'Collector',
    Artist = 'Artist',
    RightsHolder = 'RightsHolder',
    // RecordLabel = 'RecordLabel',
    // Charity = 'Charity',
    // etc.
}

export enum AccountPosition {
    Artist = 'Artist',
    Manager = 'Manager',
    RecordLabel = 'RecordLabel',
    Agent = 'Agent',
    Publisher = 'Publisher',
    AwardShow = 'AwardShow',
    Festival = 'Festival',
    Promoter = 'Promoter',
    Other = 'Other',
}

export const AccountPositionHumanized = {
    [AccountPosition.Artist]: 'Artist',
    [AccountPosition.Manager]: 'Manager',
    [AccountPosition.RecordLabel]: 'Record Label',
    [AccountPosition.Agent]: 'Agent',
    [AccountPosition.Publisher]: 'Publisher',
    [AccountPosition.AwardShow]: 'Award Show',
    [AccountPosition.Festival]: 'Festival',
    [AccountPosition.Promoter]: 'Promoter',
    [AccountPosition.Other]: 'Other',
};

export enum AccountTag {
    Top = 'Top',
}

export enum AccountEventType {
    APPROVED = 'APPROVED',
}
