import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useHoverDirty } from 'react-use';

import { Icon, Typography } from '@srnade/component-ui';

interface ViewNowProps extends React.HTMLAttributes<HTMLDivElement> {
    heading: string;
    isLocked?: boolean;
}

export function ViewNow({ heading, isLocked = true, ...props }: ViewNowProps) {
    const rootRef = useRef(null);
    const hovering = useHoverDirty(rootRef);

    return (
        <div
            className={clsx(
                'my-[2rem] p-[1px] h-[6rem] rounded-[1rem] bg-gradient-to-b from-gradientBlue via-gradientGreen to-gradientPurple shadow-gradient hover:shadow-none hover:from-white hover:to-white',
                {
                    'pointer-events-none cursor-default opacity-40': isLocked,
                    'cursor-pointer': !isLocked,
                },
            )}
            data-testid="view-now"
            ref={rootRef}
            {...props}
        >
            <div className="flex h-full items-center justify-between rounded-[1rem] px-[2rem] py-[1.2rem] bg-white">
                <div className="flex items-center gap-[1rem]">
                    {hovering === true ? (
                        <Icon icon="playHoverGradient" size={37} />
                    ) : (
                        <Icon icon={'playGradient'} size={37} />
                    )}
                    <Typography variant="bodyBold">{heading}</Typography>
                </div>
                <Icon icon={isLocked ? 'padlock-closed' : 'padlock-open'} size={25} />
            </div>
        </div>
    );
}
