import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { AccountStatus, AccountType, addUsernamePrefix, isArtist } from '@srnade/component-ui';
import { BaseComponentProps, Typography } from '@srnade/component-ui';

import { useAuth } from '@srnade/web/auth';
import { Link } from '@srnade/web/components';
import { useFeatureFlags, useGtmEvents } from '@srnade/web/hooks';
import { TreatmentType } from '@srnade/web/constants';
import { GtmLoggedInStatus, GtmUserEvent } from '@srnade/web/types';
import { signOut } from 'next-auth/react';

type ArtistStudioLinkProps = {
    accountType: AccountType;
    accountStatus: AccountStatus;
};
export const CREATOR_URL = `${process.env.NEXT_PUBLIC_CREATOR_URL}`;

const ArtistStudioLink: React.FC<ArtistStudioLinkProps> = ({ accountType, accountStatus }) => {
    const { t } = useTranslation('components', { keyPrefix: 'userMenu' });

    return (
        <div className="mt-[3rem] flex flex-row">
            <Typography className="text-darkGray mr-[1rem]" variant="subheadingBold" uppercase>
                {t('artistStudio')}
            </Typography>

            <Typography className="text-darkGray lowercase italic font-light" variant="subheading" uppercase>
                {t('underMaintenance')}
            </Typography>
        </div>
    );

    // leaving it here for reference
    // return (
    //     <Link href="/artist-studio" passHref legacyBehavior>
    //         <Typography
    //             className={clsx('mt-[3rem]', {
    //                 'text-darkGray pointer-events-none': !isArtistApproved(accountType, accountStatus),
    //             })}
    //             variant="subheadingBold"
    //             uppercase
    //         >
    //             {t('artistStudio')}
    //             {!isArtistApproved(accountType, accountStatus) && (
    //                 <span className="lowercase italic ml-[1rem] font-light">{t('waitingForApproval')}</span>
    //             )}
    //         </Typography>
    //     </Link>
    // );
};

const CreatorPlatformLink = () => {
    const { t } = useTranslation('components', { keyPrefix: 'userMenu' });
    return (
        <div className="mt-[3rem] flex flex-row">
            <a href={`${CREATOR_URL}`} target="_blank" rel="noreferrer">
                <Typography className="text-black mr-[1rem]" variant="subheadingBold" uppercase>
                    {t('creatorPlatform')}
                </Typography>
            </a>
        </div>
    );
};

interface UserMenuProps {}

export function UserMenu({}: UserMenuProps & BaseComponentProps): JSX.Element {
    const { useFeatureOn } = useFeatureFlags();
    const creatorPlatformFlag: boolean = useFeatureOn(TreatmentType.CreatorPlatform);

    // @todo rename `activeAccount` to `account` for easier access
    // @todo find out why activeAccount is possible null as it shouldn't be
    const { logout, user, activeAccount } = useAuth();
    const { t } = useTranslation('components', { keyPrefix: 'userMenu' });
    const { pushUserEvent } = useGtmEvents(GtmUserEvent.LogoutSuccess);

    const handleLogout = async () => {
        if (user && activeAccount) {
            // TODO temp, remove once splitio issue is fixed (MARK-641)
            pushUserEvent(user.id, GtmLoggedInStatus.NotLoggedIn, activeAccount.type);
        }

        await logout();
    };

    const accountType = activeAccount?.type as AccountType;
    const accountStatus = activeAccount?.status as AccountStatus;

    return (
        <div className="px-[2rem] pb-[10rem] border-t">
            <Typography className="mt-[2rem]" variant="subheading">
                {t('welcome')}, {isArtist(accountType) ? activeAccount?.name : user?.firstName}
            </Typography>
            {activeAccount && (
                <>
                    <Link href={`/${addUsernamePrefix(activeAccount?.username)}`} passHref legacyBehavior>
                        <Typography className="mt-[5rem]" variant="subheadingBold" uppercase>
                            {t('profile')}
                        </Typography>
                    </Link>
                    {isArtist(accountType) && (
                        <>
                            {creatorPlatformFlag ? (
                                <CreatorPlatformLink />
                            ) : (
                                <ArtistStudioLink accountType={accountType} accountStatus={accountStatus} />
                            )}
                        </>
                    )}
                    <Link href="/settings" passHref legacyBehavior>
                        <Typography className="mt-[3rem]" variant="subheadingBold" uppercase>
                            {t('settings')}
                        </Typography>
                    </Link>
                    <Typography
                        className="mt-[3rem] cursor-pointer"
                        variant="subheadingBold"
                        onClick={() => handleLogout()}
                        uppercase
                        data-testid="usermenu-sign-out-button"
                    >
                        {t('signOut')}
                    </Typography>
                </>
            )}
        </div>
    );
}
