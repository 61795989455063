import { Carousel } from '@srnade/component-ui';
import { ProductFormatOrderBy, ProductStatusNew, ProductTag } from '@srnade/web/__generated__/graphql';
import { Container } from '@srnade/web/components';
import { HeroActions } from '@srnade/web/components/HeroProducts/HeroActions';
import { useGtmEvents } from '@srnade/web/hooks';
import { useGtmContext } from '@srnade/web/store/context/gtm.context';
import { useAppDispatch, useAppSelector } from '@srnade/web/store/global';
import { ProductFormatsStatus, fetchProductFormats } from '@srnade/web/store/reducers/product-formats';
import {
    GtmPageNames,
    GtmProductEvent,
    GtmProductEventType,
    GtmPromoNames,
    GtmPromoOrder,
    GtmPromotion,
} from '@srnade/web/types';
import { GtmEvents, promoId, slot } from '@srnade/web/utils';
import { useEffect, useRef } from 'react';
import * as utils from '../../utils';
import { SkeletonProductTile } from '../SkeletonProductTile/SkeletonProductTile.component';
import { ProductTile } from '../Tiles/ProductTile/ProductTile.component';

const HERO_PAGE_SIZE = 8;

export const HeroProducts = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            fetchProductFormats({
                variables: {
                    first: HERO_PAGE_SIZE,
                    filters: {
                        tags: [ProductTag.Hero],
                        isListed: true,
                        productStatus: [ProductStatusNew.RELEASED, ProductStatusNew.PRE_ORDER],
                    },
                    orderBy: [ProductFormatOrderBy.RELEASE_OR_PREORDER_START_DATE_DESC],
                },
                key: 'hero',
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const error = useAppSelector((state) => state.products.error['hero']);
    const loadingStatus = useAppSelector((state) => state.products.status['hero']);
    const formats = useAppSelector((state) => state.products.productFormats['hero']);

    const gtmClickEvents = useRef(new GtmEvents(GtmPageNames.PAGE_HOME_SLIDER, [GtmProductEventType]));
    const { pushProductEvent: pushProductViewList } = useGtmEvents(GtmProductEvent.ViewItemList);
    const { startPromo } = useGtmContext();

    const startCollectingPromo = useRef<GtmPromotion>({
        creative_name: GtmPromoNames.CREATIVE_START_COLLECTING,
        creative_slot: slot(GtmPromoOrder.StartCollecting),
        promotion_id: promoId(GtmPromoOrder.StartCollecting),
        promotion_name: GtmPromoNames.PROMO_START_COLLECTING,
    });

    useEffect(() => {
        if (loadingStatus === ProductFormatsStatus.Success) {
            pushProductViewList(formats, GtmPageNames.PAGE_HOME_SLIDER);
        }
    }, [loadingStatus, formats, pushProductViewList]);

    const onStartCollectingClick = () => {
        startPromo(startCollectingPromo.current);
    };

    if (!!error || loadingStatus === ProductFormatsStatus.Failed) {
        return null;
    }

    const productFormats = utils.extractNodes(formats);

    return (
        <Container className="mt-[6rem]">
            <div
                className="grid justify-items-center tablet:justify-items-start tablet:grid-cols-2"
                data-gtm-id="home_top"
            >
                <HeroActions onStartCollectingClick={() => onStartCollectingClick()}></HeroActions>
                <div className="mx-end mobile:w-full tablet:ml-auto tablet:max-w-[33.5rem] laptop:max-w-[50.5rem] overflow-hidden">
                    <Carousel centeredSlides background>
                        {
                            loadingStatus === ProductFormatsStatus.Pending || !formats
                                ? Array.apply(null, Array(8)).map((_, index: number) => (
                                      <SkeletonProductTile key={`SkeletonProductTile_${index}`} type={'carousel'} />
                                  ))
                                : productFormats.map((productFormat) => (
                                      <ProductTile
                                          key={productFormat.id}
                                          productFormat={productFormat}
                                          className="slick-holder"
                                          type="carousel"
                                          hoverable
                                          clickable
                                          url={utils.getProductUrlPath(
                                              productFormat.artist.username,
                                              productFormat.slug,
                                          )}
                                      />
                                  ))
                            /*  TODO restore gtm props in ProfileTile
                                gtmEvents={gtmClickEvents.current}
                                gtmIndex={index + 1}
                            */
                        }
                    </Carousel>
                </div>
            </div>
        </Container>
    );
};
