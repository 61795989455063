import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { Typography } from '@srnade/component-ui';

import { Container } from '@srnade/web/components';

export function InformationBanner() {
    const { t } = useTranslation('homepage', { keyPrefix: 'informationBanner' });

    const renderSection = (title: string, description: string, marginRight?: boolean) => {
        return (
            <div className="flex justify-items-center">
                <div className="bg-gradient-to-t from-gradientGreen via-gradientBlue to-gradientPurple h-full laptop:h-[8.7rem] w-[0.2rem]" />
                <div className={`ml-[2rem] laptop:w-full ${marginRight ? 'laptop:mr-[5rem]' : ''}`}>
                    <Typography variant="subheadingBold" className="text-white normal-case">
                        {title}
                    </Typography>
                    <Typography variant="body" className="text-white mt-[2rem]">
                        {description}
                    </Typography>
                </div>
            </div>
        );
    };

    const renderFutureOfMerch = (marginRight?: boolean) => {
        return renderSection(t('artistsCreate.heading'), t('artistsCreate.subheading'), marginRight);
    };

    const renderCompensationForArtists = (marginRight?: boolean) => {
        return renderSection(t('fansCollect.heading'), t('fansCollect.subheading'), marginRight);
    };

    const renderBuiltForSustainability = (marginRight?: boolean) => {
        return renderSection(t('communitiesForm.heading'), t('communitiesForm.subheading'), marginRight);
    };

    // Displays mobile and tablet banner in first section, then laptop and desktop banner in second section
    return (
        <>
            <div className="grid content-center laptop:hidden bg-black tablet:h-[43rem] p-[2rem] tablet:p-[4rem]">
                <div className="py-[6rem] tablet:py">
                    <div className="flex">{renderFutureOfMerch()}</div>
                    <div className="flex my-[4rem]">{renderCompensationForArtists()}</div>
                    <div className="flex">{renderBuiltForSustainability()}</div>
                </div>
            </div>

            <div className="hidden laptop:flex bg-black h-[20.5rem] items-center">
                <Container>
                    <div className="laptop:flex laptop:justify-center laptop:items-start">
                        {renderFutureOfMerch(true)}
                        {renderCompensationForArtists(true)}
                        {renderBuiltForSustainability()}
                    </div>
                </Container>
            </div>
        </>
    );
}
