/**
 * This files keeps track of the constants used in creating a product.
 */
// @todo incorrect enum format
export enum CreateProductErrorCodes {
    StripeOnboarding = 'stripeOnboarding',
}

export const DESCRIPTION_MAX_LENGTH = 5000;
export const ADDITIONAL_DETAILS_MAX_LENGTH = 2500;
