import { StreamableBonusContentSlide, useLightboxState } from 'yet-another-react-lightbox';
import { MuxStreamablePlayer } from '@srnade/web/components/MuxStreamablePlayer';
import { useEffect, useRef, useState } from 'react';

interface StreamableSlideProps {
    slide: StreamableBonusContentSlide;
}

export default function StreamableSlide({ slide }: StreamableSlideProps) {
    const { currentIndex } = useLightboxState();
    const playerRef = useRef<{ pause(): void }>(null);
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.pause();
        }
    }, [currentIndex]);

    return (
        <MuxStreamablePlayer
            audioOnly={slide.type === 'audio'}
            onGetStreamableUrl={slide.onRefreshStreamableUrl}
            src={slide.src}
            posterUrl={slide.poster}
            assetId={slide.assetId}
            title={slide.title}
            ref={playerRef}
            metadata={{
                custom_1: slide.pressingTitle,
                custom_2: slide.artistName,
                custom_3: 'Bonus Content',
            }}
        />
    );
}
