import { TreatmentDisplay, TreatmentDisplayMap } from '../constants';

import { createServerFactory } from 'clients/splitio';

/**
 * Returns true when a specified feature flag is turned on
 * Please note: This is required to be used in a getServerSideProps function
 * @param ff String - Feature flag identifier
 * @param userKey String - default set to 'no-user' (optional)
 * @returns featureFlag Boolean
 */
export const isFeatureFlag = async (ff: string, userKey = 'no-user'): Promise<boolean> => {
    const splitIo = createServerFactory();

    if (!splitIo) {
        return false;
    }

    const client: SplitIO.IClient = splitIo.client();
    return new Promise<boolean>((resolve) =>
        client.on(client.Event.SDK_READY, function () {
            const treatment = client.getTreatment(userKey, ff);
            resolve(treatment === TreatmentDisplayMap[TreatmentDisplay.On]);
        }),
    );
};
