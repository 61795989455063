import { gql } from '@apollo/client';

import { ReservedProductSummary } from '../types';

import { LISTING_FRAGMENT } from './listings.service';

export interface FindReservedProductSummaryData {
    findReservedProductSummary: ReservedProductSummary;
}

export const FIND_RESERVED_PRODUCT_SUMMARY = gql`
    query FindReservedProductSummary($reservedProductId: String!) {
        findReservedProductSummary(reservedProductId: $reservedProductId) {
            id
            transactionId
            listing {
                ...listing
            }
            provider
            userId
            paymentStatus
            transactionStatus
            presentmentCurrency
            presentmentAmount
            settlementCurrency
            settlementAmount
        }
    }
    ${LISTING_FRAGMENT}
`;
