import { gql } from '@apollo/client';

import { PRODUCT_FRAGMENT } from './products.service';

/**
 * Types
 */

export interface FindListingsData {
    findListings: {};
}

/**
 * Fragments
 */

export const LISTING_FRAGMENT = gql`
    fragment listing on Listing {
        id
        type
        saleType
        currency
        price
        product {
            ...product
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const LISTING_TILE_FRAGMENT = gql`
    fragment ListingTileFragment on Listing {
        id
        type
        status
        saleType
        currency
        price
        status
        plannedCloseTimeZone
        plannedCloseDateTime
        product {
            id
            status
            allowTransfers
            title
            slug
            additionalDetails
            minted
            limit
            thumbnail {
                id
                url
            }
            account {
                id
                name
                username
                avatar {
                    id
                    url
                }
            }
            productType
            pressing {
                id
                pressingType
            }
        }
    }
`;

/**
 * Queries
 */

export const FIND_HERO_BANNER_LISTINGS = gql`
    query FindHeroBannerListings {
        findListings(first: 8, type: Product, tags: [Hero]) {
            edges {
                cursor
                node {
                    ...ListingTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${LISTING_TILE_FRAGMENT}
`;

export const FIND_GRID_LISTINGS = gql`
    query FindGridListings(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: ListingsSortingInput
        $type: ListingType!
        $tags: [ListingTag!]
        $excludeTags: [ListingTag!]
        $genres: [Genre!]
        $username: String
    ) {
        findListings(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            type: $type
            tags: $tags
            excludeTags: $excludeTags
            genres: $genres
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...ListingTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${LISTING_TILE_FRAGMENT}
`;

export const FIND_LISTINGS = gql`
    query FindListings(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: ListingsSortingInput
        $type: ListingType!
        $tags: [ListingTag!]
        $excludeTags: [ListingTag!]
        $genres: [Genre!]
        $username: String
    ) {
        findListings(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            type: $type
            tags: $tags
            excludeTags: $excludeTags
            genres: $genres
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...listing
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${LISTING_FRAGMENT}
`;

export const FIND_LATEST_LISTING_FOR_PRODUCT_PAGE = gql`
    query FindLatestListingForProductPage($productId: String!) {
        findLatestProductListing(productId: $productId) {
            id
            type
            saleType
            currency
            price
            status
            plannedCloseTimeZone
            plannedCloseDateTime
        }
    }
`;

export const FIND_LATEST_PRODUCT_LISTING = gql`
    query FindLatestProductListing($productId: String!) {
        findLatestProductListing(productId: $productId) {
            ...listing
        }
    }
    ${LISTING_FRAGMENT}
`;

export const FIND_LISTINGS_GENRES = gql`
    query FindListingsGenres {
        findListingsGenres
    }
`;

export const PURCHASE_WITH_CRYPTO = gql`
    mutation PurchaseWithCrypto($listingId: String!) {
        purchaseWithCrypto(listingId: $listingId) {
            id
            coinbaseCharge {
                id
                coinbaseChargeCode
            }
            product {
                id
                parentRecordLabel
                account {
                    id
                    name
                }
            }
        }
    }
`;
