import { Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';

type EditionSequenceProps = {
    sequence: number;
    displayLimit: boolean;
    ofLimit: number | null;
};

export const EditionSequence = ({ sequence, displayLimit, ofLimit }: EditionSequenceProps) => {
    const { t } = useTranslation('product-page');
    const { t: commonT } = useTranslation('common');
    const productLimit = ofLimit ?? t('unlimited');

    return (
        <>
            {displayLimit ? (
                <span className="font-bold">{`#${sequence} ${commonT('of')} ${productLimit}`}</span>
            ) : (
                <span className="font-bold">#{sequence}</span>
            )}
        </>
    );
};
