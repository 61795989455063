import clsx from 'clsx';
import { extension } from 'mime-types';

import { Icon, Link, PlayButton, Typography } from '@srnade/component-ui';
import { formatBytes } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';
import { BonusContent as BonusContentType } from '@srnade/web/__generated__/graphql';

type ProductBonusContentLinkProps = {
    bonusContent: BonusContentType;
    locked: boolean;
    isLoading: boolean;
    onDownload: (id: string) => void;
} & React.HTMLAttributes<HTMLDivElement>;

function getIconName(mimeType: string): string {
    if (mimeType.includes('audio')) {
        return 'audio-bg-grey';
    }

    if (mimeType.includes('video')) {
        return 'video-bg-grey';
    }

    if (mimeType.includes('image')) {
        return 'image-bg-grey';
    }

    if (mimeType.includes('pdf') || mimeType.includes('zip')) {
        return 'document-bg-grey';
    }

    return 'fallback-bg-grey';
}

export const ProductBonusContentLink: React.FC<ProductBonusContentLinkProps> = ({
    bonusContent,
    locked,
    isLoading,
    onDownload,
    onClick,
}) => {
    const { t } = useTranslation('components', { keyPrefix: 'bonusContent' });

    const releasedBonusContent = Boolean(
        bonusContent.active && bonusContent?.displayName && bonusContent?.displayName?.length > 0,
    );

    // fix locked state, we need to check if the bonus content is released
    if (!releasedBonusContent) {
        locked = true;
    }

    const iconName = bonusContent.mimeType ? getIconName(bonusContent.mimeType) : 'fallback-bg-grey';
    const iconSize = locked ? 44 : 46;

    const productLinkContents = (
        <div
            className={clsx('p-[1rem] rounded-[1rem] flex items-center hover:opacity-100', {
                'cursor-default pointer-events-none': locked,
                'bg-white': !locked,
            })}
            data-testid={locked ? 'locked-bonus-content' : 'unlocked-bonus-content'}
        >
            <div className="mr-[1.2rem]">
                {releasedBonusContent ? (
                    <Icon icon={iconName} size={iconSize} />
                ) : (
                    <div className={`w-[37px] h-[37px] border border-[#AFAFAF] rounded-md`}></div>
                )}
            </div>

            <div className="flex justify-between items-center w-full">
                {releasedBonusContent ? (
                    <div>
                        <Typography
                            className={clsx('leading-none mb-[0.3rem] line-clamp-1', {
                                'opacity-40': locked,
                            })}
                            variant="bodyBold"
                        >
                            {bonusContent.displayName}
                        </Typography>

                        <Typography className={clsx('leading-none', { 'opacity-60': locked })} variant="caption">
                            External Link
                        </Typography>
                    </div>
                ) : (
                    <Typography
                        variant="h2"
                        className="italic text-darkGray text-[1.5rem] leading-[2rem]"
                        data-testid={`releasing-soon-${bonusContent?.assetId}`}
                    >
                        {t('unreleased')}
                    </Typography>
                )}

                <div
                    className={clsx('mx-[2rem]', { 'opacity-50': locked, 'cursor-pointer': !locked })}
                    onClick={(e) => {
                        return releasedBonusContent ? onClick : undefined;
                    }}
                    data-testid="product-bonus-content-right-up-icon"
                >
                    {isLoading ? (
                        <PlayButton variant="loading" />
                    ) : (
                        <>
                            {!locked ? (
                                <Icon size={20} icon="arrow-right-up" hoverIcon="arrow-right-up" />
                            ) : (
                                <Icon size={20} icon="arrow-right-up" />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div
            className={clsx(
                'rounded-[1rem] mb-[1rem] last:mb-0 p-[1px]',
                {
                    'bg-cream border-[#AFAFAF] border-[0.1rem]': locked,
                },
                {
                    'bg-white cursor-pointer shadow:none from-white to-white hover:bg-gradient-to-b hover:from-[#92E4FD] hover:via-[#93ECB5] hover:to-[#BFA9FC] hover:shadow-[0_0_10px_0_rgba(146,228,253,0.50)]':
                        !locked,
                },
            )}
            data-testid="product-bonus-content-link"
        >
            {!locked ? (
                <a href={bonusContent?.assetId ?? '#'} target="_blank" rel="noopener noreferrer">
                    {productLinkContents}
                </a>
            ) : (
                productLinkContents
            )}
        </div>
    );
};
