import React from 'react';

import { TopFooter, BottomFooter } from './components';

export function Footer() {
    return (
        <div data-gtm-id="footer">
            <TopFooter />
            <BottomFooter />
        </div>
    );
}
