import { AccountType } from '@srnade/component-ui';

// Event types
export enum GtmPromotionEvent {
    // Promotion impressions
    ViewPromotion = 'view_promotion',
    // Promotion clicks
    SelectPromotion = 'select_promotion',
}

export enum GtmProductEvent {
    // Product impressions
    ViewItemList = 'view_item_list',
    // Product clicks
    SelectItem = 'select_item',
    // Product details
    ViewItem = 'view_item',
}

export enum GtmPurchaseEvent {
    // 'Buy now' button clicked
    AddToCart = 'add_to_cart',
    // 'Buy now' proceed to payment
    BeginCheckout = 'begin_checkout',
    // Buy transaction completed
    Purchase = 'purchase',
    Refund = 'refund', // full or partial
}

export enum GtmUserEvent {
    // Sign-up/in
    LoginSuccess = 'login_success',
    SignupSuccess = 'signup_success',
    LogoutSuccess = 'logout_success',
}

export type GtmEvent = GtmPromotionEvent | GtmProductEvent | GtmPurchaseEvent | GtmUserEvent;

export const GtmPromotionEventType = 'GtmPromotionEvent';
export const GtmPurchaseEventType = 'GtmPurchaseEvent';
export const GtmProductEventType = 'GtmProductEvent';
export const GtmUserEventType = 'GtmUserEvent';

export type GtmEventType = 'GtmPromotionEvent' | 'GtmProductEvent' | 'GtmPurchaseEvent' | 'GtmUserEvent';

// Item list names (should not change, only new keys added)
export const GtmPageNames: { [key: string]: string } = {
    PAGE_SHOP_ALL: 'Shop all page',
    PAGE_HOME_SLIDER: 'Home page item slider',
    PAGE_HEADLINE_RELEASES: 'Headline releases',
    PAGE_PREVIOUS_RELEASES: 'Previous releases',
    PAGE_MORE_LIKE_THIS: 'more like this',
    PAGE_TOP_ARTISTS: 'Top Artists', // used?
    PAGE_TOP_ARTISTS_SOLD_OUT: 'Top Artists | Sold out releases',
    PAGE_TOP_ARTISTS_NEW_RELEASES: 'Top Artists | New releases',
    PAGE_TOP_FANS: 'Top Fans | Collection',
};

// Promotional events
export const GtmPromoNames: { [key: string]: string } = {
    CREATIVE_START_COLLECTING: 'Collect Digital Pressings from your favourite',
    PROMO_START_COLLECTING: 'Start Collecting',
    CREATIVE_TOP_ARTISTS: 'Top Artists slider',
    PROMO_TOP_ARTISTS: 'Top Artists',
    CREATIVE_TOP_FANS: 'Top Fans slider',
    PROMO_TOP_FANS: 'Top Fans',
    CREATIVE_HEADLINE: 'Headline releases',
    PROMO_HEADLINE: 'Shop all',
    CREATIVE_PREVIOUS: 'Previous releases',
    PROMO_PREVIOUS: 'See all',
};

export enum GtmLoggedInStatus {
    LoggedIn = 'logged_in',
    NotLoggedIn = 'not_logged_in',
    Unverified = 'unverified', // yet to click Verify in Email
}

export enum GtmPaymentMethod {
    CreditCard = 'credit card',
    Crypto = 'crypto',
}

// Details pushed for User events
export type GtmUser = {
    user_id: string;
    logged_in_status: GtmLoggedInStatus;
    account_type: AccountType;
};

export type GtmPurchase = {
    transaction_id?: string; // Req for purchases and refunds
    affiliation: string;
    value?: number; // Req for purchases and refunds
    currency: string;
    tax?: number; // Req for purchases and refunds
    coupon: string;
    payment_type: string;
};

export type GtmItem = {
    item_name: string;
    item_id: string;
    affiliation: string;
    coupon: string;
    currency: string;
    price: number;
    item_category: string;
    item_stock_detail?: string;
    item_brand: string;
    item_list_name: string;
    item_list_id?: string;
    index: number;
    quantity: number;
};

export type GtmViewItem = {
    view_item_list: string;
};

// E-Commerce events
export type GtmECommerce = {
    items: GtmItem[];
};

// Unique promotion identifier
export enum GtmPromoOrder {
    StartCollecting = 1,
    HeadlineShopAll = 2,
    TopArtists = 3,
    PreviousSeeAll = 4,
    TopFans = 5,
}
// Page order promotions appear
export enum GtmSlotOrder {
    StartCollecting = 1,
    HeadlineShopAll = 2,
    TopArtistsOrFan = 3,
    PreviousSeeAll = 4,
}

export type GtmPromotion = {
    creative_name: string;
    creative_slot: string;
    promotion_id: string; // view promo, select promo
    promotion_name: string;
};

export const GtmLocalStoragePageName = 'gtmPageName';
