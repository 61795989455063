import { Typography } from '@srnade/component-ui';
import clsx from 'clsx';

interface SeparatorProps {
    className?: string;
}

const Separator = ({ className }: SeparatorProps) => {
    return (
        <div
            className={clsx('w-full h-[0.1rem]', className)}
            style={{
                background: 'var(--gradient-main)',
            }}
        ></div>
    );
};

interface ProductInclusionsWithoutIconsProps {
    inclusions: { items: string[] };
    title: string;
}

export const ProductInclusionsWithoutIcons = ({ inclusions, title }: ProductInclusionsWithoutIconsProps) => {
    return (
        <div data-testid="product-inclusions">
            <Separator className="mb-[2rem] mt-[4rem]" />
            <Typography variant="h5" className="mb-[2rem]">
                <span className="font-h2 italic font-normal">{title}</span>
            </Typography>

            <Typography variant="h5">
                <ul className="font-normal flex flex-col">
                    {inclusions.items.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </Typography>
            <Separator className="mb-[2rem] mt-[4rem]" />
        </div>
    );
};
