import React from 'react';

import { Image } from '@srnade/web/components/Image';
import { imageHandlerBlur, imageHandlerLoader } from '@srnade/web/utils';

type ImageTileProps = {
    src: string;
    alt: string;
};

export const ImageTile: React.FC<ImageTileProps> = ({ src, alt }) => {
    return (
        <div className="mb-[3rem] border-0 overflow-hidden rounded-[1rem] drop-shadow-xl aspect-square">
            <Image
                src={src}
                alt={alt}
                width={1000}
                height={1000}
                quality={75}
                loader={imageHandlerLoader}
                placeholder="blur"
                blurDataURL={imageHandlerBlur}
                layout="responsive"
                priority
            />
        </div>
    );
};
