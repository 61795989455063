/* eslint-disable no-restricted-imports */
import { ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { Context, HYDRATE, createWrapper } from 'next-redux-wrapper';
import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux';
import { Action } from 'redux';

import editionFormatSlice from '@srnade/web/store/reducers/edition-format';
import listingsSlice from '@srnade/web/store/reducers/listings';
import playerSlice from '@srnade/web/store/reducers/player';
import productFormatSlice from '@srnade/web/store/reducers/product-format';
import productFormatsSlice from '@srnade/web/store/reducers/product-formats';
import voucherSlice from '@srnade/web/store/reducers/voucher';
import { storeTracksMiddleware } from './middleware';

import compressedTracksSlice from '@srnade/web/store/reducers/compressed-tracks';

// Create the root reducer independently to obtain the GlobalState type
export const combinedReducer = combineReducers({
    [listingsSlice.name]: listingsSlice.reducer,
    [productFormatSlice.name]: productFormatSlice.reducer,
    [productFormatsSlice.name]: productFormatsSlice.reducer,
    [editionFormatSlice.name]: editionFormatSlice.reducer,
    [voucherSlice.name]: voucherSlice.reducer,
    [playerSlice.name]: playerSlice.reducer,
    [compressedTracksSlice.name]: compressedTracksSlice.reducer,
});

const rootReducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        };
        return nextState;
    } else {
        return combinedReducer(state, action);
    }
};

export interface GlobalState extends ReturnType<typeof combinedReducer> {}

export const setupStore = (_: Context, preloadedState?: Partial<GlobalState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        devTools: false,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(storeTracksMiddleware),
    });
};

export type AppStore = ReturnType<typeof setupStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<GlobalState> = useSelector;
export const useAppStore: () => AppStore = useStore;

// Use this on getServerSideProps @see https://codesandbox.io/p/devbox/next-redux-wrapper-demo-7n2t5?file=/pages/server.tsx:35,21 and https://github.com/kirill-konshin/next-redux-wrapper?tab=readme-ov-file#getserversideprops
export const nextReduxStoreWrapper = createWrapper<AppStore>(setupStore, { debug: false });
