import React from 'react';
import clsx from 'clsx';
import { BaseComponentProps, Icon, Typography, Card, CardContent } from '@srnade/component-ui';
import { SocialNetwork } from '@srnade/component-ui';

import { removeTrailingSlash } from '@srnade/web/utils';
export interface SocialProps {
    link: string;
    platform: SocialNetwork;
}

const generateDisplayTextFromLink = (account: string, platform?: SocialNetwork): string => {
    if (platform === SocialNetwork.Website) {
        const result = removeTrailingSlash(account).replace(/^(?:https?:\/\/)?(?:www\.)?/i, ''); // removes the start of the url for all scenarios eg. https://www. or https://
        return result;
    }
    let index = removeTrailingSlash(account).lastIndexOf('/');
    let result = removeTrailingSlash(account).substring(index + 1);
    return platform === SocialNetwork.Twitter || platform === SocialNetwork.Instagram ? `@${result}` : result; // if it's a TikTok or discord, @ already in string
};

export function Social({ link, platform }: SocialProps & BaseComponentProps) {
    return (
        <a target="_blank" href={link} rel="noreferrer">
            <Card
                className={clsx(
                    'h-[4rem] rounded-2xl bg-darkGray p-[0.1rem] border-none cursor-pointer relative hover:bg-gradient-to-bl from-gradientGreen via-gradientBlue to-gradientPurple hover:fill-[#addbea] mr-[0.7rem] mb-[0.7rem] max-w-[14.4rem]',
                )}
                border
            >
                <CardContent className="bg-cream hover:bg-white h-full p-[0.7rem] rounded-2xl flex items-center">
                    <div>
                        <Icon
                            icon={platform == SocialNetwork.Website ? 'web' : platform.toLocaleLowerCase()} // website doesnt have an icon, so we have to use web
                            size={18}
                        />
                    </div>
                    <Typography className="ml-[0.7rem] truncate lowercase" variant="body">
                        {generateDisplayTextFromLink(link, platform)}
                    </Typography>
                </CardContent>
            </Card>
        </a>
    );
}
