import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';

import { PlayableTrack } from '@srnade/web/types';
import { getTokenExpiryFromUrl, imageHandlerBlur, imageHandlerLoader } from '@srnade/web/utils';
import { useGetStreamUrl } from '@srnade/web/hooks';
import { Image } from '@srnade/web/components/Image';

import { ControlPanel, Slider, TrackDetails } from './components';

interface AudioPlayerProps {
    imageUrl?: string;
    imageAlt?: string;
    playlist: PlayableTrack[];
    showPlayerOnly?: boolean;
}

export function AudioPlayer({ imageUrl, imageAlt, playlist, showPlayerOnly = false }: AudioPlayerProps) {
    const [currentTrack, setCurrentTrack] = useState<PlayableTrack>();
    const [percentage, setPercentage] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [url, setUrl] = useState<string>();
    const [tokenExpiry, setTokenExpiry] = useState<number | null>(null);
    const [shouldFetchStreamUrl, setShouldFetchStreamUrl] = useState<boolean>(true);

    const refreshTokenTimerRef = useRef<NodeJS.Timeout>();
    const audioRef = useRef<ReactPlayer>(null);
    const { t } = useTranslation('components', {
        keyPrefix: 'audioPlayer',
    });

    const fetchStreamUrl = useGetStreamUrl();
    const disabled = false;

    const clearRefreshTokenTimer = useCallback(() => {
        if (refreshTokenTimerRef.current) {
            clearTimeout(refreshTokenTimerRef.current);
            refreshTokenTimerRef.current = undefined;
        }
    }, []);

    const getStreamUrl = useCallback(
        async (mediaId?: string) => {
            if (!mediaId) return;

            const response = await fetchStreamUrl(mediaId);
            if (response && response.ok && response.url) {
                const tokenExpiryFromUrl = getTokenExpiryFromUrl(response.url);
                if (tokenExpiryFromUrl) {
                    setTokenExpiry(tokenExpiryFromUrl - Date.now());
                }
                setUrl(response.url);
                setShouldFetchStreamUrl(false);
                clearRefreshTokenTimer();
            }
        },
        [fetchStreamUrl, clearRefreshTokenTimer],
    );

    useEffect(() => {
        if (!playlist && !playlist[0]) return;
        setCurrentTrack(playlist[0]);
    }, [playlist]);

    useEffect(() => {
        if (tokenExpiry && !refreshTokenTimerRef.current) {
            refreshTokenTimerRef.current = setTimeout(() => {
                setShouldFetchStreamUrl(true);
                clearRefreshTokenTimer();
            }, tokenExpiry - 10000); // tokenExpiry minus 10 seconds
        }

        return () => clearRefreshTokenTimer();
    }, [tokenExpiry, clearRefreshTokenTimer]);

    // deal with current time when token is refreshed
    useEffect(() => {
        audioRef.current?.seekTo(currentTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]); // ignoring currentTime dep here as we only want to set this when url changes

    useEffect(() => {
        if (currentTrack?.mediaId && shouldFetchStreamUrl) {
            getStreamUrl(currentTrack?.mediaId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTrack?.mediaId, shouldFetchStreamUrl]);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    const skipBack = () => {
        const index = playlist.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
        if (index === 0) {
            setCurrentTrack(playlist[playlist.length - 1]);
        } else {
            setCurrentTrack(playlist[index - 1]);
        }

        audioRef.current?.seekTo(0);
        setCurrentTime(0);
        setIsPlaying(true);
        setTokenExpiry(null);
        setShouldFetchStreamUrl(true);
        clearRefreshTokenTimer();
    };

    const skipToNext = () => {
        const index = playlist.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
        if (index === playlist.length - 1) {
            setCurrentTrack(playlist[0]);
        } else {
            setCurrentTrack(playlist[index + 1]);
        }

        audioRef.current?.seekTo(0);
        setCurrentTime(0);
        setIsPlaying(true);
        setTokenExpiry(null);
        setShouldFetchStreamUrl(true);
        clearRefreshTokenTimer();
    };

    const handleProgress = (e: { playedSeconds: number }) => {
        const percent = ((e.playedSeconds / duration) * 100).toFixed(2);
        if (!seeking) {
            setCurrentTime(Number(e.playedSeconds.toFixed(2)));
            setPercentage(+percent);
        }
    };

    const handleDuration = (e: React.SetStateAction<number>) => {
        setDuration(e);
    };

    const handleEnded = () => {
        const index = playlist.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
        if (index === playlist?.length - 1) setIsPlaying(false);
        else skipToNext();
    };

    const handleSeekChange = (e) => {
        const newValue = e.target?.value;
        if (newValue) {
            setCurrentTime(newValue / 100);
            setPercentage(+newValue);
        }
    };

    const handleSeekMouseDown = () => {
        setSeeking(true);
    };

    const handleSeekMouseUp = (e: { target: { value: number } }) => {
        setSeeking(false);
        audioRef.current?.seekTo(e.target.value / 100);
    };

    return (
        <>
            {imageUrl && imageAlt && (
                <div className="mb-[3rem] border-0 overflow-hidden rounded-[1rem] drop-shadow-xl">
                    <Image
                        src={imageUrl}
                        height={1000}
                        width={1000}
                        layout="intrinsic"
                        alt={imageUrl}
                        loader={imageHandlerLoader}
                        placeholder="blur"
                        blurDataURL={imageHandlerBlur}
                        priority
                    />
                </div>
            )}
            <div className={clsx('flex', { 'px-[2rem] tablet:px-0': !showPlayerOnly })}>
                {url && (
                    <ReactPlayer
                        id={currentTrack?.mediaId}
                        key={currentTrack?.mediaId}
                        ref={audioRef}
                        url={url}
                        width={0}
                        height={0}
                        playing={isPlaying}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        onEnded={handleEnded}
                        onSeek={handleSeekChange}
                        onError={(error) => {
                            console.error('ReactPlayer::onError');
                            console.error(error);
                        }}
                        playsinline
                    />
                )}

                {(currentTrack || disabled) && (
                    <>
                        <ControlPanel
                            onPlayPause={handlePlayPause}
                            isPlaying={isPlaying}
                            skipBack={skipBack}
                            skipToNext={skipToNext}
                            currentTrack={currentTrack}
                            tracks={playlist}
                            showPlayerOnly={showPlayerOnly}
                            disabled={disabled}
                        />
                        {!showPlayerOnly && currentTrack?.trackName && (
                            <div className="w-full ml-[1.6rem] flex-grow laptop:flex-grow-0">
                                <TrackDetails
                                    title={currentTrack.trackName}
                                    currentTime={currentTime}
                                    duration={duration}
                                    disabled={disabled}
                                />
                                <div className="mt-[0.5rem]">
                                    <Slider
                                        percentage={percentage}
                                        onSeek={handleSeekChange}
                                        onSeekMouseDown={handleSeekMouseDown}
                                        onSeekMouseUp={handleSeekMouseUp}
                                        onDuration={handleDuration}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
