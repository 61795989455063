import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import { imageHandlerLoader, imageHandlerBlur } from '@srnade/web/utils';

import { ControlPanel, Slider, TrackDetails } from './components';

interface DisabledAudioPlayerProps {
    imageUrl?: string;
    imageAlt?: string;
    showPlayerOnly?: boolean;
}

export function DisabledAudioPlayer({ imageUrl, imageAlt, showPlayerOnly = false }: DisabledAudioPlayerProps) {
    const { t } = useTranslation('components', {
        keyPrefix: 'audioPlayer',
    });
    const disabled = true;

    return (
        <>
            {imageUrl && imageAlt && (
                <div className="mb-[3rem] border-0 overflow-hidden rounded-[1rem] drop-shadow-xl">
                    <Image
                        src={imageUrl}
                        height={1000}
                        width={1000}
                        layout="responsive"
                        alt={imageUrl}
                        loader={imageHandlerLoader}
                        placeholder="blur"
                        blurDataURL={imageHandlerBlur}
                        priority
                    />
                </div>
            )}
            <div className={clsx('flex', { 'px-[2rem] tablet:px-0': !showPlayerOnly })}>
                <ControlPanel
                    onPlayPause={() => {}}
                    isPlaying={false}
                    skipBack={() => {}}
                    skipToNext={() => {}}
                    tracks={[]}
                    showPlayerOnly={showPlayerOnly}
                    disabled={disabled}
                />
                {!showPlayerOnly && (
                    <div className="ml-[1.6rem] flex-grow laptop:flex-grow-0">
                        <TrackDetails
                            title={t('disabledPlayerTrackName')}
                            currentTime={0}
                            duration={0}
                            disabled={disabled}
                        />
                        <div className="mt-[0.5rem]">
                            <Slider
                                percentage={0}
                                disabled={disabled}
                                onSeek={() => {}}
                                onSeekMouseDown={() => {}}
                                onSeekMouseUp={() => {}}
                                onDuration={() => {}}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
