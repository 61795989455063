import React from 'react';
import { Typography } from '@srnade/component-ui';
import clsx from 'clsx';

interface TrackDetailsProps {
    title: string;
    duration: number;
    currentTime: number;
    disabled?: boolean;
}

export function TrackDetails({ title, duration, currentTime, disabled }: TrackDetailsProps) {
    const calculateTime = (secs: number) => {
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    };
    return (
        <div className="flex items-center justify-between">
            <Typography
                className={clsx(
                    {
                        'text-darkGray italic': disabled,
                        'line-clamp-1': !disabled,
                    },
                    'tracking-[initial]',
                )}
                variant="body"
            >
                {title}
            </Typography>
            {!disabled && (
                <div
                    className={clsx('flex', {
                        'text-darkGray mobile:hidden tablet:flex': disabled,
                    })}
                >
                    <Typography
                        className={clsx(' ml-[2rem]', {
                            'text-darkGray': disabled,
                        })}
                        variant="body"
                    >
                        {calculateTime(currentTime)}
                    </Typography>
                    <>
                        <Typography className="mx-[0.4rem]" variant="body">
                            /
                        </Typography>
                        <Typography variant="body">{duration ? calculateTime(duration) : '00:00'}</Typography>
                    </>
                </div>
            )}
        </div>
    );
}
