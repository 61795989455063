import React, { useRef } from 'react';
import { Icon } from '@srnade/component-ui';
import clsx from 'clsx';

import { PlayableTrack } from '@srnade/web/types';
import { useHoverDirty } from 'react-use';

type ControlPanelProps = {
    onPlayPause: () => void;
    isPlaying: boolean;
    skipBack: () => void;
    skipToNext: () => void;
    currentTrack?: PlayableTrack | undefined;
    tracks: PlayableTrack[];
    showPlayerOnly?: boolean;
    disabled: boolean;
};

type PlayButtonIconProps = {
    disabled: boolean;
    playing: boolean;
    hovering: boolean;
};

const PlayButtonIcon = ({ disabled, playing, hovering }: PlayButtonIconProps) => {
    let icon = 'playGradient';

    if (playing) {
        icon = 'pauseGradient';
    } else if (disabled && hovering) {
        icon = 'playButtonDisabled';
    }

    return <Icon icon={icon} size={37} />;
};

type SkipButtonVariant = 'left' | 'right';

const skipButtonIconGradientMapping: Record<SkipButtonVariant, string> = {
    left: 'skipLeftGradient',
    right: 'skipRightGradient',
};

const skipButtonIconDisabledMapping: Record<SkipButtonVariant, string> = {
    left: 'skipLeftDisabled',
    right: 'skipRightDisabled',
};

const skipButtonDataTestIdMapping: Record<SkipButtonVariant, string> = {
    left: 'skipLeft',
    right: 'skipRight',
};

type SkipButtonProps = {
    variant: SkipButtonVariant;
    disabledPlayer: boolean;
    disabled: boolean;
    hovering: boolean;
    onSkip: () => void;
    className?: string;
};

const SkipButton = ({ variant, disabledPlayer, disabled, hovering, onSkip, className }: SkipButtonProps) => {
    let icon = skipButtonIconGradientMapping[variant];

    if (disabledPlayer && hovering) {
        icon = skipButtonIconDisabledMapping[variant];
    }

    return (
        <button
            type="button"
            disabled={disabledPlayer || disabled}
            className={clsx(
                'laptop:hover:opacity-75',
                {
                    'opacity-40 pointer-events-none': disabledPlayer ? false : disabled,
                    'laptop:cursor-pointer': !disabledPlayer && !disabled,
                },
                className,
            )}
            onClick={onSkip}
            data-testid={skipButtonDataTestIdMapping[variant]}
        >
            <Icon icon={icon} size={18} className="min-h-[auto]" />
        </button>
    );
};

export function ControlPanel({
    onPlayPause,
    isPlaying,
    skipBack,
    skipToNext,
    currentTrack,
    tracks,
    showPlayerOnly = false,
    disabled,
}: ControlPanelProps) {
    const index = tracks?.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
    const disableSkipBack = index === 0;
    const disableSkipToNext = index === tracks?.length - 1;
    const rootRef = useRef(null);

    const hovering = useHoverDirty(rootRef);

    return (
        <div className="flex items-center" ref={rootRef} data-testid="control-panel">
            {!showPlayerOnly && (
                <SkipButton
                    variant="left"
                    disabled={disableSkipBack}
                    disabledPlayer={disabled}
                    hovering={hovering}
                    onSkip={skipBack}
                    className="mr-[0.9rem]"
                />
            )}
            <button
                type="button"
                className={clsx('laptop:hover:opacity-75 laptop:cursor-pointer', {
                    'pointer-events-none': disabled,
                })}
                onClick={onPlayPause}
                data-testid="playButton"
                disabled={disabled}
            >
                <PlayButtonIcon disabled={disabled} hovering={hovering} playing={isPlaying} />
            </button>
            {!showPlayerOnly && (
                <SkipButton
                    variant="right"
                    disabled={disableSkipToNext}
                    disabledPlayer={disabled}
                    hovering={hovering}
                    onSkip={skipToNext}
                    className="ml-[0.9rem]"
                />
            )}
        </div>
    );
}
