import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { BaseComponentProps, Typography } from '@srnade/component-ui';

import { PlayableTrack } from '@srnade/web/types';

export interface TracksProps {
    trackList: PlayableTrack[];
}

export function Tracks({ className, trackList }: TracksProps & BaseComponentProps) {
    const { t } = useTranslation('components', { keyPrefix: 'tracks' });

    return (
        <div className={className}>
            {trackList?.map((track) => (
                <Typography key={track.trackNumber} variant="body">
                    {track?.trackNumber}. {track?.trackName}
                </Typography>
            ))}
        </div>
    );
}
