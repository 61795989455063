import React, { createContext, PropsWithChildren, useContext } from 'react';

import { TreatmentType } from '@srnade/web/constants';

import { useAuth } from '@srnade/web/auth';
import { useFeatureFlags } from '@srnade/web/hooks';
import { AnalyticsEventType, AnalyticsEventTypeToPayloadMap, EventBase } from '@srnade/web/types';

type Event = EventBase & AnalyticsEventTypeToPayloadMap[AnalyticsEventType];

// Define dataLayer in window
declare const window: Window & { dataLayer: Event[] };

type AnalyticsContextType = {
    pushAnalytics: <T extends AnalyticsEventType>(_eventType: T, payload: AnalyticsEventTypeToPayloadMap[T]) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

export const AnalyticsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { user } = useAuth();
    const { useFeatureOn } = useFeatureFlags();
    const googleAnalyticsEventsIsOn = useFeatureOn(TreatmentType.GoogleAnalyticsEvents);

    const pushAnalytics = <T extends AnalyticsEventType>(eventType: T, payload: AnalyticsEventTypeToPayloadMap[T]) => {
        if (!window.dataLayer || !googleAnalyticsEventsIsOn) {
            return;
        }

        const event: Event = {
            user_id: user?.id ?? 'anonymous', // caters for anonymous (logged out) flows
            event: eventType,
            ...payload,
        };

        window.dataLayer.push(event);
    };

    const contextValue = {
        pushAnalytics,
    };

    return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within AnalyticsProvider');
    }

    return context;
};
