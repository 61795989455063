import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import {
    ClaimStatus,
    ClaimVoucherInput,
    ClaimVoucherMutation,
    ClaimVoucherMutationVariables,
} from '@srnade/web/__generated__/graphql';
import { CLAIM_VOUCHER } from '@srnade/web/services/vouchers.service';
import client from 'clients/apollo';
import { HYDRATE } from 'next-redux-wrapper';

export enum VoucherLoadStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Failed = 'Failed',
}

export interface VoucherState {
    voucherCode: string | null;
    voucherStatus: ClaimStatus | null;
    status: VoucherLoadStatus;
    error: string | null | undefined;
}

const initialState: VoucherState = {
    voucherCode: null,
    voucherStatus: null,
    status: VoucherLoadStatus.Idle,
    error: null,
};

type ClaimArgs = ClaimVoucherInput & { token: string; accountId: string };

export const claimVoucher: AsyncThunk<ClaimVoucherMutation, ClaimArgs, AsyncThunkConfig> = createAsyncThunk(
    'vouchers/claimVoucher',
    async (args: ClaimArgs, thunkApi) => {
        try {
            const claimVoucherResponse = await client.mutate<ClaimVoucherMutation, ClaimVoucherMutationVariables>({
                mutation: CLAIM_VOUCHER,
                variables: {
                    voucher: {
                        voucherCode: args.voucherCode,
                    },
                },
                context: {
                    headers: {
                        authorization: args.token ? `Bearer ${args.token}` : undefined,
                        'account-id': args.accountId,
                    },
                },
            });

            // const claimStatus = claimVoucherResponse.data?.claimVoucher ?? ClaimStatus.INVALID;
            return thunkApi.fulfillWithValue(claimVoucherResponse.data as ClaimVoucherMutation);
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

const voucheSlice = createSlice({
    name: 'vouchers',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        startVoucherClaim(_, action) {
            return {
                status: action.payload?.status ?? VoucherLoadStatus.Pending,
                voucherCode: action.payload?.voucherCode ?? null,
                voucherStatus: null,
                error: action.payload?.error ?? null,
            };
        },
        endVoucherClaim(_, action) {
            return {
                status: action.payload?.status ?? VoucherLoadStatus.Idle,
                voucherCode: null,
                voucherStatus: null,
                error: null,
            };
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // Please read https://github.com/kirill-konshin/next-redux-wrapper?tab=readme-ov-file#state-reconciliation-during-hydration
            .addCase(HYDRATE, (state, action: PayloadAction<ClaimVoucherInput, typeof HYDRATE>) => {
                state.status = VoucherLoadStatus.Idle;
                state.voucherCode = action.payload.voucherCode;
                state.error = null;
            })
            .addCase(claimVoucher.pending, (state) => {
                state.status = VoucherLoadStatus.Pending;
            })
            .addCase(claimVoucher.fulfilled, (state, action) => {
                state.status = VoucherLoadStatus.Idle;
                state.voucherStatus = action.payload.claimVoucher;
                state.error = null;
            })
            .addCase(claimVoucher.rejected, (state, action) => {
                state.status = VoucherLoadStatus.Failed;
                if (action.payload instanceof Error) {
                    // For example this could be validation error handling
                    state.error = action?.payload?.message;
                } else {
                    state.error = action.error.message;
                }
            });
    },
});

export const { startVoucherClaim, endVoucherClaim } = voucheSlice.actions;
export const ClaimVoucherReducer = voucheSlice.reducer;
export default voucheSlice;
