import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import {
    GetReleasesProductFormatsQuery,
    GetReleasesProductFormatsQueryVariables,
} from '@srnade/web/__generated__/graphql';
import { GET_RELEASES } from '@srnade/web/services';
import client from 'clients/apollo';
import { HYDRATE } from 'next-redux-wrapper';

export enum ProductFormatsStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Success = 'Success',
    Failed = 'Failed',
}

export type ProductListKeys = 'headline' | 'hero';

export interface ProductFormatsState {
    productFormats: {
        [key in ProductListKeys]?: GetReleasesProductFormatsQuery['productFormats'] | null;
    };
    status: {
        [key in ProductListKeys]?: ProductFormatsStatus;
    };
    error: {
        [key in ProductListKeys]?: string | null | undefined;
    };
}

const initialState: ProductFormatsState = {
    productFormats: {},
    status: {},
    error: {},
};

export const fetchProductFormats: AsyncThunk<
    { data: GetReleasesProductFormatsQuery; key: string },
    { variables: GetReleasesProductFormatsQueryVariables; key: string },
    AsyncThunkConfig
> = createAsyncThunk('products/fetchProductFormat', async ({ variables, key }, thunkApi) => {
    try {
        const response = await client.query<GetReleasesProductFormatsQuery, GetReleasesProductFormatsQueryVariables>({
            query: GET_RELEASES,
            fetchPolicy: 'no-cache',
            variables,
        });
        return { data: response.data, key };
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

const productFormatsSlice = createSlice({
    name: 'products',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        clearProductFormats(_, action: PayloadAction<{ key: string }>) {
            const { key } = action.payload;
            return {
                productFormats: {
                    [key]: null,
                },
                error: {
                    [key]: null,
                },
                status: {
                    [key]: ProductFormatsStatus.Idle,
                },
            };
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // Please read https://github.com/kirill-konshin/next-redux-wrapper?tab=readme-ov-file#state-reconciliation-during-hydration
            .addCase(
                HYDRATE,
                (
                    state,
                    action: PayloadAction<
                        { productFormats: GetReleasesProductFormatsQuery; key: ProductListKeys },
                        typeof HYDRATE
                    >,
                ) => {
                    state.status[action.payload.key] = ProductFormatsStatus.Pending;
                    state.productFormats[action.payload.key] = action.payload.productFormats.productFormats;
                    state.error[action.payload.key] = null;
                },
            )
            .addCase(fetchProductFormats.pending, (state, action) => {
                const key = action.meta.arg.key;
                state.status[key] = ProductFormatsStatus.Pending;
            })
            .addCase(fetchProductFormats.fulfilled, (state, action) => {
                const { key, data } = action.payload;
                const { productFormats } = data;
                const currentProductFormats = state.productFormats[key];
                const newProductFormats = productFormats;

                state.status[key] = ProductFormatsStatus.Success;

                if (!currentProductFormats || !newProductFormats?.pageInfo.hasPreviousPage) {
                    state.productFormats[key] = data.productFormats;
                } else {
                    const newEdges = [...currentProductFormats.edges, ...newProductFormats.edges];
                    const newPageInfo = newProductFormats.pageInfo || currentProductFormats.pageInfo;

                    state.productFormats[key] = {
                        edges: newEdges,
                        pageInfo: newPageInfo,
                    };
                }
                state.error[key] = null;
            })
            .addCase(fetchProductFormats.rejected, (state, action) => {
                const key = action.meta.arg.key;
                state.status[key] = ProductFormatsStatus.Failed;
                if (action.payload instanceof Error) {
                    // For example this could be validation error handling
                    state.error[key] = action?.payload?.message;
                } else {
                    state.error[key] = action.error.message;
                }
            });
    },
});

export const { clearProductFormats } = productFormatsSlice.actions;
export const ProductFormatsReducer = productFormatsSlice.reducer;
export default productFormatsSlice;
