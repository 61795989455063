import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import { FileType, replaceUnderscores } from '@srnade/component-ui';
import { formikFields as form, Typography, Icon } from '@srnade/component-ui';

import { RewardItemProps } from '@srnade/web/types';
import { removeWhitespaceAndDot } from '@srnade/web/utils';

export function RewardItem({ item }: RewardItemProps): JSX.Element {
    const { fileName, readableFileSize, type, playlist, src } = item;
    const formattedFilename = removeWhitespaceAndDot(fileName);
    const fileUploadCompleted = !!playlist || !!src;
    const { t } = useTranslation('create-pressing');

    return (
        <div className="tablet:flex tablet:items-center w-full">
            <div className="flex justify-start items-center w-full">
                <div className="w-[4.7rem] flex items-center">
                    {type === FileType.Audio && <Icon icon="audio-item" size={47} />}
                    {type === FileType.Video && <Icon icon="video" size={47} />}
                    {(type === FileType.Document || type === FileType.Image) && <Icon icon="file" size={47} />}
                    {type === FileType.Other && <Icon icon="zip" size={47} />}
                </div>
                <div className="ml-[1.6rem]">
                    <div className="tablet:w-[20rem] laptop:w-[45rem] tablet:mr-[0.5rem]">
                        <Typography data-testid="reward-file-name" variant="h4" className="line-clamp-1">
                            {replaceUnderscores(fileName)}
                        </Typography>
                        <Typography
                            data-testid="reward-file-size"
                            className={clsx({ italic: !fileUploadCompleted })}
                            variant="h4"
                        >
                            {!fileUploadCompleted ? t('loading') : readableFileSize}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="tablet:flex w-full mt-[1rem] tablet:mt-0 laptop:mt-[1rem]">
                <form.TextField
                    name={`${formattedFilename}-reward`}
                    type="text"
                    label={t('pages.rewards.label')}
                    placeholder={t('pages.rewards.label')}
                    className="w-full"
                    required
                    backgroundWhite
                />
            </div>
        </div>
    );
}
