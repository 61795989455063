import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_MARKETING = gql`
    mutation SubscribeToMarketing($input: SubscribeToMarketingInput!) {
        subscribeToMarketing(input: $input)
    }
`;

export const SET_AS_MARKETING_CONTACT = gql`
    mutation SetAsMarketingContact($input: SubscribeToMarketingInput!) {
        setAsMarketingContact(input: $input)
    }
`;
