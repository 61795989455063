import { gql } from '@apollo/client';
import { Account, AccountRelay } from '@srnade/web/types';
/**
 * Types
 */

export interface FindAccountsData {
    findAccounts: AccountRelay;
}

export interface UpdateAccountData {
    updateAccount: Account;
}

export interface ApprovedArtistsData {
    findApprovedArtists: Account[];
}

/**
 * Fragments
 */

export const ACCOUNT_FRAGMENT = gql`
    fragment account on Account {
        id
        isActive
        isDeleted
        type
        status
        name
        username
        genres
        bio
        missionStatement
        socials
        avatar {
            id
            url
            width
            height
        }
        tags
    }
`;

export const ACCOUNT_TILE_FRAGMENT = gql`
    fragment AccountTileFragment on Account {
        id
        type
        status
        name
        username
        genres
        avatar {
            id
            url
        }
        tags
    }
`;

/**
 * Mutations
 */

export const UPDATE_AVATAR = gql`
    mutation UpdateAvatar($input: CreateImageInput!) {
        updateAvatar(input: $input) {
            avatar {
                id
                url
            }
        }
    }
`;

export const UPDATE_ACCOUNT = gql`
    mutation UpdateAccount($id: String!, $user: UpdateUserInput, $account: UpdateAccountInput!) {
        updateAccount(id: $id, user: $user, account: $account) {
            ...account
        }
    }
    ${ACCOUNT_FRAGMENT}
`;

/**
 * Queries
 */

export const FIND_ACCOUNT = gql`
    query FindAccount($id: String, $username: String) {
        findAccount(id: $id, username: $username) {
            ...account
        }
    }
    ${ACCOUNT_FRAGMENT}
`;

export const FIND_ACCOUNT_ID = gql`
    query FindAccountId($id: String, $username: String) {
        findAccount(id: $id, username: $username) {
            id
        }
    }
`;

export const FIND_TOP_ACCOUNTS = gql`
    query FindTopAccounts($first: Float, $type: AccountType!, $tags: [AccountTag!], $status: AccountStatus) {
        findAccounts(first: $first, type: $type, tags: $tags, status: $status) {
            edges {
                cursor
                node {
                    ...AccountTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${ACCOUNT_TILE_FRAGMENT}
`;

// @todo use a specific fragment based on a condition using directive
// this query is used in many places but not all places require all the fields that the `ACCOUNT_FRAGMENT` returns.
export const FIND_ACCOUNTS = gql`
    query FindAccounts(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: JSON
        $type: AccountType!
        $username: String
        $tags: [AccountTag!]
        $status: AccountStatus
    ) {
        findAccounts(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            type: $type
            tags: $tags
            username: $username
            status: $status
        ) {
            edges {
                cursor
                node {
                    ...account
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${ACCOUNT_FRAGMENT}
`;

export const AVAILABLE_ARTIST_LETTERS = gql`
    query AvailableArtistLetters {
        availableArtistLetters
    }
`;

export const LIST_APPROVED_ARTISTS_NAV = gql`
    query ListApprovedArtistsNav {
        findApprovedArtists {
            id
            name
            username
            tags
        }
    }
`;

export const COUNT_PRODUCTS = gql`
    query ProductCount($accountId: String!) {
        countProducts(accountId: $accountId)
    }
`;

export const IS_MEMBER = gql`
    query IsMember($accountId: String!) {
        isMember(accountId: $accountId)
    }
`;
