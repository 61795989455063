import React, { useCallback, useEffect, useRef, useState } from 'react';
import videojs from '@mux/videojs-kit';
import clsx from 'clsx';
import { BaseComponentProps } from '@srnade/component-ui';

import '@mux/videojs-kit/dist/index.css';

interface MuxPlayerOptions {
    autoplay: boolean;
    loop: boolean;
    muted: boolean;
    controls: boolean;
}

interface MuxPlayerProps extends Omit<BaseComponentProps, 'children'> {
    src: string;
    options?: MuxPlayerOptions;
    optimiseForAudio?: boolean;
}

const defaultOptions: MuxPlayerOptions = {
    autoplay: false,
    loop: false,
    muted: false,
    controls: true,
};

export const MuxPlayer = ({ src, options = defaultOptions, optimiseForAudio = false, className }: MuxPlayerProps) => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const playerRef = useRef(null);

    useEffect(() => {
        const video = playerRef.current;

        if (playerRef && !initialized) {
            // Note: we currently aren't passing any meta data to mux which means the metrics
            // won't be as good as we'd like. We should populate these by passing the nft object
            // into this component then parsing the artist, title etc.
            // See https://docs.mux.com/guides/data/monitor-video-js#2-initialize-mux-data
            const player = videojs(playerRef.current, {
                bigPlayButton: false,
                plugins: {
                    mux: {
                        debug: false,
                        data: {
                            env_key: process.env.NEXT_PUBLIC_MUX_ENV_KEY,
                        },
                    },
                },
                timelineHoverPreviews: true,
                preload: 'auto',
                inactivityTimeout: optimiseForAudio ? 0 : 2000,
                playsinline: options.autoplay, // Safari autoplay option @todo Should this be `playsInline`??
                ...options,
            });
            // Hide the controls on first load/play for video, for a cleaner user experience (they will still appear on hover)
            if (!optimiseForAudio) {
                player.one('playing', () => {
                    player.userActive(false);
                });
            }
            setInitialized(true);
        }
        return function cleanup() {
            if (video) {
                videojs(video).dispose();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerRef]);

    return (
        <video
            id="player"
            ref={playerRef}
            className={clsx('video-js vjs-fluid bg-transparent', className)}
            controls
            preload="auto"
            width="100%"
            data-setup="{}"
        >
            <source src={src} type="video/mux" />
        </video>
    );
};

MuxPlayer.displayName = 'MuxPlayer';
