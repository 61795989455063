import { BaseEntity } from './base-entity.type';

export enum IdentityManagementSystem {
    Auth0 = 'Auth0',
    Okta = 'Okta',
}

interface Attributes {
    /**
     * User id
     */
    id?: string;
    /**
     * First name
     */
    firstName: string;
    /**
     * Last name
     */
    lastName: string;
    /**
     * Email
     */
    email: string;
    /**
     * Is email verified (derived from Auth0)
     */
    isEmailVerified?: boolean;
    /**
     * Schema version, used to determine provenance (optional)
     */
    schemaVersion?: string;
    /**
     * Country
     */
    country?: string;
    /**
     * User is happy to receive marketing emails
     */
    acceptsMarketing: boolean;
    /**
     * Identity management system (IDM), i.e 'Auth0'
     */
    idm?: IdentityManagementSystem;
    /**
     * IDM id
     */
    idmId?: string;
}

interface Relations {
    /**
     * Accounts
     */
    accounts: any; // Account[];
    /**
     * Wallets
     */
    wallets: any; // Wallet[];
    /**
     * Default wallet
     */
    defaultWallet?: any; // Wallet;
}

export type User = BaseEntity & Attributes & Relations;

export type CreateUserInput = Attributes;

export type CreateUserParams = Attributes & Relations;

export type UpdateUserInput = Partial<Attributes>;

export type UpdateUserParams = Partial<Attributes>;
