import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import Marquee from 'react-fast-marquee';
import { Button, Icon, Typography } from '@srnade/component-ui';

import { Container, Link } from '@srnade/web/components';

export function SubscribeBanner() {
    const { t } = useTranslation('components', { keyPrefix: 'subscribeBanner' });

    const renderMarqueeContent = (headingCount: number, mobile?: boolean) => {
        return (
            <>
                <Typography key={headingCount} variant="outline" white uppercase>
                    {t('marquee.outlineHeading')}
                </Typography>
                <Typography variant="h1" className="text-white ml-[0.96rem]" uppercase>
                    {t('marquee.heading')}
                </Typography>
                <div className="flex items-center fill-white stroke-2 ml-[0.4rem]">
                    <Icon icon="arrow-right-up" size={mobile ? 26 : 32} />
                </div>
            </>
        );
    };

    return (
        <div className="bg-black h-[38rem] tablet:h-[28rem] laptop:h-[26rem]">
            <div className="flex flex-col items-center py-[2rem]">
                <Marquee gradient={false} className="overflow-hidden">
                    {[1, 2, 3, 4].map((headingCount) => (
                        <div key={headingCount} className="flex justify-center mx-[3rem]">
                            <div className="flex laptop:hidden">{renderMarqueeContent(headingCount, true)}</div>
                            <div className="hidden laptop:flex">{renderMarqueeContent(headingCount)}</div>
                        </div>
                    ))}
                </Marquee>
            </div>
            <Container className="mt-[4rem]">
                <div className="flex justify-center flex-wrap gap-x-[6rem] gap-y-[4rem] tablet:gap-x-[2rem]">
                    <Typography
                        variant="subheading"
                        className="text-white tracking-normal desktop:max-w-[468px] tablet:max-w-[482px]"
                        data-testid="subscribe-banner-subheading"
                    >
                        {t('banner.subheading')}
                    </Typography>
                    <Link href="/collectors-club" passHref legacyBehavior>
                        <Button
                            className="w-full tablet:max-w-[231px] desktop:max-w-[295px]"
                            text={t('banner.button')}
                            theme="dark"
                        />
                    </Link>
                </div>
            </Container>
        </div>
    );
}
