import React from 'react';

import { Typography, Tooltip } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';

interface DownloadAudioUnavailabledProps {
    limit: number;
}

export function DownloadAudioUnavailable({ limit }: DownloadAudioUnavailabledProps) {
    const { t } = useTranslation('components', { keyPrefix: 'audioDownloadCounter' });

    return (
        <div data-testid="download-audio-unavailable" className="flex justify-start items-center gap-[0.323rem]">
            <Typography
                variant="bodyGrey"
                className="text-darkGray text-[1.5rem] leading-[2rem]"
                htmlContent
                data-testid="download-audio-unavailable-text"
            >
                {t('unavailableDownloads')}
            </Typography>

            <Tooltip className="ml-[0.3rem]" data-testid="download-audio-unavailable-tooltip" active="aboveOffCenter">
                {t('unavailableDownloadsTooltipContent', {
                    limit,
                })}
            </Tooltip>
        </div>
    );
}
