import React from 'react';
import clsx from 'clsx';

import { GTM_ID } from '@srnade/component-ui';

interface ContainerProps {
    className?: string;
    children: any;
    centeredContent?: boolean;
    dataTestId?: string;
    gtmId?: string;
}

// adds responsive max width to content within the container
export function Container({ className, children, centeredContent, dataTestId, gtmId }: ContainerProps) {
    const containerClasses =
        'mx-auto px-[2rem] tablet:px-[5rem] laptop:px-[3rem] desktop:px-[10rem] tablet:max-w-screen-tablet laptop:max-w-screen-laptop desktop:max-w-screen-desktop';
    const centeredClasses = 'w-full flex items-center flex-col';
    const gtmAttr = !!gtmId ? { [GTM_ID]: gtmId } : {};

    return (
        <div
            data-testid={dataTestId}
            className={clsx(className, containerClasses, { [centeredClasses]: centeredContent })}
            {...gtmAttr}
        >
            {children}
        </div>
    );
}
