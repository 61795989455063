import type MuxPlayerElement from '@mux/mux-player';
import MuxPlayer from '@mux/mux-player-react';
import { time } from '@srnade/component-ui';
import { useAuth } from '@srnade/web/auth';
import { getTokenExpiryFromUrl } from '@srnade/web/utils';
import { useEffect, useState, CSSProperties, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';

interface MuxStreamablePlayerProps {
    audioOnly?: boolean;
    onGetStreamableUrl: () => Promise<string>;
    posterUrl: string;
    src: string;
    assetId: string;
    title: string;
    metadata?: {
        [k: string]: any;
    };
}

interface MuxStreamablePlayerStyles extends CSSProperties {
    '--seek-backward-button': string;
    '--seek-forward-button': string;
    '--pip-button': string;
    '--cast-button': string;
    '--playback-rate-button': string;
    '--rendition-selectmenu': string;
}

export const MuxStreamablePlayer = forwardRef<{ pause(): void }, MuxStreamablePlayerProps>(function MuxStreamablePlayer(
    { src, audioOnly, onGetStreamableUrl, posterUrl, title, assetId, metadata },
    setParentRef,
) {
    const [srcUrl, setSrcUrl] = useState<string>();
    const [currentTime, setCurrentTime] = useState<number | undefined>(0);
    const [autoPlay, setAutoPlay] = useState(false);
    const [tokenExpiry, setTokenExpiry] = useState<number | null>(null);
    const refreshTokenTimerRef = useRef<NodeJS.Timeout>();
    const innerPlayerRef = useRef<MuxPlayerElement>(null);
    const { user } = useAuth();

    const customStyles: MuxStreamablePlayerStyles = {
        '--seek-backward-button': 'none',
        '--seek-forward-button': 'none',
        '--pip-button': 'none',
        '--cast-button': 'none',
        '--playback-rate-button': 'none',
        '--rendition-selectmenu': 'none',
    };

    const clearRefreshTokenTimer = useCallback(() => {
        if (refreshTokenTimerRef.current) {
            clearTimeout(refreshTokenTimerRef.current);
            refreshTokenTimerRef.current = undefined;
        }
    }, []);

    const refreshStreamingUrl = useCallback(async () => {
        if (onGetStreamableUrl) {
            clearRefreshTokenTimer();
            const url = await onGetStreamableUrl();
            if (url) {
                setCurrentTime(innerPlayerRef?.current?.currentTime ?? 0);
                setSrcUrl(url);
            } else {
                refreshStreamingUrl();
            }
        }
    }, [clearRefreshTokenTimer, onGetStreamableUrl]);

    useEffect(() => {
        if (tokenExpiry && !refreshTokenTimerRef.current && refreshStreamingUrl) {
            refreshTokenTimerRef.current = setTimeout(async () => {
                refreshStreamingUrl();
            }, tokenExpiry - time.TEN_SECONDS_MS);
        }
        return () => clearRefreshTokenTimer();
    }, [tokenExpiry, refreshStreamingUrl, clearRefreshTokenTimer]);

    useEffect(() => {
        if (src) {
            const tokenExpiryFromUrl = getTokenExpiryFromUrl(src);
            if (tokenExpiryFromUrl) {
                const expiry = tokenExpiryFromUrl - Date.now();
                if (expiry - time.TEN_SECONDS_MS > 0) {
                    setSrcUrl(src);
                } else {
                    refreshStreamingUrl();
                }
            }
        }
    }, [refreshStreamingUrl, src]);

    useEffect(() => {
        if (srcUrl) {
            const tokenExpiryFromUrl = getTokenExpiryFromUrl(srcUrl);
            if (tokenExpiryFromUrl) {
                setTokenExpiry(tokenExpiryFromUrl - Date.now());
            }
        }
    }, [srcUrl]);

    useEffect(() => {
        if (innerPlayerRef?.current && currentTime) {
            innerPlayerRef.current.currentTime = currentTime;
        }
    }, [innerPlayerRef, currentTime, srcUrl]);

    useImperativeHandle(setParentRef, () => {
        return {
            pause() {
                if (innerPlayerRef.current && !innerPlayerRef.current?.paused) {
                    innerPlayerRef.current.pause();
                }
            },
        };
    }, []);

    const handleOnEnded = () => {
        setCurrentTime(0);
        setAutoPlay(false);
    };

    const handleOnPlaying = () => setAutoPlay(true);
    const handleOnPause = () => setAutoPlay(false);

    return (
        <MuxPlayer
            src={srcUrl}
            streamType="on-demand"
            audio={audioOnly}
            poster={posterUrl}
            primaryColor="#FFF"
            accentColor="#FA7DC9"
            style={customStyles}
            autoPlay={autoPlay}
            ref={innerPlayerRef}
            onEnded={handleOnEnded}
            onPlaying={handleOnPlaying}
            onPause={handleOnPause}
            metadataVideoId={assetId}
            metadataVideoTitle={title}
            metadataViewerUserId={user?.id}
            metadata={metadata}
            className="tablet:w-[90%] tablet:h-[80%]"
            onError={refreshStreamingUrl}
        />
    );
});
