import { lookup } from 'mime-types';
import { readFile, stat } from 'fs/promises';
import getBlobDuration from 'get-blob-duration';
import sizeOf from 'buffer-image-size';
import { IAudioMetadata, parseFile } from 'music-metadata';
import * as mm from 'music-metadata/lib/core';
import { extname } from 'path';

import { ValidAudioFileFormat, InvalidFileErrorCode, FileType, bpsToKbps, time } from '@srnade/component-ui';
import type {
    CombinedFileConstraints,
    FileConstraintsValidationResponse,
    BasicFileProperties,
} from '@srnade/component-ui';

/**
 * Returns file metadata for Audio or Video files
 * @param file File | string
 * @returns Promise<IAudioMetadata>
 */
export const getMediaFileMetadata = async (file: File | string): Promise<IAudioMetadata> => {
    const options = {
        duration: true,
    };
    const mimeType = getFileMimeType(file);
    if (typeof file !== 'string') {
        const buffer = await getFileBufferData(file);
        return await mm.parseBuffer(buffer, mimeType, options);
    } else {
        return await parseFile(file);
    }
};

/**
 * Returns the dimensions of an image file
 * @param file System file
 * @returns Width and height
 */
export const getDimensions = async (file: File | string): Promise<{ width: number; height: number }> => {
    if (typeof file !== 'string') {
        const fileType = getFileType(file);
        if (fileType !== FileType.Image) {
            throw 'Cannot get dimensions of file as not of type image';
        }
        const bufferData = await getFileBufferData(file);
        const { width = 0, height = 0 } = sizeOf(bufferData);
        return { width, height };
    } else {
        const bufferData = await readFile(file);
        const { width = 0, height = 0 } = sizeOf(bufferData);
        return { width, height };
    }
};
/**
 * Returns the duration of an audio or video file
 * @param file System file
 * @returns Duration in milliseconds
 */
export const getDuration = async (file: File): Promise<number> => {
    const fileType = getFileType(file);
    if (fileType !== FileType.Audio && fileType !== FileType.Video) {
        throw 'Cannot get duration of file as not of type audio or video';
    }
    const seconds = await getBlobDuration(file);
    return Math.round(seconds * time.ONE_SECOND_MS);
};

/**
 * Returns file extension based on the file name
 * @param file
 * @returns string
 */
export const getFileExtension = (file: File | string): string => {
    if (typeof file !== 'string') {
        return file.name.split('.')?.pop() as string;
    } else {
        return extname(file).replace('.', '');
    }
};

/**
 * Returns file size
 * @param file
 * @returns number
 */
export const getFileSize = async (file: File | string): Promise<number> => {
    if (typeof file !== 'string') {
        return file.size;
    } else {
        const stats = await stat(file);
        return stats?.size;
    }
};

/**
 * Extracts basic file properties
 * @param file File | string
 * @returns BasicFileProperties
 */
// TOOD SSR - Fix this
export const getFileBasicProperties = async (file: File | string): Promise<BasicFileProperties> => {
    const fileProperties = {} as BasicFileProperties;

    const fileType = getFileType(file);
    fileProperties.extension = getFileExtension(file);
    fileProperties.size = await getFileSize(file);
    fileProperties.mimeType = getFileMimeType(file);

    if (fileType === FileType.Audio || fileType === FileType.Video) {
        const {
            format: { duration, bitrate },
        } = await getMediaFileMetadata(file);
        fileProperties.durationMilliseconds = Math.round((duration || 0) * time.ONE_SECOND_MS);
        fileProperties.kbpsRate = Math.round(bpsToKbps(bitrate || 0));
    } else if (fileType === FileType.Image) {
        const { width, height } = await getDimensions(file);
        fileProperties.width = width;
        fileProperties.height = height;
    }

    return { ...fileProperties, fileType };
};

/**
 * Validates a file against the provided the constraints
 * @param file
 * @param fileConstraints
 * @returns
 */
export const validateFileConstraints = async (
    file: File | string,
    fileConstraints: CombinedFileConstraints,
): Promise<FileConstraintsValidationResponse> => {
    try {
        const basicFileProperties = await getFileBasicProperties(file);
        return isValidFile(basicFileProperties, fileConstraints);
    } catch (error) {
        return { valid: false, errorCode: InvalidFileErrorCode.InvalidFileOrPath };
    }
};

/**
 * Validates file properties against the constraints
 * @param fileProps BasicFileProperties
 * @param fileConstraints CombinedFileConstraints
 * @returns FileConstraintsValidationResponse
 */
export const isValidFile = (
    fileProps: BasicFileProperties,
    fileConstraints: CombinedFileConstraints,
): FileConstraintsValidationResponse => {
    const {
        validFormats,
        maxFileSizeBytes = 0,
        maxDurationMilliSeconds = 0,
        minKbps = 0,
        minHeight = 0,
        maxHeight = 0,
        minWidth = 0,
        maxWidth = 0,
    } = fileConstraints || {};
    const { extension, size = 0, durationMilliseconds = 0, width = 0, height = 0, kbpsRate = 0 } = fileProps || {};
    const fileExtension = extension.toUpperCase() as (typeof validFormats)[number];

    if (!validFormats.includes(fileExtension)) {
        return {
            valid: false,
            errorCode: InvalidFileErrorCode.InvalidFormat,
            actualValue: fileExtension,
            expectedValue: validFormats,
        };
    }

    if (size > maxFileSizeBytes) {
        return {
            valid: false,
            errorCode: InvalidFileErrorCode.InvalidSize,
            actualValue: size,
            expectedValue: maxFileSizeBytes,
        };
    }

    if (durationMilliseconds > maxDurationMilliSeconds) {
        return {
            valid: false,
            errorCode: InvalidFileErrorCode.InvalidDuration,
            actualValue: durationMilliseconds,
            expectedValue: maxDurationMilliSeconds,
        };
    }

    if (fileExtension === ValidAudioFileFormat.MP3 && kbpsRate < minKbps) {
        return {
            valid: false,
            errorCode: InvalidFileErrorCode.InvalidBitRate,
            actualValue: kbpsRate,
            expectedValue: minKbps,
        };
    }

    if (width < minWidth || height < minHeight) {
        return {
            valid: false,
            errorCode: InvalidFileErrorCode.InvalidMinDimensions,
            actualValue: { width, height },
            expectedValue: { minWidth, minHeight },
        };
    }

    if (width > maxWidth || height > maxHeight) {
        return {
            valid: false,
            errorCode: InvalidFileErrorCode.InvalidMaxDimensions,
            actualValue: { width, height },
            expectedValue: { maxWidth, maxHeight },
        };
    }
    return { valid: true };
};

/**
 * Returns file buffer data
 * @param file File | string
 * @returns Promise<Buffer>
 */
export const getFileBufferData = async (file: File): Promise<Buffer> => {
    const arrayBuffer = await file.arrayBuffer();
    const bufferData = Buffer.from(arrayBuffer);
    return bufferData;
};

/**
 * Returns file mime type
 * @param file File | string
 * @returns
 */
export const getFileMimeType = (file: File | string): string => {
    return typeof file !== 'string' ? file?.type : (lookup(file) as string);
};
/**
 * Return file type based on the file mime type
 * @param file File | string
 * @returns File type
 */
export const getFileType = (file: File | string): FileType => {
    const fileType = getFileMimeType(file);
    switch (true) {
        case fileType.includes('audio'):
            return FileType.Audio;
        case fileType.includes('image'):
            return FileType.Image;
        case fileType.includes('video') && !fileType.includes('quicktime'):
            return FileType.Video;
        case fileType.includes('pdf'):
            return FileType.Document;
        default:
            return FileType.Other;
    }
};

/**
 * Determine the sub-folder to place files within the s3 bucket
 * @param fileType FileType
 * @returns
 */
export function getFileUploadLocation(fileType: FileType): string {
    switch (fileType) {
        case FileType.Audio:
            return 'audio';
        case FileType.Document:
            return 'documents';
        case FileType.Other:
            return 'other';
        case FileType.Image:
            return 'images';
        case FileType.Video:
            return 'videos';
        default:
            throw new Error(`Invalid filetype -- ${fileType}`);
    }
}

/**
 * Determine the sub-folder to place files within the s3 bucket for rewards
 * @param fileType FileType
 * @returns
 */
export function getFileUploadLocationForRewards(isRewardType: boolean, fileType: FileType): string {
    return (isRewardType ? 'rewards/' : '') + getFileUploadLocation(fileType);
}

/**
 * Remove whitespace and dot symbol
 * @param fileName
 * @returns formatted filename without whitespace and dot.
 */
export const removeWhitespaceAndDot = (fileName: string): string => {
    /* eslint-disable-next-line no-useless-escape */
    return fileName.replace(/[ .\[\]]+/g, '');
};
