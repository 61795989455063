import { ImageTile, NewFilePreviewType } from '@srnade/web/components';

import { GetEditionFormatQuery } from '@srnade/web/__generated__/graphql';
import { ImageFilePreview } from '../NewFilePreview/components/ImageFilePreview';
import { VideoFilePreview } from '../NewFilePreview/components/VideoFilePreview';

type NonNullableEditionFormat = Exclude<GetEditionFormatQuery['editionFormat'], null | undefined>;

interface CollectibleDetailsProps {
    editionFormat: NonNullableEditionFormat;
}

export const EditionCollectibleDetails = ({ editionFormat }: CollectibleDetailsProps) => {
    const { collectible, formatInstanceId, title, artworkUrl } = editionFormat;
    const assetId = (collectible?.audio || collectible?.video)?.assetId || '';

    const filePreviewType = editionFormat.collectible?.video
        ? NewFilePreviewType.Video
        : editionFormat.collectible?.image
          ? NewFilePreviewType.Image
          : NewFilePreviewType.Unknown;

    switch (filePreviewType) {
        case NewFilePreviewType.Video:
            return (
                <VideoFilePreview
                    assetId={assetId}
                    formatInstanceId={formatInstanceId || ''}
                    loop
                    muted
                    controls
                    autoPlay
                />
            );
        case NewFilePreviewType.Image:
            if (artworkUrl && title) {
                return <ImageTile src={artworkUrl} alt={title} />;
            }
            return <ImageFilePreview assetId={assetId} formatInstanceId={formatInstanceId || ''} imageAlt={title} />;

        default:
            return null;
    }
};
