import { gql } from '@apollo/client';
import { FirstOwnerRelay } from '../types';

/**
 * Fragments
 */
export const FIRST_OWNER_FRAGMENT = gql`
    fragment firstOwner on FirstOwner {
        id
        name
        edition {
            id
            sequence
        }
        account {
            id
            username
            avatar {
                id
                url
            }
        }
    }
`;

/**
 * DTOs
 */
export interface FindFirstOwnersData {
    findFirstOwners: FirstOwnerRelay;
}

/**
 * Queries
 */

export const FIND_FIRST_OWNERS = gql`
    query FindFirstOwners($productSlug: String!, $first: Float!, $after: String) {
        findFirstOwners(productSlug: $productSlug, first: $first, after: $after) {
            edges {
                node {
                    ...firstOwner
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
    ${FIRST_OWNER_FRAGMENT}
`;
