import React, { forwardRef, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import BaseLink, { LinkProps as BaseLinkProps } from 'next/link';
import { useRouter } from 'next/router';

type LinkProps = React.ForwardRefExoticComponent<
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseLinkProps> &
        BaseLinkProps & {
            children?: React.ReactNode;
        } & React.RefAttributes<HTMLAnchorElement>
>;

const Link: LinkProps = forwardRef((props, ref) => {
    const router = useRouter();

    // disable prefetching on hover
    useEffect(() => {
        const prefetch = router.prefetch;
        router.prefetch = async () => {};
        return () => {
            router.prefetch = prefetch;
        };
    }, [router]);

    // defaults prefetch to false if `prefetch` is not true
    return (
        <BaseLink {...props} prefetch={props.prefetch ?? false} ref={ref}>
            {props.children}
        </BaseLink>
    );
});

Link.displayName = 'Link';

export { Link };
