import { gql } from '@apollo/client';
import { Product } from '@srnade/component-ui';
// import { DraftEntity, DraftRelay, Product } from '@srnade/component-ui';

/**
 * Types
 */
export interface GetMyDraftData {
    getMyDraft: {};
}

export interface GetMyDraftsData {
    getMyDrafts: {};
}

export interface ConvertMyDraftProductData {
    convertMyDraftProduct: Product;
}

/**
 * Fragments
 */

export const DRAFT_FRAGMENT = gql`
    fragment draft on Draft {
        id
        status
        type
        updated
        data {
            data {
                pressingType
                coverArt {
                    imageId
                    url
                }
                credit {
                    artworkBy
                    performedBy
                    producedBy
                    recordLabel
                    writtenBy
                }
                detail {
                    description
                    genres
                    limit
                    pressingType
                    subGenres
                    termsAndConditionsUrl
                    title
                    upc
                }
                listing {
                    currency
                    price
                    releaseDateTime
                    releaseTimeZone
                }
                rewards {
                    displayOrder
                    fileId
                    fileType
                    muxPlaybackId

                    name
                    url
                }
                tracks {
                    audioId
                    isrc
                    muxPlaybackId
                    trackName
                    trackNumber
                }
            }
        }
    }
`;

/**
 * Queries
 */

export const GET_MY_DRAFT = gql`
    query GetMyDraft($id: String!) {
        getMyDraft(id: $id) {
            ...draft
        }
    }
    ${DRAFT_FRAGMENT}
`;

export const GET_MY_DRAFTS = gql`
    query GetMyDrafts($first: Float, $last: Float, $before: String, $after: String, $status: DraftStatus) {
        getMyDrafts(first: $first, last: $last, before: $before, after: $after, status: $status) {
            edges {
                cursor
                node {
                    id
                    data {
                        data {
                            pressingType
                            detail {
                                title
                            }
                            coverArt {
                                url
                            }
                        }
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const CREATE_DRAFT = gql`
    mutation CreateDraft($input: CreateDraftInput!) {
        createDraft(input: $input) {
            id
        }
    }
`;

export const UPDATE_MY_DRAFT = gql`
    mutation UpdateMyDraft($id: String!, $input: UpdateDraftInput!) {
        updateMyDraft(id: $id, input: $input) {
            ...draft
        }
    }
    ${DRAFT_FRAGMENT}
`;

export const CONVERT_MY_DRAFT_PRODUCT = gql`
    mutation ConvertMyDraftProduct($id: String!) {
        convertMyDraftProduct(id: $id) {
            id
            slug
            account {
                username
            }
        }
    }
`;
