import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import { Icon, Typography } from '@srnade/component-ui';

import { Icon as WebIcon, Link, Container } from '@srnade/web/components';

export function BottomFooter() {
    const { t } = useTranslation('footer');

    const renderPrivacyTerms = () => {
        return (
            <>
                <div className="mr-[1rem]">
                    <Link href="/privacy" passHref legacyBehavior>
                        <Typography className="underline hover:no-underline" variant="body">
                            {t('privacy')}
                        </Typography>
                    </Link>
                </div>
                <Typography variant="body">|</Typography>
                <div className="ml-[1rem]">
                    <Link href="/terms" passHref legacyBehavior>
                        <Typography className="underline hover:no-underline" variant="body">
                            {t('terms')}
                        </Typography>
                    </Link>
                </div>
            </>
        );
    };

    const renderSecurePayment = (verticalLine?: boolean) => {
        return (
            <>
                <Icon className="mr-[1rem]" icon="padlock-closed" size={18} />
                <Typography variant="body">{t('securePayment')}</Typography>
                {verticalLine && (
                    <Typography className="mx-[1rem]" variant="body">
                        |
                    </Typography>
                )}
            </>
        );
    };

    const renderPaymentMethods = () => {
        return (
            <>
                <Typography className="grid items-center mr-[1rem]" variant="body">
                    {t('weAccept')}
                </Typography>
                <WebIcon icon="visa" size={32} />
                <WebIcon className="ml-[0.5rem]" icon="mastercard" size={32} />
                <WebIcon className="ml-[0.5rem]" icon="amex" size={32} />
                <WebIcon className="ml-[0.5rem]" icon="union-pay" size={32} />
                {/*<Icon className="ml-[0.5rem]" icon="coinbase" size={32} />*/}
                <Icon className="ml-[0.5rem]" icon="jcb" size={32} />
            </>
        );
    };

    const renderCopyright = () => {
        return (
            <div className="flex justify-center mt-[3rem] laptop:mt-0">
                <Typography variant="body">{t('copyright', { year: new Date().getFullYear() })}</Typography>
            </div>
        );
    };

    return (
        <div className="bg-white">
            <div className="py-[1.5rem] tablet:hidden">
                <div className="flex justify-center">{renderPrivacyTerms()}</div>
                <div className="grid">
                    <div className="flex justify-center mt-[3rem]">
                        <div className="flex items-center">{renderSecurePayment()}</div>
                    </div>
                    <div className="flex justify-center mt-[1rem]">{renderPaymentMethods()}</div>
                    {renderCopyright()}
                </div>
            </div>
            <div className="hidden tablet:flex tablet:justify-center laptop:hidden">
                <div className="py-[1.5rem]">
                    <div className="flex justify-center">{renderPrivacyTerms()}</div>
                    <div className="flex justify-center items-center mt-[3rem]">
                        {renderSecurePayment(true)}
                        {renderPaymentMethods()}
                    </div>
                    {renderCopyright()}
                </div>
            </div>
            <Container className="hidden laptop:flex laptop:items-center laptop:py-[1.5rem] laptop:justify-between">
                <div className="flex">{renderPrivacyTerms()}</div>
                <div className="flex items-center">
                    {renderSecurePayment(true)}
                    {renderPaymentMethods()}
                </div>
                {renderCopyright()}
            </Container>
            <div className="bg-cream h-[117px] tablet:h-[95px]"></div>
        </div>
    );
}
