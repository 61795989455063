import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';

import { Button, Typography } from '@srnade/component-ui';

export function JoinCommunity() {
    const { t } = useTranslation('homepage', { keyPrefix: 'joinCommunity' });

    // Currently the url is stored in a variable but will be stored in CMS in the future
    const url = 'https://discord.com/invite/YaGqegcv5v';

    return (
        <div className="flex justify-center">
            <div className="text-center w-full laptop:max-w-[82rem]">
                <Typography variant="h1">{t('heading')}</Typography>
                <div className="mt-[2rem]">
                    <Typography variant="subheading">
                        <span className="normal-case">{t('subheading')}</span>
                    </Typography>
                </div>
                <div className="mt-[4rem]">
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <Button className="w-full tablet:w-[29.5rem]" type="standard" text={t('button')} />
                    </a>
                </div>
            </div>
        </div>
    );
}
