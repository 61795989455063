import React, { useEffect, useState } from 'react';
import { AnnouncementBar as AnnouncementBarBase, type BaseComponentProps } from '@srnade/component-ui';
import { AccountStatus } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import { useMutation } from '@apollo/client';

import { useAuth } from '@srnade/web/auth';
import { useLocalStorage, useSessionStorage } from '@srnade/web/hooks';
import { RESEND_EMAIL_VERIFICATION_EMAIL } from '@srnade/web/services';
import {
    ResendEmailVerificationEmailMutation,
    ResendEmailVerificationEmailMutationVariables,
} from '@srnade/web/__generated__/graphql';

interface AnnouncementBarProps {
    subscribeBar?: Boolean;
    verifyEmailBar?: Boolean;
}

export function AnnouncementBar({ subscribeBar, verifyEmailBar }: AnnouncementBarProps & BaseComponentProps) {
    const [showSubscribeBar, setShowSubscribeBar] = useState(true);
    const [subscribed] = useLocalStorage('subscribedToNewsletter', '');
    const [closed] = useSessionStorage('announcementBarClosed', '');

    const { t } = useTranslation('components', { keyPrefix: 'announcementBar' });
    const { activeAccount, user, authenticating } = useAuth();
    const [resendEmailVerificationEmail, { data }] = useMutation<
        ResendEmailVerificationEmailMutation,
        ResendEmailVerificationEmailMutationVariables
    >(RESEND_EMAIL_VERIFICATION_EMAIL);

    useEffect(() => {
        closed && setShowSubscribeBar(false);
        subscribed && setShowSubscribeBar(false);
    }, [closed, subscribed]);

    return (
        <>
            {showSubscribeBar && subscribeBar && activeAccount === null && !authenticating && (
                <AnnouncementBarBase
                    type="dark"
                    textCenter={t('subscribeSignup')}
                    linkIcon={false}
                    link={t('subscribeLink')}
                    linkUrl="subscribe"
                />
            )}
            {activeAccount?.status === AccountStatus.Pending && (
                <AnnouncementBarBase type="dark" textCenter={t('accountPending')} removable={false} linkIcon={false} />
            )}
            {verifyEmailBar && activeAccount && user?.isEmailVerified === false && !data && (
                <AnnouncementBarBase
                    type="dark"
                    textCenter={t('verifyEmail')}
                    link={t('verifyEmailLink')}
                    verifyEmail={resendEmailVerificationEmail}
                    removable={false}
                />
            )}
        </>
    );
}
