import React from 'react';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';
import { Card, CardHeader, CardContent } from '@srnade/component-ui';
import type { BaseComponentProps } from '@srnade/component-ui';

import 'react-loading-skeleton/dist/skeleton.css';
export interface SkeletonProductTileProps {
    type?: 'standard' | 'carousel';
}

export function SkeletonProductTile({
    className,
    type = 'standard',
}: SkeletonProductTileProps & BaseComponentProps): JSX.Element {
    return (
        <div className={className} data-testid="skeleton-product-tile">
            <Card
                className={clsx('rounded-2xl p-[1rem]', {
                    'w-full h-[51.5rem] tablet:w-[33.5rem] laptop:w-[29.5rem] laptop:h-[47.5rem]': type === 'standard',
                    'max-w-[29.5rem] h-[47.5rem]': type === 'carousel',
                })}
                border
            >
                <CardHeader>
                    {type === 'carousel' ? (
                        <Skeleton height="275px" />
                    ) : (
                        <>
                            <div className="laptop:hidden">
                                <Skeleton height="315px" />
                            </div>
                            <div className="hidden laptop:grid">
                                <Skeleton height="275px" />
                            </div>
                        </>
                    )}
                </CardHeader>
                <CardContent className="relative w-[4.6rem] h-[4.6rem] ml-[1rem] mt-[1.5rem] rounded-lg bg-gradient-to-t from-gradientGreen via-gradientBlue to-gradientPurple">
                    <div className="absolute left-[0.15rem] bottom-[0.15rem] bg-cream rounded-lg w-[4.35rem] h-[4.35rem]">
                        <div className="flex items-center">
                            <Skeleton
                                className="left-[0.15rem] bottom-[0.0156rem] border border-cream rounded-xl mr-[2rem]"
                                width="40px"
                                height="40px"
                            />
                            <Skeleton width="58px" height="13px" />
                        </div>
                    </div>
                </CardContent>
                <CardContent className="ml-[1rem] mt-[2rem]">
                    <Skeleton className="max-w-[27.3rem]" width="100%" height="17px" />
                    <Skeleton className="mt-[0.7rem] mb-[1rem]" width="173px" height="17px" />
                    <Skeleton width="86px" height="14px" />
                    <Skeleton className="mt-[0.7rem]" width="66px" height="14px" />
                </CardContent>
            </Card>
        </div>
    );
}
