import React, { useContext, useState } from 'react';
import { SplitContext } from '@splitsoftware/splitio-react';
import { useDebounce } from 'react-use';

import { useAuth } from '@srnade/web/auth';
import { Loading } from './Loading.component';

export const LOADING_DELAY_MS = 300;

export function LoadingStateWrapper({ children }) {
    const { authenticating } = useAuth();
    const { isReady: isFeatureFlagReady } = useContext(SplitContext);
    const showChildren = !authenticating && isFeatureFlagReady;
    const [isLoading, setIsLoading] = useState(!showChildren);

    useDebounce(
        () => {
            setIsLoading(!showChildren);
        },
        LOADING_DELAY_MS,
        [showChildren],
    );

    return isLoading ? <Loading /> : children;
}
