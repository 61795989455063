import { useReducer, createContext, useContext } from 'react';
import { GtmPromotion } from '../../types';

/*
    State (store) for Google Analytics and sharing event context and data between pages
*/

type GtmContext = {
    promo: GtmPromotion | undefined;
    pageName: string;
    itemIndex: number;
    savePageName: (name: string) => void;
    saveItemIndex: (index: number) => void;
    startPromo: (promo: GtmPromotion) => void;
    endPromo: () => void;
};

type GtmState = {
    promo: GtmPromotion | undefined;
    pageName: string;
    itemIndex: number;
};

const initialGtmContext: GtmContext = {
    promo: undefined,
    pageName: '',
    itemIndex: 0,
    savePageName: (name) => {},
    saveItemIndex: (index) => {},
    startPromo: (promo) => {},
    endPromo: () => {},
};

export const GtmContext = createContext(initialGtmContext);

enum GtmActionKind {
    SavePageName = 'SavePageName',
    SaveItemIndex = 'SaveItemIndex',
    StartPromo = 'StartPromo',
    EndPromo = 'EndPromo',
}

type GtmAction =
    | { type: GtmActionKind.SavePageName; payload: { pageName: string } }
    | { type: GtmActionKind.SaveItemIndex; payload: { itemIndex: number } }
    | { type: GtmActionKind.StartPromo; payload: { promo: GtmPromotion } }
    | { type: GtmActionKind.EndPromo; payload: undefined };

// Action defines a 'type' and 'payload'
const gtmReducer = (state: GtmState, action: GtmAction) => {
    const { type, payload } = action;
    switch (type) {
        case 'SavePageName':
            return {
                ...state,
                pageName: payload.pageName,
            };
        case 'SaveItemIndex':
            return {
                ...state,
                itemIndex: payload.itemIndex,
            };
        case 'StartPromo':
            return {
                ...state,
                promo: payload.promo,
            };
        case 'EndPromo':
            return {
                ...state,
                promo: undefined,
            };
        default:
            throw Error('Unknown action type in gtmReducer: ' + action.type);
    }
};

export const GtmProvider = ({ children }) => {
    const [state, dispatch] = useReducer(gtmReducer, initialGtmContext);
    const savePageName = (name: string): void => {
        dispatch({
            type: GtmActionKind.SavePageName,
            payload: {
                pageName: name,
            },
        });
    };
    const saveItemIndex = (index: number): void => {
        dispatch({
            type: GtmActionKind.SaveItemIndex,
            payload: {
                itemIndex: index,
            },
        });
    };
    const startPromo = (promo: GtmPromotion): void => {
        dispatch({
            type: GtmActionKind.StartPromo,
            payload: {
                promo: promo,
            },
        });
    };
    const endPromo = (): void => {
        dispatch({
            type: GtmActionKind.EndPromo,
            payload: undefined,
        });
    };
    const value = {
        promo: state.promo,
        pageName: state.pageName,
        itemIndex: state.itemIndex,
        savePageName,
        saveItemIndex,
        startPromo,
        endPromo,
    };
    return <GtmContext.Provider value={value}>{children}</GtmContext.Provider>;
};

export const useGtmContext = () => {
    const context = useContext(GtmContext);
    if (context === undefined) {
        throw new Error('useGtmContext must be used within GtmProvider');
    }

    return context;
};
