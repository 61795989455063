import { Middleware } from '@reduxjs/toolkit';
import { fetchEditionFormat } from './reducers/edition-format';
import { storeTrackDetails } from './reducers/player';

// Redux middleware to be configured into the Store

// This middleware allows for the 'player' slice to store the tracks from the 'edition-format' slice
export const storeTracksMiddleware: Middleware = (storeAPI) => (next) => (action) => {
    let result = next(action);

    // If the fetchEditionFormat action is fulfilled, store the active/streamable tracks in the player state
    if (fetchEditionFormat.fulfilled.match(action)) {
        const { pressing, formatType, artist, title, artworkUrl } = action.payload.editionFormat || {};
        const creator = artist || { name: '', username: '' };
        const hasArtwork = artworkUrl || title;
        const tracks = pressing?.tracks || [];
        const streamableActiveTracks = tracks
            .filter((track) => track.streamable && track.active && !!track.assetId)
            .map((track) => ({
                ...track,
                streamingUrl: null,
            }));
        storeAPI.dispatch(
            storeTrackDetails({
                tracks: streamableActiveTracks ?? [],
                artworkUrl: hasArtwork ? artworkUrl || '' : '',
                artist: creator,
            }),
        );
    }

    return result;
};
