import { gql } from '@apollo/client';

import { ACCOUNT_FRAGMENT } from './accounts.service';

export const REGISTER_MUTATION = gql`
    mutation Register($user: CreateUserInput!, $account: CreateAccountInput!) {
        register(user: $user, account: $account) {
            accessToken
            refreshToken
        }
    }
`;

export const FIND_PROFILE = gql`
    query FindProfile {
        findProfile {
            id
            firstName
            lastName
            country
            isEmailVerified
            accounts {
                ...account
            }
        }
    }
    ${ACCOUNT_FRAGMENT}
`;

export const GET_UPDATED_TOKENS = gql`
    mutation GetUpdatedTokens($refreshToken: String!) {
        getUpdatedTokens(refreshToken: $refreshToken) {
            accessToken
            refreshToken
        }
    }
`;

export const HAS_PERMISSIONS = gql`
    query HasPermissions($keys: [PermissionKey!]!) {
        hasPermissions(keys: $keys)
    }
`;

export const RESEND_EMAIL_VERIFICATION_EMAIL = gql`
    mutation ResendEmailVerificationEmail($voucher: VoucherCodeInput) {
        resendEmailVerificationEmail(voucher: $voucher) {
            sendResult
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(input: { email: $email })
    }
`;

export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            accessToken
            refreshToken
        }
    }
`;
