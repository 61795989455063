import clsx from 'clsx';

import { BonusContent } from '@srnade/web/components';

import { Typography } from '@srnade/component-ui';
import { GetProductFormatQuery } from '@srnade/web/__generated__/graphql';
import { useTranslation } from '@srnade/web/i18n/client';
import { TrackList } from '../TrackList';

interface PressingDetailsProps {
    productFormat: GetProductFormatQuery['productFormat'];
    showBonusContent: boolean;
}

export const ProductPressingDetails = ({ productFormat, showBonusContent }: PressingDetailsProps) => {
    const { t: tTracks } = useTranslation('components', { keyPrefix: 'tracks' });

    if (!productFormat) {
        return null;
    }

    const { pressing } = productFormat;
    const tracks = pressing?.tracks || [];

    return (
        <>
            {(tracks || []).length > 0 && (
                <div className="flex flex-col mt-[4rem] mb-16">
                    <div className="flex flex-row gap-4 justify-between items-center min-h-[5rem]">
                        <Typography variant="bodyBold">
                            {tTracks('title')} {(tracks || []).length > 0 && <>({tracks.length})</>}
                        </Typography>
                        <div className="flex flex-col">
                            <div className={clsx('flex flex-col w-full items-end max-w-[18rem]')}></div>
                        </div>
                    </div>

                    <TrackList tracks={tracks} locked={true} />
                </div>
            )}

            {showBonusContent && <BonusContent locked bonusContents={pressing?.bonusContent || []} />}
        </>
    );
};
