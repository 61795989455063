export enum ParentRecordLabel {
    UniversalMusicGroup = 'UniversalMusicGroup',
    WarnerMusicGroup = 'WarnerMusicGroup',
    SonyMusic = 'SonyMusic',
    Independent = 'Independent',
}

export const ParentRecordLabelHumanized: { [key in ParentRecordLabel]: string } = {
    [ParentRecordLabel.UniversalMusicGroup]: 'Universal Music Group',
    [ParentRecordLabel.WarnerMusicGroup]: 'Warner Music Group',
    [ParentRecordLabel.SonyMusic]: 'Sony Music',
    [ParentRecordLabel.Independent]: 'Independent',
};
