import React from 'react';
import { isValid } from 'date-fns';
import { format, toDate } from 'date-fns-tz';

import { Typography } from '@srnade/component-ui';

import { CountdownTimer } from '@srnade/web/components';

interface ProductCountdownProps {
    dateTime: string | number | Date;
    timeZone: string;
    text: string;
}

export const ProductCountdown = ({ dateTime, timeZone, text }: ProductCountdownProps) => {
    const closeDatetime = toDate(dateTime, { timeZone });

    // NOTE: not using isFuture here as it would convert the date to local time before checking and that is not correct
    if (!isValid(closeDatetime) || !(closeDatetime?.getTime() > Date.now())) {
        return null;
    }

    return (
        <CountdownTimer end={closeDatetime}>
            <Typography variant="body" className="mt-[1.0rem]">
                {text}
            </Typography>
        </CountdownTimer>
    );
};
