import clsx from 'clsx';
import { Link, ExpandableText, Credits } from '@srnade/web/components';
import { Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import { Credits as CreditsType } from '@srnade/web/__generated__/graphql';

interface ProductMusicDetailsProps {
    termsAndConditionsUrl?: string;
    behindTheMusicDesc?: string;
    behindTheMusicTitle?: string;
    credits?: CreditsType | null;
    recordLabel?: string;
}

export const ProductMusicDetails = ({
    termsAndConditionsUrl,
    behindTheMusicDesc,
    behindTheMusicTitle,
    credits,
    recordLabel,
}: ProductMusicDetailsProps) => {
    const { t: tCredits } = useTranslation('components', { keyPrefix: 'credits' });
    const { t } = useTranslation('product-page');

    return (
        <>
            {behindTheMusicDesc && (
                <div data-testid="behindTheMusicDesc">
                    <Typography className={clsx('mt-[4rem]')} variant="bodyBold">
                        {behindTheMusicTitle}
                    </Typography>
                    <ExpandableText className="whitespace-pre-line">{behindTheMusicDesc!}</ExpandableText>
                </div>
            )}
            {termsAndConditionsUrl && (
                <Typography variant="body" className="mt-[3.5rem]" data-testid="termsAndConditions">
                    {t('termsConditionsPrefix')}{' '}
                    <Link
                        href={termsAndConditionsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline hover:no-underline cursor-pointer"
                    >
                        {t('termsConditions')}
                    </Link>
                </Typography>
            )}
            {credits && <Credits className="mt-[4rem]" credits={credits} />}
            {recordLabel && (
                <>
                    <Typography className="text-darkGray" variant="body">
                        {tCredits('recordLabel')}
                    </Typography>
                    <Typography data-testid="record-label" variant="body">
                        {recordLabel}
                    </Typography>
                </>
            )}
        </>
    );
};
