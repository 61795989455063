import React from 'react';
import { addUsernamePrefix } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';

import { Link } from '@srnade/web/components';
import { ProductStatusNew } from '@srnade/web/__generated__/graphql';

type ProductOwnedByProps = {
    preOrder: boolean;
    productStatus: ProductStatusNew;
    editionSequence: number;
    editionOwner?: {
        username: string;
    } | null;
    activeAccount?: {
        username: string;
    } | null;
};

export const ProductOwnedBy = ({
    preOrder,
    productStatus,
    editionSequence,
    editionOwner,
    activeAccount,
}: ProductOwnedByProps) => {
    const { t } = useTranslation('product-page');
    const showEditionSequence =
        !!editionSequence && (productStatus === ProductStatusNew.RELEASED || productStatus === ProductStatusNew.CLOSED);
    const ownedByText = preOrder ? t('OwnedBy') : t('ownedBy');

    return (
        <>
            {showEditionSequence && <span className="font-bold">#{editionSequence}</span>}
            &nbsp;
            <span>
                {ownedByText}{' '}
                {editionOwner ? (
                    <>
                        <Link href={`/${addUsernamePrefix(editionOwner.username)}`} passHref legacyBehavior>
                            <span className="cursor-pointer hover:underline">
                                {addUsernamePrefix(editionOwner.username)}
                            </span>
                        </Link>
                        {activeAccount?.username === editionOwner.username && ' (you)'}
                    </>
                ) : (
                    t('unknown')
                )}
            </span>
        </>
    );
};
