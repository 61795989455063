import { useCallback } from 'react';
import Cookies from 'js-cookie';

import { CookieKey } from '@srnade/web/auth';
import { getStreamUrl } from '@srnade/web/utils';
import { useSession } from 'next-auth/react';

export const useGetStreamUrl = () => {
    const { data: sessionData } = useSession();
    const accessToken = sessionData?.accessToken;

    const fetchStreamUrl = useCallback(
        async (mediaId: string | undefined | null) => {
            if (mediaId) {
                const internalUrl = getStreamUrl(mediaId);
                const account = Cookies.get(CookieKey.Account);
                const headers: Record<string, string> = {
                    // @ts-ignore
                    'account-id': account,
                };

                if (accessToken) {
                    headers.Authorization = `Bearer ${accessToken}`;
                }

                return await fetch(internalUrl, { headers });
            }

            return null;
        },
        [accessToken],
    );

    return fetchStreamUrl;
};
