import React from 'react';
import { VideoFilePreview } from './components/VideoFilePreview';
import { ImageFilePreview } from './components/ImageFilePreview';

export enum NewFilePreviewType {
    Video = 'video',
    Image = 'image',
    Unknown = 'unknown',
}

type NewFilePreviewProps = {
    type: NewFilePreviewType;
    assetId: string;
    formatInstanceId: string;
    title?: string;
};

export const NewFilePreview: React.FC<NewFilePreviewProps> = ({ type, assetId, formatInstanceId, title }) => {
    switch (type) {
        case NewFilePreviewType.Video:
            return (
                <VideoFilePreview assetId={assetId} formatInstanceId={formatInstanceId} loop muted controls autoPlay />
            );
        case NewFilePreviewType.Image:
            return <ImageFilePreview assetId={assetId} formatInstanceId={formatInstanceId} imageAlt={title} />;

        default:
            return null;
    }
};
