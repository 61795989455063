import React from 'react';

import { Icon, Typography } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';

interface DownloadAudioAvailableProps {
    limit: number;
    count: number;
    onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}
export function DownloadAudioAvailable({ limit, count, onClick }: DownloadAudioAvailableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'audioDownloadCounter' });

    return (
        <button
            type="button"
            onClick={onClick}
            data-testid="download-audio-available"
            className="flex justify-start items-center gap-[0.8rem] cursor-pointer pointer-events-auto group"
        >
            <Icon icon="download-circle" size={20} />

            <Typography variant="bodyBold" htmlContent className="group-hover:underline">
                {t('availableDownloads')}
            </Typography>

            <Typography variant="body" htmlContent>
                <span data-testid="download-audio-count">{count}</span>
                <span>/</span>
                <span data-testid="download-audio-limit">{limit}</span>
            </Typography>
        </button>
    );
}
