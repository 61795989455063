import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { format } from 'date-fns-tz';
import { useWindowSize } from 'react-use';

import { addUsernamePrefix, time } from '@srnade/component-ui';
import { Typography } from '@srnade/component-ui';

import {
    Avatar,
    Container,
    DisabledStreamPlayer,
    EditionOwnerBanner,
    ImageTile,
    Link,
    ProductBuyTabs,
    ProductInclusionsWithoutIcons,
    ProductMusicDetails,
    ProductTitle,
    ProductTypeInfo,
    USPBanner,
} from '@srnade/web/components';
import {
    Currency,
    FormatType,
    ResendEmailVerificationEmailMutation,
    ResendEmailVerificationEmailMutationVariables,
} from '@srnade/web/__generated__/graphql';
import { useAuth } from '@srnade/web/auth';
import { BreakpointWidth } from '@srnade/web/const';
import { useStripe } from '@srnade/web/hooks';
import { useTranslation } from '@srnade/web/i18n/client';
import { RESEND_EMAIL_VERIFICATION_EMAIL } from '@srnade/web/services';
import { useAppSelector } from '@srnade/web/store/global';
import { ProductFormatStatus } from '@srnade/web/store/reducers/product-format';
import { formatPrice } from '@srnade/web/utils';

import { ProductCountdown } from '../ProductCountdown';
import { ProductCollectibleDetails } from './ProductCollectibleDetails.component';
import { ProductPressingDetails } from './ProductPressingDetails.component';

const getCssClassesForProductDetails = (width: number): string => {
    if (BreakpointWidth.Laptop <= width) {
        return 'hidden w-full laptop:grid laptop:mt-[0rem] laptop:col-span-1';
    }

    if (BreakpointWidth.Tablet <= width) {
        return 'hidden w-full tablet:px-0 tablet:grid tablet:mt-[4rem] laptop:hidden';
    }

    return 'w-full mt-[4rem] px-[2rem] tablet:hidden';
};

/**
 * Display product details
 */
type ProductDetailsProps = {
    origin: string;
};

export const ProductDetails: React.FC<ProductDetailsProps> = ({ origin }) => {
    const { t: tProductTitle } = useTranslation('components', { keyPrefix: 'productTile' });
    const { t: tProductPage } = useTranslation('product-page');
    const { t: tProductInclusions } = useTranslation('components', { keyPrefix: 'productInclusions' });

    const { width } = useWindowSize();
    const cssClassesForProductTile = getCssClassesForProductDetails(width);

    const productFormat = useAppSelector((state) => state.product.productFormat);
    const stripe = useStripe(productFormat?.id || '', origin);
    const status: ProductFormatStatus = useAppSelector((state) => state.product.status);
    const [countdownDateTime, setCountdownDateTime] = useState<Date | null>(null);
    const [countdownTimeZone, setCountdownTimeZone] = useState<string | null>(null);
    const [countdownText, setCountdownText] = useState<string | null>(null);
    const [emailSent, setEmailSent] = useState<boolean>(false);

    const [resendEmailVerificationEmail, { data, loading, error }] = useMutation<
        ResendEmailVerificationEmailMutation,
        ResendEmailVerificationEmailMutationVariables
    >(RESEND_EMAIL_VERIFICATION_EMAIL);
    const { user, activeAccount } = useAuth();

    // Countdown timer
    useEffect(() => {
        const now = new Date();

        if (!productFormat) {
            return;
        }

        // Check if the product is in pre-order and has a planned pre-order start date
        if (
            productFormat.plannedPreOrderStartDate &&
            productFormat.plannedPreOrderStartDateTimezone &&
            new Date(productFormat.plannedPreOrderStartDate) > now
        ) {
            const plannedPreOrderStartDate = new Date(productFormat.plannedPreOrderStartDate);
            setCountdownDateTime(plannedPreOrderStartDate);
            setCountdownTimeZone(productFormat.plannedPreOrderStartDateTimezone);
            setCountdownText(format(plannedPreOrderStartDate, "'Pre order starts' dd MMMM, yyyy 'at' h:mma"));
            return;
        }

        // Check if the product is released and has a planned release date
        if (
            productFormat.plannedReleaseDate &&
            productFormat.plannedReleaseDateTimezone &&
            new Date(productFormat.plannedReleaseDate) > now
        ) {
            const plannedReleaseDate = new Date(productFormat.plannedReleaseDate);
            setCountdownDateTime(plannedReleaseDate);
            setCountdownTimeZone(productFormat.plannedReleaseDateTimezone);
            setCountdownText(format(plannedReleaseDate, "'Releases' dd MMMM, yyyy 'at' h:mma"));
            return;
        }

        // Check if the product is not closed and has a planned close date
        if (
            productFormat.plannedCloseDate &&
            productFormat.plannedCloseDateTimezone &&
            new Date(productFormat.plannedCloseDate) > now
        ) {
            const plannedCloseDate = new Date(productFormat.plannedCloseDate);
            setCountdownDateTime(plannedCloseDate);
            setCountdownTimeZone(productFormat.plannedCloseDateTimezone);
            setCountdownText(format(plannedCloseDate, "'Available until' dd MMMM, yyyy 'at' h:mma"));
            return;
        }

        // remove countdown if no date is available
        setCountdownDateTime(null);
        setCountdownTimeZone(null);
        setCountdownText(null);
    }, [
        productFormat?.plannedPreOrderStartDate,
        productFormat?.plannedPreOrderStartDateTimezone,
        productFormat?.plannedReleaseDate,
        productFormat?.plannedReleaseDateTimezone,
        productFormat?.plannedCloseDate,
        productFormat?.plannedCloseDateTimezone,
        productFormat,
    ]);

    /**
     * Handles the reset of the resend button and message after 30s
     */
    const verifyEmail = !user?.isEmailVerified;

    useEffect(() => {
        let timer: NodeJS.Timeout;
        const THIRTY_SECONDS = time.ONE_SECOND_MS * 30;
        if (data && verifyEmail) {
            setEmailSent(true);
            timer = setTimeout(() => setEmailSent(false), THIRTY_SECONDS);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [data, verifyEmail]);

    // TODO handle status Failed, redirect to the 404 page
    if (!status || status !== 'Idle' || !productFormat) {
        return null;
    }
    const {
        collectible,
        pressing,
        formatType,
        productInclusions, // pressing only
        soldOut,
        artist,
        title,
        slug,
        artworkUrl,
        wholesale, // pressing only
        price,
        currency,
        recordLabel,
        parentRecordLabel, // pressing only
        productStatus,
        behindTheMusic,
        termsAndConditionsUrl,
        userOwnsAnyEditions,
    } = productFormat;

    const isCollectible = formatType === FormatType.DIGITAL_COLLECTIBLE;
    const hasArtwork = productFormat?.artworkUrl || productFormat?.title;
    const display = {
        showPressing: !isCollectible && !!pressing,
        showCollectible: userOwnsAnyEditions && isCollectible && !!collectible,
        showBonusContent: !isCollectible && pressing?.bonusContent?.length ? pressing.bonusContent.length > 0 : false,
        showInclusions: !isCollectible && productInclusions.length > 0,
        showBuyTabs: currency && price && !wholesale && !soldOut,
        showArtwork: isCollectible ? !userOwnsAnyEditions && hasArtwork : hasArtwork,
        showStreamPlayer: !isCollectible,
        showOwnerBanner: userOwnsAnyEditions && !!activeAccount,
        showCountdown: countdownDateTime && countdownTimeZone && countdownText,
        behindTitle: collectible ? tProductPage('collectibleTitle') : tProductPage('pressingTitle'),
    };

    return (
        <>
            <Container className="grid gap-x-[12.5rem] justify-content-center justify-items-end pt-[0.8rem] mobile:px-0 tablet:justify-content-start tablet:grid-cols-[54.7rem] laptop:grid-cols-[repeat(2,50.5rem)]">
                <div
                    className={clsx('w-full h-full laptop:col-span-1', {
                        'laptop:w-[37.9rem]': !collectible,
                    })}
                    data-testid="view-product"
                >
                    {display.showArtwork && <ImageTile src={artworkUrl || ''} alt={title || ''} />}

                    {display.showStreamPlayer && (
                        <div className="sticky top-[10.5rem]">
                            <DisabledStreamPlayer />
                        </div>
                    )}
                    {display.showCollectible && (
                        <>
                            <div className="border-0 overflow-hidden rounded-[1rem] drop-shadow-xl sticky top-[14rem]">
                                <ProductCollectibleDetails productFormat={productFormat} />
                            </div>
                        </>
                    )}
                </div>
                <div className={cssClassesForProductTile}>
                    <div data-gtm-id="product_details">
                        {display.showOwnerBanner && (
                            <EditionOwnerBanner
                                className="mb-[3rem]"
                                collectionUrl={`/${addUsernamePrefix(activeAccount?.username || '')}`}
                                preOrderProduct={productFormat.preOrder}
                            />
                        )}
                        <div className="flex items-center justify-between">
                            <div className="flex items-center" data-gtm-id="product_details_artist">
                                <Link href={`/${addUsernamePrefix(artist.username)}`} passHref legacyBehavior>
                                    <span className="cursor-pointer">
                                        <Avatar src={artist.profilePicUrl} name={artist.name} size="small" />
                                    </span>
                                </Link>
                                <Link href={`/${addUsernamePrefix(artist.username)}`} passHref legacyBehavior>
                                    <Typography className="ml-[1rem] hover:underline" variant="body">
                                        {artist.name}
                                    </Typography>
                                </Link>
                            </div>
                        </div>
                        <ProductTypeInfo productFormat={productFormat} className="mt-[4rem] mb-[1rem]" />
                        <div className="tablet:pr-[18.7rem] laptop:pr-0">
                            <ProductTitle
                                title={title}
                                slug={slug}
                                artistUsername={artist.username}
                                isEdition={false}
                            />
                        </div>
                        {display.showCountdown && (
                            <div className="mt-[4rem]">
                                <ProductCountdown
                                    dateTime={countdownDateTime as string | number | Date}
                                    timeZone={countdownTimeZone as string}
                                    text={countdownText as string}
                                />
                            </div>
                        )}
                        {display.showBuyTabs && (
                            <ProductBuyTabs
                                stripe={stripe}
                                emailSent={emailSent}
                                resendEmailVerificationEmail={resendEmailVerificationEmail}
                                resendEmailVerificationError={error}
                                resendEmailVerificationLoading={loading}
                                verifyEmail={verifyEmail}
                                price={formatPrice(currency as Currency, price as number)}
                                parentRecordLabel={parentRecordLabel}
                                buyClicked={() => {}}
                                productStatus={productStatus}
                            />
                        )}
                        {soldOut && (
                            <div className="flex flex-col mt-[4rem] mb-16">
                                <Typography variant="h3">{tProductTitle('soldOut')}</Typography>
                            </div>
                        )}

                        {display.showInclusions && (
                            <ProductInclusionsWithoutIcons
                                title={tProductInclusions('title')}
                                inclusions={{ items: productInclusions }}
                            />
                        )}

                        {display.showPressing && (
                            <ProductPressingDetails
                                productFormat={productFormat}
                                showBonusContent={display.showBonusContent}
                            />
                        )}

                        <ProductMusicDetails
                            termsAndConditionsUrl={termsAndConditionsUrl!}
                            behindTheMusicDesc={behindTheMusic!}
                            behindTheMusicTitle={display.behindTitle}
                            credits={pressing?.credits}
                            recordLabel={recordLabel!}
                        />
                    </div>
                </div>
            </Container>
            <Container>
                <div className="my-[6rem] " />
            </Container>
            <div className="mb-[8rem]">
                <USPBanner />
            </div>
        </>
    );
};
