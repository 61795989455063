import { BaseComponentProps } from '@srnade/component-ui';

import { Link } from '@srnade/web/components';

interface LinkWrapperProps {
    href?: string;
    queryData?: { [key: string]: string | undefined };
    handleClick?: Function;
}

/**
 * Render children as a link, if a link exists, otherwise render children
 * @param
 * @returns
 */
export function LinkWrapper({
    href,
    queryData,
    handleClick,
    children,
}: LinkWrapperProps & BaseComponentProps): JSX.Element {
    const clickHandler = () => {
        if (handleClick) {
            handleClick();
        }
    };
    const linkRef = queryData && href ? { pathname: href, query: queryData } : href;
    return linkRef ? (
        <Link href={linkRef} passHref legacyBehavior>
            <div onClick={() => clickHandler()} className="cursor-pointer">
                {children}
            </div>
        </Link>
    ) : (
        <div className="cursor-default">{children}</div>
    );
}
