import { addUsernamePrefix } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';

import { Link } from '@srnade/web/components';

type EditionOwnedByProps = {
    ownerUsername: string | null;
    ownerIsMe: boolean;
    withSequence: boolean;
};

export const EditionOwnedBy = ({ ownerUsername, ownerIsMe, withSequence }: EditionOwnedByProps) => {
    const { t } = useTranslation('product-page');
    const { t: commonT } = useTranslation('common');
    return (
        <>
            <span>
                {withSequence ? t('ownedBy') : t('OwnedBy')}{' '}
                {ownerUsername ? (
                    <>
                        <Link href={`/${addUsernamePrefix(ownerUsername)}`} passHref legacyBehavior>
                            <span className="cursor-pointer hover:underline">{addUsernamePrefix(ownerUsername)}</span>
                        </Link>
                        {ownerIsMe && ' (you)'}
                    </>
                ) : (
                    t('unknown')
                )}
            </span>
        </>
    );
};
