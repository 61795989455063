export enum Environment {
    Local = 'local',
    Development = 'development',
    Production = 'production',
}

export interface GlobalConfig {
    envName: Environment;
}

export const globalConfig = {
    envName: process.env.NEXT_PUBLIC_ENV_NAME ?? 'local',
} as GlobalConfig;
