import { gql } from '@apollo/client';

import { ProductRelay } from '../types';
import { ACCOUNT_FRAGMENT } from './accounts.service';

/**
 * Fragments
 */

export const COLLECTIBLE_FRAGMENT = gql`
    fragment collectible on ProductCollectible {
        image {
            id
            url
            width
            height
        }
        audio {
            id
        }
        video {
            id
        }
    }
`;

export const PRESSINGS_FRAGMENT = gql`
    fragment pressing on ProductPressing {
        pressingType
        coverArtImage {
            id
            url
            width
            height
        }
        tracks {
            id
            audio {
                id
                file {
                    size
                    extension
                }
            }
            trackName
            trackNumber
            metadata
        }
    }
`;

export const REWARDS_FRAGMENT = gql`
    fragment rewards on Reward {
        id
        name
        downloadable
        displayOrder
        playable
        created
        image {
            id
            url
            file {
                size
            }
        }
        video {
            id
            file {
                size
            }
        }
        audio {
            id
            file {
                size
            }
        }
        document {
            id
            url
            file {
                size
            }
        }
        other {
            file {
                size
            }
        }
    }
`;

/**
 * Types
 */

// Old create product API

export interface FindProductsData {
    findProducts: ProductRelay;
}

/**
 * Fragments
 */

export const PRODUCT_FRAGMENT = gql`
    fragment product on Product {
        id
        status
        allowTransfers
        title
        slug
        description
        additionalDetails
        termsAndConditionsUrl
        minted
        limit
        parentRecordLabel
        termsAndConditionsUrl
        metadata
        thumbnail {
            id
            url
        }
        account {
            ...account
        }
        productType
        collectible {
            ...collectible
        }
        pressing {
            ...pressing
        }
        rewards {
            ...rewards
        }
    }
    ${ACCOUNT_FRAGMENT}
    ${PRESSINGS_FRAGMENT}
    ${COLLECTIBLE_FRAGMENT}
    ${REWARDS_FRAGMENT}
`;

export const PRODUCT_TILE_FRAGMENT = gql`
    fragment ProductTileFragment on Product {
        id
        status
        allowTransfers
        title
        slug
        additionalDetails
        minted
        limit
        thumbnail {
            id
            url
        }
        account {
            id
            name
            username
            avatar {
                id
                url
            }
        }
        productType
        pressing {
            id
            pressingType
        }
    }
`;

/**
 * Mutations
 */

// Old create product API
export const CREATE_PRODUCT = gql`
    mutation CreateProduct($product: CreateProductInput!, $listing: CreateListingInput!) {
        product: createProduct(product: $product, listing: $listing) {
            id
        }
    }
`;

export const CREATE_PRODUCT_V2 = gql`
    mutation CreateProductV2($product: CreateProductInputV2!, $listing: CreateListingInput!) {
        product: createProductV2(product: $product, listing: $listing) {
            id
        }
    }
`;

export const CREATE_PRODUCT_MARKETING_CONSENT = gql`
    mutation CreateProductMarketingConsent($input: CreateProductMarketingConsentInput!) {
        createProductMarketingConsent(input: $input) {
            id
        }
    }
`;

/**
 * Queries
 */

export const FIND_PRODUCT_PAGE = gql`
    query FindProductPage($id: String, $slug: String) {
        findProduct(id: $id, slug: $slug) {
            id
            status
            allowTransfers
            allowTrackDownloads
            title
            slug
            description
            parentRecordLabel
            additionalDetails
            termsAndConditionsUrl
            limit
            minted
            termsAndConditionsUrl
            metadata
            parentRecordLabel
            productType
            thumbnail {
                id
                url
                width
                height
            }
            account {
                id
                name
                username
                missionStatement
                socials
                avatar {
                    id
                    url
                }
            }
            collectible {
                ...collectible
            }
            pressing {
                ...pressing
            }
            rewards {
                ...rewards
            }
            compressed {
                id
                file {
                    size
                    s3Url
                }
            }
        }
    }
    ${PRESSINGS_FRAGMENT}
    ${COLLECTIBLE_FRAGMENT}
    ${REWARDS_FRAGMENT}
`;

export const FIND_PRODUCT = gql`
    query FindProduct($id: String, $slug: String) {
        findProduct(id: $id, slug: $slug) {
            ...product
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const FIND_GRID_PRODUCTS = gql`
    query FindGridProducts(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: JSON
        $title: String
        $username: String
    ) {
        findProducts(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            title: $title
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...ProductTileFragment
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${PRODUCT_TILE_FRAGMENT}
`;

export const FIND_PRODUCTS = gql`
    query FindProducts(
        $first: Float
        $last: Float
        $before: String
        $after: String
        $order: JSON
        $title: String
        $username: String
    ) {
        findProducts(
            first: $first
            last: $last
            before: $before
            after: $after
            order: $order
            title: $title
            username: $username
        ) {
            edges {
                cursor
                node {
                    ...product
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;
