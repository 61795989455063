import React from 'react';
import { Typography } from '@srnade/component-ui';
import clsx from 'clsx';
import type { BaseComponentProps } from '@srnade/component-ui';
import { PaymentChannelStatus } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';

export type StripeConnectAccountProps = BaseComponentProps & {
    status: PaymentChannelStatus;
};

export const StripeConnectAccount: React.FC<StripeConnectAccountProps> = ({ status }) => {
    const { t } = useTranslation('payments');

    const isSuccess = status === PaymentChannelStatus.APPROVED;
    const isError = status === PaymentChannelStatus.ACTION_NEEDED; // Error and Declined should display submitted

    if (status === PaymentChannelStatus.NOT_STARTED) {
        return null;
    }

    return (
        <div className="flex flex-row justify-between items-center py-[1rem] px-[2rem] rounded-[0.6rem] shadow-[0_0_10px_0_rgba(0,0,0,0.1)]">
            <Typography variant="h6" className="text-darkGray mr-[1rem]">
                {t('stripeConnectAccountStatusInfo')}
            </Typography>
            <div
                className={clsx('py-[0.6rem] px-[1rem] rounded-[0.4rem] border flex flex-row items-center', {
                    ['border-success']: isSuccess,
                    ['border-error']: isError,
                    ['border-darkGray']: !isSuccess && !isError,
                })}
                data-testid="stripe-connect-account__status-container"
            >
                <div
                    className={clsx('w-[0.6rem] h-[0.6rem] rounded-[0.6rem] mr-[1rem]', {
                        ['bg-success']: isSuccess,
                        ['bg-error']: isError,
                        ['bg-darkGray']: !isSuccess && !isError,
                    })}
                    data-testid="stripe-connect-account__status-circle"
                />
                <Typography
                    variant="h6"
                    className="whitespace-nowrap"
                    data-testid="stripe-connect-account__status-text"
                >
                    {t(`paymentChannelStatus.${status}`)}
                </Typography>
            </div>
        </div>
    );
};
