import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { useTranslation } from '@srnade/web/i18n/client';

import { type BaseComponentProps, Button, RangeSlider } from '@srnade/component-ui';
import getCroppedImg from './cropImage';

interface ImageCropperProps {
    image: File;
    aspect?: number;
    buttonText?: string;
    onCrop: (file: File) => void;
}

export function ImageCropper({
    image,
    aspect = 1 / 1,
    buttonText,
    onCrop,
    children,
}: ImageCropperProps & BaseComponentProps): JSX.Element {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.0);
    const { t } = useTranslation('components', { keyPrefix: 'cropImage' });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const imageDOMString = URL.createObjectURL(image);

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onCropConfirm = async (): Promise<void> => {
        setIsLoading(true);
        const file = await getCroppedImg(imageDOMString, croppedAreaPixels as Area);
        if (file) {
            onCrop(
                new File([file], 'cropped.jpg', {
                    type: file.type,
                }),
            ); // convert blob to file and set `cropped.jpg` as the name
        }
    };

    return (
        <div className="w-full">
            <div className="relative min-h-[300px] max-h-[400px] h-full mb-[30px]">
                <Cropper
                    image={imageDOMString}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect}
                    showGrid={false}
                    onZoomChange={setZoom}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                />
            </div>

            <RangeSlider
                className="mb-[6rem] border rounded-[5px]"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={setZoom}
                icons
            />

            <div className="mx-auto	max-w-[350px]">
                <Button
                    type="standard"
                    text={buttonText ?? t('cropAndUpload')}
                    onClick={onCropConfirm}
                    loading={isLoading}
                    className="mb-5 w-full"
                />
                {children}
            </div>
        </div>
    );
}
