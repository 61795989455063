import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';

import { DragAndDrop, DragAndDropItem } from '@srnade/component-ui';
import { DragAndDropFormikChildItemProps, DragAndDropFormikChildProps } from '@srnade/web/types';
import { DragAndDropFormikChildItem } from './components';
import { removeWhitespaceAndDot } from '@srnade/web/utils';

export function DragAndDropFormikChild({ items, setItems, dndItemType }: DragAndDropFormikChildProps) {
    const formik = useFormikContext();
    /**
     * Delete an item out of the drag and drop container.
     */
    const onDeleteInput: DragAndDropFormikChildItemProps['onDeleteInput'] = (event) => {
        setItems(items.filter((item) => item.fileName !== event.fileName));
    };

    /**
     * Update the parent state when there's a change on any of the inputs
     */
    useEffect(() => {
        const { initialValues, values } = formik || {};
        if (!isEqual(values, initialValues)) {
            updateStatesToIncludeInputValues(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.initialValues, formik?.values]);

    /**
     * Map parent state items to include values from the track and reward input fields.
     *
     * @param inputFields
     */
    const updateStatesToIncludeInputValues = (inputFields): void => {
        const inputFieldKeys = Object.keys(inputFields);
        if (inputFieldKeys.length < 1) {
            return;
        }

        setItems((prevItems) => {
            return prevItems.map((item) => {
                const fileNameWithoutDot = removeWhitespaceAndDot(item.fileName);
                const isrcValue = inputFields[`${fileNameWithoutDot}-isrc`];
                const trackValue = inputFields[`${fileNameWithoutDot}-track`];
                const rewardValue = inputFields[`${fileNameWithoutDot}-reward`];

                if (isrcValue || isrcValue === '') {
                    item.isrc = isrcValue;
                }
                if (trackValue || trackValue === '') {
                    item.name = trackValue;
                }
                if (rewardValue || rewardValue === '') {
                    item.name = rewardValue;
                }

                return item;
            });
        });
    };

    return (
        <div>
            <DragAndDrop
                listItems={items}
                onReorderHandler={setItems}
                className="max-w-screen-lg list-none p-0 border-darkGray border rounded-[10px] mb-8"
            >
                {items.map((item, index) => {
                    // itemName must be a unique string
                    const itemName = `${dndItemType}-${item.fileName}`;
                    return (
                        <DragAndDropItem
                            key={itemName}
                            item={item}
                            className="bg-white border-darkGray py-8 pl-7 pr-8 tablet:pr-16 border-b last:border-b-0 first:rounded-t-[10px] last:rounded-b-[10px]"
                        >
                            <DragAndDropFormikChildItem
                                item={item}
                                index={index}
                                progressValue={item?.progress}
                                onDeleteInput={onDeleteInput}
                                dndItemType={dndItemType}
                            />
                        </DragAndDropItem>
                    );
                })}
            </DragAndDrop>
        </div>
    );
}
