import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { AccountStatus, AccountType, PermissionKey, BaseComponentProps } from '@srnade/component-ui';

import { useAuth } from '@srnade/web/auth';
import { useSafeRouterPush } from '@srnade/web/hooks';

// Note: ideally we'd do the authentication and authorisation check at the server side level
// However, the following code isn't working and needs some TLC. If we use the normal apollo
// client the cookie token can't be accessed, so we need to pull it from the request and use
// a server side version of the client. So far so good, however the profile query isn't
// firing properly for some reason. For now we just do the check on the client, which means
// the user will see the contents of the page before it redirects (which isn't ideal).

// We would need to add something like this to the clients/apollo.ts file
// export const serverSideClient = new ApolloClient({
//     cache: new InMemoryCache()
// });

// const PROFILE_QUERY = gql`
//     query EmailVerified {
//         profile {
//             isEmailVerified
//         }
//     }
// `;
//
// export const getServerSideProps = async ({ req, res, query }) => {
//     const { cookies } = req;
//     const { token } = cookies;
//     const { verified } = query;

//     if (token && verified !== undefined) {
//         console.log(`authorization: Bearer ${token}`);
//         const result = await serverSideClient.query({ query: PROFILE_QUERY, context: {
//             headers: {
//                 authorization: `Bearer ${token}`
//             }
//         } });
//         console.log(result);
//     }

//     return {
//         props: {}
//     };
// }

interface AuthGuardProps {
    accountId?: string;
    permissionKey?: PermissionKey;
    children: React.ReactElement;
    artistOnly?: boolean;
}

export const AuthGuard = ({
    permissionKey,
    children,
    artistOnly,
}: AuthGuardProps & BaseComponentProps): JSX.Element => {
    const router = useRouter();
    const { safePush } = useSafeRouterPush();
    const { authenticating, user, accountId, hasPermission, activeAccount } = useAuth();

    const checkForUnauthorized = useCallback(async () => {
        if (permissionKey && accountId) {
            const hasPerm = await hasPermission(accountId, permissionKey);
            if (!hasPerm) {
                await safePush('/unauthorized');
            }
        }
    }, [accountId, permissionKey, hasPermission, safePush]);

    const checkForArtist = useCallback(async () => {
        if (
            activeAccount &&
            (AccountType[activeAccount.type] !== AccountType.Artist ||
                AccountStatus[activeAccount.status] !== AccountStatus.Approved)
        ) {
            await safePush('/unauthorized');
        }
    }, [activeAccount, safePush]);

    const determinedIntent = useCallback(async () => {
        if (!user) {
            // Remember the page that the user tried to access
            await signIn('auth0', { callbackUrl: router.asPath });
        } else if (permissionKey) {
            await checkForUnauthorized();
        } else if (artistOnly) {
            await checkForArtist();
        }
    }, [permissionKey, user, artistOnly, checkForUnauthorized, router.asPath, checkForArtist]);

    useEffect(() => {
        if (!authenticating) {
            void determinedIntent();
        }
    }, [authenticating, determinedIntent]);

    return children;
};
