import { ParentRecordLabel } from '@srnade/component-ui';
import { Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';

type ProductUmgStatementProps = {
    parentRecordLabel: ParentRecordLabel;
    isEdition?: boolean;
    className?: string;
};

export const ProductUmgStatement = ({ parentRecordLabel, isEdition = false, className }: ProductUmgStatementProps) => {
    const { t } = useTranslation('product-page');

    if (isEdition || parentRecordLabel !== ParentRecordLabel.UniversalMusicGroup) {
        return null;
    }

    return (
        <Typography variant="h6" className={clsx('text-darkGray', className)}>
            {t('umgStatement')}
        </Typography>
    );
};
