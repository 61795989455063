import { Icon, Typography } from '@srnade/component-ui';
import clsx from 'clsx';

interface SeparatorProps {
    className?: string;
}

const Separator = ({ className }: SeparatorProps) => {
    return (
        <div
            className={clsx('w-full h-[0.1rem]', className)}
            style={{
                background: 'var(--gradient-main)',
            }}
        ></div>
    );
};

export interface ProductInclusions {
    icon: string;
    text: string;
    id: string;
    onClick?: () => void;
}
interface ProductInclusionsProps {
    inclusions: ProductInclusions[];
    title: string;
}

export const ProductInclusions = ({ inclusions, title }: ProductInclusionsProps) => {
    return (
        <div data-testid="product-inclusions">
            <Separator className="mb-[2rem] mt-[4rem]" />
            <Typography variant="h5" className="mb-[2rem]">
                <span className="font-h2 italic font-normal">{title}</span>
            </Typography>
            <Typography variant="h5">
                <ul className="font-normal list-none p-0 flex flex-col gap-[2rem]">
                    {inclusions?.map((item) => (
                        <li
                            key={item.id}
                            className={clsx('flex inclusions-center', {
                                'cursor-pointer hover:underline': typeof item?.onClick === 'function',
                            })}
                            onClick={item?.onClick}
                        >
                            <Icon icon={item.icon} size={20} className="mr-[0.6rem] min-h-[initial]" />
                            {item.text}
                        </li>
                    ))}
                </ul>
            </Typography>
            <Separator className="mt-[2rem] mb-[4rem]" />
        </div>
    );
};
