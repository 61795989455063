import React, { useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from '@srnade/web/i18n/client';
import { Typography, Card, CardHeader, CardContent, type BaseComponentProps } from '@srnade/component-ui';
import { AccountType, GenresHumanized, addUsernamePrefix } from '@srnade/component-ui';
import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';

import { COUNT_PRODUCTS } from '@srnade/web/services';

import { LinkWrapper } from '../LinkWrapper';
import { Avatar } from '../Avatar';
import { GtmPromoNames, GtmPromoOrder, GtmPromotion, GtmSlotOrder } from '@srnade/web/types';
import { promoId, slot } from '@srnade/web/utils';
import { useGtmContext } from '@srnade/web/store/context/gtm.context';
import { ProductCountQuery, ProductCountQueryVariables } from '@srnade/web/__generated__/graphql';

import { Account } from '../../types';

export interface AccountTileProp {
    type?: 'standard' | 'carousel';
    account: Account;
    href?: string;
    hoverable?: boolean;
    clickable?: boolean;
    disabled?: boolean;
}

export function AccountTile({
    type = 'standard',
    account,
    hoverable = false,
    clickable = false,
    disabled = false,
    className,
    style,
}: AccountTileProp & BaseComponentProps): JSX.Element {
    const { t } = useTranslation('components', { keyPrefix: 'accountTile' });
    const { startPromo } = useGtmContext();
    const productCount = useQuery<ProductCountQuery, ProductCountQueryVariables>(COUNT_PRODUCTS, {
        variables: { accountId: account.id },
    });

    const genres = account.genres.map((genre) => GenresHumanized[genre]).join(', ');

    const startTopArtistPromo = useRef<GtmPromotion>({
        creative_name: GtmPromoNames.CREATIVE_TOP_ARTISTS,
        creative_slot: slot(GtmSlotOrder.TopArtistsOrFan),
        promotion_id: promoId(GtmPromoOrder.TopArtists),
        promotion_name: GtmPromoNames.PROMO_TOP_ARTISTS,
    });

    const startTopFanPromo = useRef<GtmPromotion>({
        creative_name: GtmPromoNames.CREATIVE_TOP_FANS,
        creative_slot: slot(GtmSlotOrder.TopArtistsOrFan),
        promotion_id: promoId(GtmPromoOrder.TopFans),
        promotion_name: GtmPromoNames.PROMO_TOP_FANS,
    });

    const handleTopArtistOrFanClick = () => {
        if (account?.type === AccountType.Artist) {
            startPromo(startTopArtistPromo.current);
        } else {
            startPromo(startTopFanPromo.current);
        }
    };

    return (
        <motion.div
            className={clsx(className, 'w-full h-full')}
            style={style}
            whileHover={hoverable ? { y: -15 } : { y: 0 }}
            whileTap={hoverable ? { scale: 0.98 } : { scale: 1 }}
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
            data-testid={`account-tile-${account.username}`}
        >
            <LinkWrapper
                href={clickable ? `/${addUsernamePrefix(account.username)}` : undefined}
                handleClick={handleTopArtistOrFanClick}
            >
                <Card
                    className={clsx('rounded-2xl p-[1rem]', {
                        'max-w-[33.5rem] max-h-[46rem] tablet:w-[33.5rem] laptop:max-w-[29.5rem] laptop:max-h-[42rem]':
                            type === 'standard',
                        'max-w-[29.5rem] max-h-[42rem]': type === 'carousel',
                    })}
                    border
                    disabled={disabled}
                >
                    <CardHeader>
                        <div
                            className={clsx('relative w-full laptop:h-[27.2rem]', {
                                'h-[31.2rem]': type === 'standard',
                                'h-[27.2rem]': type === 'carousel',
                            })}
                        >
                            <Avatar src={account.avatar?.url} name={account.name} size="tile" />
                        </div>
                    </CardHeader>
                    <CardContent className="mx-[1rem]">
                        <Typography className="mt-[1.5rem] mb-[0.5rem] line-clamp-1" variant="subheadingBold">
                            {account.name}
                        </Typography>
                        <Typography className="mb-[0.8rem] line-clamp-1" variant="body">
                            {addUsernamePrefix(account.username)}
                        </Typography>
                        {account.type === AccountType.Artist ? (
                            <Typography className="mb-[0.8rem] line-clamp-1" variant="body">
                                {t('genres')} {genres}
                            </Typography>
                        ) : (
                            <Typography className="mb-[0.8rem] line-clamp-1" variant="body">
                                {t('favouriteGenres')} {genres}
                            </Typography>
                        )}
                        {account.type === AccountType.Artist && (
                            <Typography className="mb-[0.8rem]" variant="body">
                                {t('releases')} {productCount.data?.countProducts}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </LinkWrapper>
        </motion.div>
    );
}
