import React from 'react';
import clsx from 'clsx';
import type { BaseComponentProps } from '@srnade/component-ui';

import styles from './ScrollableTextArea.module.scss';

interface ScrollableTextProps {
    content: string;
}

export function ScrollableTextArea({ className, content }: ScrollableTextProps & BaseComponentProps) {
    return (
        <div className="border rounded-2xl py-[2rem] pl-[2rem] pr-[1rem] w-full bg-white cursor-default">
            <div className={clsx(className, 'bg-white h-[14.5rem] overflow-x-hidden', styles.scrollbar)}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: content || '',
                    }}
                />
            </div>
        </div>
    );
}
