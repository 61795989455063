import { PaymentChannelStatus } from '@srnade/component-ui';

export enum AnalyticsEventType {
    SelectDownloadableAudioFilesInclusion = 'select_downloadable_audio_files_inclusion',
    SelectDisabledDownloadAudioButton = 'select_disabled_download_audio_button',
    ViewAudioDownloadsPopup = 'view_audio_downloads_popup',
    SelectDownloadAudioButton = 'select_download_audio_button',
    SelectAudioDownloadButtonFromPopup = 'select_audio_download_button_from_popup',
    ViewRHPaymentChannelStatus = 'view_rh_payment_channel_status',
    AutoPopulatedRHArtistCountry = 'auto_populated_rh_artist_country',
    SelectRHPaymentCountry = 'select_rh_payment_country',
    ChangeRHPaymentCountry = 'change_rh_payment_country',
    FailedCreatingRHPaymentChannel = 'failed_creating_rh_payment_channel',
    FailedCreatingRHStripeOnBoardingLink = 'failed_creating_rh_stripe_on_boarding_link',
    SelectRHSetUpPayment = 'select_rh_set_up_payment',
    SelectRHContinueSetup = 'select_rh_continue_setup',
    SelectRHUpdateDetails = 'select_rh_update_details',
    ViewEndOfOwnersList = 'view_end_of_owners_list',
}

type ViewEndOfOwnersListPayload = {
    page_name: 'product_page' | 'edition_page';
    product_id: string;
    product_title: string;
};

type SelectDownloadableAudioFilesInclusionPayload = {
    page_name: 'product_page';
    product_id: string;
    product_title: string;
};

type SelectDisabledDownloadAudioButtonPayload = {
    page_name: 'product_page';
    product_id: string;
    product_title: string;
};

type ViewAudioDownloadsPopupPayload = {
    page_name: 'product_page' | 'edition_page';
    product_id: string;
    tracks_count: number;
    zip_size_bytes: number;
    product_title: string;
};

type SelectDownloadAudioButtonPayload = {
    page_name: 'edition_page';
    product_id: string;
    edition_id: string;
    remaining_download_count: number;
    total_downloads_available_count: number;
    tracks_count: number;
    zip_size_bytes: number;
    product_title: string;
};

type SelectAudioDownloadButtonFromPopupPayload = {
    page_name: 'edition_page';
    product_id: string;
    edition_id: string;
    remaining_download_count: number;
    total_downloads_available_count: number;
    tracks_count: number;
    zip_size_bytes: number;
    product_title: string;
};

export type EventBase = {
    event: AnalyticsEventType;
    user_id: string | 'anonymous';
};

type ViewRHPaymentChannelStatusPayload = {
    payment_status: PaymentChannelStatus;
};

type FailedCreatingRHStripeOnBoardingLinkPayload = ViewRHPaymentChannelStatusPayload;

type AutoPopulatedRHArtistPayload = {
    pre_populated_country_code: string;
};

type ChangeRHPaymentCountryPayload = {
    pre_populated_country_code: string;
    new_selected_country_code: string;
};

type SelectRHPaymentCountryPayload = {
    selected_country_code: string;
};

export type AnalyticsEventTypeToPayloadMap = {
    [AnalyticsEventType.SelectDownloadableAudioFilesInclusion]: SelectDownloadableAudioFilesInclusionPayload;
    [AnalyticsEventType.SelectDisabledDownloadAudioButton]: SelectDisabledDownloadAudioButtonPayload;
    [AnalyticsEventType.ViewAudioDownloadsPopup]: ViewAudioDownloadsPopupPayload;
    [AnalyticsEventType.SelectDownloadAudioButton]: SelectDownloadAudioButtonPayload;
    [AnalyticsEventType.SelectAudioDownloadButtonFromPopup]: SelectAudioDownloadButtonFromPopupPayload;
    [AnalyticsEventType.ViewRHPaymentChannelStatus]: ViewRHPaymentChannelStatusPayload;
    [AnalyticsEventType.AutoPopulatedRHArtistCountry]: AutoPopulatedRHArtistPayload;
    [AnalyticsEventType.SelectRHPaymentCountry]: SelectRHPaymentCountryPayload;
    [AnalyticsEventType.ChangeRHPaymentCountry]: ChangeRHPaymentCountryPayload;
    [AnalyticsEventType.FailedCreatingRHPaymentChannel]: {};
    [AnalyticsEventType.FailedCreatingRHStripeOnBoardingLink]: FailedCreatingRHStripeOnBoardingLinkPayload;
    [AnalyticsEventType.SelectRHSetUpPayment]: {};
    [AnalyticsEventType.SelectRHContinueSetup]: {};
    [AnalyticsEventType.SelectRHUpdateDetails]: {};
    [AnalyticsEventType.ViewEndOfOwnersList]: ViewEndOfOwnersListPayload;
};
