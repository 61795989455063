import React from 'react';
import Image from 'next/legacy/image';

import { Typography } from '@srnade/component-ui';

import { Container, StyledLink } from '@srnade/web/components';
import { siteLinks } from '@srnade/web/data';

import britAwards from 'assets/icons/brit-awards.svg';
import afem from 'assets/icons/afem.svg';
import mmf from 'assets/icons/mmf.svg';
import era from 'assets/icons/era.svg';

export function TopFooter() {
    const showCollectorsClubLink = () => {
        return siteLinks;
    };

    const renderIcons = (mobile?: boolean) => {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row">
                    <div className="flex justify-items-center">
                        <Image
                            src={britAwards}
                            height={mobile ? 95 : 110}
                            width={mobile ? 95 : 110}
                            alt="Brit Awards"
                            unoptimized
                        />
                    </div>
                    <div className="flex justify-items-center mx-[4rem] tablet:mx-[6rem]">
                        <Image src={afem} height={mobile ? 85 : 100} width={mobile ? 85 : 100} alt="AFEM" unoptimized />
                    </div>
                    <div className="flex justify-items-center">
                        <Image src={mmf} height={mobile ? 45 : 50} width={mobile ? 45 : 50} alt="MMF" unoptimized />
                    </div>
                </div>

                <div className="flex flex-row pt-[2rem] pb-[3rem] laptop:pb-0">
                    <div className="flex justify-items-center">
                        <Image src={era} height={mobile ? 80 : 95} width={mobile ? 80 : 95} alt="ERA" unoptimized />
                    </div>
                </div>
            </div>
        );
    };

    const renderLinks = (general?: boolean) => {
        return (
            <>
                <Typography className="mt-[1.5rem] laptop:mt-[3rem] cursor-default" variant="captionBold" uppercase>
                    {general ? 'General' : 'Socials'}
                </Typography>
                {general
                    ? showCollectorsClubLink().map((link) =>
                          link.type === 'general' ? (
                              <StyledLink
                                  className="hover:underline"
                                  key={`Link_${link.label}`}
                                  label={link.label.toLowerCase()}
                                  url={link.url}
                              />
                          ) : null,
                      )
                    : showCollectorsClubLink().map((link, index) =>
                          link.type === 'social' ? (
                              <StyledLink
                                  className="hover:underline"
                                  key={`Link_${link.label}`}
                                  label={link.label.toLowerCase()}
                                  url={link.url}
                              />
                          ) : null,
                      )}
            </>
        );
    };

    return (
        <Container className="mt-[1rem]">
            <div className="tablet:hidden py-[4rem]">
                <div className="flex justify-around justify-items-center">{renderIcons(true)}</div>
                <div className="flex justify-center">
                    <div className="mr-[8rem]" data-gtm-id="general">
                        {renderLinks(true)}
                    </div>
                    <div data-gtm-id="social">{renderLinks()}</div>
                </div>
            </div>
            <div className="hidden tablet:grid py-[4rem] laptop:hidden">
                <div className="flex justify-center justify-items-center">{renderIcons()}</div>
                <div className="flex justify-center">
                    <div className="mr-[12rem]" data-gtm-id="general">
                        {renderLinks(true)}
                    </div>
                    <div data-gtm-id="social">{renderLinks()}</div>
                </div>
            </div>
            <div className="hidden laptop:grid laptop:grid-cols-2 pt-[3rem] pb-[4rem]">
                <div>
                    <div className="flex justify-items-center">{renderIcons()}</div>
                </div>
                <div className="flex justify-end desktop:mr-[10rem]" data-gtm-id="general">
                    <div className="mr-[12rem]">{renderLinks(true)}</div>
                    <div data-gtm-id="social">{renderLinks()}</div>
                </div>
            </div>
        </Container>
    );
}
