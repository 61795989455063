import React from 'react';
import { Typography } from '@srnade/component-ui';
import type { BaseComponentProps } from '@srnade/component-ui';

import { Link } from '@srnade/web/components';

export interface StyledLinkProps {
    label: string;
    url: string;
}

export function StyledLink({ className, label, url }: StyledLinkProps & BaseComponentProps): JSX.Element {
    const externalLink = url?.includes('https://' || 'http://');

    return (
        <div className={className}>
            {externalLink ? (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <Typography className="mt-[1.5rem]" variant="bodyBold" uppercase>
                        {label}
                    </Typography>
                </a>
            ) : (
                <Link href={url} passHref legacyBehavior>
                    <Typography className="mt-[1.5rem]" variant="bodyBold" uppercase>
                        {label}
                    </Typography>
                </Link>
            )}
        </div>
    );
}
