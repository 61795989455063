import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import { Typography, BaseComponentProps } from '@srnade/component-ui';
import { useIsomorphicLayoutEffect, useWindowSize } from 'react-use';

import styles from './ExpandableText.module.scss';

interface ExpandableTextProps {
    collapsedLines?: 3 | 4 | 5;
    children: string;
}

export function ExpandableText({
    className,
    collapsedLines = 3,
    children,
    ...rest
}: ExpandableTextProps & BaseComponentProps): JSX.Element {
    const [showContent, setShowContent] = useState(false);
    const [requireLineClap, setRequireLineClap] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();
    const lineClampClass = {
        3: styles.lineClampThree,
        4: styles.lineClampFour,
        5: styles.lineClampFive,
    };

    useIsomorphicLayoutEffect(() => {
        const ele = ref?.current;

        if (ele && ele.scrollHeight > ele.clientHeight) {
            setRequireLineClap(true);
        } else {
            setRequireLineClap(false);
        }
    }, [ref, width, children]);

    const { t } = useTranslation('common', { keyPrefix: 'buttonText' });

    return (
        <>
            <Typography
                ref={ref}
                dangerouslySetInnerHTML={{ __html: children }}
                className={clsx(className, styles.root, 'mt-[1rem] cursor-default', {
                    [lineClampClass[collapsedLines]]: !showContent,
                })}
                variant="body"
                {...rest}
            ></Typography>

            {requireLineClap && (
                <Typography
                    variant="body"
                    className="underline laptop:hover:no-underline laptop:cursor-pointer capitalize"
                    onClick={() => setShowContent(!showContent)}
                >
                    {showContent ? t('readLess') : t('readMore')}
                </Typography>
            )}
        </>
    );
}
