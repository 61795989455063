import React from 'react';
import type { BaseComponentProps } from '@srnade/component-ui';
import Head from 'next/head';
import { useRouter } from 'next/router';

import {
    Navbar,
    Footer,
    Breadcrumb,
    Breadcrumbs,
    Container,
    AnnouncementBar,
    SubscribeBanner,
    Faqs,
    LoadingStateWrapper,
} from '@srnade/web/components';

interface PageTemplateProps {
    title?: string;
    description?: string;
    breadCrumbs?: Breadcrumb[];
    showSubscribeBanner?: boolean;
    showFaqs?: boolean;
    showVerifyEmailBanner?: boolean;
    showNavMenu?: boolean;
    showProfileMenu?: boolean;
    dataTestId?: string;
    stickyNav?: boolean;
    ogTags?: {
        type?: string; // https://ogp.me/#types / https://ogp.me/#type_music / https://ogp.me/#type_video
        image?: {
            url: string;
            width?: string;
            height?: string;
        }; // https://ogp.me/#structured
    };
}

// @todo move the header and footer to a separate component(Layout) and import it directly inside the _app component
// so it will be generated only once. Currently this component is generated for each page.
export function PageTemplate({
    title,
    description,
    breadCrumbs,
    children,
    className,
    showSubscribeBanner,
    showFaqs,
    showVerifyEmailBanner,
    showNavMenu = true,
    showProfileMenu = true,
    dataTestId,
    stickyNav,
    ogTags,
}: PageTemplateProps & BaseComponentProps) {
    const { asPath } = useRouter();

    const addCanonicalTag = asPath.includes('@'); // any page with @ in the path requires a canonical tag
    const canonicalUrl =
        addCanonicalTag && typeof window !== 'undefined' ? `${window.location.origin}${asPath.toLowerCase()}` : '';
    const originUrl = typeof window !== 'undefined' ? window.location.origin : '';
    const desc = description ?? 'The home of eco-friendly Digital Pressings';

    return (
        <>
            <Head>
                {/* HTML Meta Tags */}
                {title && <title>{title}</title>}
                {desc && <meta name="description" content={desc} key="description" />}
                {/* OpenGraph Meta Tags */}
                {title && <meta name="og:title" content={title} key="title" />}
                {desc && <meta name="og:description" content={desc} key="description" />}
                <meta property="og:type" content={ogTags?.type ?? 'website'} />
                {ogTags?.image && (
                    <>
                        <meta property="og:image" content={ogTags.image.url} />
                        <meta property="og:image:width" content={ogTags.image.width} />
                        <meta property="og:image:height" content={ogTags.image.height} />
                    </>
                )}
                {canonicalUrl && <meta name="og:url" content={`${window.location.origin}${asPath.toLowerCase()}`} />}
                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                {originUrl && <meta property="twitter:domain" content={originUrl} />}
                {canonicalUrl && (
                    <meta property="twitter:url" content={`${window.location.origin}${asPath.toLowerCase()}`} />
                )}
                {title && <meta name="twitter:title" content={title} />}
                {desc && <meta name="twitter:description" content={desc} />}
                {ogTags?.image && <meta name="twitter:image" content={ogTags.image.url} />}
                {/* Canonical URL */}
                {canonicalUrl && <link rel="canonical" href={`${window.location.origin}${asPath.toLowerCase()}`} />}
            </Head>
            <LoadingStateWrapper>
                <div data-testid={dataTestId} className="h-full w-full bg-cream">
                    <AnnouncementBar subscribeBar={showSubscribeBanner} verifyEmailBar={showVerifyEmailBanner} />
                    <Navbar sticky={stickyNav} showNavMenu={showNavMenu} showProfileMenu={showProfileMenu} />
                    {breadCrumbs && (
                        <Container>
                            <Breadcrumbs links={breadCrumbs} />
                        </Container>
                    )}
                    <div className={className}>{children}</div>
                    {showFaqs && (
                        <Container className="mb-[6rem]">
                            <Faqs />
                        </Container>
                    )}
                    {showSubscribeBanner && (
                        <div id="subscribe">
                            <SubscribeBanner />
                        </div>
                    )}
                    <Footer />
                </div>
            </LoadingStateWrapper>
        </>
    );
}
