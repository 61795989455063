import { gql } from '@apollo/client';

/**
 * Queries
 */

export const GET_ASSET_STREAMING_URL = gql`
    query GetAssetStreamingUrl($input: AssetStreamingUrlInput!) {
        formatInstanceAssetStreamingUrl(input: $input)
    }
`;

export const GET_ASSET_DOWNLOAD_URL = gql`
    query GetAssetDownloadUrl($input: FormatInstanceAssetDownloadInput!) {
        formatInstanceAssetDownloadUrl(input: $input)
    }
`;

export const GET_VIDEO_ASSET_THUMBNAIL_URL = gql`
    query GetVideoAssetThumbnailUrl($input: FormatInstanceVideoAssetThumbnailUrlInput!) {
        formatInstanceVideoAssetThumbnailUrl(input: $input)
    }
`;

export const GET_COMPRESSED_TRACK_DOWNLOAD_INFO = gql`
    query GetCompressedTrackDownloadInfo($editionId: String!) {
        compressedTrackDownloadInfo(editionId: $editionId) {
            url
            userDownloadCount
        }
    }
`;

export const GET_EDITION_FORMAT = gql`
    query GetEditionFormat($filters: EditionFormatFilters!) {
        editionFormat(filters: $filters) {
            artist {
                name
                profilePicUrl
                username
            }
            artworkUrl
            behindTheMusic
            collectible {
                audio {
                    assetId
                    mimeType
                }
                image {
                    assetId
                    mimeType
                }
                video {
                    assetId
                    mimeType
                }
            }
            contentConsumable
            editionId
            editionOwner {
                profilePicUrl
                username
            }
            editionSequence
            editionSize
            editionStatus
            formatInstanceId
            formatInstanceStatus
            formatSubType
            formatType
            limited
            parentRecordLabel
            plannedCloseDate
            plannedCloseDateTimezone
            plannedReleaseDate
            plannedReleaseDateTimezone
            plannedPreOrderStartDate
            plannedPreOrderStartDateTimezone
            preOrder
            pressing {
                bonusContent {
                    active
                    assetId
                    displayName
                    downloadable
                    duration
                    fileSize
                    mimeType
                    streamable
                }
                compressedTracks {
                    downloadLimit
                    userDownloadCount
                    fileSize
                }
                credits {
                    artDirectionBy
                    artworkBy
                    creativeDirectionBy
                    designedBy
                    performedBy
                    photographyBy
                    producedBy
                    writtenBy
                }
                tracks {
                    active
                    assetId
                    displayName
                    downloadable
                    duration
                    fileSize
                    mimeType
                    streamable
                    trackNumber
                    fileExtension
                }
            }
            productId
            productStatus
            recordLabel
            slug
            soldOut
            termsAndConditionsUrl
            title
        }
    }
`;

export const GET_EDITION_FORMATS_FOR_PROFILE_PAGE = gql`
    query GetEditionFormatsForProfilePage($filters: EditionFormatsFilters!, $first: Int, $after: String) {
        editionFormats(filters: $filters, first: $first, after: $after) {
            edges {
                cursor
                node {
                    editionId
                    title
                    slug
                    productStatus
                    editionSequence
                    editionSize
                    artworkUrl
                    editionOwner {
                        profilePicUrl
                        username
                    }
                    formatType
                    formatSubType
                    limited
                    preOrder
                    soldOut
                    editionStatus
                    tokenId
                    artist {
                        name
                        profilePicUrl
                        username
                    }
                    contentConsumable
                    wholesale
                    plannedCloseDate
                    plannedCloseDateTimezone
                    plannedReleaseDate
                    plannedReleaseDateTimezone
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                totalCount
                startCursor
            }
        }
    }
`;
