import { Button } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';

import { Link, LinkWrapper } from '@srnade/web/components';
import { HeroHeading } from './HeroHeading.component';

export interface HeroActionProps {
    onStartCollectingClick: () => void;
}

export const HeroActions = ({ onStartCollectingClick }: HeroActionProps) => {
    const { t } = useTranslation('homepage', { keyPrefix: 'heroBanner' });

    return (
        <div className="mb-[5.5rem] w-full tablet:mb-0">
            <HeroHeading />
            <div className="tablet:max-w-[29.6rem]" data-gtm-id="home_top_cta">
                <LinkWrapper href="shop-all" handleClick={() => onStartCollectingClick()}>
                    <Button className="mt-[4rem] w-full" text={t('startCollectingButton')} />
                </LinkWrapper>
                <Link href="about" passHref legacyBehavior>
                    <Button type="secondary" setIcon={false} className="mt-[2rem] w-full" text={t('learnMoreButton')} />
                </Link>
            </div>
        </div>
    );
};
