import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { FindHeroBannerListingsQuery, FindHeroBannerListingsQueryVariables } from '__generated__/graphql';
import { FIND_HERO_BANNER_LISTINGS } from '@srnade/web/services';
import client from 'clients/apollo';

export enum ListingsStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Failed = 'Failed',
}

export interface ListingsState {
    listings: FindHeroBannerListingsQuery | null;
    status: ListingsStatus;
    error: string | null | undefined;
}

const initialState: ListingsState = {
    listings: null,
    status: ListingsStatus.Idle,
    error: null,
};

// TODO: move this to a separate file once we have data fetching logic in place
export const fetchListings = createAsyncThunk('listings/fetchListings', async (_, thunkApi) => {
    try {
        const response = await client.query<FindHeroBannerListingsQuery, FindHeroBannerListingsQueryVariables>({
            query: FIND_HERO_BANNER_LISTINGS,
            fetchPolicy: 'cache-first', // 'cache-and-network',
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

const listingsSlice = createSlice({
    name: 'listings',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setListings(_, action) {
            return {
                status: action.payload?.status ?? ListingsStatus.Idle,
                listings: action.payload?.listings ?? null,
                error: action.payload?.error ?? null,
            };
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // Please read https://github.com/kirill-konshin/next-redux-wrapper?tab=readme-ov-file#state-reconciliation-during-hydration
            .addCase(HYDRATE, (state, action: PayloadAction<FindHeroBannerListingsQuery, typeof HYDRATE>) => {
                state.status = ListingsStatus.Idle;
                state.listings = action.payload;
                state.error = null;
            })
            .addCase(fetchListings.pending, (state) => {
                state.status = ListingsStatus.Pending;
            })
            .addCase(fetchListings.fulfilled, (state, action) => {
                state.status = ListingsStatus.Idle;
                state.listings = action.payload;
                state.error = null;
            })
            .addCase(fetchListings.rejected, (state, action) => {
                state.status = ListingsStatus.Failed;
                if (action.payload instanceof Error) {
                    // For example this could be validation error handling
                    state.error = action?.payload?.message;
                } else {
                    state.error = action.error.message;
                }
            });
    },
});

export const { setListings } = listingsSlice.actions;

export const listingsReducer = listingsSlice.reducer;

export default listingsSlice;
