export enum ProductStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
}

export enum OriginalProductTag {
    Original = 'Original', // This isn't a real product tag, but we need at least one
}

export const ProductTagsHumanized: { [key: string]: string } = {};

export enum ProductType {
    Pressing = 'Pressing',
    Collectible = 'Collectible',
}

export enum PressingType {
    ExtendedPlay = 'ExtendedPlay',
    Single = 'Single',
    Album = 'Album',
}

export const PressingTypeHumanized: { [key: string]: string } = {
    [PressingType.ExtendedPlay]: 'EP',
    [PressingType.Single]: 'Single',
    [PressingType.Album]: 'Album',
};
