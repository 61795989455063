import React, { createContext, PropsWithChildren } from 'react';
import { v4 as uuidV4 } from 'uuid';
// see https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#get-treatments-with-configurations
//     https://help.split.io/hc/en-us/articles/360038825091-React-SDK
import {
    ISplitTreatmentsChildProps,
    SplitClient,
    SplitFactoryProvider,
    useSplitTreatments,
} from '@splitsoftware/splitio-react';

import {
    TreatmentDisplay,
    TreatmentDisplayMap,
    treatmentNames,
    TreatmentType,
    TreatmentTypeMap,
} from '../../constants';

import { useAuth } from '@srnade/web/auth/AuthProvider.component';
import { getSplitSdkConfig } from 'clients/splitio';

export type FeatureFlagsContextType = ISplitTreatmentsChildProps & {
    useFeatureOn: (treatmentType: TreatmentType) => boolean;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextType | null>(null);

export const FeatureFlagsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const treatments = useSplitTreatments({ names: treatmentNames });

    const useFeatureOn = (treatmentType: TreatmentType): boolean => {
        const featureName: string = TreatmentTypeMap[treatmentType];
        const treatment: SplitIO.TreatmentWithConfig | undefined = treatments.treatments[featureName];

        if (!treatment) {
            return false;
        }

        return treatment.treatment === TreatmentDisplayMap[TreatmentDisplay.On];
    };

    return (
        <FeatureFlagsContext.Provider
            value={{
                ...treatments,
                useFeatureOn,
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    );
};

const anonymous_userId = `anonymous_${uuidV4()}`;

// Wrapper component for split sdk initialisation
export const FeatureFlagsProviderWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const { user } = useAuth();
    const userId = user?.id ?? anonymous_userId;
    const splitConfig = getSplitSdkConfig(userId);

    return (
        <SplitFactoryProvider config={splitConfig}>
            <SplitClient splitKey={userId}>
                <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
            </SplitClient>
        </SplitFactoryProvider>
    );
};
