import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';

import { ControlPanel, Slider, TrackDetails } from './components';

interface DisabledStreamPlayerProps {
    showPlayerOnly?: boolean;
}

export function DisabledStreamPlayer({ showPlayerOnly = false }: DisabledStreamPlayerProps) {
    const { t } = useTranslation('components', {
        keyPrefix: 'audioPlayer',
    });
    const disabled = true;

    return (
        <div className={clsx('flex', { 'px-[2rem] tablet:px-0': !showPlayerOnly })}>
            <ControlPanel
                onPlayPause={() => {}}
                isPlaying={false}
                skipBack={() => {}}
                skipToNext={() => {}}
                tracks={[]}
                showPlayerOnly={showPlayerOnly}
                disabled={disabled}
            />
            {!showPlayerOnly && (
                <div className="ml-[1.6rem] flex-grow laptop:flex-grow-0">
                    <TrackDetails
                        title={t('disabledPlayerTrackName')}
                        currentTime={0}
                        duration={0}
                        disabled={disabled}
                    />
                    <div className="mt-[0.5rem]">
                        <Slider
                            percentage={0}
                            disabled={disabled}
                            onSeek={() => {}}
                            onSeekMouseDown={() => {}}
                            onSeekMouseUp={() => {}}
                            onDuration={() => {}}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
