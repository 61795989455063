import { addUsernamePrefix } from '@srnade/component-ui';
import { Product } from '../__generated__/graphql';

export type EditionWithSaleType = {
    product: Pick<Product, 'listings'>;
};

// Edition for ProductTile and EditionWithSaleType for TransferNft
export const getSalesType = (edition: EditionWithSaleType) => {
    return edition?.product?.listings?.[0]?.saleType ?? null;
};

export const getEditionUrlPath = (artistUsername: string, slug: string, sequence: number) => {
    return `/${addUsernamePrefix(artistUsername)}/${slug}/${sequence}`;
};
