/**
 * Removes a trailing slash from a string
 * @param str string
 * @returns {string}
 */
export const removeTrailingSlash = (str: string): string => {
    return str.replace(/\/+$/, '');
};

/**
 * @param {string} html
 * @returns {string}
 */
export const stripHtml = (html: string): string => {
    return html.replace(/(<([^>]+)>)/gi, '');
};
