import { useMedia } from 'react-use';

export const useDevice = () => {
    // the below breakpoints have been defined in the tailwind.root.preset.js
    const mobile = useMedia('(min-width: 320px) and (max-width: 833px)', false);
    const tablet = useMedia('(min-width: 833px) and (max-width: 1289px)', false);
    const laptop = useMedia('(min-width: 1290px) and (max-width: 1439px)', false);
    const desktop = useMedia('(min-width: 1440px)', false);

    return {
        mobile,
        tablet,
        laptop,
        desktop,
    };
};
