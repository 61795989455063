import React from 'react';
import Image from 'next/legacy/image';
import clsx from 'clsx';
import { isFuture } from 'date-fns';
import { motion } from 'framer-motion';

import { Typography, Card, CardHeader, CardContent } from '@srnade/component-ui';
import { addUsernamePrefix, Currency, time } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';
import { formatPrice, imageHandlerBlur, imageHandlerLoader } from '@srnade/web/utils';
import { Avatar } from '@srnade/web/components/Avatar';
import { LinkWrapper } from '@srnade/web/components/LinkWrapper';
import { FormatSubType, FormatType, ProductStatusNew } from '@srnade/web/__generated__/graphql';
import { FormatSubTypeHumanized } from '@srnade/web/const';
import { RemainingTime } from '@srnade/web/components/Tiles/RemainingTime/RemainingTime.component';

export type ProductFormatTileType = {
    title: string;
    formatType: FormatType;
    formatSubType: FormatSubType;
    artworkUrl: string;
    productStatus: ProductStatusNew;
    price?: number | null;
    currency?: Currency | null;
    limited: boolean;
    preOrder?: boolean;
    plannedCloseDate?: string | null;
    plannedReleaseDate?: string | null;
    editionSize?: number | null;
    wholesale?: boolean | null;
    artist: {
        username: string;
        name: string;
        profilePicUrl?: string | null;
    };
};

export interface ProductTileProp {
    productFormat: ProductFormatTileType;
    url?: string;
    className?: string;
    type?: 'standard' | 'carousel';
    hoverable?: boolean;
    clickable?: boolean;
    disabled?: boolean;
}

interface PriceProps {
    currency?: Currency | null;
    price?: number | null;
}

function Price({ currency, price }: PriceProps): React.ReactNode {
    if (!currency || !price) {
        return <Typography variant="subheadingBold"></Typography>;
    }

    return <Typography variant="subheadingBold">{formatPrice(currency, price)}</Typography>;
}

export function ProductTile({
    type = 'standard',
    hoverable = false,
    clickable = false,
    disabled = false,
    className,
    productFormat,
    url,
}: ProductTileProp): React.ReactNode {
    const { t } = useTranslation('components', { keyPrefix: 'productTile' });

    const pressingType = productFormat.formatSubType;
    const single = pressingType === FormatSubType.SINGLE;
    const album = pressingType === FormatSubType.ALBUM;
    const ep = pressingType === FormatSubType.EP;

    const preOrder = productFormat?.preOrder || productFormat?.productStatus === ProductStatusNew.PRE_ORDER;
    const limited = Boolean(productFormat?.limited && productFormat?.editionSize);
    const wholesale = Boolean(productFormat?.wholesale);

    // ONLY show timer if product is PRE_ORDER or RELEASED
    //   and if it's a PRE-ORDER or NOT limited edition or NOT wholesale
    const showRemainingTime =
        (preOrder || !limited || !wholesale) &&
        productFormat?.productStatus &&
        (ProductStatusNew.PRE_ORDER === productFormat.productStatus ||
            ProductStatusNew.RELEASED === productFormat.productStatus);

    // Limited edition = NO unless in pre order. If in pre order Yes, until RELEASE
    // Pre order = YES, until RELEASE
    // Whole sale = YES, until RELEASE
    // Limited time = YES, until RELEASE and CLOSE
    let remainingTime: string | undefined;
    if (preOrder) {
        remainingTime =
            productFormat?.plannedReleaseDate && isFuture(new Date(productFormat.plannedReleaseDate))
                ? time.formatDistanceToNow(new Date(productFormat.plannedReleaseDate))
                : '0s';
    } else if (!limited || !wholesale) {
        remainingTime =
            productFormat?.plannedCloseDate && isFuture(new Date(productFormat.plannedCloseDate))
                ? time.formatDistanceToNow(new Date(productFormat.plannedCloseDate))
                : '0s';
    }

    return (
        <motion.div
            className={className}
            whileHover={hoverable ? { y: -15 } : { y: 0 }}
            whileTap={hoverable ? { scale: 0.98 } : { scale: 1 }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            data-gtm-id="product_tile"
            data-testid="product-tile"
        >
            <Card
                className={clsx('rounded-2xl p-[1rem]', {
                    'w-full h-full tablet:h-[51.5rem] tablet:w-[33.5rem] laptop:w-[29.5rem] laptop:h-[47.5rem]':
                        type === 'standard',
                    'max-w-[29.5rem] h-auto': type === 'carousel',
                    'h-[27.2rem]': type === 'carousel',
                })}
                border
                disabled={disabled}
            >
                <LinkWrapper href={!disabled && clickable ? url : undefined}>
                    <CardHeader
                        className={clsx('relative', {
                            ['opacity-40']: productFormat?.productStatus === ProductStatusNew.ACTIVE,
                            'h-[27.2rem]': type === 'carousel',
                        })}
                    >
                        <Image
                            className="object-cover"
                            alt={productFormat.title}
                            src={productFormat.artworkUrl}
                            layout="responsive"
                            width={100}
                            height={100}
                            loader={imageHandlerLoader}
                            placeholder="blur"
                            blurDataURL={imageHandlerBlur}
                            priority
                        />
                        {preOrder && (
                            <Typography
                                className="bg-white text-center absolute top-[1rem] right-[1rem] rounded-md inline-flex items-center justify-center leading-none px-[1rem] py-[0.70rem]"
                                variant="captionBold"
                                uppercase
                                data-testid="pre-order-badge"
                            >
                                {t('preOrder')}
                            </Typography>
                        )}
                    </CardHeader>
                </LinkWrapper>
                <CardContent className="m-[1rem]">
                    <div className="flex items-center">
                        <LinkWrapper
                            href={
                                !disabled && clickable && productFormat.artist.username
                                    ? `/${addUsernamePrefix(productFormat.artist.username)}`
                                    : ''
                            }
                        >
                            <div className="flex items-center mt-[1rem]" data-testid="product-artist">
                                <Avatar
                                    className="mr-[1rem] shrink-0"
                                    src={productFormat.artist.profilePicUrl}
                                    name={productFormat.artist.name}
                                    size="small"
                                />
                                <div className="tablet:hidden">
                                    <Typography
                                        variant="body"
                                        className={clsx('line-clamp-2 mr-[0.5rem]', {
                                            'hover:underline': clickable,
                                        })}
                                        data-gtm-id="productFormat.artist_name"
                                    >
                                        {productFormat.artist.name}
                                    </Typography>
                                </div>
                                <div className="hidden tablet:flex">
                                    <Typography
                                        variant="body"
                                        className={clsx('line-clamp-2 mr-[0.5rem]', {
                                            'hover:underline': clickable,
                                            'truncate text-wrap max-w-[23.5rem] laptop:max-w-[20rem]':
                                                productFormat.formatType === FormatType.DIGITAL_COLLECTIBLE,
                                            'truncate text-wrap max-w-[13.5rem] laptop:max-w-[12rem]': single || album,
                                            'truncate text-wrap max-w-[18.5rem] laptop:max-w-[14.5rem]': ep,
                                        })}
                                        data-gtm-id="productFormat.artist_name"
                                    >
                                        {productFormat.artist.name}
                                    </Typography>
                                </div>
                            </div>
                        </LinkWrapper>
                        <div className="ml-auto flex items-center">
                            {productFormat.formatType === FormatType.DIGITAL_PRESSING &&
                                productFormat.formatSubType && (
                                    <div className="border rounded-lg px-[1rem] h-[3rem] mt-[1.5rem] flex items-center cursor-default">
                                        <Typography variant="body">
                                            {FormatSubTypeHumanized[productFormat.formatSubType]}
                                        </Typography>
                                    </div>
                                )}
                        </div>
                    </div>
                    <LinkWrapper href={!disabled && clickable ? url : undefined}>
                        <Typography
                            className="h-[2.5rem] my-[1.5rem] line-clamp-1"
                            variant="subheadingBold"
                            data-gtm-id="album_name"
                        >
                            {productFormat.title}
                        </Typography>
                        {wholesale ? (
                            <div
                                className={clsx({
                                    'grid grid-rows-2 grid-flow-col': showRemainingTime,
                                })}
                            >
                                {showRemainingTime === true && <span />}
                                <div className="flex justify-between">
                                    <Typography
                                        className={clsx('opacity-30', {
                                            'mt-[2.5rem]': !showRemainingTime,
                                        })}
                                    >
                                        {t('exclusive')}
                                    </Typography>
                                </div>
                                {showRemainingTime === true && remainingTime && (
                                    <RemainingTime remainingTime={remainingTime} preOrder={preOrder} />
                                )}
                            </div>
                        ) : (
                            <div
                                className={clsx('grid grid-flow-col', {
                                    'grid-rows-2': !limited,
                                })}
                            >
                                {limited ? (
                                    <>
                                        <div className="mt-[2.5rem]">
                                            <Price currency={productFormat.currency} price={productFormat.price} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Typography className="mb-[0.5rem]" variant="body">
                                            {t('limitedTime')}
                                        </Typography>
                                        <Price currency={productFormat.currency} price={productFormat.price} />
                                        {showRemainingTime === true && remainingTime && (
                                            <RemainingTime remainingTime={remainingTime} preOrder={preOrder} />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </LinkWrapper>
                </CardContent>
            </Card>
        </motion.div>
    );
}
