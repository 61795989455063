import React, { useState } from 'react';
import { Typography, Icon, BaseComponentProps } from '@srnade/component-ui';
import clsx from 'clsx';
import { useTranslation } from '@srnade/web/i18n/client';
import { motion } from 'framer-motion';

export interface ButtonTileProps {
    header?: string;
    listHeading?: string;
    icon?: string;
    iconSize?: number;
    list?: string[];
    selected?: boolean;
    disabled?: boolean;
}

export function ButtonTile({
    header,
    listHeading,
    icon,
    iconSize = 34,
    list,
    selected,
    disabled,
    children,
}: ButtonTileProps & BaseComponentProps): JSX.Element {
    const [showContent, setShowContent] = useState(false);

    const { t } = useTranslation('common', { keyPrefix: 'buttonText' });

    return (
        <motion.div
            className={clsx(
                'h-full rounded-[1rem] bg-black p-[0.1rem] border-none relative',
                { 'bg-gradient-to-bl': selected },
                {
                    'hover:bg-gradient-to-bl from-gradientGreen via-gradientBlue to-gradientPurple': !disabled,
                },
                { 'laptop:cursor-default': selected },
                { 'laptop:cursor-pointer': !selected && !disabled },
                { 'opacity-30 laptop:cursor-default': disabled },
            )}
            whileTap={disabled || selected ? { scale: 1 } : { scale: 0.98 }}
        >
            <div
                className={clsx(
                    'h-full bg-cream rounded-[1rem] pt-[2rem] px-[2rem]',
                    {
                        'pb-[2rem]': children,
                    },
                    { 'bg-white': selected && !disabled },
                )}
            >
                <div className="flex items-center">
                    {icon && <Icon icon={icon} size={iconSize} />}
                    <Typography className={clsx({ 'ml-[2rem]': icon })} variant="subheadingBold">
                        {header}
                    </Typography>
                </div>
                <div className="mt-[2rem] border-b-[0.1rem] border-slate-200" />
                {listHeading && (
                    <Typography className="my-[2rem]" variant="body">
                        {listHeading}
                    </Typography>
                )}
                <div className={clsx({ flex: !showContent && list })}>
                    {list && (
                        <>
                            <div className={clsx('tablet:hidden w-full', { 'line-clamp-1': !showContent })}>
                                {list.map((item) => (
                                    <ul key={item}>
                                        <li>
                                            <Typography variant="body">{item}</Typography>
                                        </li>
                                        &nbsp;
                                    </ul>
                                ))}
                            </div>
                            <div className={clsx('hidden tablet:block')}>
                                {list.map((item) => (
                                    <ul key={item}>
                                        <li>
                                            <Typography variant="body">{item}</Typography>
                                        </li>
                                        &nbsp;
                                    </ul>
                                ))}
                            </div>
                        </>
                    )}
                    {children && (
                        <div className="mt-[2rem]">
                            <Typography
                                className={clsx('tablet:hidden w-full', { 'line-clamp-2': !showContent })}
                                variant="body"
                            >
                                {children}
                            </Typography>
                            <Typography className={clsx('hidden tablet:block')} variant="body">
                                {children}
                            </Typography>
                        </div>
                    )}
                    <div className="flex tablet:hidden">
                        {showContent && (
                            <Typography className={clsx({ 'ml-[1rem]': list })} variant="body">
                                ...
                            </Typography>
                        )}
                        <Typography
                            variant="body"
                            className={clsx('capitalize w-[13rem] underline', {
                                'ml-[0.5rem] mb-[2rem]': showContent,
                            })}
                            onClick={() => setShowContent(!showContent)}
                        >
                            {showContent ? t('readLess') : t('readMore')}
                        </Typography>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
