import { GetAssetStreamingUrlQuery, GetAssetStreamingUrlQueryVariables } from '@srnade/web/__generated__/graphql';
import client, { useLazyQuery } from '@srnade/web/clients/apollo';
import { GET_ASSET_STREAMING_URL } from '@srnade/web/services';
import { getTokenExpiryFromUrl } from '@srnade/web/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

type VideoFilePreviewProps = {
    formatInstanceId: string;
    assetId: string;
    loop?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    controls?: boolean;
};

export const VideoFilePreview: React.FC<VideoFilePreviewProps> = ({
    assetId,
    formatInstanceId,
    loop,
    autoPlay,
    muted,
    controls,
}) => {
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [tokenExpiry, setTokenExpiry] = useState<number | null>(null);
    const [shouldFetchStreamUrl, setShouldFetchStreamUrl] = useState<boolean>(true);

    const refreshTokenTimerRef = useRef<NodeJS.Timeout>();
    const playerRef = useRef<ReactPlayer>(null);

    const [getAudioStreamingUrl] = useLazyQuery<GetAssetStreamingUrlQuery, GetAssetStreamingUrlQueryVariables>(
        GET_ASSET_STREAMING_URL,
        {
            variables: { input: { assetId, formatInstanceId } },
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
            onCompleted: (data) => {
                const streamUrl = data.formatInstanceAssetStreamingUrl;

                const tokenExpiryFromUrl = getTokenExpiryFromUrl(streamUrl);
                if (tokenExpiryFromUrl) {
                    setTokenExpiry(tokenExpiryFromUrl - Date.now());
                }
                setUrl(streamUrl);
                setShouldFetchStreamUrl(false);
                clearRefreshTokenTimer();
            },
        },
    );

    const clearRefreshTokenTimer = useCallback(() => {
        if (refreshTokenTimerRef.current) {
            clearTimeout(refreshTokenTimerRef.current);
            refreshTokenTimerRef.current = undefined;
        }
    }, []);

    // deal with current time when token is refreshed
    useEffect(() => {
        playerRef.current?.seekTo(playerRef.current?.getCurrentTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]); // ignoring currentTime dep here as we only want to set this when url changes

    useEffect(() => {
        if (tokenExpiry && !refreshTokenTimerRef.current) {
            refreshTokenTimerRef.current = setTimeout(() => {
                setShouldFetchStreamUrl(true);
                clearRefreshTokenTimer();
            }, tokenExpiry - 10000); // tokenExpiry minus 10 seconds
        }

        return () => clearRefreshTokenTimer();
    }, [tokenExpiry, clearRefreshTokenTimer]);

    useEffect(() => {
        if (assetId && formatInstanceId && shouldFetchStreamUrl) {
            getAudioStreamingUrl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId, formatInstanceId, shouldFetchStreamUrl]);

    return (
        <>
            {url && (
                <div key={assetId}>
                    <ReactPlayer
                        id={assetId}
                        data-testid="file-preview-video"
                        key={assetId}
                        ref={playerRef}
                        url={url}
                        width="100%"
                        height="100%"
                        playing={autoPlay}
                        loop={loop}
                        muted={muted}
                        controls={controls}
                        onError={(error) => {
                            console.error('ReactPlayer::onError');
                            console.error(error);
                        }}
                    />
                </div>
            )}
        </>
    );
};
