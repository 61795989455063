// https://github.com/formium/formik/blob/master/examples/MultistepWizard.js

import React, { createContext, useState } from 'react';
import { type BaseComponentProps, Stepper } from '@srnade/component-ui';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';

interface FormikWizardStepProps<FormValues> {
    id?: string;
    stepName: string;
    validationSchema: any;
    onSubmit?: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<any>;
    children: React.ReactElement;
}

export interface FormikWizardContextInterface {
    nextStep: (values: any) => void;
    previousStep: (values: any) => void;
    gotoStep: (values: any, index: string) => void;
}

const FormikWizardContext = createContext({} as FormikWizardContextInterface);

export function useFormikWizard() {
    const context = React.useContext<FormikWizardContextInterface>(FormikWizardContext);

    if (context === undefined) {
        throw new Error('useFormikWizard must be used within a FormikWizardContext');
    }

    return context;
}

/**
 * Formik Wizard Step
 * Simple component that enables us to access the `validationSchema` and `onSubmit` props on a Step
 * from within the Formik Wizard
 */

export const FormikWizardStep = <FormValues extends FormikValues = FormikValues>({
    children,
}: FormikWizardStepProps<FormValues> & BaseComponentProps): JSX.Element => children;

/**
 * Formik Wizard
 */

interface FormikWizardProps<FormValues> {
    initialValues: FormValues;
    onSubmit?: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<any>;
}

export const FormikWizard = <FormValues extends FormikValues = FormikValues>({
    initialValues,
    onSubmit,
    children,
}: FormikWizardProps<FormValues> & BaseComponentProps): JSX.Element => {
    // Current step (index)
    const [stepIndex, setStepIndex] = useState(0);
    // All steps (React children)
    const steps = React.Children.toArray(children) as React.ReactElement[];

    const stepItems = steps.map((step, index) => {
        return {
            number: index + 1,
            label: step.props.stepName,
        };
    });

    // Snapshot values (captured between pages)
    const [snapshot, setSnapshot] = useState<FormValues>(initialValues);

    // Current step (React child)
    const step = steps[stepIndex];
    // Total number of steps
    const totalSteps = steps.length;
    // Are we on the last step?
    const isLastStep = stepIndex === totalSteps - 1;

    /**
     * Go to the next step in the sequence
     * @param values Form values
     */
    const nextStep = (values: FormValues) => {
        setSnapshot(values);
        setStepIndex(Math.min(stepIndex + 1, totalSteps - 1));
    };

    /**
     * Go to the previous step in the sequence
     * @param values Form values
     */
    const previousStep = (values: FormValues) => {
        setSnapshot(values);
        setStepIndex(Math.max(stepIndex - 1, 0));
    };

    /**
     * Go to the previous step in the sequence
     * @param values Form values
     */
    const gotoStep = (values: FormValues, id: string) => {
        setSnapshot(values);
        const index = steps.findIndex((s) => {
            return s.props.id === id;
        });
        if (index) {
            setStepIndex(index);
        }
    };

    /**
     * Handle form submit
     * @param values Form values
     * @param bag Formik bag (see https://formik.org/docs/api/withFormik#the-formikbag)
     */
    const handleSubmit = async (values: FormValues, bag: FormikHelpers<FormValues>) => {
        if (step.props.onSubmit) {
            await step.props.onSubmit(values, bag);
        }
        if (isLastStep) {
            onSubmit && onSubmit(values, bag);
        } else {
            bag.setTouched({});
            nextStep(values);
        }
    };

    return (
        <FormikWizardContext.Provider value={{ nextStep, previousStep, gotoStep }}>
            <div className="w-full flex items-center flex-col">
                <Stepper className="w-full max-w-[64rem] mb-[9rem]" steps={stepItems} currentStep={stepIndex + 1} />
            </div>
            <Formik initialValues={snapshot} validationSchema={step.props.validationSchema} onSubmit={handleSubmit}>
                {(formik) => (
                    <>
                        <Form>{step}</Form>
                    </>
                )}
            </Formik>
        </FormikWizardContext.Provider>
    );
};
