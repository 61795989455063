import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENT } from './accounts.service';
import { PRODUCT_FRAGMENT } from './products.service';

/**
 * Types
 */

export interface FindEditionData {
    findEdition: {};
}

// @todo use one query with one args dto
export interface FindEditionsData {
    findEditionsByUsername: {};
}

/**
 * Fragments
 */

export const EDITION_TILE_FRAGMENT = gql`
    fragment EditionTileFragment on Edition {
        id
        sequence
        status
        tokenId
        transactionHash
        product {
            id
            status
            allowTransfers
            title
            slug
            additionalDetails
            minted
            limit
            thumbnail {
                id
                url
            }
            account {
                id
                name
                username
                avatar {
                    id
                    url
                }
            }
            productType
            pressing {
                id
                pressingType
            }
            listings {
                id
                saleType
            }
        }
    }
`;

export const EDITION_FRAGMENT = gql`
    fragment edition on Edition {
        id
        sequence
        status
        tokenId
        transactionHash
        product {
            ...product
        }
    }
    ${PRODUCT_FRAGMENT}
`;

/**
 * Mutations
 */
export const CREATE_DOWNLOADABLE_TRACKS_URL = gql`
    mutation CreateDownloadableTracksUrl($editionId: String!) {
        createDownloadableTracksUrl(editionId: $editionId)
    }
`;

/**
 * Queries
 */

export const FIND_EDITION_FOR_PRODUCT_PAGE = gql`
    query FindEditionForProductPage($productId: String!, $sequence: Float!) {
        findEdition(productId: $productId, sequence: $sequence) {
            id
            sequence
            status
            tokenId
            transactionHash
        }
    }
`;

export const FIND_EDITION = gql`
    query FindEdition($productId: String, $sequence: Float) {
        findEdition(productId: $productId, sequence: $sequence) {
            ...edition
        }
    }
    ${EDITION_FRAGMENT}
`;

export const FIND_EDITIONS_FOR_PROFILE_PAGE = gql`
    query FindEditionsForProfilePage($username: String!, $includeMinting: Boolean) {
        findEditionsByUsername(username: $username, includeMinting: $includeMinting) {
            ...EditionTileFragment
        }
    }
    ${EDITION_TILE_FRAGMENT}
`;

export const FIND_EDITION_BY_ID = gql`
    query FindEditionById($id: String!) {
        findEdition(id: $id) {
            ...EditionTileFragment
        }
    }
    ${EDITION_TILE_FRAGMENT}
`;

// This is a very expensive query, its better to use the find_editions above
export const FIND_EDITIONS = gql`
    query FindEditionsByUsername($username: String!, $includeMinting: Boolean) {
        findEditionsByUsername(username: $username, includeMinting: $includeMinting) {
            ...edition
        }
    }
    ${EDITION_FRAGMENT}
`;

export const FIND_TRACKS_DOWNLOAD_COUNT = gql`
    query FindTracksDownloadCount($editionId: String!) {
        findTracksDownloadCount(editionId: $editionId) {
            downloadCount
            allowedDownloadCount
        }
    }
`;
