import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';

import { Typography } from '@srnade/component-ui';

import { AuthGuard } from '@srnade/web/auth';
import { useTranslation } from '@srnade/web/i18n/client';
import { Container, SettingsLink } from '@srnade/web/components';
import { PageTemplate } from '@srnade/web/templates';

interface SettingsPageTemplateProps {
    children: ReactNode;
    artistOnly?: boolean;
    isArtist?: boolean;
}

export function SettingsPageTemplate({ children, artistOnly, isArtist }: SettingsPageTemplateProps) {
    const { t } = useTranslation('settings');
    const router = useRouter();

    return (
        <AuthGuard artistOnly={artistOnly}>
            <PageTemplate title={t('title')} breadCrumbs={[{ label: t('title') }]}>
                <Container gtmId="settings_container">
                    <Typography variant="h1">{t('title')}</Typography>
                    <div className="m-10  flex flex-col laptop:flex-row gap-y-[6rem] laptop:gap-x-[10rem] my-16">
                        <div
                            className="flex laptop:flex-col laptop:w-[30rem] gap-x-8 laptop:gap-y-8"
                            data-testid="settings-links"
                        >
                            <SettingsLink
                                href="/settings"
                                linkText={t('profile')}
                                isActive={router.route === '/settings'}
                            ></SettingsLink>

                            {isArtist && (
                                <SettingsLink
                                    href="/settings/payments"
                                    linkText={t('payments')}
                                    isActive={router.route === '/settings/payments'}
                                ></SettingsLink>
                            )}
                        </div>
                        <div className="flex flex-col flex-1" data-testid="settings-children">
                            {children}
                        </div>
                    </div>
                </Container>
            </PageTemplate>
        </AuthGuard>
    );
}
