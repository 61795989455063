import { useCallback } from 'react';
import type { BaseComponentProps } from '@srnade/component-ui';
import { FilePreview, FileUploadZone } from '@srnade/web/components';
import { CombinedFileConstraints } from '@srnade/component-ui';

export interface ReUploadImageProps {
    image: string;
    fileSizeLimit: number;
    fileConstraints?: CombinedFileConstraints;
    onError: (errorCode: string) => void;
    onAccepted: (file: File[]) => void;
}

export function ReUploadImage({
    image,
    onError,
    fileSizeLimit,
    fileConstraints,
    onAccepted,
}: ReUploadImageProps & BaseComponentProps) {
    const onDropError = useCallback(
        (error: string | string[]) => {
            if (Array.isArray(error) && error.length === 1) {
                onError?.(error[0]);
            } else if (typeof error === 'string') {
                onError?.(error);
            }
        },
        [onError],
    );
    return (
        <div className="w-full h-full flex justify-center flex-col basis-full p-[2rem] relative border rounded-[2rem]">
            <FilePreview imageUrl={image} />
            <div className="w-[5rem] h-[5rem] absolute border-0 right-[3rem] bottom-[3rem] cursor-pointer">
                <FileUploadZone
                    className="w-[5rem] h-[5rem] absolute border-0 right-[2.5rem] bottom-[2.5rem] cursor-pointer"
                    onError={onDropError}
                    maxSize={fileSizeLimit}
                    noDrag={true}
                    noTextContent={true}
                    onAccepted={onAccepted}
                    fileConstraints={fileConstraints}
                    reUploadImage
                />
            </div>
        </div>
    );
}
