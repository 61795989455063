import Image from 'next/image';
import { useTranslation } from '@srnade/web/i18n/client';
import reactHtmlParser from 'react-html-parser';

import { Typography } from '@srnade/component-ui';

import loader from 'assets/icons/loading.svg';

export function Loading() {
    const { t } = useTranslation('components', { keyPrefix: 'loading' });

    return (
        <div
            className="flex items-center justify-center flex-col h-screen p-[3rem] bg-cream"
            data-testid="splash-loading"
        >
            <Image
                className="flex mb-[3rem]"
                src={loader}
                alt="loading"
                width={100}
                height={100}
                priority={true}
                unoptimized
            />
            <Typography className="w-full tablet:max-w-[56rem] text-center text-[3.5rem] leading-[5.3rem]" htmlContent>
                {reactHtmlParser(t('tagLine', { interpolation: { escapeValue: false } }))}
            </Typography>
        </div>
    );
}
