// GTM Event hook
//
// Usage:
// function App() {
//     const { pushEvent } = useGtmEvents(GtmEventType.LoginSuccess);
//     return (
//         <div>
//             <Login onCompleted={(user) => pushEvent(user.id, true, user.accountType)} />
//         </div>
//     );
// }
import { AccountType } from '@srnade/component-ui';
import { TreatmentType } from '@srnade/web/constants';
import { useCallback } from 'react';
import { ReservedProductSummary } from '../types';

import {
    GtmEvent,
    GtmLoggedInStatus,
    GtmProductEvent,
    GtmPromotion,
    GtmPromotionEvent,
    GtmPurchaseEvent,
    GtmUserEvent,
} from '@srnade/web/types';
import { gtmPushProduct, gtmPushProductList, gtmPushPromoList, gtmPushPurchase, gtmPushUser } from '@srnade/web/utils';
import { useFeatureFlags } from './use-feature-flag.hook';

/**
 * useGtmEvents hook - push GA events
 *
 * This hook is created for a given event type. Calls to 'pushEvent' wrap the dataLayer.push()
 * method used to push events to Google Analytics.
 *
 * At present this hook returns a single pushEvent method for User events, it may be extended
 * as needed to support other events with different params.
 *
 * @param {GtmEventType} gtmEventType The event type being pushed
 */
export function useGtmEvents(gtmEventType: GtmEvent): GtmPushEvents {
    // Kill switch FF on dynamic GA push calls
    const { useFeatureOn } = useFeatureFlags();
    const googleAnalyticsEnabled = useFeatureOn(TreatmentType.GoogleAnalyticsEvents);
    const pushUserEvent = useCallback(
        (userId: string, loggedInStatus: GtmLoggedInStatus, accountType: AccountType) => {
            if (googleAnalyticsEnabled) {
                gtmPushUser(gtmEventType as GtmUserEvent, {
                    userId,
                    loggedInStatus,
                    accountType,
                });
            }
        },
        [gtmEventType, googleAnalyticsEnabled],
    );
    const pushProductEvent = useCallback(
        (listings, pageName?: string, itemIndex?: number) => {
            if (googleAnalyticsEnabled) {
                // Listing contain an 'edges' array, editions are a straight array
                const list = 'edges' in listings ? listings.edges : !!listings?.length ? listings : undefined;
                if (list) {
                    gtmPushProductList(gtmEventType as GtmProductEvent, list, pageName);
                } else {
                    gtmPushProduct(gtmEventType as GtmProductEvent, listings, pageName, itemIndex);
                }
            }
        },
        [gtmEventType, googleAnalyticsEnabled],
    );
    const pushPurchaseEvent = useCallback(
        (
            listing,
            paymentMethod: string,
            paymentSummary?: ReservedProductSummary,
            pageName?: string,
            itemIndex?: number,
        ) => {
            if (googleAnalyticsEnabled) {
                gtmPushPurchase(
                    gtmEventType as GtmPurchaseEvent,
                    listing,
                    paymentMethod,
                    paymentSummary,
                    pageName,
                    itemIndex,
                );
            }
        },
        [gtmEventType, googleAnalyticsEnabled],
    );
    const pushPromoEvent = useCallback(
        (listings: any, promoContext: GtmPromotion, pageName?: string) => {
            if (googleAnalyticsEnabled) {
                gtmPushPromoList(gtmEventType as GtmPromotionEvent, listings, promoContext, pageName);
            }
        },
        [gtmEventType, googleAnalyticsEnabled],
    );
    return { pushUserEvent, pushProductEvent, pushPurchaseEvent, pushPromoEvent } as const;
}

export interface GtmPushEvents {
    pushUserEvent: (userId: string, loggedInStatus: GtmLoggedInStatus, accountType: AccountType) => void;
    pushProductEvent: (listing: any, pageName?: string, itemIndex?: number) => void;
    pushPurchaseEvent: (
        listing: any,
        paymentMethod: string,
        paymentSummary?: ReservedProductSummary,
        pageName?: string,
        itemIndex?: number,
    ) => void;
    pushPromoEvent: (listings: any, promoContext: GtmPromotion, pageName?: string) => void;
}
