import React from 'react';
import Image from 'next/legacy/image';
import Skeleton from 'react-loading-skeleton';
import { BaseComponentProps, Icon, Typography, Card, CardContent } from '@srnade/component-ui';
import numeral from 'numeral';
import { format } from 'date-fns';
import { addUsernamePrefix } from '@srnade/component-ui';

import { LinkWrapper } from '../LinkWrapper';

import 'react-loading-skeleton/dist/skeleton.css';

import { GetUpcomingDropsQuery, UpcomingDrop } from '@srnade/web/__generated__/hygraph/graphql';

export interface ReleaseTileProps {
    release?: UpcomingDrop | GetUpcomingDropsQuery['upcomingDrops'][number];
    spotifyMonthlyListenersText: string;
}
export function ReleaseTile({ release, spotifyMonthlyListenersText }: ReleaseTileProps & BaseComponentProps) {
    return (
        <div className="flex flex-col items-center">
            <Card
                className="w-full h-[33.5rem] max-w-[33.5rem] tablet:w-[35rem] tablet:max-w-none tablet:h-[35rem] desktop:w-[40rem] desktop:h-[40rem] relative rounded-[0.5rem] p-8"
                border
            >
                <CardContent className="relative w-full h-full">
                    {release ? (
                        <LinkWrapper href={release.username ? `/${addUsernamePrefix(release.username)}` : '#'}>
                            <Image src={release.image.url} layout="fill" alt={release.name} />

                            {release.dropReleaseDate && (
                                <div className="absolute bg-white rounded-[0.5rem] top-4 left-4 p-[1rem]">
                                    <Typography variant="subheading">
                                        {format(new Date(release.dropReleaseDate), 'MMMM yyyy')}
                                    </Typography>
                                </div>
                            )}

                            {release.spotifyMonthlyListeners && (
                                <div className="absolute bg-white rounded-[0.5rem] bottom-4 left-4 px-4 py-2 flex gap-x-4 justify-between items-center">
                                    <Icon size={25} icon="spotify"></Icon>
                                    <Typography variant="subheading">
                                        {spotifyMonthlyListenersText}
                                        <br /> {numeral(release.spotifyMonthlyListeners).format('0,0')}
                                    </Typography>
                                </div>
                            )}
                        </LinkWrapper>
                    ) : (
                        <Skeleton style={{ width: '100%', height: '100%' }} />
                    )}
                </CardContent>
            </Card>
            <div className="text-center mt-8">
                {release ? (
                    <Typography variant="subheading">{release.name}</Typography>
                ) : (
                    <Skeleton style={{ maxWidth: '86px', width: '100%', height: '14px' }} />
                )}
            </div>
        </div>
    );
}
