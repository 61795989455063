import {
    Currency,
    CurrencyHumanized,
    CurrencySymbolPrefix,
    CurrencySymbolSuffix,
    CurrencySymbols,
} from '@srnade/component-ui';
import numeral from 'numeral';

type CurrencyFormatType = 'symbol' | 'prefix' | 'long' | 'suffix';

const currencyPrefix = (currency: Currency, type: CurrencyFormatType): string => {
    switch (type) {
        case 'symbol':
            return CurrencySymbols[currency];
        case 'prefix':
            const prefix = CurrencySymbolPrefix[currency];
            return prefix ? `${prefix}${CurrencySymbols[currency]}` : CurrencySymbols[currency];
        default:
            return CurrencySymbols[currency];
    }
};

const currencySuffix = (currency: Currency, type: CurrencyFormatType): string => {
    switch (type) {
        case 'suffix':
            return CurrencySymbolSuffix[currency] || '';
        case 'long':
            return CurrencyHumanized[currency];
        default:
            return '';
    }
};

/**
 * Format a price with currency
 * @param currency Currency, e.g. 'USD'
 * @param price Amount in cents (or currency equivalent)
 * @param type Currency format type
 * @returns Formatted string
 */
export const formatPrice = (currency: Currency, price: number, type: CurrencyFormatType = 'suffix'): string => {
    const showDecimals = !(`${price}`.slice(-2) === '00');
    const prefix = currencyPrefix(currency, type);
    const suffix = currencySuffix(currency, type);

    return `${prefix}${numeral(price / 100).format(showDecimals ? '0,0.00' : '0,0')} ${suffix}`;
};
