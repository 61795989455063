import React from 'react';
import { Tooltip, Typography } from '@srnade/component-ui';

interface PanelHeaderProps {
    title: string;
    toolTip: string;
    introduction: string;
}

export function PanelHeader({ title, toolTip, introduction }: PanelHeaderProps) {
    return (
        <>
            <div className="flex items-center">
                <div>
                    <Typography className="mb-[1rem]" variant="bodyBold">
                        {title}
                    </Typography>
                </div>
                <div className="relative">
                    <div className="ml-[0.3rem] absolute bottom-[1.2rem]">
                        <Tooltip active="aboveLeft">{toolTip}</Tooltip>
                    </div>
                </div>
            </div>
            <Typography variant="body">{introduction}</Typography>
        </>
    );
}
