import React from 'react';
import clsx from 'clsx';

import { type BaseComponentProps, Typography } from '@srnade/component-ui';

import { ArtistsMap } from '../types';

export interface ArtistCharacterProps {
    setShowArtists: (artistLetters: string) => void;
    artistsMap?: ArtistsMap;
}

export const ArtistCharacters = ({
    artistsMap,
    setShowArtists,
    className,
}: ArtistCharacterProps & BaseComponentProps): JSX.Element => {
    let timer: NodeJS.Timeout;

    const handleMouseOverCharacterList = (event, selectedLetters: string) => {
        if (timer) {
            clearTimeout(timer);
        }
        setShowArtists(selectedLetters);
    };

    const handleMouseLeaveCharacterList = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            clearTimeout(timer);
        }, 500);
    };

    return (
        <>
            {artistsMap && (
                <div className={clsx('grid grid-cols-1 gap-y-4', className)}>
                    {/* @ts-ignore */}
                    {Array.from(artistsMap).map(([key, { enabled }]) => (
                        <Typography
                            key={key}
                            onMouseOver={(event) => handleMouseOverCharacterList(event, key)}
                            onMouseLeave={handleMouseLeaveCharacterList}
                            className={clsx('flex w-[7rem]', {
                                'text-darkGray': !enabled,
                                'cursor-pointer hover:underline hover:font-bold': enabled,
                            })}
                            variant="body"
                        >
                            {key}
                        </Typography>
                    ))}
                </div>
            )}
        </>
    );
};
