import * as path from 'path';
import { SplitFactory as ServerSplitFactory } from '@splitsoftware/splitio';
import SplitIO from '@splitsoftware/splitio/types/splitio';

import { localDevFeatures } from './splitio.features';

// SSR calls
export const createServerFactory = (): SplitIO.ISDK => {
    const authorizationKey = process.env.SPLITIO_SERVER_SIDE_KEY;
    if (!authorizationKey) {
        // local dev
        return ServerSplitFactory({
            core: {
                authorizationKey: 'localhost',
            },
            // load local features from root .split.yml
            // We need to get the relative path referencing from `.next` directory
            // we can't load from client like `getSplitSdkConfig` does because it's not available.
            features: path.join(__dirname, '../../../', '.split.yml'),
        });
    }

    return ServerSplitFactory({
        core: {
            authorizationKey,
        },
        debug: (process.env.SPLITIO_DEBUG_MODE as any) || 'ERROR',
        scheduler: {
            featuresRefreshRate: 300,
            segmentsRefreshRate: 300,
        },
    });
};

// SpitIO automatically treats 'localhost' auth key to enable development mode, loading
// flags from a local file.
//
export const getSplitSdkConfig = (userKey: string): SplitIO.IBrowserSettings => {
    const authorizationKey = process.env.NEXT_PUBLIC_SPLITIO_KEY;
    if (!authorizationKey) {
        // local dev
        return {
            core: {
                authorizationKey: 'localhost',
                key: userKey,
            },
            // src/clients/splitio.features.ts
            features: localDevFeatures,
        };
    }
    return {
        core: {
            authorizationKey,
            key: userKey,
        },
        debug: (process.env.NEXT_PUBLIC_SPLITIO_DEBUG_MODE as any) || 'ERROR',
        scheduler: {
            featuresRefreshRate: 300,
            segmentsRefreshRate: 300,
        },
    };
};
