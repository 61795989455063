import Image from 'next/image';
import { useState } from 'react';
import {
    Slide,
    ContainerRect,
    StreamableBonusContentSlide,
    SLIDE_STATUS_LOADING,
    SlideStatus,
    SLIDE_STATUS_COMPLETE,
} from 'yet-another-react-lightbox';
import { LoadingSlide } from './LoadingSlide.component';

interface ImageSlideProps {
    slide: Slide;
    rect: ContainerRect;
    showLoadingText?: boolean;
}
export default function ImageSlide({ slide, rect, showLoadingText }: ImageSlideProps) {
    const [status, setStatus] = useState<SlideStatus>(SLIDE_STATUS_LOADING);

    const imageSrc =
        slide.type === 'audio' || slide.type === 'video' ? (slide as StreamableBonusContentSlide).poster : slide.src;

    const { width, height } = rect;

    return (
        <div style={{ position: 'relative', width, height }}>
            <Image
                fill
                onLoad={() => setStatus(SLIDE_STATUS_COMPLETE)}
                alt=""
                src={imageSrc}
                loading="eager"
                draggable={false}
                objectFit="contain"
                sizes={`${Math.ceil((width / window.innerWidth) * 100)}vw`}
            />

            {status === SLIDE_STATUS_LOADING && <LoadingSlide showLoadingText={showLoadingText} />}
        </div>
    );
}
