import { gql } from '@apollo/client';

/**
 * Mutations
 */

export const CREATE_VIDEO = gql`
    mutation CreateVideo($input: CreateVideoInput!) {
        video: createVideo(input: $input) {
            id
            muxPlaybackId
            file {
                fileType
                originalFilename
            }
        }
    }
`;

/**
 * Queries
 */

export const GET_MANY_VIDEOS = gql`
    query GetManyVideos($input: GetManyVideosInput!) {
        getManyVideos(input: $input) {
            id
            muxPlaybackId
            duration
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
