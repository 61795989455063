import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { shallowEqual } from 'react-redux';

import { Icon, Typography } from '@srnade/component-ui';
import { useAppDispatch, useAppSelector } from '@srnade/web/store/global';
import {
    changeCurrentTrack,
    changeIsPlaying,
    fetchStreamUrl,
    selectPlayerArtist,
    selectPlayerArtworkUrl,
    selectPlayerCurrentTrack,
    selectPlayerIsPlaying,
    selectPlayerStreamingUrl,
    selectPlayerTracks,
} from '@srnade/web/store/reducers/player';
import { getTokenExpiryFromUrl } from '@srnade/web/utils';
import styles from './StreamPlayer.module.scss';
import { ControlPanel, Slider } from './components';
import { Image } from '@srnade/web/components/Image';

export type StreamPlayerProps = {
    formatInstanceId: string;
    showPlayerOnly?: boolean;
    onTrackPlayPause: () => void;
    onTrackChange: () => void;
};

export const StreamPlayer: React.FC<StreamPlayerProps> = ({
    showPlayerOnly,
    formatInstanceId,
    onTrackChange,
    onTrackPlayPause,
}) => {
    const [percentage, setPercentage] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [tokenExpiry, setTokenExpiry] = useState<number | null>(null);
    const [shouldRefreshStreamUrl, setShouldRefreshStreamUrl] = useState<boolean>(true);
    const [isMinimized, setIsMinimized] = useState(false);

    const dispatch = useAppDispatch();
    const tracks = useAppSelector(selectPlayerTracks);
    const currentTrack = useAppSelector(selectPlayerCurrentTrack);
    const isPlaying = useAppSelector(selectPlayerIsPlaying);
    const streamingUrl = useAppSelector(selectPlayerStreamingUrl, shallowEqual);
    const artist = useAppSelector(selectPlayerArtist);
    const artworkUrl = useAppSelector(selectPlayerArtworkUrl);

    const refreshTokenTimerRef = useRef<NodeJS.Timeout>();
    const audioRef = useRef<ReactPlayer>(null);

    const shouldFetchStreamingUrl = () => {
        const tokenExpiryFromUrl = getTokenExpiryFromUrl(currentTrack?.streamingUrl);

        if (tokenExpiryFromUrl) {
            return tokenExpiryFromUrl - Date.now() <= 10000;
        }
        return true;
    };

    const clearRefreshTokenTimer = useCallback(() => {
        if (refreshTokenTimerRef.current) {
            clearTimeout(refreshTokenTimerRef.current);
            refreshTokenTimerRef.current = undefined;
        }
    }, []);

    const resetTime = useCallback(() => {
        audioRef.current?.seekTo(0);
        setCurrentTime(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetToken = useCallback(() => {
        setTokenExpiry(null);
        clearRefreshTokenTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!tracks || !tracks[0]?.assetId) {
            dispatch(changeCurrentTrack(null));
            return;
        }
        const requiredTrack = tracks.find((track) => track.assetId === currentTrack?.assetId);
        if (!requiredTrack) {
            return;
        }
        resetTime();
        resetToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracks, currentTrack, dispatch]);

    useEffect(() => {
        const tokenExpiryFromUrl = getTokenExpiryFromUrl(streamingUrl);
        if (tokenExpiryFromUrl) {
            setTokenExpiry(tokenExpiryFromUrl - Date.now());
        }
        setShouldRefreshStreamUrl(false);
        clearRefreshTokenTimer();
    }, [streamingUrl, clearRefreshTokenTimer]);

    useEffect(() => {
        if (tokenExpiry && !refreshTokenTimerRef.current) {
            refreshTokenTimerRef.current = setTimeout(() => {
                setShouldRefreshStreamUrl(true);
                clearRefreshTokenTimer();
            }, tokenExpiry - 10000); // tokenExpiry minus 10 seconds
        }

        return () => clearRefreshTokenTimer();
    }, [tokenExpiry, clearRefreshTokenTimer]);

    /**
     * This useEffect only fetch a new streaming url for the current track if it is about to expire based on the tokenExpiry
     */
    useEffect(() => {
        if (currentTrack?.assetId && shouldRefreshStreamUrl) {
            dispatch(fetchStreamUrl({ variables: { input: { assetId: currentTrack.assetId, formatInstanceId } } }));
            onTrackChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTrack?.assetId, shouldRefreshStreamUrl]);

    /**
     * This useEffects fetches new streaming url every time current track changes.
     */
    useEffect(() => {
        if (currentTrack?.assetId && shouldFetchStreamingUrl()) {
            dispatch(fetchStreamUrl({ variables: { input: { assetId: currentTrack.assetId, formatInstanceId } } }));
            onTrackChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTrack?.assetId]);

    const handlePlayPause = () => {
        dispatch(changeIsPlaying(!isPlaying));
        onTrackPlayPause();
    };

    const skipBack = () => {
        const index = tracks.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
        dispatch(changeCurrentTrack(tracks[(index - 1 + tracks.length) % tracks.length]));

        audioRef.current?.seekTo(0);
        setCurrentTime(0);
        setTokenExpiry(null);
        clearRefreshTokenTimer();
    };

    const skipToNext = () => {
        const index = tracks.findIndex((x) => x.trackNumber === currentTrack?.trackNumber);
        dispatch(changeCurrentTrack(tracks[(index + 1) % tracks.length]));

        audioRef.current?.seekTo(0);
        setCurrentTime(0);
        setTokenExpiry(null);
        clearRefreshTokenTimer();
    };

    const handleProgress = (e: { playedSeconds: number }) => {
        const percent = ((e.playedSeconds / duration) * 100).toFixed(2);
        if (!seeking) {
            setCurrentTime(Number(e.playedSeconds.toFixed(2)));
            setPercentage(+percent);
        }
    };

    const handleDuration = (e: React.SetStateAction<number>) => {
        setDuration(e);
    };

    const handleSeekChange = (e) => {
        const newValue = e.target?.value;
        if (newValue) {
            setCurrentTime(newValue / 100);
            setPercentage(+newValue);
        }
    };

    const handleSeekMouseDown = () => {
        setSeeking(true);
    };

    const handleSeekMouseUp = (e: { target: { value: number } }) => {
        setSeeking(false);
        audioRef.current?.seekTo(e.target.value / 100);
    };

    const calculateTime = (secs: number) => {
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    };

    const MAX_DISPLAY_NAME_LENGTH = 20;

    const truncateDisplay = (str: string) => {
        if (str.length <= MAX_DISPLAY_NAME_LENGTH) {
            return str;
        }
        return str.slice(0, MAX_DISPLAY_NAME_LENGTH) + '...';
    };

    // if (playerFetchStatus === PlayerStatus.Pending || !currentTrack) {
    //     return null; // TODO insert spinner logic here
    // }

    if (!currentTrack) {
        return null; // TODO insert spinner logic here
    }
    return (
        <>
            <div
                className={`${styles.floating_player} ${
                    isMinimized ? 'rounded-r-xl' : 'w-full'
                } transition-all duration-500 ease-in-out`}
                data-testid="stream-player"
            >
                <div className={clsx('flex', { 'tablet:px-0': !showPlayerOnly })}>
                    {streamingUrl && (
                        <ReactPlayer
                            data-testid="react-player"
                            id={currentTrack?.assetId}
                            key={currentTrack?.assetId}
                            ref={audioRef}
                            url={streamingUrl}
                            width={0}
                            height={0}
                            playing={isPlaying}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            onEnded={skipToNext} // loop by default
                            onSeek={handleSeekChange}
                            onError={(error) => {
                                console.error('ReactPlayer::onError', error);
                            }}
                            playsInline
                        />
                    )}

                    {currentTrack && (
                        <div
                            className={`grid   items-center w-full 
                            ${isMinimized ? 'grid-cols-1' : 'grid-cols-[2fr_1fr] tablet:grid-cols-[1fr_3fr_1fr]'} 
                            ${isMinimized ? 'grid-rows-1' : 'grid-rows-[70%_30%] tablet:grid_rows-2'}
                            ${styles.grid_template}`}
                        >
                            <div
                                className="absolute top-0 right-0 p-2 cursor-pointer"
                                onClick={() => setIsMinimized(!isMinimized)}
                            >
                                {isMinimized ? (
                                    <Icon icon="chevron-right" size={20} />
                                ) : (
                                    <Icon icon="chevron-left" size={20} />
                                )}
                            </div>
                            <div className="col-start-1 row-start-1 row-span-1 tablet:row-span-2">
                                <div className="flex flex-row flex-grow-0 flex-shrink-0 py-[4px]">
                                    <div className={styles.image_frame}>
                                        <Image
                                            width={100}
                                            height={100}
                                            className={styles.image}
                                            src={artworkUrl}
                                            alt={'Artwork'}
                                            priority
                                        />
                                    </div>
                                    <div className="flex-col text-left mt-[0.5rem] ml-[1.5rem]">
                                        <Typography variant="h6" className="truncate">
                                            {truncateDisplay(currentTrack?.displayName || '')}
                                        </Typography>
                                        <Typography variant="captionBold">{artist?.name}</Typography>
                                    </div>
                                </div>
                            </div>
                            {!isMinimized && (
                                <>
                                    <div className="col-start-2 row-start-1 row-span-1 w-full">
                                        <div className="flex flex-col items-end tablet:items-center justify-center mx-[20px]">
                                            <ControlPanel
                                                onPlayPause={handlePlayPause}
                                                isPlaying={isPlaying}
                                                skipBack={skipBack}
                                                skipToNext={skipToNext}
                                                currentTrack={currentTrack}
                                                tracks={tracks}
                                                showPlayerOnly={showPlayerOnly}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-start-1 tablet:col-start-2 col-span-2 tablet:col-span-1 row-start-2">
                                        {!showPlayerOnly && currentTrack?.displayName && (
                                            <div className="flex flex-row mt-[0.5rem]">
                                                <Typography className={'mr-[1rem]'} variant="body">
                                                    {calculateTime(currentTime)}
                                                </Typography>
                                                <div className="mt-[0.5rem] w-full">
                                                    <Slider
                                                        percentage={percentage}
                                                        onSeek={handleSeekChange}
                                                        onSeekMouseDown={handleSeekMouseDown}
                                                        onSeekMouseUp={handleSeekMouseUp}
                                                        onDuration={handleDuration}
                                                        disabled={false}
                                                    />
                                                </div>
                                                <Typography className={'ml-[1rem]'} variant="body">
                                                    {duration ? calculateTime(duration) : '00:00'}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
