import React from 'react';
import clsx from 'clsx';
import { useTranslation } from '@srnade/web/i18n/client';

import { type BaseComponentProps, ProgressBar, Typography } from '@srnade/component-ui';

import styles from './FileUploading.module.scss';

interface FileUploadingProps {
    currentProgress: number;
    uploadFileStep?: boolean;
}

export function FileUploading({ currentProgress, uploadFileStep, className }: FileUploadingProps & BaseComponentProps) {
    const { t } = useTranslation('components', { keyPrefix: 'fileUploading' });

    return (
        <div
            className={clsx(
                'flex w-full p-[2rem] flex-col justify-end items-center border rounded-[2rem] h-[40rem]',
                styles.active,
                className,
            )}
        >
            <Typography
                className={clsx('mb-[11rem]', { ['mb-[10rem] laptop:mb-[13.5rem]']: uploadFileStep })}
                variant="h1"
            >
                {t('uploading')}
            </Typography>
            <ProgressBar value={currentProgress} />
        </div>
    );
}
