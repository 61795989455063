/*
import { ListingSaleType } from './listing.const';

export type SaleTypeToContract = {
    [ListingSaleType.LimitedEdition]: SerenadeContract.Product;
    [ListingSaleType.OpenEdition]: SerenadeContract.OpenEdition;
};
*/

export enum SerenadeContract {
    AdministratorRegistry = 'AdministratorRegistry',
    OpenEdition = 'OpenEdition',
    Product = 'Product',

    // @legacy BCOM-498 Support legacy Account contracts
    Storage = 'Storage',
    Account = 'Account',
    RoyaltySplitter = 'RoyaltySplitter',
}

/**
 * @legacy Legacy contracts where the first version does not explicitly have the V1 suffix (eg. Product is V1).
 * New contracts should always have version suffix in their name (eg. OpenEditionV1).
 */
export const ContractWithoutV1Prefix = [
    SerenadeContract.Account,
    SerenadeContract.AdministratorRegistry,
    SerenadeContract.Product,
    SerenadeContract.Storage,
    SerenadeContract.RoyaltySplitter,
];

// @legacy BCOM-498 Support legacy Account contracts
export enum StorageContractFunction {
    GetAccount = 'account',
    GetProduct = 'product',
    GetToken = 'token',
    SetAccount = 'setAccount',
    SetProduct = 'setProduct',
    SetTokenUri = 'setTokenURI',
}

/**
 * Interface for both Account and Product contracts
 */
export enum NFTContractFunction {
    Mint = 'mint',
    TransferFrom = 'transferFrom',
    OperatorTransferFrom = 'operatorTransferFrom',
    OwnerOf = 'ownerOf', // who owns this token?
    TokenUri = 'tokenURI', // what is the metadata URL for this token?
    // AdministratorRegistry was introduced in AccountV4 and ProductV2
    AdministratorRegistry = 'administratorRegistry',
    SetAdministratorRegistry = 'setAdministratorRegistry',
}

export enum ProductContractFunction {
    SetContractURI = 'setContractURI',
    SetTokenUri = 'setTokenURI',
}

// @legacy BCOM-498 Support legacy Account contracts
export enum RoyaltySplitterContractFunction {
    Accept = 'accept',
}

/**
 * The source of truth of what the latest version of each contract is.
 * The blockchain code will refer to this when upgrading contracts.
 */
export const SerenadeContractLatestVersions = {
    [SerenadeContract.AdministratorRegistry]: 1,
    [SerenadeContract.OpenEdition]: 1,
    [SerenadeContract.Product]: 2,

    // @legacy BCOM-498 Support legacy Account contracts
    [SerenadeContract.Storage]: 3,
    [SerenadeContract.Account]: 4,
    [SerenadeContract.RoyaltySplitter]: 1,
};

export type ContractVersion = 'latest' | number;

interface BigNumber {
    toNumber: () => number;
}

export interface StorageAccount {
    typeOf: 'Artist';
    name: string;
    symbol: string;
    uri: string;
    currentSupply: BigNumber;
    operator: string;
    exists: boolean;
}

export interface StorageProduct {
    maxSupply: BigNumber;
    currentSupply: BigNumber;
    royaltyPercent: number;
    royaltyRecipient: string;
    accountId: string;
    exists: boolean;
}

/**
 * Default / Null address for EVM chains (ie. Polygon)
 */
export const EvmDefaultAddress = '0x0000000000000000000000000000000000000000';
