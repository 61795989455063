import React, { useState } from 'react';
import Image from 'next/legacy/image';
import { imageHandlerBlur, imageHandlerLoader } from '@srnade/web/utils';
import { useQuery } from '@apollo/client';
import { GetAssetStreamingUrlQuery, GetAssetStreamingUrlQueryVariables } from '@srnade/web/__generated__/graphql';
import { GET_ASSET_STREAMING_URL } from '@srnade/web/services';

type ImageFilePreviewProps = {
    imageAlt?: string;
    assetId: string;
    formatInstanceId: string;
};

export const ImageFilePreview: React.FC<ImageFilePreviewProps> = ({ assetId, formatInstanceId, imageAlt }) => {
    const [url, setUrl] = useState<string | undefined>(undefined);

    useQuery<GetAssetStreamingUrlQuery, GetAssetStreamingUrlQueryVariables>(GET_ASSET_STREAMING_URL, {
        variables: { input: { assetId: assetId, formatInstanceId } },
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        skip: !assetId || !formatInstanceId,
        onCompleted: (data) => {
            setUrl(data.formatInstanceAssetStreamingUrl);
        },
    });

    return (
        <>
            {url && (
                <Image
                    data-testid="file-preview-img"
                    src={url}
                    height={1000}
                    width={1000}
                    layout="responsive"
                    alt={imageAlt}
                    loader={imageHandlerLoader}
                    placeholder="blur"
                    blurDataURL={imageHandlerBlur}
                    priority
                />
            )}
        </>
    );
};
