import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';

import { BaseComponentProps, Typography } from '@srnade/component-ui';
import { Link } from '../Link';

type NeedHelpContactUsProps = BaseComponentProps & {
    hideNeedHelp?: boolean;
};

export const NeedHelpContactUs: React.FC<NeedHelpContactUsProps> = ({ className, hideNeedHelp }) => {
    const { t } = useTranslation('common');

    const containerClass = className ? className : 'flex';

    return (
        <Typography className={containerClass} variant="subheading" data-testid="needHelp">
            {!hideNeedHelp && t('needHelp')}&nbsp;
            <Link href="/contact" passHref legacyBehavior>
                <span
                    data-testid="contactBtn"
                    className="cursor-pointer font-bold uppercase underline hover:no-underline"
                >
                    {t('contactUs')}
                </span>
            </Link>
        </Typography>
    );
};
