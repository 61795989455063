import { useRef } from 'react';
import { useHoverDirty } from 'react-use';
import clsx from 'clsx';
import { Icon, Typography } from '@srnade/component-ui';
import { TrackList } from '../TrackList';
import { Track } from '../TrackList/TrackItem.component';
import { SelectedTrack } from '../TrackList/TrackList.component';

type PlayableTracksProps = {
    playerTracks: Track[];
    allTracks: Track[];
    handlePlayPause: () => void;
    selectedTrack?: SelectedTrack;
    onSelectTrack: (assetId: string) => void;
};
export function PlayableTracks({ allTracks, playerTracks, handlePlayPause, selectedTrack, onSelectTrack }: PlayableTracksProps) {
    const playing = Boolean(selectedTrack?.isPlaying);
    const rootRef = useRef(null);
    const hovering = useHoverDirty(rootRef);

    return (
        <>
            {playerTracks.length > 0 && (
                <div
                    className="p-[1px] cursor-pointer rounded-[1rem] bg-gradient-to-b from-gradientBlue via-gradientGreen to-gradientPurple shadow-gradient hover:shadow-none hover:from-white hover:to-white"
                    data-testid="listen-now"
                    ref={rootRef}
                    onClick={handlePlayPause}
                >
                    <div className="flex items-center rounded-[1rem] px-[2rem] py-[1.2rem] bg-white gap-[1rem]">
                        {hovering ? (
                            <Icon icon={playing ? 'pauseHoverGradient' : 'playHoverGradient'} size={37} />
                        ) : (
                            <Icon icon={playing ? 'pauseGradient' : 'playGradient'} size={37} />
                        )}

                        <Typography
                            className={clsx('flex-grow', {
                                'h2 italic': playing,
                            })}
                            variant="bodyBold"
                        >
                            {playing ? 'Listening' : 'Listen Now'}
                        </Typography>

                        <Icon className="flex-end justify-end" icon="padlock-open" size={25} />
                    </div>
                </div>
            )}
            <TrackList
                tracks={allTracks}
                locked={false}
                showEmpty={true}
                selectedTrack={selectedTrack}
                onClick={onSelectTrack}
            />
        </>
    );
}
