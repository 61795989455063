interface Accreditation {
    [key: string]: string;
}

export const organizations: Accreditation = {
    'Official Charts Company (UK)': 'OfficialChartCompany',
};

export const promoFlags: Accreditation = {
    'Paid For (most likely)': 'PaidFor',
    Free: 'Free',
};

export const accreditationCodes: Accreditation = {
    isrc: 'ISRC',
    upc: 'UPC',
};

export const ORGANIZATION_OPTIONS = Object.entries(organizations).map(([value, key]) => {
    return { label: value, value: key };
});

export const PROMO_FLAG_OPTIONS = Object.entries(promoFlags).map(([value, key]) => {
    return { label: value, value: key };
});
