import { PropsWithChildren, forwardRef } from 'react';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import { Typography } from '@srnade/component-ui';
import { addUsernamePrefix } from '@srnade/component-ui';
import { FirstOwner, Product, Account } from '../../../../../types';

import { useAuth } from '@srnade/web/auth';
import { Link } from '@srnade/web/components';

import { Avatar } from '../../../../Avatar';
import styles from '../../FirstOwners.module.scss';

import avatarDeleted from 'assets/icons/avatar-deleted.svg';

interface FirstOwnersCardProps {
    firstOwner: FirstOwner;
    className?: string;
    product: Pick<Product, 'slug'> & {
        account: Pick<Account, 'username'>;
    };
}

const FirstOwnerAccountLink = ({
    children,
    firstOwner,
    testId,
}: PropsWithChildren<{ firstOwner: FirstOwner; testId: string }>) => {
    if (firstOwner.account) {
        return (
            <Link href={`/${addUsernamePrefix(firstOwner.account.username)}`} passHref legacyBehavior>
                <a data-testid={testId}>{children}</a>
            </Link>
        );
    }
    return <>{children}</>;
};

export const FirstOwnersDetailsCard = forwardRef<HTMLDivElement, FirstOwnersCardProps>(
    ({ className, firstOwner, product }, ref) => {
        const { activeAccount } = useAuth();

        const { t } = useTranslation('components', { keyPrefix: 'community' });

        const isSameUser = firstOwner.account?.id === activeAccount?.id;

        const getUsernameToShow = (firstOwner: FirstOwner) => {
            if (!firstOwner.account) {
                return t('firstOwners.privateCollector');
            }

            const { username } = firstOwner.account;

            return isSameUser ? username + t('firstOwners.you') : username;
        };

        const avatarName = firstOwner.account ? firstOwner.account.username : t('firstOwners.privateCollector');

        return (
            <div ref={ref} className={className} data-gtm-id="product_owners">
                <div className="p-[0.8rem] w-full flex justify-between items-center">
                    <div className="flex w-full items-center">
                        <FirstOwnerAccountLink firstOwner={firstOwner} testId="avatar-link">
                            <Avatar
                                size="small"
                                src={firstOwner.account ? firstOwner.account.avatar?.url : avatarDeleted}
                                name={avatarName}
                                alt={avatarName}
                                className="cursor-pointer"
                                data-testid="avatar"
                                unoptimized
                            />
                        </FirstOwnerAccountLink>
                        <FirstOwnerAccountLink firstOwner={firstOwner} testId="username-link">
                            <Typography
                                variant="h6"
                                className={clsx(
                                    {
                                        'hover:underline capitalize': firstOwner.account,
                                    },
                                    'cursor-pointer pl-[1rem] pr-[2rem] line-clamp-1 break-all',
                                    styles.name,
                                )}
                            >
                                {getUsernameToShow(firstOwner)}
                            </Typography>
                        </FirstOwnerAccountLink>
                    </div>
                    <div className={isSameUser ? styles.editionSequenceGradient : styles.editionSequence}>
                        <Link
                            href={`/${addUsernamePrefix(product.account.username)}/${product.slug}/${
                                firstOwner.edition.sequence
                            }`}
                            passHref
                            legacyBehavior
                        >
                            <Typography variant="h5" className="cursor-pointer">
                                {`#${firstOwner.edition.sequence}`}
                            </Typography>
                        </Link>
                    </div>
                </div>
            </div>
        );
    },
);

FirstOwnersDetailsCard.displayName = 'FirstOwnersDetailsCard';
