import React from 'react';
import { useTranslation } from '@srnade/web/i18n/client';

import { Button, Typography } from '@srnade/component-ui';
import type { BaseComponentProps } from '@srnade/component-ui';

import { NeedHelpContactUs } from '../NeedHelpContactUs';

export type PaymentInformationProps = BaseComponentProps & {
    buttonTextTransKey: string;
    onClick: () => void;
    isDisabled: boolean;
    isLoading: boolean;
    subHeadingTransKey: string;
};

export const PaymentInformation: React.FC<PaymentInformationProps> = ({
    buttonTextTransKey,
    onClick,
    isDisabled,
    isLoading,
    children,
    subHeadingTransKey,
}) => {
    const { t } = useTranslation('payments');

    return (
        <div className="flex full-width flex-col">
            <div data-testid="payments-information__heading-container" className="mb-[3rem]">
                <Typography variant="h3" className="mb-[1rem]">
                    {t('heading')}
                </Typography>

                <Typography variant="h6" className="mb-[1rem]">
                    {t(subHeadingTransKey)}
                </Typography>
            </div>

            <div data-testid="payments-information__children-container" className="mb-[6rem]">
                {children}
            </div>

            <div data-testid="payments-information__button-container" className="mb-[6rem] flex justify-center">
                <Button
                    type="primary"
                    text={t(buttonTextTransKey)}
                    className="grow laptop:min-w-[40rem] laptop:grow-0"
                    disabled={isDisabled}
                    onClick={onClick}
                    loading={isLoading}
                />
            </div>

            <div
                data-testid="payments-information__contact-us-container"
                className="flex flex-row justify-center items-center"
            >
                <NeedHelpContactUs />
            </div>
        </div>
    );
};
