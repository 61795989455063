import { GetProductFormatQuery } from '@srnade/web/__generated__/graphql';
import { ImageTile, NewFilePreviewType } from '@srnade/web/components';

type NonNullableProductFormat = Exclude<GetProductFormatQuery['productFormat'], null | undefined>;

interface CollectibleDetailsProps {
    productFormat: NonNullableProductFormat;
}

export const ProductCollectibleDetails = ({ productFormat }: CollectibleDetailsProps) => {
    const { title, artworkUrl } = productFormat;

    const filePreviewType = productFormat.collectible?.video
        ? NewFilePreviewType.Video
        : productFormat.collectible?.image
          ? NewFilePreviewType.Image
          : NewFilePreviewType.Unknown;

    // Simplified to expect artwork for all collectible formats
    switch (filePreviewType) {
        case NewFilePreviewType.Video:
        case NewFilePreviewType.Image:
            if (artworkUrl && title) {
                return <ImageTile src={artworkUrl} alt={title} />;
            }
            return null;
        default:
            return null;
    }
};
