import { Currency } from '@srnade/component-ui';

interface CurrencyInterface {
    [key: string]: string;
}

/**
 * List of currencies used by the validation schema
 */
export const CURRENCIES: CurrencyInterface = {
    'USD $': Currency.USD,
    'AUD $': Currency.AUD,
    'GBP £': Currency.GBP,
};

const CurrencyHumanized = {
    [Currency.USD]: 'USD $',
    [Currency.AUD]: 'AUD $',
    [Currency.GBP]: 'GBP £',
};

export const CURRENCY_OPTIONS = Object.entries(CurrencyHumanized).map(([value, key]) => {
    return { label: key, value };
});
