import React from 'react';
import { Icon, Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import { useWindowSize } from 'react-use';

import { Container } from '@srnade/web/components';
import { BreakpointWidth } from '@srnade/web/const';

import gradientMobile from 'assets/images/gradient-mobile.png';
import gradientTablet from 'assets/images/gradient-tablet.png';
import gradientDesktop from 'assets/images/gradient-desktop.png';

export function USPBanner() {
    const { t } = useTranslation('components', { keyPrefix: 'uspBanner' });

    const { width } = useWindowSize();

    const renderBackground = () => {
        return width < BreakpointWidth.Tablet
            ? gradientMobile.src
            : width >= BreakpointWidth.Tablet && width < BreakpointWidth.Laptop
              ? gradientTablet.src
              : gradientDesktop.src;
    };

    return (
        <div
            className="w-full h-full"
            data-testid="usp-banner"
            style={{
                backgroundImage: `url(${renderBackground()})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
        >
            <Container className="relative grid justify-items-center py-[8rem]">
                {/* Heading for all breakpoints */}
                <Typography
                    className="text-center max-w-[63rem] laptop:max-w-[82rem]"
                    variant="h1"
                    data-testid="usp-banner-heading"
                >
                    {t('heading.build')} <span className="font-h2 font-normal italic">{t('heading.tomorrow')}</span>{' '}
                    {t('heading.collect')} <span className="font-h2 font-normal italic">{t('heading.today')}</span>
                </Typography>
            </Container>
        </div>
    );
}
