import { AccountType, Button, Icon, MenuSlideOut, Typography } from '@srnade/component-ui';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import { signIn } from 'next-auth/react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useAuth } from '@srnade/web/auth';
import { Avatar, Container, Link } from '@srnade/web/components';
import { useDevice, useFeatureFlags } from '@srnade/web/hooks';
import { TreatmentType } from '@srnade/web/constants';

import { ListArtistNames, NavMenu, UserMenu } from './components';

import logo from 'assets/images/logo.svg';

interface LogoProps {
    mobile: boolean;
}

interface NavProps {
    sticky?: boolean;
    showNavMenu?: boolean;
    showProfileMenu?: boolean;
}

function LinkCircleBorder(): JSX.Element {
    return <div className="w-[0.6rem] h-[0.6rem] rounded-full bg-black absolute right-[50%] bottom-[-1.2rem]" />;
}

function Logo({ mobile }: LogoProps): JSX.Element {
    const { t } = useTranslation('components', { keyPrefix: 'navBar' });
    const propsByBreakpoint = mobile ? { width: 200, height: 34 } : { width: 252, height: 45 };

    return (
        <Link href="/" passHref legacyBehavior>
            <span className="flex cursor-pointer" data-gtm-id="header_logo">
                <Image src={logo} alt={t('logo')} priority={true} {...propsByBreakpoint} unoptimized />
            </span>
        </Link>
    );
}

export function Navbar({ sticky = true, showNavMenu = true, showProfileMenu = true }: NavProps) {
    const router = useRouter();
    const { tablet } = useDevice();
    const { activeAccount } = useAuth();

    const [openNavSlideOutMenu, setOpenNavSlideOutMenu] = useState(false);
    const [openUserSlideOutMenu, setOpenUserSlideOutMenu] = useState(false);
    const [showArtistsMenu, setShowArtistsMenu] = useState<boolean>(false);
    const [showReleasesLinkBorder, setShowReleasesLinkBorder] = useState<boolean>(false);
    const [showArtistsLinkBorder, setShowArtistsLinkBorder] = useState<boolean>(false);
    const [showAboutLinkBorder, setShowAboutLinkBorder] = useState<boolean>(false);

    const accountType = activeAccount?.type as AccountType;
    let timer: NodeJS.Timeout;

    const { t } = useTranslation('components', { keyPrefix: 'navBar' });

    const handleMouseEnterArtistsMenu = () => {
        if (timer) {
            clearTimeout(timer);
        }
        setShowArtistsMenu(true);
        setShowArtistsLinkBorder(true);
    };

    const handleMouseLeaveArtistsMenu = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            clearTimeout(timer);
            setShowArtistsMenu(false);
            setShowArtistsLinkBorder(false);
        }, 500);
    };

    const handleMouseOverReleasesLink = (mouseOver: boolean) => {
        setShowReleasesLinkBorder(mouseOver);
    };

    const handleMouseOverAboutLink = (mouseOver: boolean) => {
        setShowAboutLinkBorder(mouseOver);
    };

    const SlideOutUserMenu = (position, openSlideOutMenu) => {
        return (
            <MenuSlideOut
                className="absolute"
                label="Account"
                position={position}
                gtmId="account_nav"
                open={openSlideOutMenu}
                setOpen={() => setOpenUserSlideOutMenu(!openUserSlideOutMenu)}
                userMenu={true}
            >
                <UserMenu />
            </MenuSlideOut>
        );
    };

    const SlideOutMobileNavMenu = (position, openSlideOutMenu) => {
        return (
            <MenuSlideOut
                className="absolute"
                label="Serenade"
                position={position}
                gtmId="side_navigation"
                open={openSlideOutMenu}
                setOpen={() => setOpenNavSlideOutMenu(!openNavSlideOutMenu)}
                userMenu={false}
                userLoginStatus={UserLoginStatus(true)}
                tablet={tablet}
            >
                <NavMenu visible={openSlideOutMenu} />
            </MenuSlideOut>
        );
    };

    // Need better name to render "Sign In" or "Avatar"
    const UserLoginStatus = (isNavMenu = false) => {
        const authenticationButtons = (
            <>
                <div className={clsx('hidden', { 'tablet:hidden': isNavMenu, 'tablet:grid': !isNavMenu })}>
                    <div className="flex items-center">
                        <Typography
                            className={'flex relative hover:underline mr-[2rem] cursor-pointer'}
                            variant="bodyBold"
                            uppercase
                            onClick={() => signIn('auth0', { callbackUrl: '/me' })}
                            data-testid="nav-sign-in-button"
                        >
                            {t('signIn')}
                        </Typography>
                        <Button
                            text={t('signUp')}
                            type="secondary"
                            variant="smaller"
                            setIcon={false}
                            onClick={() => signIn('auth0', { callbackUrl: '/shop-all' }, { screen_hint: 'signup' })}
                            data-testid="nav-sign-up-button"
                        />
                    </div>
                </div>
                <div className={clsx('grid', { 'table:grid': isNavMenu, 'tablet:hidden': !isNavMenu })}>
                    <span data-testid="account-signin-icon" className="cursor-pointer" onClick={() => signIn('auth0', { callbackUrl: '/me' })}>
                        <Icon icon="account" size={27} />
                    </span>
                </div>
            </>
        )

        return activeAccount ? (
            <button
                type="button"
                data-testid="slide-out-menu"
                className="cursor-pointer"
                onClick={() => {
                    if (isNavMenu) {
                        setOpenNavSlideOutMenu(!openNavSlideOutMenu);
                        setTimeout(() => setOpenUserSlideOutMenu(true), 50); // wait for the menu slide out to close before we show the user slide out
                    } else {
                        setOpenUserSlideOutMenu(!openUserSlideOutMenu);
                    }
                }}
            >
                {activeAccount && <Avatar name={activeAccount.name} src={activeAccount.avatar?.url} />}
            </button>
        ) : (
            authenticationButtons
        );
    };

    const resetUserPreviousInteractions = () => {
        setOpenNavSlideOutMenu(false);
        setShowArtistsMenu(false);
        // setShowUserSlideOutMenu(false);
        setOpenUserSlideOutMenu(false);
    };

    useEffect(() => {
        router.events.on('routeChangeStart', resetUserPreviousInteractions);
        return () => router.events.off('routeChangeStart', resetUserPreviousInteractions);
    }, [router.events]);

    return (
        // @todo: review the "provider" pattern to share data between desktop and mobile components
        <div
            className={clsx(
                'flex flex-row justify-center overflow-hidden laptop:overflow-visible top-0 z-10 bg-cream',
                { sticky: sticky },
            )}
            data-gtm-id="main_header"
        >
            <Container
                className={clsx('flex flex-row h-[8rem] tablet:h-[9.5rem] w-full relative', {
                    'justify-center': !showNavMenu,
                    'justify-between': showNavMenu,
                })}
            >
                {showNavMenu && (
                    <div className="grid items-center tablet:mr-[2.5rem] laptop:hidden">
                        <Icon
                            className="cursor-pointer"
                            onClick={() => setOpenNavSlideOutMenu(!openNavSlideOutMenu)}
                            icon="menu"
                            size={26}
                        />
                        {SlideOutMobileNavMenu('left', openNavSlideOutMenu)}
                    </div>
                )}
                <div className="grid items-center tablet:hidden">{<Logo mobile={true} />}</div>
                <div
                    className={clsx('hidden tablet:grid items-center', {
                        'mr-auto laptop:mr-0': showNavMenu,
                    })}
                >
                    {<Logo mobile={false} />}
                </div>
                {showNavMenu && (
                    <div className="hidden flex-row items-center laptop:inline-flex">
                        <Link href="/shop-all" passHref legacyBehavior>
                            <span
                                className="flex relative cursor-pointer"
                                onMouseOver={() => handleMouseOverReleasesLink(true)}
                                onMouseLeave={() => handleMouseOverReleasesLink(false)}
                            >
                                <Typography variant="bodyBold" uppercase>
                                    {t('releases')}
                                </Typography>
                                {showReleasesLinkBorder && <LinkCircleBorder />}
                            </span>
                        </Link>
                        <div
                            className="flex relative mx-[3rem] cursor-pointer"
                            onMouseOver={handleMouseEnterArtistsMenu}
                            onMouseLeave={handleMouseLeaveArtistsMenu}
                        >
                            <Typography variant="bodyBold" uppercase>
                                {t('artists')}
                            </Typography>
                            {showArtistsLinkBorder && <LinkCircleBorder />}
                        </div>
                        <Link href="https://artists.serenade.co" passHref legacyBehavior>
                            <span
                                className="flex relative mr-[3rem] cursor-pointer"
                                onMouseOver={() => handleMouseOverAboutLink(true)}
                                onMouseLeave={() => handleMouseOverAboutLink(false)}
                            >
                                <Typography variant="bodyBold" uppercase>
                                    {t('about')}
                                </Typography>
                                {showAboutLinkBorder && <LinkCircleBorder />}
                            </span>
                        </Link>
                    </div>
                )}
                {showProfileMenu && <div className="flex flex-row items-center">{UserLoginStatus(false)}</div>}
                {showProfileMenu && <>{SlideOutUserMenu('right', openUserSlideOutMenu)}</>}
            </Container>
            <div
                className={clsx('flex flex-row absolute z-10 bg-cream w-full top-[8.5rem] left-0 py-[3rem]', {
                    visible: showArtistsMenu,
                    invisible: !showArtistsMenu,
                })}
                onMouseOver={handleMouseEnterArtistsMenu}
                onMouseLeave={handleMouseLeaveArtistsMenu}
            >
                {showArtistsMenu && (
                    <div className="flex laptop:h-[30rem] laptop:mx-auto" data-gtm-id="nav_artist">
                        <ListArtistNames />
                    </div>
                )}
            </div>
        </div>
    );
}
