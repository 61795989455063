import { gql } from '@apollo/client';

/**
 * Queries
 */

export const GET_PRODUCT_FORMAT = gql`
    query GetProductFormat($filters: ProductFormatFilters!) {
        productFormat(filters: $filters) {
            artist {
                name
                profilePicUrl
                username
            }
            id
            title
            behindTheMusic
            pressing {
                bonusContent {
                    active
                    assetId
                    displayName
                    downloadable
                    fileSize
                    mimeType
                    streamable
                    duration
                }
                credits {
                    artDirectionBy
                    artworkBy
                    creativeDirectionBy
                    designedBy
                    performedBy
                    photographyBy
                    producedBy
                    writtenBy
                }
                tracks {
                    active
                    assetId
                    displayName
                    downloadable
                    duration
                    fileSize
                    mimeType
                    streamable
                    trackNumber
                }
            }
            artworkUrl
            collectible {
                audio {
                    assetId
                    mimeType
                }
                image {
                    assetId
                    mimeType
                }
                video {
                    assetId
                    mimeType
                }
            }
            formatInstanceId
            formatType
            formatSubType
            limited
            preOrder
            price
            productStatus
            slug
            soldOut
            parentRecordLabel
            plannedCloseDate
            plannedCloseDateTimezone
            plannedPreOrderStartDate
            plannedPreOrderStartDateTimezone
            plannedReleaseDate
            plannedReleaseDateTimezone
            currency
            productInclusions
            recordLabel
            termsAndConditionsUrl
            visible
            userOwnsAnyEditions
            wholesale
        }
    }
`;

export const GET_RELEASES = gql`
    query GetReleasesProductFormats(
        $filters: ProductFormatsFilters
        $first: Int
        $orderBy: [ProductFormatOrderBy!]
        $after: String
    ) {
        productFormats(filters: $filters, first: $first, orderBy: $orderBy, after: $after) {
            edges {
                cursor
                node {
                    artist {
                        name
                        profilePicUrl
                        username
                    }
                    id
                    title
                    artworkUrl
                    formatType
                    formatSubType
                    limited
                    editionSize
                    preOrder
                    price
                    currency
                    productStatus
                    slug
                    soldOut
                    plannedCloseDate
                    preOrderStartDate
                    plannedPreOrderStartDate
                    releaseDate
                    plannedReleaseDate
                    visible
                    wholesale
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                totalCount
                startCursor
            }
        }
    }
`;

export const GET_PAYMENT_URL = gql`
    query GetPaymentUrl($paymentUrlInput: PaymentUrlInput!) {
        paymentUrl(paymentUrlInput: $paymentUrlInput)
    }
`;

export const GET_ARTIST_RELEASES = gql`
    query GetProductFormats(
        $filters: ProductFormatsFilters!
        $first: Int
        $orderBy: [ProductFormatOrderBy!]
        $after: String
    ) {
        productFormats(filters: $filters, first: $first, orderBy: $orderBy, after: $after) {
            edges {
                cursor
                node {
                    artist {
                        name
                        profilePicUrl
                        username
                    }
                    id
                    artworkUrl
                    currency
                    editionSize
                    formatSubType
                    formatType
                    id
                    limited
                    plannedCloseDate
                    plannedPreOrderStartDate
                    plannedReleaseDate
                    preOrder
                    price
                    productStatus
                    slug
                    soldOut
                    title
                    visible
                    wholesale
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                totalCount
                startCursor
            }
        }
    }
`;
