import React from 'react';
import clsx from 'clsx';

import { BaseComponentProps, Button, GTM_ID, Typography } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';
import { GtmEvents } from '@srnade/web/utils';
import { useGtmContext } from '@srnade/web/store/context/gtm.context';
import { LinkWrapper } from '../LinkWrapper';

interface GridHolderProps {
    title?: string;
    buttonText?: string;
    buttonUrl?: string;
    showMoreButton?: boolean;
    loading?: boolean;
    gtmCollectionId?: string;
    gtmLoadMoreId?: string;
    gtmEvents?: GtmEvents;
    onClickShowMore?: () => void;
}

export function GridHolder({
    title,
    buttonText,
    buttonUrl,
    showMoreButton,
    onClickShowMore,
    loading = false,
    gtmCollectionId,
    gtmLoadMoreId,
    gtmEvents,
    children,
}: GridHolderProps & BaseComponentProps): JSX.Element | null {
    const { t } = useTranslation('components', { keyPrefix: 'productGrid' });
    // If a non-empty GTM id is given, include the GA attribute
    const gtmCollectionAttr = gtmCollectionId ? { [GTM_ID]: gtmCollectionId } : {};
    const gtmLoadMoreAttr = gtmLoadMoreId ? { [GTM_ID]: gtmLoadMoreId } : {};
    const externalLink = buttonUrl?.includes('https://' || 'http://');
    const { startPromo } = useGtmContext();

    const handleClick = () => {
        // Only start a promo once Show All is clicked
        gtmEvents?.promoMap(() => {
            if (gtmEvents.promoContext) {
                startPromo(gtmEvents.promoContext);
            }
        });
    };

    return (
        <>
            <div className="tablet:flex">
                <>
                    <Typography variant="h1">{title}</Typography>
                    {buttonText && (
                        <div className="flex ml-auto mt-[3rem] tablet:mt-0 tablet:items-center">
                            {externalLink ? (
                                <a href={buttonUrl} target="_blank" rel="noopener noreferrer">
                                    <Button type="text" text={buttonText} />
                                </a>
                            ) : (
                                <LinkWrapper href={`/${buttonUrl}`} handleClick={() => handleClick()}>
                                    <Button type="text" text={buttonText} />
                                </LinkWrapper>
                            )}
                        </div>
                    )}
                </>
            </div>
            <div
                className={clsx(
                    { ['mt-0']: !buttonText && title?.length === 0 },
                    'grid px-0 mx-auto max-w-[45rem] gap-y-[5rem] tablet:mx-0 tablet:max-w-full tablet:flex tablet:flex-wrap tablet:justify-between laptop:grid laptop:grid-cols-4 laptop:gap-x-[1.6rem] desktop:gap-x-[2rem] mt-[3rem]',
                )}
                {...gtmCollectionAttr}
            >
                {children}
            </div>
            {showMoreButton && (
                <div className="flex justify-center mt-[5rem]">
                    <Button
                        className="w-[33.5rem] tablet:w-[29.5rem]"
                        type="standard"
                        setIcon={false}
                        text={t('loadMoreButton')}
                        onClick={onClickShowMore}
                        loading={loading}
                        {...gtmLoadMoreAttr}
                    />
                </div>
            )}
        </>
    );
}
