import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';

import { Typography, formikFields as form, Icon } from '@srnade/component-ui';
import { replaceUnderscores } from '@srnade/component-ui';

import { removeWhitespaceAndDot } from '@srnade/web/utils';
import { TrackItemProps } from '@srnade/web/types';
import { AudioPlayer } from '@srnade/web/components';

export function TrackItem({ item }: TrackItemProps): JSX.Element {
    const { isrc, fileName, duration, playlist, progress } = item;
    // @todo - Need confirmation on what isrc tooltip content is.
    const { t } = useTranslation('create-pressing');
    const isrcTooltipContent = !isrc ? t('pages.charts.fields.isrcToolTip') : undefined;

    const formattedFileName = removeWhitespaceAndDot(fileName);

    return (
        <div className="laptop:flex laptop:items-center w-full">
            <div className="flex justify-start items-center w-full">
                <div className="w-[3.7rem]">
                    {playlist ? (
                        <div data-testid={`${formattedFileName}-audio-player`}>
                            <AudioPlayer playlist={playlist} showPlayerOnly />
                        </div>
                    ) : (
                        <div data-testid={`${formattedFileName}-audio-player`} className="opacity-20">
                            <Icon icon="playGradient" size={37} />
                        </div>
                    )}
                </div>

                <div className="ml-[1.6rem]">
                    <div className="tablet:max-w-[60rem] laptop:w-[31.5rem]">
                        <Typography data-testid="track-file-name" variant="h4" className="line-clamp-1">
                            {replaceUnderscores(fileName)}
                        </Typography>
                    </div>
                    <Typography data-testid="track-duration" className={clsx({ italic: !item.playlist })} variant="h4">
                        {progress < 100 || !item.playlist ? t('loading') : duration}
                    </Typography>
                </div>
            </div>

            <div className="tablet:flex w-full mt-[1rem]">
                <form.TextField
                    name={`${formattedFileName}-isrc`}
                    type="text"
                    label={t('pages.audio.isrcLabel')}
                    placeholder={t('pages.audio.isrcLabel')}
                    className="w-full pb-[1rem] tablet:mb-0 tablet:mr-[2rem]"
                    toolTipContent={isrcTooltipContent}
                    backgroundWhite
                />
                <form.TextField
                    name={`${formattedFileName}-track`}
                    type="text"
                    label={t('pages.audio.trackNameLabel')}
                    placeholder={t('pages.audio.trackNameLabel')}
                    className="w-full"
                    required
                    backgroundWhite
                />
            </div>
        </div>
    );
}
