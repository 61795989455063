import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/legacy/image';
import ReactPlayer from 'react-player';

import type { BaseComponentProps } from '@srnade/component-ui';

import { useGetStreamUrl } from '@srnade/web/hooks';
import { getTokenExpiryFromUrl, imageHandlerBlur, imageHandlerLoader } from '@srnade/web/utils';

export interface ImageFilePreviewProps {
    imageUrl: string;
    imageAlt?: string;
    imageWidth?: number;
    imageHeight?: number;
}

export interface VideoFilePreviewProps {
    mediaId?: string;
    loop?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    controls?: boolean;
}

export interface FilePreviewProps extends ImageFilePreviewProps, VideoFilePreviewProps {}

export function FilePreview(props: FilePreviewProps & BaseComponentProps) {
    return (
        <>
            {Boolean(props?.mediaId) ? (
                <VideoFilePreview {...props} />
            ) : (
                Boolean(props?.imageUrl) && <ImageFilePreview {...props} />
            )}
        </>
    );
}

function ImageFilePreview({
    imageUrl,
    imageAlt,
    imageWidth = 1000,
    imageHeight = 1000,
}: ImageFilePreviewProps & BaseComponentProps) {
    return (
        <>
            {imageUrl && (
                <Image
                    data-testid="file-preview-img"
                    src={imageUrl}
                    height={imageHeight}
                    width={imageWidth}
                    layout="responsive"
                    alt={imageAlt}
                    loader={imageHandlerLoader}
                    placeholder="blur"
                    blurDataURL={imageHandlerBlur}
                    priority
                />
            )}
        </>
    );
}

function VideoFilePreview({ mediaId, loop, autoPlay, muted, controls }: VideoFilePreviewProps & BaseComponentProps) {
    const [url, setUrl] = useState<string>();
    const [tokenExpiry, setTokenExpiry] = useState<number | null>(null);
    const [shouldFetchStreamUrl, setShouldFetchStreamUrl] = useState<boolean>(true);

    const refreshTokenTimerRef = useRef<NodeJS.Timeout>();
    const playerRef = useRef<ReactPlayer>(null);

    const fetchStreamUrl = useGetStreamUrl();

    const clearRefreshTokenTimer = useCallback(() => {
        if (refreshTokenTimerRef.current) {
            clearTimeout(refreshTokenTimerRef.current);
            refreshTokenTimerRef.current = undefined;
        }
    }, []);

    const getStreamUrl = useCallback(
        async (mediaId?: string) => {
            if (!mediaId) return;

            const response = await fetchStreamUrl(mediaId);
            if (response && response.ok && response.url) {
                const tokenExpiryFromUrl = getTokenExpiryFromUrl(response.url);
                if (tokenExpiryFromUrl) {
                    setTokenExpiry(tokenExpiryFromUrl - Date.now());
                }
                setUrl(response.url);
                setShouldFetchStreamUrl(false);
                clearRefreshTokenTimer();
            }
        },
        [fetchStreamUrl, clearRefreshTokenTimer],
    );

    // deal with current time when token is refreshed
    useEffect(() => {
        playerRef.current?.seekTo(playerRef.current?.getCurrentTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]); // ignoring currentTime dep here as we only want to set this when url changes

    useEffect(() => {
        if (tokenExpiry && !refreshTokenTimerRef.current) {
            refreshTokenTimerRef.current = setTimeout(() => {
                setShouldFetchStreamUrl(true);
                clearRefreshTokenTimer();
            }, tokenExpiry - 10000); // tokenExpiry minus 10 seconds
        }

        return () => clearRefreshTokenTimer();
    }, [tokenExpiry, clearRefreshTokenTimer]);

    useEffect(() => {
        if (mediaId && shouldFetchStreamUrl) {
            getStreamUrl(mediaId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaId, shouldFetchStreamUrl]);

    return (
        <>
            {url && (
                <div key={mediaId}>
                    <ReactPlayer
                        id={mediaId}
                        key={mediaId}
                        ref={playerRef}
                        url={url}
                        width="100%"
                        height="100%"
                        playing={autoPlay}
                        loop={loop}
                        muted={muted}
                        controls={controls}
                        onError={(error) => {
                            console.error('ReactPlayer::onError');
                            console.error(error);
                        }}
                    />
                </div>
            )}
        </>
    );
}
