import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = { context: { clientName: 'hygraph' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    Date: { input: any; output: any };
    DateTime: { input: any; output: any };
    Hex: { input: any; output: any };
    Json: { input: any; output: any };
    Long: { input: any; output: any };
    RGBAHue: { input: any; output: any };
    RGBATransparency: { input: any; output: any };
    RichTextAST: { input: any; output: any };
};

export type Ad = Entity &
    Node & {
        __typename?: 'Ad';
        asset?: Maybe<Asset>;
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** User that created this document */
        createdBy?: Maybe<User>;
        /** Get the document in other stages */
        documentInStages: Array<Ad>;
        /** List of Ad versions */
        history: Array<Version>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** User that last published this document */
        publishedBy?: Maybe<User>;
        scheduledIn: Array<ScheduledOperation>;
        /** System stage field */
        stage: Stage;
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
        /** User that last updated this document */
        updatedBy?: Maybe<User>;
        url?: Maybe<Scalars['String']['output']>;
    };

export type AdAssetArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type AdCreatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type AdDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

export type AdHistoryArgs = {
    limit?: Scalars['Int']['input'];
    skip?: Scalars['Int']['input'];
    stageOverride?: InputMaybe<Stage>;
};

export type AdPublishedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type AdScheduledInArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type AdUpdatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type AdConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: AdWhereUniqueInput;
};

/** A connection to a list of items. */
export type AdConnection = {
    __typename?: 'AdConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<AdEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type AdCreateInput = {
    asset?: InputMaybe<AssetCreateOneInlineInput>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    url?: InputMaybe<Scalars['String']['input']>;
};

export type AdCreateManyInlineInput = {
    /** Connect multiple existing Ad documents */
    connect?: InputMaybe<Array<AdWhereUniqueInput>>;
    /** Create and connect multiple existing Ad documents */
    create?: InputMaybe<Array<AdCreateInput>>;
};

export type AdCreateOneInlineInput = {
    /** Connect one existing Ad document */
    connect?: InputMaybe<AdWhereUniqueInput>;
    /** Create and connect one Ad document */
    create?: InputMaybe<AdCreateInput>;
};

/** An edge in a connection. */
export type AdEdge = {
    __typename?: 'AdEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: Ad;
};

/** Identifies documents */
export type AdManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<AdWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<AdWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<AdWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    asset?: InputMaybe<AssetWhereInput>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<AdWhereStageInput>;
    documentInStages_none?: InputMaybe<AdWhereStageInput>;
    documentInStages_some?: InputMaybe<AdWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
    url?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    url_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    url_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    url_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    url_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    url_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    url_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    url_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum AdOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    url_ASC = 'url_ASC',
    url_DESC = 'url_DESC',
}

export type AdUpdateInput = {
    asset?: InputMaybe<AssetUpdateOneInlineInput>;
    url?: InputMaybe<Scalars['String']['input']>;
};

export type AdUpdateManyInlineInput = {
    /** Connect multiple existing Ad documents */
    connect?: InputMaybe<Array<AdConnectInput>>;
    /** Create and connect multiple Ad documents */
    create?: InputMaybe<Array<AdCreateInput>>;
    /** Delete multiple Ad documents */
    delete?: InputMaybe<Array<AdWhereUniqueInput>>;
    /** Disconnect multiple Ad documents */
    disconnect?: InputMaybe<Array<AdWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing Ad documents */
    set?: InputMaybe<Array<AdWhereUniqueInput>>;
    /** Update multiple Ad documents */
    update?: InputMaybe<Array<AdUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Ad documents */
    upsert?: InputMaybe<Array<AdUpsertWithNestedWhereUniqueInput>>;
};

export type AdUpdateManyInput = {
    url?: InputMaybe<Scalars['String']['input']>;
};

export type AdUpdateManyWithNestedWhereInput = {
    /** Update many input */
    data: AdUpdateManyInput;
    /** Document search */
    where: AdWhereInput;
};

export type AdUpdateOneInlineInput = {
    /** Connect existing Ad document */
    connect?: InputMaybe<AdWhereUniqueInput>;
    /** Create and connect one Ad document */
    create?: InputMaybe<AdCreateInput>;
    /** Delete currently connected Ad document */
    delete?: InputMaybe<Scalars['Boolean']['input']>;
    /** Disconnect currently connected Ad document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
    /** Update single Ad document */
    update?: InputMaybe<AdUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Ad document */
    upsert?: InputMaybe<AdUpsertWithNestedWhereUniqueInput>;
};

export type AdUpdateWithNestedWhereUniqueInput = {
    /** Document to update */
    data: AdUpdateInput;
    /** Unique document search */
    where: AdWhereUniqueInput;
};

export type AdUpsertInput = {
    /** Create document if it didn't exist */
    create: AdCreateInput;
    /** Update document if it exists */
    update: AdUpdateInput;
};

export type AdUpsertWithNestedWhereUniqueInput = {
    /** Upsert data */
    data: AdUpsertInput;
    /** Unique document search */
    where: AdWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AdWhereComparatorInput = {
    /** This field can be used to request to check if the entry is outdated by internal comparison */
    outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type AdWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<AdWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<AdWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<AdWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    asset?: InputMaybe<AssetWhereInput>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<AdWhereStageInput>;
    documentInStages_none?: InputMaybe<AdWhereStageInput>;
    documentInStages_some?: InputMaybe<AdWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
    url?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    url_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    url_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    url_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    url_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    url_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    url_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    url_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AdWhereStageInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<AdWhereStageInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<AdWhereStageInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<AdWhereStageInput>>;
    /** This field contains fields which can be set as true or false to specify an internal comparison */
    compareWithParent?: InputMaybe<AdWhereComparatorInput>;
    /** Specify the stage to compare with */
    stage?: InputMaybe<Stage>;
};

/** References Ad record uniquely */
export type AdWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type Aggregate = {
    __typename?: 'Aggregate';
    count: Scalars['Int']['output'];
};

/** Asset system model */
export type Asset = Entity &
    Node & {
        __typename?: 'Asset';
        assetAd: Array<Ad>;
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** User that created this document */
        createdBy?: Maybe<User>;
        /** Get the document in other stages */
        documentInStages: Array<Asset>;
        /** The file name */
        fileName: Scalars['String']['output'];
        /** The file handle */
        handle: Scalars['String']['output'];
        /** The height of the file */
        height?: Maybe<Scalars['Float']['output']>;
        /** List of Asset versions */
        history: Array<Version>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        imagePartner: Array<Partner>;
        imageUpcomingDrop: Array<UpcomingDrop>;
        /** System Locale field */
        locale: Locale;
        /** Get the other localizations for this document */
        localizations: Array<Asset>;
        /** The mime type of the file */
        mimeType?: Maybe<Scalars['String']['output']>;
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** User that last published this document */
        publishedBy?: Maybe<User>;
        scheduledIn: Array<ScheduledOperation>;
        /** The file size */
        size?: Maybe<Scalars['Float']['output']>;
        /** System stage field */
        stage: Stage;
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
        /** User that last updated this document */
        updatedBy?: Maybe<User>;
        /** Get the url for the asset with provided transformations applied. */
        url: Scalars['String']['output'];
        /** The file width */
        width?: Maybe<Scalars['Float']['output']>;
    };

/** Asset system model */
export type AssetAssetAdArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    orderBy?: InputMaybe<AdOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<AdWhereInput>;
};

/** Asset system model */
export type AssetCreatedAtArgs = {
    variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetCreatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

/** Asset system model */
export type AssetHistoryArgs = {
    limit?: Scalars['Int']['input'];
    skip?: Scalars['Int']['input'];
    stageOverride?: InputMaybe<Stage>;
};

/** Asset system model */
export type AssetImagePartnerArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    orderBy?: InputMaybe<PartnerOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<PartnerWhereInput>;
};

/** Asset system model */
export type AssetImageUpcomingDropArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    orderBy?: InputMaybe<UpcomingDropOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<UpcomingDropWhereInput>;
};

/** Asset system model */
export type AssetLocalizationsArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    locales?: Array<Locale>;
};

/** Asset system model */
export type AssetPublishedAtArgs = {
    variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPublishedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetScheduledInArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Asset system model */
export type AssetUpdatedAtArgs = {
    variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetUpdatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetUrlArgs = {
    transformation?: InputMaybe<AssetTransformationInput>;
};

export type AssetConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
    __typename?: 'AssetConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<AssetEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type AssetCreateInput = {
    assetAd?: InputMaybe<AdCreateManyInlineInput>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    fileName: Scalars['String']['input'];
    handle: Scalars['String']['input'];
    height?: InputMaybe<Scalars['Float']['input']>;
    imagePartner?: InputMaybe<PartnerCreateManyInlineInput>;
    imageUpcomingDrop?: InputMaybe<UpcomingDropCreateManyInlineInput>;
    /** Inline mutations for managing document localizations excluding the default locale */
    localizations?: InputMaybe<AssetCreateLocalizationsInput>;
    mimeType?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Float']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetCreateLocalizationDataInput = {
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    fileName: Scalars['String']['input'];
    handle: Scalars['String']['input'];
    height?: InputMaybe<Scalars['Float']['input']>;
    mimeType?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Float']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetCreateLocalizationInput = {
    /** Localization input */
    data: AssetCreateLocalizationDataInput;
    locale: Locale;
};

export type AssetCreateLocalizationsInput = {
    /** Create localizations for the newly-created document */
    create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
    /** Connect multiple existing Asset documents */
    connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
    /** Create and connect multiple existing Asset documents */
    create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
    /** Connect one existing Asset document */
    connect?: InputMaybe<AssetWhereUniqueInput>;
    /** Create and connect one Asset document */
    create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
    __typename?: 'AssetEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<AssetWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<AssetWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<AssetWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    assetAd_every?: InputMaybe<AdWhereInput>;
    assetAd_none?: InputMaybe<AdWhereInput>;
    assetAd_some?: InputMaybe<AdWhereInput>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<AssetWhereStageInput>;
    documentInStages_none?: InputMaybe<AssetWhereStageInput>;
    documentInStages_some?: InputMaybe<AssetWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    imagePartner_every?: InputMaybe<PartnerWhereInput>;
    imagePartner_none?: InputMaybe<PartnerWhereInput>;
    imagePartner_some?: InputMaybe<PartnerWhereInput>;
    imageUpcomingDrop_every?: InputMaybe<UpcomingDropWhereInput>;
    imageUpcomingDrop_none?: InputMaybe<UpcomingDropWhereInput>;
    imageUpcomingDrop_some?: InputMaybe<UpcomingDropWhereInput>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AssetOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    fileName_ASC = 'fileName_ASC',
    fileName_DESC = 'fileName_DESC',
    handle_ASC = 'handle_ASC',
    handle_DESC = 'handle_DESC',
    height_ASC = 'height_ASC',
    height_DESC = 'height_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    mimeType_ASC = 'mimeType_ASC',
    mimeType_DESC = 'mimeType_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    size_ASC = 'size_ASC',
    size_DESC = 'size_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    width_ASC = 'width_ASC',
    width_DESC = 'width_DESC',
}

/** Transformations for Assets */
export type AssetTransformationInput = {
    document?: InputMaybe<DocumentTransformationInput>;
    image?: InputMaybe<ImageTransformationInput>;
    /** Pass true if you want to validate the passed transformation parameters */
    validateOptions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssetUpdateInput = {
    assetAd?: InputMaybe<AdUpdateManyInlineInput>;
    fileName?: InputMaybe<Scalars['String']['input']>;
    handle?: InputMaybe<Scalars['String']['input']>;
    height?: InputMaybe<Scalars['Float']['input']>;
    imagePartner?: InputMaybe<PartnerUpdateManyInlineInput>;
    imageUpcomingDrop?: InputMaybe<UpcomingDropUpdateManyInlineInput>;
    /** Manage document localizations */
    localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
    mimeType?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Float']['input']>;
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateLocalizationDataInput = {
    fileName?: InputMaybe<Scalars['String']['input']>;
    handle?: InputMaybe<Scalars['String']['input']>;
    height?: InputMaybe<Scalars['Float']['input']>;
    mimeType?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Float']['input']>;
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateLocalizationInput = {
    data: AssetUpdateLocalizationDataInput;
    locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
    /** Localizations to create */
    create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
    /** Localizations to delete */
    delete?: InputMaybe<Array<Locale>>;
    /** Localizations to update */
    update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
    upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
    /** Connect multiple existing Asset documents */
    connect?: InputMaybe<Array<AssetConnectInput>>;
    /** Create and connect multiple Asset documents */
    create?: InputMaybe<Array<AssetCreateInput>>;
    /** Delete multiple Asset documents */
    delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
    /** Disconnect multiple Asset documents */
    disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing Asset documents */
    set?: InputMaybe<Array<AssetWhereUniqueInput>>;
    /** Update multiple Asset documents */
    update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Asset documents */
    upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
    fileName?: InputMaybe<Scalars['String']['input']>;
    height?: InputMaybe<Scalars['Float']['input']>;
    /** Optional updates to localizations */
    localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
    mimeType?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Float']['input']>;
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateManyLocalizationDataInput = {
    fileName?: InputMaybe<Scalars['String']['input']>;
    height?: InputMaybe<Scalars['Float']['input']>;
    mimeType?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Float']['input']>;
    width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateManyLocalizationInput = {
    data: AssetUpdateManyLocalizationDataInput;
    locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
    /** Localizations to update */
    update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
    /** Update many input */
    data: AssetUpdateManyInput;
    /** Document search */
    where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
    /** Connect existing Asset document */
    connect?: InputMaybe<AssetWhereUniqueInput>;
    /** Create and connect one Asset document */
    create?: InputMaybe<AssetCreateInput>;
    /** Delete currently connected Asset document */
    delete?: InputMaybe<Scalars['Boolean']['input']>;
    /** Disconnect currently connected Asset document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
    /** Update single Asset document */
    update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Asset document */
    upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
    /** Document to update */
    data: AssetUpdateInput;
    /** Unique document search */
    where: AssetWhereUniqueInput;
};

export type AssetUpsertInput = {
    /** Create document if it didn't exist */
    create: AssetCreateInput;
    /** Update document if it exists */
    update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
    create: AssetCreateLocalizationDataInput;
    locale: Locale;
    update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
    /** Upsert data */
    data: AssetUpsertInput;
    /** Unique document search */
    where: AssetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
    /** This field can be used to request to check if the entry is outdated by internal comparison */
    outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type AssetWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<AssetWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<AssetWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<AssetWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    assetAd_every?: InputMaybe<AdWhereInput>;
    assetAd_none?: InputMaybe<AdWhereInput>;
    assetAd_some?: InputMaybe<AdWhereInput>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<AssetWhereStageInput>;
    documentInStages_none?: InputMaybe<AssetWhereStageInput>;
    documentInStages_some?: InputMaybe<AssetWhereStageInput>;
    fileName?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    fileName_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    fileName_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    fileName_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    fileName_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    fileName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    fileName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    fileName_starts_with?: InputMaybe<Scalars['String']['input']>;
    handle?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    handle_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    handle_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    handle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    handle_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    handle_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    handle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    handle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    handle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    handle_starts_with?: InputMaybe<Scalars['String']['input']>;
    height?: InputMaybe<Scalars['Float']['input']>;
    /** All values greater than the given value. */
    height_gt?: InputMaybe<Scalars['Float']['input']>;
    /** All values greater than or equal the given value. */
    height_gte?: InputMaybe<Scalars['Float']['input']>;
    /** All values that are contained in given list. */
    height_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
    /** All values less than the given value. */
    height_lt?: InputMaybe<Scalars['Float']['input']>;
    /** All values less than or equal the given value. */
    height_lte?: InputMaybe<Scalars['Float']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    height_not?: InputMaybe<Scalars['Float']['input']>;
    /** All values that are not contained in given list. */
    height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    imagePartner_every?: InputMaybe<PartnerWhereInput>;
    imagePartner_none?: InputMaybe<PartnerWhereInput>;
    imagePartner_some?: InputMaybe<PartnerWhereInput>;
    imageUpcomingDrop_every?: InputMaybe<UpcomingDropWhereInput>;
    imageUpcomingDrop_none?: InputMaybe<UpcomingDropWhereInput>;
    imageUpcomingDrop_some?: InputMaybe<UpcomingDropWhereInput>;
    mimeType?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    mimeType_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    mimeType_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    mimeType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    mimeType_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    mimeType_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    mimeType_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    mimeType_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    mimeType_starts_with?: InputMaybe<Scalars['String']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    size?: InputMaybe<Scalars['Float']['input']>;
    /** All values greater than the given value. */
    size_gt?: InputMaybe<Scalars['Float']['input']>;
    /** All values greater than or equal the given value. */
    size_gte?: InputMaybe<Scalars['Float']['input']>;
    /** All values that are contained in given list. */
    size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
    /** All values less than the given value. */
    size_lt?: InputMaybe<Scalars['Float']['input']>;
    /** All values less than or equal the given value. */
    size_lte?: InputMaybe<Scalars['Float']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    size_not?: InputMaybe<Scalars['Float']['input']>;
    /** All values that are not contained in given list. */
    size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
    width?: InputMaybe<Scalars['Float']['input']>;
    /** All values greater than the given value. */
    width_gt?: InputMaybe<Scalars['Float']['input']>;
    /** All values greater than or equal the given value. */
    width_gte?: InputMaybe<Scalars['Float']['input']>;
    /** All values that are contained in given list. */
    width_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
    /** All values less than the given value. */
    width_lt?: InputMaybe<Scalars['Float']['input']>;
    /** All values less than or equal the given value. */
    width_lte?: InputMaybe<Scalars['Float']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    width_not?: InputMaybe<Scalars['Float']['input']>;
    /** All values that are not contained in given list. */
    width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<AssetWhereStageInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<AssetWhereStageInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<AssetWhereStageInput>>;
    /** This field contains fields which can be set as true or false to specify an internal comparison */
    compareWithParent?: InputMaybe<AssetWhereComparatorInput>;
    /** Specify the stage to compare with */
    stage?: InputMaybe<Stage>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type BatchPayload = {
    __typename?: 'BatchPayload';
    /** The number of nodes that have been affected by the Batch operation. */
    count: Scalars['Long']['output'];
};

export enum City {
    London = 'London',
    Sydney = 'Sydney',
}

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
    __typename?: 'Color';
    css: Scalars['String']['output'];
    hex: Scalars['Hex']['output'];
    rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
    hex?: InputMaybe<Scalars['Hex']['input']>;
    rgba?: InputMaybe<RgbaInput>;
};

export type ConnectPositionInput = {
    /** Connect document after specified document */
    after?: InputMaybe<Scalars['ID']['input']>;
    /** Connect document before specified document */
    before?: InputMaybe<Scalars['ID']['input']>;
    /** Connect document at last position */
    end?: InputMaybe<Scalars['Boolean']['input']>;
    /** Connect document at first position */
    start?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Country {
    Australia = 'Australia',
    United_Kingdom = 'United_Kingdom',
}

export enum DocumentFileTypes {
    doc = 'doc',
    docx = 'docx',
    html = 'html',
    jpg = 'jpg',
    odp = 'odp',
    ods = 'ods',
    odt = 'odt',
    pdf = 'pdf',
    png = 'png',
    ppt = 'ppt',
    pptx = 'pptx',
    svg = 'svg',
    txt = 'txt',
    webp = 'webp',
    xls = 'xls',
    xlsx = 'xlsx',
}

export type DocumentOutputInput = {
    /**
     * Transforms a document into a desired file type.
     * See this matrix for format support:
     *
     * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
     * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
     * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
     * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
     * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
     * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
     * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
     * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
     * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
     * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
     * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
     * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * SVG:	jpg, odp, ods, odt, pdf, png, and webp
     * HTML:	jpg, odt, pdf, svg, txt, and webp
     * TXT:	jpg, html, odt, pdf, svg, and webp
     */
    format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
    /** Changes the output for the file. */
    output?: InputMaybe<DocumentOutputInput>;
};

export type DocumentVersion = {
    __typename?: 'DocumentVersion';
    createdAt: Scalars['DateTime']['output'];
    data?: Maybe<Scalars['Json']['output']>;
    id: Scalars['ID']['output'];
    revision: Scalars['Int']['output'];
    stage: Stage;
};

/** An object with an ID */
export type Entity = {
    /** The id of the object. */
    id: Scalars['ID']['output'];
    /** The Stage of an object */
    stage: Stage;
};

/** This enumeration holds all typenames that implement the Entity interface. Components and models implement the Entity interface. */
export enum EntityTypeName {
    Ad = 'Ad',
    /** Asset system model */
    Asset = 'Asset',
    Partner = 'Partner',
    /** Scheduled Operation system model */
    ScheduledOperation = 'ScheduledOperation',
    /** Scheduled Release system model */
    ScheduledRelease = 'ScheduledRelease',
    SimplePage = 'SimplePage',
    UpcomingDrop = 'UpcomingDrop',
    /** User system model */
    User = 'User',
}

/** Allows to specify input to query models and components directly */
export type EntityWhereInput = {
    /** The ID of an object */
    id: Scalars['ID']['input'];
    locale?: InputMaybe<Locale>;
    stage: Stage;
    /** The Type name of an object */
    typename: EntityTypeName;
};

export enum ImageFit {
    /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
    clip = 'clip',
    /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
    crop = 'crop',
    /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
    max = 'max',
    /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
    scale = 'scale',
}

export type ImageResizeInput = {
    /** The default value for the fit parameter is fit:clip. */
    fit?: InputMaybe<ImageFit>;
    /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
    height?: InputMaybe<Scalars['Int']['input']>;
    /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
    width?: InputMaybe<Scalars['Int']['input']>;
};

/** Transformations for Images */
export type ImageTransformationInput = {
    /** Resizes the image */
    resize?: InputMaybe<ImageResizeInput>;
};

/** Locale system enumeration */
export enum Locale {
    /** System locale */
    en = 'en',
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
    __typename?: 'Location';
    distance: Scalars['Float']['output'];
    latitude: Scalars['Float']['output'];
    longitude: Scalars['Float']['output'];
};

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
    from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
    latitude: Scalars['Float']['input'];
    longitude: Scalars['Float']['input'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Create one ad */
    createAd?: Maybe<Ad>;
    /**
     * Create one asset
     * @deprecated Asset mutations will be overhauled soon
     */
    createAsset?: Maybe<Asset>;
    /** Create one partner */
    createPartner?: Maybe<Partner>;
    /** Create one scheduledRelease */
    createScheduledRelease?: Maybe<ScheduledRelease>;
    /** Create one simplePage */
    createSimplePage?: Maybe<SimplePage>;
    /** Create one upcomingDrop */
    createUpcomingDrop?: Maybe<UpcomingDrop>;
    /** Delete one ad from _all_ existing stages. Returns deleted document. */
    deleteAd?: Maybe<Ad>;
    /** Delete one asset from _all_ existing stages. Returns deleted document. */
    deleteAsset?: Maybe<Asset>;
    /**
     * Delete many Ad documents
     * @deprecated Please use the new paginated many mutation (deleteManyAdsConnection)
     */
    deleteManyAds: BatchPayload;
    /** Delete many Ad documents, return deleted documents */
    deleteManyAdsConnection: AdConnection;
    /**
     * Delete many Asset documents
     * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
     */
    deleteManyAssets: BatchPayload;
    /** Delete many Asset documents, return deleted documents */
    deleteManyAssetsConnection: AssetConnection;
    /**
     * Delete many Partner documents
     * @deprecated Please use the new paginated many mutation (deleteManyPartnersConnection)
     */
    deleteManyPartners: BatchPayload;
    /** Delete many Partner documents, return deleted documents */
    deleteManyPartnersConnection: PartnerConnection;
    /**
     * Delete many SimplePage documents
     * @deprecated Please use the new paginated many mutation (deleteManySimplePagesConnection)
     */
    deleteManySimplePages: BatchPayload;
    /** Delete many SimplePage documents, return deleted documents */
    deleteManySimplePagesConnection: SimplePageConnection;
    /**
     * Delete many UpcomingDrop documents
     * @deprecated Please use the new paginated many mutation (deleteManyUpcomingDropsConnection)
     */
    deleteManyUpcomingDrops: BatchPayload;
    /** Delete many UpcomingDrop documents, return deleted documents */
    deleteManyUpcomingDropsConnection: UpcomingDropConnection;
    /** Delete one partner from _all_ existing stages. Returns deleted document. */
    deletePartner?: Maybe<Partner>;
    /** Delete and return scheduled operation */
    deleteScheduledOperation?: Maybe<ScheduledOperation>;
    /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
    deleteScheduledRelease?: Maybe<ScheduledRelease>;
    /** Delete one simplePage from _all_ existing stages. Returns deleted document. */
    deleteSimplePage?: Maybe<SimplePage>;
    /** Delete one upcomingDrop from _all_ existing stages. Returns deleted document. */
    deleteUpcomingDrop?: Maybe<UpcomingDrop>;
    /** Publish one ad */
    publishAd?: Maybe<Ad>;
    /** Publish one asset */
    publishAsset?: Maybe<Asset>;
    /**
     * Publish many Ad documents
     * @deprecated Please use the new paginated many mutation (publishManyAdsConnection)
     */
    publishManyAds: BatchPayload;
    /** Publish many Ad documents */
    publishManyAdsConnection: AdConnection;
    /**
     * Publish many Asset documents
     * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
     */
    publishManyAssets: BatchPayload;
    /** Publish many Asset documents */
    publishManyAssetsConnection: AssetConnection;
    /**
     * Publish many Partner documents
     * @deprecated Please use the new paginated many mutation (publishManyPartnersConnection)
     */
    publishManyPartners: BatchPayload;
    /** Publish many Partner documents */
    publishManyPartnersConnection: PartnerConnection;
    /**
     * Publish many SimplePage documents
     * @deprecated Please use the new paginated many mutation (publishManySimplePagesConnection)
     */
    publishManySimplePages: BatchPayload;
    /** Publish many SimplePage documents */
    publishManySimplePagesConnection: SimplePageConnection;
    /**
     * Publish many UpcomingDrop documents
     * @deprecated Please use the new paginated many mutation (publishManyUpcomingDropsConnection)
     */
    publishManyUpcomingDrops: BatchPayload;
    /** Publish many UpcomingDrop documents */
    publishManyUpcomingDropsConnection: UpcomingDropConnection;
    /** Publish one partner */
    publishPartner?: Maybe<Partner>;
    /** Publish one simplePage */
    publishSimplePage?: Maybe<SimplePage>;
    /** Publish one upcomingDrop */
    publishUpcomingDrop?: Maybe<UpcomingDrop>;
    /** Schedule to publish one ad */
    schedulePublishAd?: Maybe<Ad>;
    /** Schedule to publish one asset */
    schedulePublishAsset?: Maybe<Asset>;
    /** Schedule to publish one partner */
    schedulePublishPartner?: Maybe<Partner>;
    /** Schedule to publish one simplePage */
    schedulePublishSimplePage?: Maybe<SimplePage>;
    /** Schedule to publish one upcomingDrop */
    schedulePublishUpcomingDrop?: Maybe<UpcomingDrop>;
    /** Unpublish one ad from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    scheduleUnpublishAd?: Maybe<Ad>;
    /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    scheduleUnpublishAsset?: Maybe<Asset>;
    /** Unpublish one partner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    scheduleUnpublishPartner?: Maybe<Partner>;
    /** Unpublish one simplePage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    scheduleUnpublishSimplePage?: Maybe<SimplePage>;
    /** Unpublish one upcomingDrop from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    scheduleUnpublishUpcomingDrop?: Maybe<UpcomingDrop>;
    /** Unpublish one ad from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    unpublishAd?: Maybe<Ad>;
    /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    unpublishAsset?: Maybe<Asset>;
    /**
     * Unpublish many Ad documents
     * @deprecated Please use the new paginated many mutation (unpublishManyAdsConnection)
     */
    unpublishManyAds: BatchPayload;
    /** Find many Ad documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyAdsConnection: AdConnection;
    /**
     * Unpublish many Asset documents
     * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
     */
    unpublishManyAssets: BatchPayload;
    /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyAssetsConnection: AssetConnection;
    /**
     * Unpublish many Partner documents
     * @deprecated Please use the new paginated many mutation (unpublishManyPartnersConnection)
     */
    unpublishManyPartners: BatchPayload;
    /** Find many Partner documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyPartnersConnection: PartnerConnection;
    /**
     * Unpublish many SimplePage documents
     * @deprecated Please use the new paginated many mutation (unpublishManySimplePagesConnection)
     */
    unpublishManySimplePages: BatchPayload;
    /** Find many SimplePage documents that match criteria in specified stage and unpublish from target stages */
    unpublishManySimplePagesConnection: SimplePageConnection;
    /**
     * Unpublish many UpcomingDrop documents
     * @deprecated Please use the new paginated many mutation (unpublishManyUpcomingDropsConnection)
     */
    unpublishManyUpcomingDrops: BatchPayload;
    /** Find many UpcomingDrop documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyUpcomingDropsConnection: UpcomingDropConnection;
    /** Unpublish one partner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    unpublishPartner?: Maybe<Partner>;
    /** Unpublish one simplePage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    unpublishSimplePage?: Maybe<SimplePage>;
    /** Unpublish one upcomingDrop from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
    unpublishUpcomingDrop?: Maybe<UpcomingDrop>;
    /** Update one ad */
    updateAd?: Maybe<Ad>;
    /** Update one asset */
    updateAsset?: Maybe<Asset>;
    /**
     * Update many ads
     * @deprecated Please use the new paginated many mutation (updateManyAdsConnection)
     */
    updateManyAds: BatchPayload;
    /** Update many Ad documents */
    updateManyAdsConnection: AdConnection;
    /**
     * Update many assets
     * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
     */
    updateManyAssets: BatchPayload;
    /** Update many Asset documents */
    updateManyAssetsConnection: AssetConnection;
    /**
     * Update many partners
     * @deprecated Please use the new paginated many mutation (updateManyPartnersConnection)
     */
    updateManyPartners: BatchPayload;
    /** Update many Partner documents */
    updateManyPartnersConnection: PartnerConnection;
    /**
     * Update many simplePages
     * @deprecated Please use the new paginated many mutation (updateManySimplePagesConnection)
     */
    updateManySimplePages: BatchPayload;
    /** Update many SimplePage documents */
    updateManySimplePagesConnection: SimplePageConnection;
    /**
     * Update many upcomingDrops
     * @deprecated Please use the new paginated many mutation (updateManyUpcomingDropsConnection)
     */
    updateManyUpcomingDrops: BatchPayload;
    /** Update many UpcomingDrop documents */
    updateManyUpcomingDropsConnection: UpcomingDropConnection;
    /** Update one partner */
    updatePartner?: Maybe<Partner>;
    /** Update one scheduledRelease */
    updateScheduledRelease?: Maybe<ScheduledRelease>;
    /** Update one simplePage */
    updateSimplePage?: Maybe<SimplePage>;
    /** Update one upcomingDrop */
    updateUpcomingDrop?: Maybe<UpcomingDrop>;
    /** Upsert one ad */
    upsertAd?: Maybe<Ad>;
    /** Upsert one asset */
    upsertAsset?: Maybe<Asset>;
    /** Upsert one partner */
    upsertPartner?: Maybe<Partner>;
    /** Upsert one simplePage */
    upsertSimplePage?: Maybe<SimplePage>;
    /** Upsert one upcomingDrop */
    upsertUpcomingDrop?: Maybe<UpcomingDrop>;
};

export type MutationCreateAdArgs = {
    data: AdCreateInput;
};

export type MutationCreateAssetArgs = {
    data: AssetCreateInput;
};

export type MutationCreatePartnerArgs = {
    data: PartnerCreateInput;
};

export type MutationCreateScheduledReleaseArgs = {
    data: ScheduledReleaseCreateInput;
};

export type MutationCreateSimplePageArgs = {
    data: SimplePageCreateInput;
};

export type MutationCreateUpcomingDropArgs = {
    data: UpcomingDropCreateInput;
};

export type MutationDeleteAdArgs = {
    where: AdWhereUniqueInput;
};

export type MutationDeleteAssetArgs = {
    where: AssetWhereUniqueInput;
};

export type MutationDeleteManyAdsArgs = {
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationDeleteManyAdsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationDeleteManyAssetsArgs = {
    where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAssetsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyPartnersArgs = {
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationDeleteManyPartnersConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationDeleteManySimplePagesArgs = {
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationDeleteManySimplePagesConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationDeleteManyUpcomingDropsArgs = {
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationDeleteManyUpcomingDropsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationDeletePartnerArgs = {
    where: PartnerWhereUniqueInput;
};

export type MutationDeleteScheduledOperationArgs = {
    where: ScheduledOperationWhereUniqueInput;
};

export type MutationDeleteScheduledReleaseArgs = {
    where: ScheduledReleaseWhereUniqueInput;
};

export type MutationDeleteSimplePageArgs = {
    where: SimplePageWhereUniqueInput;
};

export type MutationDeleteUpcomingDropArgs = {
    where: UpcomingDropWhereUniqueInput;
};

export type MutationPublishAdArgs = {
    to?: Array<Stage>;
    where: AdWhereUniqueInput;
};

export type MutationPublishAssetArgs = {
    locales?: InputMaybe<Array<Locale>>;
    publishBase?: InputMaybe<Scalars['Boolean']['input']>;
    to?: Array<Stage>;
    where: AssetWhereUniqueInput;
    withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationPublishManyAdsArgs = {
    to?: Array<Stage>;
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationPublishManyAdsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: InputMaybe<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    to?: Array<Stage>;
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationPublishManyAssetsArgs = {
    locales?: InputMaybe<Array<Locale>>;
    publishBase?: InputMaybe<Scalars['Boolean']['input']>;
    to?: Array<Stage>;
    where?: InputMaybe<AssetManyWhereInput>;
    withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationPublishManyAssetsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: InputMaybe<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    publishBase?: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    to?: Array<Stage>;
    where?: InputMaybe<AssetManyWhereInput>;
    withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationPublishManyPartnersArgs = {
    to?: Array<Stage>;
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationPublishManyPartnersConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: InputMaybe<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    to?: Array<Stage>;
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationPublishManySimplePagesArgs = {
    to?: Array<Stage>;
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationPublishManySimplePagesConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: InputMaybe<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    to?: Array<Stage>;
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationPublishManyUpcomingDropsArgs = {
    to?: Array<Stage>;
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationPublishManyUpcomingDropsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: InputMaybe<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    to?: Array<Stage>;
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationPublishPartnerArgs = {
    to?: Array<Stage>;
    where: PartnerWhereUniqueInput;
};

export type MutationPublishSimplePageArgs = {
    to?: Array<Stage>;
    where: SimplePageWhereUniqueInput;
};

export type MutationPublishUpcomingDropArgs = {
    to?: Array<Stage>;
    where: UpcomingDropWhereUniqueInput;
};

export type MutationSchedulePublishAdArgs = {
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    to?: Array<Stage>;
    where: AdWhereUniqueInput;
};

export type MutationSchedulePublishAssetArgs = {
    locales?: InputMaybe<Array<Locale>>;
    publishBase?: InputMaybe<Scalars['Boolean']['input']>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    to?: Array<Stage>;
    where: AssetWhereUniqueInput;
    withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSchedulePublishPartnerArgs = {
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    to?: Array<Stage>;
    where: PartnerWhereUniqueInput;
};

export type MutationSchedulePublishSimplePageArgs = {
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    to?: Array<Stage>;
    where: SimplePageWhereUniqueInput;
};

export type MutationSchedulePublishUpcomingDropArgs = {
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    to?: Array<Stage>;
    where: UpcomingDropWhereUniqueInput;
};

export type MutationScheduleUnpublishAdArgs = {
    from?: Array<Stage>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    where: AdWhereUniqueInput;
};

export type MutationScheduleUnpublishAssetArgs = {
    from?: Array<Stage>;
    locales?: InputMaybe<Array<Locale>>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
    where: AssetWhereUniqueInput;
};

export type MutationScheduleUnpublishPartnerArgs = {
    from?: Array<Stage>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    where: PartnerWhereUniqueInput;
};

export type MutationScheduleUnpublishSimplePageArgs = {
    from?: Array<Stage>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    where: SimplePageWhereUniqueInput;
};

export type MutationScheduleUnpublishUpcomingDropArgs = {
    from?: Array<Stage>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    releaseId?: InputMaybe<Scalars['String']['input']>;
    where: UpcomingDropWhereUniqueInput;
};

export type MutationUnpublishAdArgs = {
    from?: Array<Stage>;
    where: AdWhereUniqueInput;
};

export type MutationUnpublishAssetArgs = {
    from?: Array<Stage>;
    locales?: InputMaybe<Array<Locale>>;
    unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
    where: AssetWhereUniqueInput;
};

export type MutationUnpublishManyAdsArgs = {
    from?: Array<Stage>;
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationUnpublishManyAdsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationUnpublishManyAssetsArgs = {
    from?: Array<Stage>;
    locales?: InputMaybe<Array<Locale>>;
    unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
    where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAssetsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: InputMaybe<Stage>;
    unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
    where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyPartnersArgs = {
    from?: Array<Stage>;
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUnpublishManyPartnersConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUnpublishManySimplePagesArgs = {
    from?: Array<Stage>;
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationUnpublishManySimplePagesConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationUnpublishManyUpcomingDropsArgs = {
    from?: Array<Stage>;
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationUnpublishManyUpcomingDropsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationUnpublishPartnerArgs = {
    from?: Array<Stage>;
    where: PartnerWhereUniqueInput;
};

export type MutationUnpublishSimplePageArgs = {
    from?: Array<Stage>;
    where: SimplePageWhereUniqueInput;
};

export type MutationUnpublishUpcomingDropArgs = {
    from?: Array<Stage>;
    where: UpcomingDropWhereUniqueInput;
};

export type MutationUpdateAdArgs = {
    data: AdUpdateInput;
    where: AdWhereUniqueInput;
};

export type MutationUpdateAssetArgs = {
    data: AssetUpdateInput;
    where: AssetWhereUniqueInput;
};

export type MutationUpdateManyAdsArgs = {
    data: AdUpdateManyInput;
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationUpdateManyAdsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    data: AdUpdateManyInput;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<AdManyWhereInput>;
};

export type MutationUpdateManyAssetsArgs = {
    data: AssetUpdateManyInput;
    where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAssetsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    data: AssetUpdateManyInput;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyPartnersArgs = {
    data: PartnerUpdateManyInput;
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUpdateManyPartnersConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    data: PartnerUpdateManyInput;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUpdateManySimplePagesArgs = {
    data: SimplePageUpdateManyInput;
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationUpdateManySimplePagesConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    data: SimplePageUpdateManyInput;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<SimplePageManyWhereInput>;
};

export type MutationUpdateManyUpcomingDropsArgs = {
    data: UpcomingDropUpdateManyInput;
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationUpdateManyUpcomingDropsConnectionArgs = {
    after?: InputMaybe<Scalars['ID']['input']>;
    before?: InputMaybe<Scalars['ID']['input']>;
    data: UpcomingDropUpdateManyInput;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<UpcomingDropManyWhereInput>;
};

export type MutationUpdatePartnerArgs = {
    data: PartnerUpdateInput;
    where: PartnerWhereUniqueInput;
};

export type MutationUpdateScheduledReleaseArgs = {
    data: ScheduledReleaseUpdateInput;
    where: ScheduledReleaseWhereUniqueInput;
};

export type MutationUpdateSimplePageArgs = {
    data: SimplePageUpdateInput;
    where: SimplePageWhereUniqueInput;
};

export type MutationUpdateUpcomingDropArgs = {
    data: UpcomingDropUpdateInput;
    where: UpcomingDropWhereUniqueInput;
};

export type MutationUpsertAdArgs = {
    upsert: AdUpsertInput;
    where: AdWhereUniqueInput;
};

export type MutationUpsertAssetArgs = {
    upsert: AssetUpsertInput;
    where: AssetWhereUniqueInput;
};

export type MutationUpsertPartnerArgs = {
    upsert: PartnerUpsertInput;
    where: PartnerWhereUniqueInput;
};

export type MutationUpsertSimplePageArgs = {
    upsert: SimplePageUpsertInput;
    where: SimplePageWhereUniqueInput;
};

export type MutationUpsertUpcomingDropArgs = {
    upsert: UpcomingDropUpsertInput;
    where: UpcomingDropWhereUniqueInput;
};

/** An object with an ID */
export type Node = {
    /** The id of the object. */
    id: Scalars['ID']['output'];
    /** The Stage of an object */
    stage: Stage;
};

/** Information about pagination in a connection. */
export type PageInfo = {
    __typename?: 'PageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Number of items in the current page. */
    pageSize?: Maybe<Scalars['Int']['output']>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type Partner = Entity &
    Node & {
        __typename?: 'Partner';
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** User that created this document */
        createdBy?: Maybe<User>;
        /** Get the document in other stages */
        documentInStages: Array<Partner>;
        /** List of Partner versions */
        history: Array<Version>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        image: Asset;
        name: Scalars['String']['output'];
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** User that last published this document */
        publishedBy?: Maybe<User>;
        scheduledIn: Array<ScheduledOperation>;
        /** System stage field */
        stage: Stage;
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
        /** User that last updated this document */
        updatedBy?: Maybe<User>;
    };

export type PartnerCreatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type PartnerDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

export type PartnerHistoryArgs = {
    limit?: Scalars['Int']['input'];
    skip?: Scalars['Int']['input'];
    stageOverride?: InputMaybe<Stage>;
};

export type PartnerImageArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type PartnerPublishedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type PartnerScheduledInArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PartnerUpdatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type PartnerConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: PartnerWhereUniqueInput;
};

/** A connection to a list of items. */
export type PartnerConnection = {
    __typename?: 'PartnerConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<PartnerEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type PartnerCreateInput = {
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    image: AssetCreateOneInlineInput;
    name: Scalars['String']['input'];
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PartnerCreateManyInlineInput = {
    /** Connect multiple existing Partner documents */
    connect?: InputMaybe<Array<PartnerWhereUniqueInput>>;
    /** Create and connect multiple existing Partner documents */
    create?: InputMaybe<Array<PartnerCreateInput>>;
};

export type PartnerCreateOneInlineInput = {
    /** Connect one existing Partner document */
    connect?: InputMaybe<PartnerWhereUniqueInput>;
    /** Create and connect one Partner document */
    create?: InputMaybe<PartnerCreateInput>;
};

/** An edge in a connection. */
export type PartnerEdge = {
    __typename?: 'PartnerEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: Partner;
};

/** Identifies documents */
export type PartnerManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<PartnerWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<PartnerWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<PartnerWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<PartnerWhereStageInput>;
    documentInStages_none?: InputMaybe<PartnerWhereStageInput>;
    documentInStages_some?: InputMaybe<PartnerWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    image?: InputMaybe<AssetWhereInput>;
    name?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    name_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    name_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    name_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    name_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    name_starts_with?: InputMaybe<Scalars['String']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PartnerOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    name_ASC = 'name_ASC',
    name_DESC = 'name_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
}

export type PartnerUpdateInput = {
    image?: InputMaybe<AssetUpdateOneInlineInput>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerUpdateManyInlineInput = {
    /** Connect multiple existing Partner documents */
    connect?: InputMaybe<Array<PartnerConnectInput>>;
    /** Create and connect multiple Partner documents */
    create?: InputMaybe<Array<PartnerCreateInput>>;
    /** Delete multiple Partner documents */
    delete?: InputMaybe<Array<PartnerWhereUniqueInput>>;
    /** Disconnect multiple Partner documents */
    disconnect?: InputMaybe<Array<PartnerWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing Partner documents */
    set?: InputMaybe<Array<PartnerWhereUniqueInput>>;
    /** Update multiple Partner documents */
    update?: InputMaybe<Array<PartnerUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Partner documents */
    upsert?: InputMaybe<Array<PartnerUpsertWithNestedWhereUniqueInput>>;
};

export type PartnerUpdateManyInput = {
    /** No fields in updateMany data input */
    _?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerUpdateManyWithNestedWhereInput = {
    /** Update many input */
    data: PartnerUpdateManyInput;
    /** Document search */
    where: PartnerWhereInput;
};

export type PartnerUpdateOneInlineInput = {
    /** Connect existing Partner document */
    connect?: InputMaybe<PartnerWhereUniqueInput>;
    /** Create and connect one Partner document */
    create?: InputMaybe<PartnerCreateInput>;
    /** Delete currently connected Partner document */
    delete?: InputMaybe<Scalars['Boolean']['input']>;
    /** Disconnect currently connected Partner document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
    /** Update single Partner document */
    update?: InputMaybe<PartnerUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Partner document */
    upsert?: InputMaybe<PartnerUpsertWithNestedWhereUniqueInput>;
};

export type PartnerUpdateWithNestedWhereUniqueInput = {
    /** Document to update */
    data: PartnerUpdateInput;
    /** Unique document search */
    where: PartnerWhereUniqueInput;
};

export type PartnerUpsertInput = {
    /** Create document if it didn't exist */
    create: PartnerCreateInput;
    /** Update document if it exists */
    update: PartnerUpdateInput;
};

export type PartnerUpsertWithNestedWhereUniqueInput = {
    /** Upsert data */
    data: PartnerUpsertInput;
    /** Unique document search */
    where: PartnerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PartnerWhereComparatorInput = {
    /** This field can be used to request to check if the entry is outdated by internal comparison */
    outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type PartnerWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<PartnerWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<PartnerWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<PartnerWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<PartnerWhereStageInput>;
    documentInStages_none?: InputMaybe<PartnerWhereStageInput>;
    documentInStages_some?: InputMaybe<PartnerWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    image?: InputMaybe<AssetWhereInput>;
    name?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    name_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    name_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    name_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    name_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    name_starts_with?: InputMaybe<Scalars['String']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PartnerWhereStageInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<PartnerWhereStageInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<PartnerWhereStageInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<PartnerWhereStageInput>>;
    /** This field contains fields which can be set as true or false to specify an internal comparison */
    compareWithParent?: InputMaybe<PartnerWhereComparatorInput>;
    /** Specify the stage to compare with */
    stage?: InputMaybe<Stage>;
};

/** References Partner record uniquely */
export type PartnerWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type PublishLocaleInput = {
    /** Locales to publish */
    locale: Locale;
    /** Stages to publish selected locales to */
    stages: Array<Stage>;
};

export type Query = {
    __typename?: 'Query';
    /** Retrieve a single ad */
    ad?: Maybe<Ad>;
    /** Retrieve document version */
    adVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple ads */
    ads: Array<Ad>;
    /** Retrieve multiple ads using the Relay connection interface */
    adsConnection: AdConnection;
    /** Retrieve a single asset */
    asset?: Maybe<Asset>;
    /** Retrieve document version */
    assetVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple assets */
    assets: Array<Asset>;
    /** Retrieve multiple assets using the Relay connection interface */
    assetsConnection: AssetConnection;
    /** Fetches an object given its ID */
    entities?: Maybe<Array<Entity>>;
    /** Fetches an object given its ID */
    node?: Maybe<Node>;
    /** Retrieve a single partner */
    partner?: Maybe<Partner>;
    /** Retrieve document version */
    partnerVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple partners */
    partners: Array<Partner>;
    /** Retrieve multiple partners using the Relay connection interface */
    partnersConnection: PartnerConnection;
    /** Retrieve a single scheduledOperation */
    scheduledOperation?: Maybe<ScheduledOperation>;
    /** Retrieve multiple scheduledOperations */
    scheduledOperations: Array<ScheduledOperation>;
    /** Retrieve multiple scheduledOperations using the Relay connection interface */
    scheduledOperationsConnection: ScheduledOperationConnection;
    /** Retrieve a single scheduledRelease */
    scheduledRelease?: Maybe<ScheduledRelease>;
    /** Retrieve multiple scheduledReleases */
    scheduledReleases: Array<ScheduledRelease>;
    /** Retrieve multiple scheduledReleases using the Relay connection interface */
    scheduledReleasesConnection: ScheduledReleaseConnection;
    /** Retrieve a single simplePage */
    simplePage?: Maybe<SimplePage>;
    /** Retrieve document version */
    simplePageVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple simplePages */
    simplePages: Array<SimplePage>;
    /** Retrieve multiple simplePages using the Relay connection interface */
    simplePagesConnection: SimplePageConnection;
    /** Retrieve a single upcomingDrop */
    upcomingDrop?: Maybe<UpcomingDrop>;
    /** Retrieve document version */
    upcomingDropVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple upcomingDrops */
    upcomingDrops: Array<UpcomingDrop>;
    /** Retrieve multiple upcomingDrops using the Relay connection interface */
    upcomingDropsConnection: UpcomingDropConnection;
    /** Retrieve a single user */
    user?: Maybe<User>;
    /** Retrieve multiple users */
    users: Array<User>;
    /** Retrieve multiple users using the Relay connection interface */
    usersConnection: UserConnection;
};

export type QueryAdArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: AdWhereUniqueInput;
};

export type QueryAdVersionArgs = {
    where: VersionWhereInput;
};

export type QueryAdsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<AdOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<AdWhereInput>;
};

export type QueryAdsConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<AdOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<AdWhereInput>;
};

export type QueryAssetArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: AssetWhereUniqueInput;
};

export type QueryAssetVersionArgs = {
    where: VersionWhereInput;
};

export type QueryAssetsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<AssetOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<AssetWhereInput>;
};

export type QueryAssetsConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<AssetOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<AssetWhereInput>;
};

export type QueryEntitiesArgs = {
    locales?: InputMaybe<Array<Locale>>;
    where: Array<EntityWhereInput>;
};

export type QueryNodeArgs = {
    id: Scalars['ID']['input'];
    locales?: Array<Locale>;
    stage?: Stage;
};

export type QueryPartnerArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: PartnerWhereUniqueInput;
};

export type QueryPartnerVersionArgs = {
    where: VersionWhereInput;
};

export type QueryPartnersArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<PartnerOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<PartnerWhereInput>;
};

export type QueryPartnersConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<PartnerOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<PartnerWhereInput>;
};

export type QueryScheduledOperationArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: ScheduledOperationWhereUniqueInput;
};

export type QueryScheduledOperationsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledOperationsConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledReleaseArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: ScheduledReleaseWhereUniqueInput;
};

export type QueryScheduledReleasesArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryScheduledReleasesConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QuerySimplePageArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: SimplePageWhereUniqueInput;
};

export type QuerySimplePageVersionArgs = {
    where: VersionWhereInput;
};

export type QuerySimplePagesArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<SimplePageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<SimplePageWhereInput>;
};

export type QuerySimplePagesConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<SimplePageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<SimplePageWhereInput>;
};

export type QueryUpcomingDropArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: UpcomingDropWhereUniqueInput;
};

export type QueryUpcomingDropVersionArgs = {
    where: VersionWhereInput;
};

export type QueryUpcomingDropsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<UpcomingDropOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<UpcomingDropWhereInput>;
};

export type QueryUpcomingDropsConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<UpcomingDropOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<UpcomingDropWhereInput>;
};

export type QueryUserArgs = {
    locales?: Array<Locale>;
    stage?: Stage;
    where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<UserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<UserWhereInput>;
};

export type QueryUsersConnectionArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: Array<Locale>;
    orderBy?: InputMaybe<UserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    stage?: Stage;
    where?: InputMaybe<UserWhereInput>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
    __typename?: 'RGBA';
    a: Scalars['RGBATransparency']['output'];
    b: Scalars['RGBAHue']['output'];
    g: Scalars['RGBAHue']['output'];
    r: Scalars['RGBAHue']['output'];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
    a: Scalars['RGBATransparency']['input'];
    b: Scalars['RGBAHue']['input'];
    g: Scalars['RGBAHue']['input'];
    r: Scalars['RGBAHue']['input'];
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
    __typename?: 'RichText';
    /** Returns HTMl representation */
    html: Scalars['String']['output'];
    /** Returns Markdown representation */
    markdown: Scalars['String']['output'];
    /** Returns AST representation */
    raw: Scalars['RichTextAST']['output'];
    /** Returns plain-text contents of RichText */
    text: Scalars['String']['output'];
};

/** Scheduled Operation system model */
export type ScheduledOperation = Entity &
    Node & {
        __typename?: 'ScheduledOperation';
        affectedDocuments: Array<ScheduledOperationAffectedDocument>;
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** User that created this document */
        createdBy?: Maybe<User>;
        /** Operation description */
        description?: Maybe<Scalars['String']['output']>;
        /** Get the document in other stages */
        documentInStages: Array<ScheduledOperation>;
        /** Operation error message */
        errorMessage?: Maybe<Scalars['String']['output']>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** User that last published this document */
        publishedBy?: Maybe<User>;
        /** Raw operation payload including all details, this field is subject to change */
        rawPayload: Scalars['Json']['output'];
        /** The release this operation is scheduled for */
        release?: Maybe<ScheduledRelease>;
        /** System stage field */
        stage: Stage;
        /** operation Status */
        status: ScheduledOperationStatus;
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
        /** User that last updated this document */
        updatedBy?: Maybe<User>;
    };

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument = Ad | Asset | Partner | SimplePage | UpcomingDrop;

export type ScheduledOperationConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
    __typename?: 'ScheduledOperationConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<ScheduledOperationEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
    /** Connect multiple existing ScheduledOperation documents */
    connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
    /** Connect one existing ScheduledOperation document */
    connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
    __typename?: 'ScheduledOperationEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    description?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    description_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    description_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    description_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    description_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    description_starts_with?: InputMaybe<Scalars['String']['input']>;
    errorMessage?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    errorMessage_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    /** All values containing the given json path. */
    rawPayload_json_path_exists?: InputMaybe<Scalars['String']['input']>;
    /**
     * Recursively tries to find the provided JSON scalar value inside the field.
     * It does use an exact match when comparing values.
     * If you pass `null` as value the filter will be ignored.
     * Note: This filter fails if you try to look for a non scalar JSON value!
     */
    rawPayload_value_recursive?: InputMaybe<Scalars['Json']['input']>;
    release?: InputMaybe<ScheduledReleaseWhereInput>;
    status?: InputMaybe<ScheduledOperationStatus>;
    /** All values that are contained in given list. */
    status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
    /** Any other value that exists and is not equal to the given value. */
    status_not?: InputMaybe<ScheduledOperationStatus>;
    /** All values that are not contained in given list. */
    status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    description_ASC = 'description_ASC',
    description_DESC = 'description_DESC',
    errorMessage_ASC = 'errorMessage_ASC',
    errorMessage_DESC = 'errorMessage_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    status_ASC = 'status_ASC',
    status_DESC = 'status_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
    CANCELED = 'CANCELED',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING = 'PENDING',
}

export type ScheduledOperationUpdateManyInlineInput = {
    /** Connect multiple existing ScheduledOperation documents */
    connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
    /** Disconnect multiple ScheduledOperation documents */
    disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing ScheduledOperation documents */
    set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
    /** Connect existing ScheduledOperation document */
    connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
    /** Disconnect currently connected ScheduledOperation document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    description?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    description_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    description_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    description_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    description_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    description_starts_with?: InputMaybe<Scalars['String']['input']>;
    errorMessage?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    errorMessage_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    /** All values containing the given json path. */
    rawPayload_json_path_exists?: InputMaybe<Scalars['String']['input']>;
    /**
     * Recursively tries to find the provided JSON scalar value inside the field.
     * It does use an exact match when comparing values.
     * If you pass `null` as value the filter will be ignored.
     * Note: This filter fails if you try to look for a non scalar JSON value!
     */
    rawPayload_value_recursive?: InputMaybe<Scalars['Json']['input']>;
    release?: InputMaybe<ScheduledReleaseWhereInput>;
    status?: InputMaybe<ScheduledOperationStatus>;
    /** All values that are contained in given list. */
    status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
    /** Any other value that exists and is not equal to the given value. */
    status_not?: InputMaybe<ScheduledOperationStatus>;
    /** All values that are not contained in given list. */
    status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Entity &
    Node & {
        __typename?: 'ScheduledRelease';
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** User that created this document */
        createdBy?: Maybe<User>;
        /** Release description */
        description?: Maybe<Scalars['String']['output']>;
        /** Get the document in other stages */
        documentInStages: Array<ScheduledRelease>;
        /** Release error message */
        errorMessage?: Maybe<Scalars['String']['output']>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        /** Whether scheduled release should be run */
        isActive: Scalars['Boolean']['output'];
        /** Whether scheduled release is implicit */
        isImplicit: Scalars['Boolean']['output'];
        /** Operations to run with this release */
        operations: Array<ScheduledOperation>;
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** User that last published this document */
        publishedBy?: Maybe<User>;
        /** Release date and time */
        releaseAt?: Maybe<Scalars['DateTime']['output']>;
        /** System stage field */
        stage: Stage;
        /** Release Status */
        status: ScheduledReleaseStatus;
        /** Release Title */
        title?: Maybe<Scalars['String']['output']>;
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
        /** User that last updated this document */
        updatedBy?: Maybe<User>;
    };

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
    __typename?: 'ScheduledReleaseConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<ScheduledReleaseEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    errorMessage?: InputMaybe<Scalars['String']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScheduledReleaseCreateManyInlineInput = {
    /** Connect multiple existing ScheduledRelease documents */
    connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
    /** Create and connect multiple existing ScheduledRelease documents */
    create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
    /** Connect one existing ScheduledRelease document */
    connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
    /** Create and connect one ScheduledRelease document */
    create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
    __typename?: 'ScheduledReleaseEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    description?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    description_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    description_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    description_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    description_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    description_starts_with?: InputMaybe<Scalars['String']['input']>;
    errorMessage?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    errorMessage_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
    isImplicit?: InputMaybe<Scalars['Boolean']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    isImplicit_not?: InputMaybe<Scalars['Boolean']['input']>;
    operations_every?: InputMaybe<ScheduledOperationWhereInput>;
    operations_none?: InputMaybe<ScheduledOperationWhereInput>;
    operations_some?: InputMaybe<ScheduledOperationWhereInput>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    releaseAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    releaseAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    releaseAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    releaseAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    releaseAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    status?: InputMaybe<ScheduledReleaseStatus>;
    /** All values that are contained in given list. */
    status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
    /** Any other value that exists and is not equal to the given value. */
    status_not?: InputMaybe<ScheduledReleaseStatus>;
    /** All values that are not contained in given list. */
    status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
    title?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    title_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    title_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    title_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    title_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    title_starts_with?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    description_ASC = 'description_ASC',
    description_DESC = 'description_DESC',
    errorMessage_ASC = 'errorMessage_ASC',
    errorMessage_DESC = 'errorMessage_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    isActive_ASC = 'isActive_ASC',
    isActive_DESC = 'isActive_DESC',
    isImplicit_ASC = 'isImplicit_ASC',
    isImplicit_DESC = 'isImplicit_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    releaseAt_ASC = 'releaseAt_ASC',
    releaseAt_DESC = 'releaseAt_DESC',
    status_ASC = 'status_ASC',
    status_DESC = 'status_DESC',
    title_ASC = 'title_ASC',
    title_DESC = 'title_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING = 'PENDING',
}

export type ScheduledReleaseUpdateInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    errorMessage?: InputMaybe<Scalars['String']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
    /** Connect multiple existing ScheduledRelease documents */
    connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
    /** Create and connect multiple ScheduledRelease documents */
    create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
    /** Delete multiple ScheduledRelease documents */
    delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
    /** Disconnect multiple ScheduledRelease documents */
    disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing ScheduledRelease documents */
    set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
    /** Update multiple ScheduledRelease documents */
    update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple ScheduledRelease documents */
    upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    errorMessage?: InputMaybe<Scalars['String']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
    /** Update many input */
    data: ScheduledReleaseUpdateManyInput;
    /** Document search */
    where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
    /** Connect existing ScheduledRelease document */
    connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
    /** Create and connect one ScheduledRelease document */
    create?: InputMaybe<ScheduledReleaseCreateInput>;
    /** Delete currently connected ScheduledRelease document */
    delete?: InputMaybe<Scalars['Boolean']['input']>;
    /** Disconnect currently connected ScheduledRelease document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
    /** Update single ScheduledRelease document */
    update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
    /** Upsert single ScheduledRelease document */
    upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
    /** Document to update */
    data: ScheduledReleaseUpdateInput;
    /** Unique document search */
    where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
    /** Create document if it didn't exist */
    create: ScheduledReleaseCreateInput;
    /** Update document if it exists */
    update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
    /** Upsert data */
    data: ScheduledReleaseUpsertInput;
    /** Unique document search */
    where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    description?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    description_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    description_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    description_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    description_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    description_starts_with?: InputMaybe<Scalars['String']['input']>;
    errorMessage?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    errorMessage_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
    isImplicit?: InputMaybe<Scalars['Boolean']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    isImplicit_not?: InputMaybe<Scalars['Boolean']['input']>;
    operations_every?: InputMaybe<ScheduledOperationWhereInput>;
    operations_none?: InputMaybe<ScheduledOperationWhereInput>;
    operations_some?: InputMaybe<ScheduledOperationWhereInput>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    releaseAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    releaseAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    releaseAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    releaseAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    releaseAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    status?: InputMaybe<ScheduledReleaseStatus>;
    /** All values that are contained in given list. */
    status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
    /** Any other value that exists and is not equal to the given value. */
    status_not?: InputMaybe<ScheduledReleaseStatus>;
    /** All values that are not contained in given list. */
    status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
    title?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    title_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    title_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    title_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    title_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    title_starts_with?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type SimplePage = Entity &
    Node & {
        __typename?: 'SimplePage';
        content?: Maybe<RichText>;
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** User that created this document */
        createdBy?: Maybe<User>;
        /** Get the document in other stages */
        documentInStages: Array<SimplePage>;
        /** List of SimplePage versions */
        history: Array<Version>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** User that last published this document */
        publishedBy?: Maybe<User>;
        scheduledIn: Array<ScheduledOperation>;
        slug?: Maybe<Scalars['String']['output']>;
        /** System stage field */
        stage: Stage;
        title: Scalars['String']['output'];
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
        /** User that last updated this document */
        updatedBy?: Maybe<User>;
    };

export type SimplePageCreatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type SimplePageDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

export type SimplePageHistoryArgs = {
    limit?: Scalars['Int']['input'];
    skip?: Scalars['Int']['input'];
    stageOverride?: InputMaybe<Stage>;
};

export type SimplePagePublishedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type SimplePageScheduledInArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type SimplePageUpdatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type SimplePageConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: SimplePageWhereUniqueInput;
};

/** A connection to a list of items. */
export type SimplePageConnection = {
    __typename?: 'SimplePageConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<SimplePageEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type SimplePageCreateInput = {
    content?: InputMaybe<Scalars['RichTextAST']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SimplePageCreateManyInlineInput = {
    /** Connect multiple existing SimplePage documents */
    connect?: InputMaybe<Array<SimplePageWhereUniqueInput>>;
    /** Create and connect multiple existing SimplePage documents */
    create?: InputMaybe<Array<SimplePageCreateInput>>;
};

export type SimplePageCreateOneInlineInput = {
    /** Connect one existing SimplePage document */
    connect?: InputMaybe<SimplePageWhereUniqueInput>;
    /** Create and connect one SimplePage document */
    create?: InputMaybe<SimplePageCreateInput>;
};

/** An edge in a connection. */
export type SimplePageEdge = {
    __typename?: 'SimplePageEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: SimplePage;
};

/** Identifies documents */
export type SimplePageManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<SimplePageWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<SimplePageWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<SimplePageWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<SimplePageWhereStageInput>;
    documentInStages_none?: InputMaybe<SimplePageWhereStageInput>;
    documentInStages_some?: InputMaybe<SimplePageWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    slug?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    slug_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    slug_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    slug_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    slug_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    slug_starts_with?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    title_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    title_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    title_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    title_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    title_starts_with?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

export enum SimplePageOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    slug_ASC = 'slug_ASC',
    slug_DESC = 'slug_DESC',
    title_ASC = 'title_ASC',
    title_DESC = 'title_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
}

export type SimplePageUpdateInput = {
    content?: InputMaybe<Scalars['RichTextAST']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type SimplePageUpdateManyInlineInput = {
    /** Connect multiple existing SimplePage documents */
    connect?: InputMaybe<Array<SimplePageConnectInput>>;
    /** Create and connect multiple SimplePage documents */
    create?: InputMaybe<Array<SimplePageCreateInput>>;
    /** Delete multiple SimplePage documents */
    delete?: InputMaybe<Array<SimplePageWhereUniqueInput>>;
    /** Disconnect multiple SimplePage documents */
    disconnect?: InputMaybe<Array<SimplePageWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing SimplePage documents */
    set?: InputMaybe<Array<SimplePageWhereUniqueInput>>;
    /** Update multiple SimplePage documents */
    update?: InputMaybe<Array<SimplePageUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple SimplePage documents */
    upsert?: InputMaybe<Array<SimplePageUpsertWithNestedWhereUniqueInput>>;
};

export type SimplePageUpdateManyInput = {
    content?: InputMaybe<Scalars['RichTextAST']['input']>;
};

export type SimplePageUpdateManyWithNestedWhereInput = {
    /** Update many input */
    data: SimplePageUpdateManyInput;
    /** Document search */
    where: SimplePageWhereInput;
};

export type SimplePageUpdateOneInlineInput = {
    /** Connect existing SimplePage document */
    connect?: InputMaybe<SimplePageWhereUniqueInput>;
    /** Create and connect one SimplePage document */
    create?: InputMaybe<SimplePageCreateInput>;
    /** Delete currently connected SimplePage document */
    delete?: InputMaybe<Scalars['Boolean']['input']>;
    /** Disconnect currently connected SimplePage document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
    /** Update single SimplePage document */
    update?: InputMaybe<SimplePageUpdateWithNestedWhereUniqueInput>;
    /** Upsert single SimplePage document */
    upsert?: InputMaybe<SimplePageUpsertWithNestedWhereUniqueInput>;
};

export type SimplePageUpdateWithNestedWhereUniqueInput = {
    /** Document to update */
    data: SimplePageUpdateInput;
    /** Unique document search */
    where: SimplePageWhereUniqueInput;
};

export type SimplePageUpsertInput = {
    /** Create document if it didn't exist */
    create: SimplePageCreateInput;
    /** Update document if it exists */
    update: SimplePageUpdateInput;
};

export type SimplePageUpsertWithNestedWhereUniqueInput = {
    /** Upsert data */
    data: SimplePageUpsertInput;
    /** Unique document search */
    where: SimplePageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type SimplePageWhereComparatorInput = {
    /** This field can be used to request to check if the entry is outdated by internal comparison */
    outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type SimplePageWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<SimplePageWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<SimplePageWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<SimplePageWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<SimplePageWhereStageInput>;
    documentInStages_none?: InputMaybe<SimplePageWhereStageInput>;
    documentInStages_some?: InputMaybe<SimplePageWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    slug?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    slug_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    slug_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    slug_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    slug_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    slug_starts_with?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    title_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    title_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    title_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    title_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    title_starts_with?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SimplePageWhereStageInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<SimplePageWhereStageInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<SimplePageWhereStageInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<SimplePageWhereStageInput>>;
    /** This field contains fields which can be set as true or false to specify an internal comparison */
    compareWithParent?: InputMaybe<SimplePageWhereComparatorInput>;
    /** Specify the stage to compare with */
    stage?: InputMaybe<Stage>;
};

/** References SimplePage record uniquely */
export type SimplePageWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

/** Stage system enumeration */
export enum Stage {
    /** The Draft is the default stage for all your content. */
    DRAFT = 'DRAFT',
    /** The Published stage is where you can publish your content to. */
    PUBLISHED = 'PUBLISHED',
}

export enum SystemDateTimeFieldVariation {
    BASE = 'BASE',
    COMBINED = 'COMBINED',
    LOCALIZATION = 'LOCALIZATION',
}

export type UnpublishLocaleInput = {
    /** Locales to unpublish */
    locale: Locale;
    /** Stages to unpublish selected locales from */
    stages: Array<Stage>;
};

export type UpcomingDrop = Entity &
    Node & {
        __typename?: 'UpcomingDrop';
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** User that created this document */
        createdBy?: Maybe<User>;
        /** Get the document in other stages */
        documentInStages: Array<UpcomingDrop>;
        dropReleaseDate?: Maybe<Scalars['Date']['output']>;
        /** List of UpcomingDrop versions */
        history: Array<Version>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        /** Drop Image */
        image: Asset;
        /** Drops name or artist name. */
        name: Scalars['String']['output'];
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** User that last published this document */
        publishedBy?: Maybe<User>;
        scheduledIn: Array<ScheduledOperation>;
        spotifyMonthlyListeners?: Maybe<Scalars['Int']['output']>;
        /** System stage field */
        stage: Stage;
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
        /** User that last updated this document */
        updatedBy?: Maybe<User>;
        /** Username, e.g. `thekooks` */
        username?: Maybe<Scalars['String']['output']>;
    };

export type UpcomingDropCreatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type UpcomingDropDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

export type UpcomingDropHistoryArgs = {
    limit?: Scalars['Int']['input'];
    skip?: Scalars['Int']['input'];
    stageOverride?: InputMaybe<Stage>;
};

export type UpcomingDropImageArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type UpcomingDropPublishedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type UpcomingDropScheduledInArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    locales?: InputMaybe<Array<Locale>>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type UpcomingDropUpdatedByArgs = {
    forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
    locales?: InputMaybe<Array<Locale>>;
};

export type UpcomingDropConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: UpcomingDropWhereUniqueInput;
};

/** A connection to a list of items. */
export type UpcomingDropConnection = {
    __typename?: 'UpcomingDropConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<UpcomingDropEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type UpcomingDropCreateInput = {
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    dropReleaseDate?: InputMaybe<Scalars['Date']['input']>;
    image: AssetCreateOneInlineInput;
    name: Scalars['String']['input'];
    spotifyMonthlyListeners?: InputMaybe<Scalars['Int']['input']>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type UpcomingDropCreateManyInlineInput = {
    /** Connect multiple existing UpcomingDrop documents */
    connect?: InputMaybe<Array<UpcomingDropWhereUniqueInput>>;
    /** Create and connect multiple existing UpcomingDrop documents */
    create?: InputMaybe<Array<UpcomingDropCreateInput>>;
};

export type UpcomingDropCreateOneInlineInput = {
    /** Connect one existing UpcomingDrop document */
    connect?: InputMaybe<UpcomingDropWhereUniqueInput>;
    /** Create and connect one UpcomingDrop document */
    create?: InputMaybe<UpcomingDropCreateInput>;
};

/** An edge in a connection. */
export type UpcomingDropEdge = {
    __typename?: 'UpcomingDropEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: UpcomingDrop;
};

/** Identifies documents */
export type UpcomingDropManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<UpcomingDropWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<UpcomingDropWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<UpcomingDropWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<UpcomingDropWhereStageInput>;
    documentInStages_none?: InputMaybe<UpcomingDropWhereStageInput>;
    documentInStages_some?: InputMaybe<UpcomingDropWhereStageInput>;
    dropReleaseDate?: InputMaybe<Scalars['Date']['input']>;
    /** All values greater than the given value. */
    dropReleaseDate_gt?: InputMaybe<Scalars['Date']['input']>;
    /** All values greater than or equal the given value. */
    dropReleaseDate_gte?: InputMaybe<Scalars['Date']['input']>;
    /** All values that are contained in given list. */
    dropReleaseDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
    /** All values less than the given value. */
    dropReleaseDate_lt?: InputMaybe<Scalars['Date']['input']>;
    /** All values less than or equal the given value. */
    dropReleaseDate_lte?: InputMaybe<Scalars['Date']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    dropReleaseDate_not?: InputMaybe<Scalars['Date']['input']>;
    /** All values that are not contained in given list. */
    dropReleaseDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    image?: InputMaybe<AssetWhereInput>;
    name?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    name_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    name_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    name_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    name_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    name_starts_with?: InputMaybe<Scalars['String']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    spotifyMonthlyListeners?: InputMaybe<Scalars['Int']['input']>;
    /** All values greater than the given value. */
    spotifyMonthlyListeners_gt?: InputMaybe<Scalars['Int']['input']>;
    /** All values greater than or equal the given value. */
    spotifyMonthlyListeners_gte?: InputMaybe<Scalars['Int']['input']>;
    /** All values that are contained in given list. */
    spotifyMonthlyListeners_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
    /** All values less than the given value. */
    spotifyMonthlyListeners_lt?: InputMaybe<Scalars['Int']['input']>;
    /** All values less than or equal the given value. */
    spotifyMonthlyListeners_lte?: InputMaybe<Scalars['Int']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    spotifyMonthlyListeners_not?: InputMaybe<Scalars['Int']['input']>;
    /** All values that are not contained in given list. */
    spotifyMonthlyListeners_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
    username?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    username_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    username_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    username_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    username_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    username_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    username_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    username_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    username_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    username_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum UpcomingDropOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    dropReleaseDate_ASC = 'dropReleaseDate_ASC',
    dropReleaseDate_DESC = 'dropReleaseDate_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    name_ASC = 'name_ASC',
    name_DESC = 'name_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    spotifyMonthlyListeners_ASC = 'spotifyMonthlyListeners_ASC',
    spotifyMonthlyListeners_DESC = 'spotifyMonthlyListeners_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    username_ASC = 'username_ASC',
    username_DESC = 'username_DESC',
}

export type UpcomingDropUpdateInput = {
    dropReleaseDate?: InputMaybe<Scalars['Date']['input']>;
    image?: InputMaybe<AssetUpdateOneInlineInput>;
    name?: InputMaybe<Scalars['String']['input']>;
    spotifyMonthlyListeners?: InputMaybe<Scalars['Int']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type UpcomingDropUpdateManyInlineInput = {
    /** Connect multiple existing UpcomingDrop documents */
    connect?: InputMaybe<Array<UpcomingDropConnectInput>>;
    /** Create and connect multiple UpcomingDrop documents */
    create?: InputMaybe<Array<UpcomingDropCreateInput>>;
    /** Delete multiple UpcomingDrop documents */
    delete?: InputMaybe<Array<UpcomingDropWhereUniqueInput>>;
    /** Disconnect multiple UpcomingDrop documents */
    disconnect?: InputMaybe<Array<UpcomingDropWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing UpcomingDrop documents */
    set?: InputMaybe<Array<UpcomingDropWhereUniqueInput>>;
    /** Update multiple UpcomingDrop documents */
    update?: InputMaybe<Array<UpcomingDropUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple UpcomingDrop documents */
    upsert?: InputMaybe<Array<UpcomingDropUpsertWithNestedWhereUniqueInput>>;
};

export type UpcomingDropUpdateManyInput = {
    dropReleaseDate?: InputMaybe<Scalars['Date']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    spotifyMonthlyListeners?: InputMaybe<Scalars['Int']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type UpcomingDropUpdateManyWithNestedWhereInput = {
    /** Update many input */
    data: UpcomingDropUpdateManyInput;
    /** Document search */
    where: UpcomingDropWhereInput;
};

export type UpcomingDropUpdateOneInlineInput = {
    /** Connect existing UpcomingDrop document */
    connect?: InputMaybe<UpcomingDropWhereUniqueInput>;
    /** Create and connect one UpcomingDrop document */
    create?: InputMaybe<UpcomingDropCreateInput>;
    /** Delete currently connected UpcomingDrop document */
    delete?: InputMaybe<Scalars['Boolean']['input']>;
    /** Disconnect currently connected UpcomingDrop document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
    /** Update single UpcomingDrop document */
    update?: InputMaybe<UpcomingDropUpdateWithNestedWhereUniqueInput>;
    /** Upsert single UpcomingDrop document */
    upsert?: InputMaybe<UpcomingDropUpsertWithNestedWhereUniqueInput>;
};

export type UpcomingDropUpdateWithNestedWhereUniqueInput = {
    /** Document to update */
    data: UpcomingDropUpdateInput;
    /** Unique document search */
    where: UpcomingDropWhereUniqueInput;
};

export type UpcomingDropUpsertInput = {
    /** Create document if it didn't exist */
    create: UpcomingDropCreateInput;
    /** Update document if it exists */
    update: UpcomingDropUpdateInput;
};

export type UpcomingDropUpsertWithNestedWhereUniqueInput = {
    /** Upsert data */
    data: UpcomingDropUpsertInput;
    /** Unique document search */
    where: UpcomingDropWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type UpcomingDropWhereComparatorInput = {
    /** This field can be used to request to check if the entry is outdated by internal comparison */
    outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type UpcomingDropWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<UpcomingDropWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<UpcomingDropWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<UpcomingDropWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    createdBy?: InputMaybe<UserWhereInput>;
    documentInStages_every?: InputMaybe<UpcomingDropWhereStageInput>;
    documentInStages_none?: InputMaybe<UpcomingDropWhereStageInput>;
    documentInStages_some?: InputMaybe<UpcomingDropWhereStageInput>;
    dropReleaseDate?: InputMaybe<Scalars['Date']['input']>;
    /** All values greater than the given value. */
    dropReleaseDate_gt?: InputMaybe<Scalars['Date']['input']>;
    /** All values greater than or equal the given value. */
    dropReleaseDate_gte?: InputMaybe<Scalars['Date']['input']>;
    /** All values that are contained in given list. */
    dropReleaseDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
    /** All values less than the given value. */
    dropReleaseDate_lt?: InputMaybe<Scalars['Date']['input']>;
    /** All values less than or equal the given value. */
    dropReleaseDate_lte?: InputMaybe<Scalars['Date']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    dropReleaseDate_not?: InputMaybe<Scalars['Date']['input']>;
    /** All values that are not contained in given list. */
    dropReleaseDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    image?: InputMaybe<AssetWhereInput>;
    name?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    name_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    name_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    name_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    name_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    name_starts_with?: InputMaybe<Scalars['String']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    publishedBy?: InputMaybe<UserWhereInput>;
    scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
    scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
    spotifyMonthlyListeners?: InputMaybe<Scalars['Int']['input']>;
    /** All values greater than the given value. */
    spotifyMonthlyListeners_gt?: InputMaybe<Scalars['Int']['input']>;
    /** All values greater than or equal the given value. */
    spotifyMonthlyListeners_gte?: InputMaybe<Scalars['Int']['input']>;
    /** All values that are contained in given list. */
    spotifyMonthlyListeners_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
    /** All values less than the given value. */
    spotifyMonthlyListeners_lt?: InputMaybe<Scalars['Int']['input']>;
    /** All values less than or equal the given value. */
    spotifyMonthlyListeners_lte?: InputMaybe<Scalars['Int']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    spotifyMonthlyListeners_not?: InputMaybe<Scalars['Int']['input']>;
    /** All values that are not contained in given list. */
    spotifyMonthlyListeners_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedBy?: InputMaybe<UserWhereInput>;
    username?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    username_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    username_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    username_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    username_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    username_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    username_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    username_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    username_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    username_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UpcomingDropWhereStageInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<UpcomingDropWhereStageInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<UpcomingDropWhereStageInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<UpcomingDropWhereStageInput>>;
    /** This field contains fields which can be set as true or false to specify an internal comparison */
    compareWithParent?: InputMaybe<UpcomingDropWhereComparatorInput>;
    /** Specify the stage to compare with */
    stage?: InputMaybe<Stage>;
};

/** References UpcomingDrop record uniquely */
export type UpcomingDropWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

/** User system model */
export type User = Entity &
    Node & {
        __typename?: 'User';
        /** The time the document was created */
        createdAt: Scalars['DateTime']['output'];
        /** Get the document in other stages */
        documentInStages: Array<User>;
        /** The unique identifier */
        id: Scalars['ID']['output'];
        /** Flag to determine if user is active or not */
        isActive: Scalars['Boolean']['output'];
        /** User Kind. Can be either MEMBER, PAT or PUBLIC */
        kind: UserKind;
        /** The username */
        name: Scalars['String']['output'];
        /** Profile Picture url */
        picture?: Maybe<Scalars['String']['output']>;
        /** The time the document was published. Null on documents in draft stage. */
        publishedAt?: Maybe<Scalars['DateTime']['output']>;
        /** System stage field */
        stage: Stage;
        /** The time the document was updated */
        updatedAt: Scalars['DateTime']['output'];
    };

/** User system model */
export type UserDocumentInStagesArgs = {
    includeCurrent?: Scalars['Boolean']['input'];
    inheritLocale?: Scalars['Boolean']['input'];
    stages?: Array<Stage>;
};

export type UserConnectInput = {
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Document to connect */
    where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
    __typename?: 'UserConnection';
    aggregate: Aggregate;
    /** A list of edges. */
    edges: Array<UserEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
    /** Connect multiple existing User documents */
    connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
    /** Connect one existing User document */
    connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
    __typename?: 'UserEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node: User;
};

/** System User Kind */
export enum UserKind {
    MEMBER = 'MEMBER',
    PAT = 'PAT',
    PUBLIC = 'PUBLIC',
    WEBHOOK = 'WEBHOOK',
}

/** Identifies documents */
export type UserManyWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<UserWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<UserWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<UserWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    documentInStages_every?: InputMaybe<UserWhereStageInput>;
    documentInStages_none?: InputMaybe<UserWhereStageInput>;
    documentInStages_some?: InputMaybe<UserWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
    kind?: InputMaybe<UserKind>;
    /** All values that are contained in given list. */
    kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
    /** Any other value that exists and is not equal to the given value. */
    kind_not?: InputMaybe<UserKind>;
    /** All values that are not contained in given list. */
    kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
    name?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    name_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    name_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    name_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    name_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    name_starts_with?: InputMaybe<Scalars['String']['input']>;
    picture?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    picture_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    picture_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    picture_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    picture_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    picture_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    picture_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    picture_starts_with?: InputMaybe<Scalars['String']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export enum UserOrderByInput {
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    isActive_ASC = 'isActive_ASC',
    isActive_DESC = 'isActive_DESC',
    kind_ASC = 'kind_ASC',
    kind_DESC = 'kind_DESC',
    name_ASC = 'name_ASC',
    name_DESC = 'name_DESC',
    picture_ASC = 'picture_ASC',
    picture_DESC = 'picture_DESC',
    publishedAt_ASC = 'publishedAt_ASC',
    publishedAt_DESC = 'publishedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
}

export type UserUpdateManyInlineInput = {
    /** Connect multiple existing User documents */
    connect?: InputMaybe<Array<UserConnectInput>>;
    /** Disconnect multiple User documents */
    disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
    /** Override currently-connected documents with multiple existing User documents */
    set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
    /** Connect existing User document */
    connect?: InputMaybe<UserWhereUniqueInput>;
    /** Disconnect currently connected User document */
    disconnect?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
    /** This field can be used to request to check if the entry is outdated by internal comparison */
    outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type UserWhereInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<UserWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<UserWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<UserWhereInput>>;
    /** Contains search across all appropriate fields. */
    _search?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    documentInStages_every?: InputMaybe<UserWhereStageInput>;
    documentInStages_none?: InputMaybe<UserWhereStageInput>;
    documentInStages_some?: InputMaybe<UserWhereStageInput>;
    id?: InputMaybe<Scalars['ID']['input']>;
    /** All values containing the given string. */
    id_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values ending with the given string. */
    id_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are contained in given list. */
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    id_not?: InputMaybe<Scalars['ID']['input']>;
    /** All values not containing the given string. */
    id_not_contains?: InputMaybe<Scalars['ID']['input']>;
    /** All values not ending with the given string */
    id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values that are not contained in given list. */
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    /** All values not starting with the given string. */
    id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
    /** All values starting with the given string. */
    id_starts_with?: InputMaybe<Scalars['ID']['input']>;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
    kind?: InputMaybe<UserKind>;
    /** All values that are contained in given list. */
    kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
    /** Any other value that exists and is not equal to the given value. */
    kind_not?: InputMaybe<UserKind>;
    /** All values that are not contained in given list. */
    kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
    name?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    name_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    name_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    name_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    name_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    name_starts_with?: InputMaybe<Scalars['String']['input']>;
    picture?: InputMaybe<Scalars['String']['input']>;
    /** All values containing the given string. */
    picture_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values ending with the given string. */
    picture_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are contained in given list. */
    picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** Any other value that exists and is not equal to the given value. */
    picture_not?: InputMaybe<Scalars['String']['input']>;
    /** All values not containing the given string. */
    picture_not_contains?: InputMaybe<Scalars['String']['input']>;
    /** All values not ending with the given string */
    picture_not_ends_with?: InputMaybe<Scalars['String']['input']>;
    /** All values that are not contained in given list. */
    picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    /** All values not starting with the given string. */
    picture_not_starts_with?: InputMaybe<Scalars['String']['input']>;
    /** All values starting with the given string. */
    picture_starts_with?: InputMaybe<Scalars['String']['input']>;
    publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than the given value. */
    updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are contained in given list. */
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
    /** All values less than the given value. */
    updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
    /** Any other value that exists and is not equal to the given value. */
    updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
    /** Logical AND on all given filters. */
    AND?: InputMaybe<Array<UserWhereStageInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: InputMaybe<Array<UserWhereStageInput>>;
    /** Logical OR on all given filters. */
    OR?: InputMaybe<Array<UserWhereStageInput>>;
    /** This field contains fields which can be set as true or false to specify an internal comparison */
    compareWithParent?: InputMaybe<UserWhereComparatorInput>;
    /** Specify the stage to compare with */
    stage?: InputMaybe<Stage>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type Version = {
    __typename?: 'Version';
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    revision: Scalars['Int']['output'];
    stage: Stage;
};

export type VersionWhereInput = {
    id: Scalars['ID']['input'];
    revision: Scalars['Int']['input'];
    stage: Stage;
};

export enum _FilterKind {
    AND = 'AND',
    NOT = 'NOT',
    OR = 'OR',
    contains = 'contains',
    contains_all = 'contains_all',
    contains_none = 'contains_none',
    contains_some = 'contains_some',
    ends_with = 'ends_with',
    eq = 'eq',
    eq_not = 'eq_not',
    gt = 'gt',
    gte = 'gte',
    in = 'in',
    json_path_exists = 'json_path_exists',
    json_value_recursive = 'json_value_recursive',
    lt = 'lt',
    lte = 'lte',
    not_contains = 'not_contains',
    not_ends_with = 'not_ends_with',
    not_in = 'not_in',
    not_starts_with = 'not_starts_with',
    relational_every = 'relational_every',
    relational_none = 'relational_none',
    relational_single = 'relational_single',
    relational_some = 'relational_some',
    search = 'search',
    starts_with = 'starts_with',
    union_empty = 'union_empty',
    union_every = 'union_every',
    union_none = 'union_none',
    union_single = 'union_single',
    union_some = 'union_some',
}

export enum _MutationInputFieldKind {
    enum = 'enum',
    relation = 'relation',
    richText = 'richText',
    richTextWithEmbeds = 'richTextWithEmbeds',
    scalar = 'scalar',
    union = 'union',
    virtual = 'virtual',
}

export enum _MutationKind {
    create = 'create',
    delete = 'delete',
    deleteMany = 'deleteMany',
    publish = 'publish',
    publishMany = 'publishMany',
    schedulePublish = 'schedulePublish',
    scheduleUnpublish = 'scheduleUnpublish',
    unpublish = 'unpublish',
    unpublishMany = 'unpublishMany',
    update = 'update',
    updateMany = 'updateMany',
    upsert = 'upsert',
}

export enum _OrderDirection {
    asc = 'asc',
    desc = 'desc',
}

export enum _RelationInputCardinality {
    many = 'many',
    one = 'one',
}

export enum _RelationInputKind {
    create = 'create',
    update = 'update',
}

export enum _RelationKind {
    regular = 'regular',
    union = 'union',
}

export enum _SystemDateTimeFieldVariation {
    base = 'base',
    combined = 'combined',
    localization = 'localization',
}

export type GetPartnersQueryVariables = Exact<{
    orderBy?: InputMaybe<PartnerOrderByInput>;
}>;

export type GetPartnersQuery = {
    __typename?: 'Query';
    partners: Array<{ __typename?: 'Partner'; name: string; image: { __typename?: 'Asset'; url: string } }>;
};

export type PartnerSummaryFragment = {
    __typename?: 'Partner';
    name: string;
    image: { __typename?: 'Asset'; url: string };
};

export type GetSimplePagesQueryVariables = Exact<{
    slug: Scalars['String']['input'];
}>;

export type GetSimplePagesQuery = {
    __typename?: 'Query';
    simplePage?: {
        __typename?: 'SimplePage';
        title: string;
        content?: { __typename?: 'RichText'; html: string } | null;
    } | null;
};

export type SimplePagesSummaryFragment = {
    __typename?: 'SimplePage';
    title: string;
    content?: { __typename?: 'RichText'; html: string } | null;
};

export type GetUpcomingDropsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUpcomingDropsQuery = {
    __typename?: 'Query';
    upcomingDrops: Array<{
        __typename?: 'UpcomingDrop';
        name: string;
        username?: string | null;
        dropReleaseDate?: any | null;
        spotifyMonthlyListeners?: number | null;
        image: { __typename?: 'Asset'; url: string };
    }>;
};

export type UpcomingDropsSummaryFragment = {
    __typename?: 'UpcomingDrop';
    name: string;
    username?: string | null;
    dropReleaseDate?: any | null;
    spotifyMonthlyListeners?: number | null;
    image: { __typename?: 'Asset'; url: string };
};

export const PartnerSummaryFragmentDoc = gql`
    fragment PartnerSummary on Partner {
        image {
            url
        }
        name
    }
`;
export const SimplePagesSummaryFragmentDoc = gql`
    fragment SimplePagesSummary on SimplePage {
        title
        content {
            html
        }
    }
`;
export const UpcomingDropsSummaryFragmentDoc = gql`
    fragment UpcomingDropsSummary on UpcomingDrop {
        name
        username
        dropReleaseDate
        spotifyMonthlyListeners
        image {
            url
        }
    }
`;
export const GetPartnersDocument = gql`
    query GetPartners($orderBy: PartnerOrderByInput) {
        partners(orderBy: $orderBy) {
            ...PartnerSummary
        }
    }
    ${PartnerSummaryFragmentDoc}
`;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPartnersQuery(
    baseOptions?: Apollo.QueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
}
export function useGetPartnersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
}
export function useGetPartnersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
}
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<typeof useGetPartnersLazyQuery>;
export type GetPartnersSuspenseQueryHookResult = ReturnType<typeof useGetPartnersSuspenseQuery>;
export type GetPartnersQueryResult = Apollo.QueryResult<GetPartnersQuery, GetPartnersQueryVariables>;
export const GetSimplePagesDocument = gql`
    query GetSimplePages($slug: String!) {
        simplePage(where: { slug: $slug }) {
            ...SimplePagesSummary
        }
    }
    ${SimplePagesSummaryFragmentDoc}
`;

/**
 * __useGetSimplePagesQuery__
 *
 * To run a query within a React component, call `useGetSimplePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimplePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimplePagesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSimplePagesQuery(
    baseOptions: Apollo.QueryHookOptions<GetSimplePagesQuery, GetSimplePagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetSimplePagesQuery, GetSimplePagesQueryVariables>(GetSimplePagesDocument, options);
}
export function useGetSimplePagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSimplePagesQuery, GetSimplePagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetSimplePagesQuery, GetSimplePagesQueryVariables>(GetSimplePagesDocument, options);
}
export function useGetSimplePagesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetSimplePagesQuery, GetSimplePagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetSimplePagesQuery, GetSimplePagesQueryVariables>(GetSimplePagesDocument, options);
}
export type GetSimplePagesQueryHookResult = ReturnType<typeof useGetSimplePagesQuery>;
export type GetSimplePagesLazyQueryHookResult = ReturnType<typeof useGetSimplePagesLazyQuery>;
export type GetSimplePagesSuspenseQueryHookResult = ReturnType<typeof useGetSimplePagesSuspenseQuery>;
export type GetSimplePagesQueryResult = Apollo.QueryResult<GetSimplePagesQuery, GetSimplePagesQueryVariables>;
export const GetUpcomingDropsDocument = gql`
    query GetUpcomingDrops {
        upcomingDrops {
            ...UpcomingDropsSummary
        }
    }
    ${UpcomingDropsSummaryFragmentDoc}
`;

/**
 * __useGetUpcomingDropsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingDropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingDropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingDropsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpcomingDropsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetUpcomingDropsQuery, GetUpcomingDropsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUpcomingDropsQuery, GetUpcomingDropsQueryVariables>(GetUpcomingDropsDocument, options);
}
export function useGetUpcomingDropsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUpcomingDropsQuery, GetUpcomingDropsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUpcomingDropsQuery, GetUpcomingDropsQueryVariables>(
        GetUpcomingDropsDocument,
        options,
    );
}
export function useGetUpcomingDropsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetUpcomingDropsQuery, GetUpcomingDropsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetUpcomingDropsQuery, GetUpcomingDropsQueryVariables>(
        GetUpcomingDropsDocument,
        options,
    );
}
export type GetUpcomingDropsQueryHookResult = ReturnType<typeof useGetUpcomingDropsQuery>;
export type GetUpcomingDropsLazyQueryHookResult = ReturnType<typeof useGetUpcomingDropsLazyQuery>;
export type GetUpcomingDropsSuspenseQueryHookResult = ReturnType<typeof useGetUpcomingDropsSuspenseQuery>;
export type GetUpcomingDropsQueryResult = Apollo.QueryResult<GetUpcomingDropsQuery, GetUpcomingDropsQueryVariables>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        Entity: [
            'Ad',
            'Asset',
            'Partner',
            'ScheduledOperation',
            'ScheduledRelease',
            'SimplePage',
            'UpcomingDrop',
            'User',
        ],
        Node: [
            'Ad',
            'Asset',
            'Partner',
            'ScheduledOperation',
            'ScheduledRelease',
            'SimplePage',
            'UpcomingDrop',
            'User',
        ],
        ScheduledOperationAffectedDocument: ['Ad', 'Asset', 'Partner', 'SimplePage', 'UpcomingDrop'],
    },
};
export default result;
