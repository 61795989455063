import React from 'react';
import Image from 'next/image';
import checkIcon from 'assets/icons/check.svg';

import { useTranslation } from '@srnade/web/i18n/client';
import { Typography } from '@srnade/component-ui';
import { Link } from '@srnade/web/components';

type EditionOwnerBannerProps = {
    collectionUrl: string;
    className?: string;
    preOrderProduct: boolean;
};

export const EditionOwnerBanner: React.FC<EditionOwnerBannerProps> = ({
    collectionUrl,
    className,
    preOrderProduct,
}) => {
    const { t } = useTranslation('components', { keyPrefix: 'editionOwnerBanner' });

    return (
        <div
            className={`flex flex-row bg-white px-[2rem] py-[1rem] items-start rounded-[1rem] drop-shadow-xl ${className}`}
        >
            <Image
                className="mt-[0.2rem]"
                src={checkIcon}
                alt="check"
                width={16}
                height={16}
                priority={true}
                unoptimized
            />
            <div className="flex flex-col mx-[1rem]">
                <Typography className="mb-[0.5rem]" variant="bodyBold">
                    {t('title')}
                </Typography>
                {preOrderProduct && (
                    <Typography className="mb-[1rem]" variant="body">
                        {t('message')}
                    </Typography>
                )}
                <Link href={collectionUrl} passHref legacyBehavior>
                    <Typography className="underline" variant="body">
                        {t('viewCollection')}
                    </Typography>
                </Link>
            </div>
        </div>
    );
};
