import { gql } from '@apollo/client';

/**
 * Mutations
 */

export const CREATE_OTHER = gql`
    mutation CreateOther($input: CreateOtherInput!) {
        other: createOther(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
            }
        }
    }
`;

export const GET_MANY_OTHERS = gql`
    query GetManyOthers($input: GetManyOthersInput!) {
        getManyOthers(input: $input) {
            id
            url
            file {
                fileType
                originalFilename
                size
                url
            }
        }
    }
`;
