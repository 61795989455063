// TODO duplicated from common
export enum SocialNetwork {
    OpenSea = 'OpenSea',
    Website = 'Website',
    TikTok = 'TikTok',
    YouTube = 'YouTube',
    Discord = 'Discord',
    Instagram = 'Instagram',
    Twitter = 'Twitter',
    Apple = 'Apple',
    Spotify = 'Spotify',
}

export type Socials = Partial<Record<keyof typeof SocialNetwork, string>>;
