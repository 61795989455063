import { ImageLoaderProps } from 'next/image';

// The Image Resizer CDN is an open source AWS serverless tool that has been deployed
// to Cloudfront. It uses [Thumbor](https://thumbor.readthedocs.io) under the hood,
// and the source code can be found [here](https://github.com/aws-solutions/serverless-image-handler).
const cdnUrl = process.env.NEXT_PUBLIC_IMAGE_HANDLING_CDN;

if (!cdnUrl) {
    console.warn('NEXT_PUBLIC_IMAGE_HANDLING_CDN missing from environment variables!');
}

// Generate a data blur placeholder using RGB (146,232,230) (https://png-pixel.com/)
export const imageHandlerBlur =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPcsX17PQAG/AKnRWDi/AAAAABJRU5ErkJggg==';

/**
 * Transform an asset URL into an Image Resizer CDN URL
 * @param { src, width, height} ImageLoaderProps
 * @returns
 */
const resizeS3Url = ({ src, width, quality }: ImageLoaderProps) => {
    // Match any URL, breaking it into component parts - the base URL and the URI.
    // An example is https://d29kvl9qivp35s.cloudfront.net/images/c8faa3eb-448d-4a79-8041-e62f7c24a463.png
    // If there is a match:
    // [0] is the full url, e.g: https://d29kvl9qivp35s.cloudfront.net/images/c8faa3eb-448d-4a79-8041-e62f7c24a463.png
    // [1] is everything after the base, e.g: /images/c8faa3eb-448d-4a79-8041-e62f7c24a463.png

    let url = src;
    const matches = url.match(/^https?:\/\/[^\/]+(\/.+)$/);

    if (matches && matches.length >= 2) {
        const transformations: string[] = [];

        if (width) {
            transformations.push(`/fit-in/${width}x0`);
        }
        if (quality) {
            transformations.push(`/filters:quality(${quality})`);
        }

        url = `${cdnUrl}${transformations.join('')}${matches[1]}`;
    }

    return url;
};

/**
 * Custom image handler for NextJS
 * @param { src, width, height} ImageLoaderProps
 * @returns Transformed URL
 */
export const imageHandlerLoader = ({ src, width, quality = 75 }: ImageLoaderProps) => {
    // Our Image Resizer CDN only handles images that have been uploaded to our S3 buckets.
    // If the asset doesn't live on S3 or the URL has already been transformed then don't transform it.
    const fromCdn = src.includes('cloudfront.net') || src.includes('amazonaws.com');
    const transformed = src.includes('/fit-in/') || src.includes('/filters:/') || src.includes(`/${width}x0/`);

    let url = encodeURI(src);

    if (fromCdn && !transformed) {
        url = resizeS3Url({ src, width, quality });
    }

    return `${url}?w=${width}&q=${quality}`; // add width or w to so nextjs is happy
};
